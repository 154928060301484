import styled from 'styled-components';
import { Link } from 'dva/router';
import { Pagination } from 'antd';

const DMED_OrbitBlue = '#0072ED';
export const StyledLink = styled(Link)`
  :focus-visible {
    h6 {
      outline: 4px solid ${DMED_OrbitBlue};
    }
  }
`;

export const StyledPagination = styled(Pagination)`
  padding-top: 10px;
  float: right;
`;
