import styled from 'styled-components';

export const AssetBoxWrapper = styled.div`
  padding: 20px;
  background-color: #e6faff;
`;

export const LoadingTipWrapper = styled.div`
  text-align: center;
`;
