import { useMemo } from 'react';
import { VDMS, DSS, CAS } from '../constants';

const DEFAULT_SUPPLY_SOURCE_CONTENT_DETAILS = {
  contentId: '-',
  contentAssetId: '-',
  contentPartnerIdentifier: '-',
  contentGenre: '-',
  contentLen: '-',
  contentLiveStream: '-',
  contentEXTChannel: '-',
  contentSeries: '-',
  distributor: '-',
};

const vdmsSupplySourceContentDetails = (data) => ({
  type: VDMS,
  contentId: data?.content?.id,
  contentGenre: data?.content?.genre,
  contentLen: data?.content?.len,
  contentLiveStream: data?.content?.livestream,
  contentEXTChannel: data?.content?.ext?.channel,
});

const dssSupplySourceContentDetails = (data) => ({
  type: DSS,
  contentId: data?.app?.content?.id,
  contentGenre: data?.app?.content?.genre,
  contentLen: data?.app?.content?.len,
  contentLiveStream: data?.app?.content?.livestream,
  contentEXTChannel: data?.app?.content?.ext?.channel,
});

const casSupplySourceContentDetails = (data) => {
  const contentDataV2 = data?.['content-info'];
  const contentPartnerIdentifierV2 = [
    contentDataV2?.['content-partner-id'],
    contentDataV2?.['content-partner-identifier'],
  ].join(' ');

  const contentDataV3 = data?.content;
  const contentPartnerIdentifierV3 = contentDataV3?.['content-partner'];
  return {
    type: CAS,
    contentId: contentDataV2?.['asset-id'],
    contentAssetId: contentDataV3?.alid,
    contentPartnerIdentifier: contentPartnerIdentifierV2 || contentPartnerIdentifierV3,
    contentGenre: contentDataV2?.genres || contentDataV3?.genres,
    contentSeries: contentDataV2?.series || contentDataV3?.['series-name'],
    contentLiveStream: contentDataV2?.vdm || contentDataV3?.['playback-type'],
    contentEXTChannel: contentDataV2?.channel,
    distributor: contentDataV2?.distributor || contentDataV3?.distributor,
  };
};

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceContentDetails,
  [DSS]: dssSupplySourceContentDetails,
  [CAS]: casSupplySourceContentDetails,
};

const resolveSupplySourceContentDetails = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_CONTENT_DETAILS;
};

export const useSupplySourceContentDetailsResolver = (type, data) => {
  return useMemo(() => resolveSupplySourceContentDetails(type, data), [type, data]);
};
