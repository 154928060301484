import clone from 'clone-deep';
import { notification } from 'antd';
import { RESERVATION_TOOL, RESERVATION_TOOL_TABS, SUCCESS } from '../../constants';
import {
  getReservedSeriesAudits,
  searchContentPartners,
  searchSeriesByCpMonth,
  submitReservations,
} from '../../services/reservations';
import { getSelectedTabSelector, pathParamsSelector } from '../app';

const initState = {
  [RESERVATION_TOOL]: {
    audits: [],
    selectedTime: null,
    contentPartnerData: {
      selected: null,
      options: [],
    },
    seriesData: {
      numReservationsAllowed: 0,
      selected: [],
      options: [],
    },
  },
  auditWidths: [],
};

export const reservationToolDataSelector = ({ reservationTool }) => reservationTool[RESERVATION_TOOL];

export const reservationTool = {
  namespace: 'reservationTool',
  state: initState,
  reducers: {
    updateContentPartnerOptions(state, { payload: { contentPartners, toolType } }) {
      const newState = clone(state);
      newState[toolType].contentPartnerData.options = contentPartners.map((contentPartner) => {
        return { value: contentPartner?.['content-partner-id'], label: contentPartner?.['content-partner-name'] };
      });
      return newState;
    },
    updateAudits(state, { payload: { audits, toolType } }) {
      const newState = clone(state);
      newState[toolType].audits = audits;
      return newState;
    },
    updateAuditWidths(state, { payload: auditWidths }) {
      return { ...state, auditWidths };
    },
    updateSeriesSelection(state, { payload: { series, toolType } }) {
      const newState = clone(state);
      if (toolType === RESERVATION_TOOL) {
        newState[toolType].seriesData.selected = series;
      }
      return newState;
    },
    updateSeriesData(state, { payload: { seriesData, toolType } }) {
      const newState = clone(state);
      if (toolType === RESERVATION_TOOL) {
        newState[toolType].seriesData.numReservationsAllowed = seriesData['num-reservations-allowed'];
        newState[toolType].seriesData.selected = seriesData['reserved-series-ids'];
        newState[toolType].seriesData.options = seriesData['all-series'].map((series) => {
          return { value: series?.['id'], label: series?.['series-name'] };
        });
      }
      return newState;
    },
    updateSelectedContentPartner(state, { payload: { contentPartner, toolType } }) {
      const newState = clone(state);
      newState[toolType].contentPartnerData.selected = contentPartner;
      return newState;
    },
    updateSelectedTime(state, { payload: { time, toolType } }) {
      const newState = clone(state);
      newState[toolType].selectedTime = time;
      return newState;
    },
    resetState(state, { payload: toolType }) {
      return { ...state, [toolType]: initState[toolType] };
    },
  },
  effects: {
    *pageInit(emptyPayload, { call, put, select }) {
      const toolType = yield select(getSelectedTabSelector);
      const { tab } = yield select(pathParamsSelector);

      if (tab === RESERVATION_TOOL_TABS.VIEW_AUDIT_TRAIL) {
        const auditsResp = yield call(getReservedSeriesAudits);
        if (auditsResp?.status === SUCCESS && auditsResp?.data) {
          yield put({
            type: 'updateAudits',
            payload: { audits: auditsResp.data, toolType },
          });
        }
      } else {
        // "RESERVE SERIES" is the default sub tab in Reservation Tool
        const cpResp = yield call(searchContentPartners, { allowReservations: true });
        if (cpResp?.status === SUCCESS && cpResp?.data) {
          yield put({
            type: 'updateContentPartnerOptions',
            payload: { contentPartners: cpResp.data, toolType },
          });
        }
      }
    },
    *selectContentPartner({ payload: { toolType, contentPartner } }, { put }) {
      yield put({
        type: 'updateSelectedContentPartner',
        payload: { toolType, contentPartner },
      });
    },
    *nextStep({ payload: { step, toolType } }, { call, put, select }) {
      let isRequestValid;
      let invalidRequestMessage;
      let isCurrentStepValid;
      const selections = toolType === RESERVATION_TOOL ? yield select(reservationToolDataSelector) : {};

      switch (step) {
        case 0: {
          isRequestValid = selections?.contentPartnerData.selected !== null;
          invalidRequestMessage = 'Content partner not selected';

          if (toolType === RESERVATION_TOOL && isRequestValid) {
            // Reservation tool flow
            isCurrentStepValid = true;
          }

          break;
        }
        case 1: {
          // Reservation tool "Choose Month" flow
          if (toolType === RESERVATION_TOOL) {
            isRequestValid = selections?.selectedTime !== null;
            invalidRequestMessage = 'Month not selected';

            if (isRequestValid) {
              const {
                contentPartnerData: { selected: selectedContentPartnerId },
                selectedTime,
              } = selections;
              const resp = yield call(searchSeriesByCpMonth, selectedContentPartnerId, getMonth(selectedTime));
              if (resp?.status === SUCCESS && resp?.data) {
                yield put({
                  type: 'updateSeriesData',
                  payload: {
                    toolType: toolType,
                    seriesData: resp.data,
                  },
                });
                isCurrentStepValid = true;
              }
            }
          }
          break;
        }
        case 2: {
          // Reservation tool "Select Series" flow
          if (toolType === RESERVATION_TOOL) {
            const {
              contentPartnerData: { selected: selectedContentPartnerId },
              selectedTime,
              seriesData: { selected: selectedSeriesIds, numReservationsAllowed },
            } = selections;

            // to be consistent with the legacy tool, we use the first date of month to represent the selected month
            const selectedMonth = getMonth(selectedTime);
            isRequestValid = selectedSeriesIds.length <= numReservationsAllowed;
            if (isRequestValid) {
              const resp = yield call(submitReservations, selectedContentPartnerId, selectedMonth, selectedSeriesIds);
              if (resp?.status === SUCCESS) {
                yield call(notification.success, {
                  message: 'Reservation made!',
                  duration: 3,
                });
                yield put({
                  type: 'resetState',
                  payload: toolType,
                });
                yield put({
                  type: 'pageInit',
                });
                isCurrentStepValid = true;
              }
            } else {
              invalidRequestMessage = `Too many series selected, only ${numReservationsAllowed} allowed`;
            }
          }
          break;
        }
        default:
          isRequestValid = false;
          invalidRequestMessage = '';
          isCurrentStepValid = false;
      }

      if (!isRequestValid) {
        yield call(notification.error, {
          message: 'Validation Error',
          description: invalidRequestMessage,
          duration: 3,
        });
      }

      return isCurrentStepValid;
    },
  },
};

const getMonth = (time) => {
  return time.clone().format('YYYY-MM');
};
