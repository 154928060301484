import { GET, RBAC_API } from '../constants';
import { jwtTokenHeader, request } from '../utils';

export const getContentPartnerGroups = async () =>
  request(
    GET,
    `${RBAC_API}/v1/content-partner-groups`,
    null,
    await jwtTokenHeader(),
    'Getting content partner groups failed.'
  ).catch((err) => {
    console.error('Getting content partner groups failed ' + err);
  });
