import styled from 'styled-components';
import { Collapse } from 'antd';

const DMED_OrbitBlue = '#0072ED';

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header:focus-visible {
    outline: 4px solid ${DMED_OrbitBlue} !important;
  }
  .ant-checkbox-wrapper:focus-within {
    outline: 4px solid ${DMED_OrbitBlue};
  }
`;
