import React from 'react';

import { Table } from 'ant-table-extensions';

import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';

import { auctionDetailsColumns } from '../../Templates/columns';
import { AUCTION_SNAPSHOT_TABLE_DETAIL } from '../../../testUtils';

const detailTableBodyBgColor = '#f9fafb';

const StyledDetailTable = styled(Table)`
  thead > tr > th {
    background: ${colors.colorWhite};
    padding: 0 16px;
    height: 32px;
  }
  tbody > tr > td {
    border-bottom: none;
    background: ${detailTableBodyBgColor};
  }
  tr > td:first-child {
    padding: 0 32px;
  }
  tr > td:nth-child(2) {
    padding: 0px;
  }
  .ant-table-cell-row-hover {
    cursor: pointer;
    background: ${colors.colorGray05}!important;
  }
  tr.target-auction > td {
    background: ${colors.colorBlue05};
  }
`;

const DetailTable = ({ dataSource, searchValue }) => (
  <StyledDetailTable
    rowKey={({ id }) => id}
    data-testid={AUCTION_SNAPSHOT_TABLE_DETAIL}
    dataSource={dataSource}
    columns={auctionDetailsColumns}
    pagination={false}
    onRow={({ externalAuctionId }) => ({
      className:
        externalAuctionId?.toLowerCase()?.trim()?.includes(searchValue?.toLowerCase()?.trim()) && 'target-auction',
    })}
  />
);

export default DetailTable;
