import React, { useEffect, useState } from 'react';
import { connect } from 'dva';
import { Spin } from 'antd';

import { Typography } from '@hulu/react-style-components';

import signinImage from './assets/images/signin.png';
import { AUTH_DELAY_INTERVAL_MILLIS } from '../../constants';
import { CenterText, CenterTextBox } from '../Templates/CenterText';
import { Image } from '../Templates/Image';
import { DividedBox, Divider, DividerWrapper } from './styles';
import envConfig from '@hulu/env-config';
import SignUpForm from './SignUpForm';
import { StyledButton } from '../../styles';

const Authentication = ({ dispatch, authenticated, children, loading }) => {
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);

  const handleSignUp = async (values) => {
    await dispatch({ type: 'app/signUp', payload: values });
    setIsSignUpModalVisible(false);
  };

  const checkAuth = () => {
    dispatch({ type: 'app/checkAuth', payload: false });
  };

  useEffect(() => {
    /* This is a short-term solution to redirect Ad Tools users to start using the new domain in production.
       Eventually, the old domain will be removed completely. */
    if (
      envConfig.REACT_APP_COGNITO_REDIRECT === 'https://adtools.app.digital.disneyadvertising.com' &&
      window.location.hostname !== 'adtools.app.digital.disneyadvertising.com'
    ) {
      window.location.replace('https://adtools.app.digital.disneyadvertising.com/');
    }

    const backgroundCheckAuth = setInterval(() => {
      checkAuth();
    }, AUTH_DELAY_INTERVAL_MILLIS);
    return function cleanup() {
      clearInterval(backgroundCheckAuth);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UI Component for Signing in or up
  const loginView = (
    <Spin spinning={loading}>
      <CenterTextBox>
        <CenterText>
          <Image src={signinImage} />
          <Typography variant="h1">You're not signed in</Typography>
          <DividerWrapper>
            <DividedBox>
              <Typography variant="body1">If you have an account, sign in here</Typography>
              <StyledButton
                type="primary"
                onClick={() => {
                  dispatch({ type: 'app/checkAuth', payload: true });
                }}
              >
                Log in
              </StyledButton>
            </DividedBox>
            <Divider />
            <DividedBox>
              <Typography variant="body1">If you don't have an account, request one here</Typography>
              <>
                <StyledButton type="primary" onClick={() => setIsSignUpModalVisible(true)}>
                  Sign Up
                </StyledButton>
                <SignUpForm
                  visible={isSignUpModalVisible}
                  onCancel={() => setIsSignUpModalVisible(false)}
                  onSignUp={handleSignUp}
                />
              </>
            </DividedBox>
          </DividerWrapper>
        </CenterText>
      </CenterTextBox>
    </Spin>
  );

  // Inspect state if authenticated otherwise show login component
  return authenticated ? children : loginView;
};

function mapStateToProps(state) {
  return {
    authenticated: state.app.authenticated,
    permissions: state.app.currentUser.permisssions,
    loading: state.loading.effects['app/checkAuth'],
  };
}

export default connect(mapStateToProps)(Authentication);
