import { notification } from 'antd';
import moment from 'moment';
import { routerRedux } from 'dva/router';

import { createTestCase, getLinkAccounts, linkAccount, linkHuluAccount, unlinkAccount } from '../../services/adsTracer';
import { ADS_TRACER } from '../../constants';
import { arrToMap, dedupArr } from '../../utils';
import { DISNEY_PLUS_PUBLISHER } from '../../pages/AdsTracer/constants';

export const accountProfileCombinationsSelector = ({ newTestCase }) => newTestCase.accountProfileCombinations;

export const initState = {
  accountProfileCombinations: [],
};

export const newTestCase = {
  namespace: 'newTestCase',
  state: initState,
  reducers: {
    updateAccountProfileCombinations(state, { payload: accountProfileCombinations }) {
      return { ...state, accountProfileCombinations };
    },
    clearState() {
      return initState;
    },
  },
  effects: {
    *pageInit(emptyPayload, { put }) {
      yield put({ type: 'clearState' });
      yield put({ type: 'fetchLinkedHuluAccounts' });
    },
    *fetchLinkedHuluAccounts(emptyPayload, { call, put }) {
      const resp = yield call(getLinkAccounts);

      // Received Linked Accounts
      if (resp?.status === 200 && resp?.data) {
        let accountProfileCombinations = [];
        resp.data.forEach(({ id: accountId, email, 'first-name': accountName, subscription, profiles }) => {
          profiles.forEach(({ id: profileId, name: profileName }) => {
            accountProfileCombinations.push({
              key: accountId + '#' + profileId,
              accountId,
              email,
              accountName,
              subscription,
              profileId,
              profileName,
            });
          });
        });

        yield put({ type: 'updateAccountProfileCombinations', payload: accountProfileCombinations });
      } else if (resp?.status === 204) {
        yield put({ type: 'updateAccountProfileCombinations', payload: [] });
      }
    },
    *verifySelectedAccounts({ payload: { targetKeys, currentStep } }, { call, put, select }) {
      const hasSelectedAccountsForNewTestCases = targetKeys.length === 0;

      // No accounts selected, throw validation message
      if (hasSelectedAccountsForNewTestCases) {
        yield call(notification.error, {
          message: 'Validation Error',
          description: 'Please select at least one account-profile for testing',
          duration: 3,
        });
        return false;
      }
      return true;
    },
    *createNewTestCase({ payload: { targetKeys, endAt } }, { call, put, select }) {
      const isExpirationTimeValid = endAt > moment();

      // Validate expiration time has been set
      if (isExpirationTimeValid) {
        const profiles = yield select(accountProfileCombinationsSelector);

        const newTestCases = profiles
          .filter(({ accountId, profileId }) => targetKeys.includes(accountId + '#' + profileId))
          .map((profile) => ({
            'account-id': profile.accountId.toString(),
            'profile-id': profile.profileId.toString(),
            'end-time': endAt,
          }));

        // Call Ads Tracer to create the new test case
        const res = yield call(createTestCase, { 'test-cases': newTestCases });
        if (res?.data) {
          yield put(routerRedux.push('/' + ADS_TRACER));
          yield put({ type: 'clearState' });
          yield call(notification.success, {
            message: 'New Test Case(s) Created',
            duration: 3,
          });
        }

        // Invalid Expiration time
      } else {
        yield call(notification.error, {
          message: 'Validation Error',
          description: 'Please select a valid expiration time for the test case',
          duration: 3,
        });
      }
    },
    *addHuluAccount({ payload: userId }, { call, put }) {
      const linkAccountResult = yield call(linkHuluAccount, userId);

      // Link Hulu Account with Ads Tracer
      if (linkAccountResult?.data?.email) {
        yield put({ type: 'fetchLinkedHuluAccounts' });
        yield call(notification.success, {
          message: 'Account Linked',
          description: `User ${linkAccountResult.data.email} account linked`,
          duration: 5,
        });

        return true;
      }
      return false;
    },
    *addDisneyAccount({ payload: accountId, profileId, nickname }, { call, put }) {
      const linkAccountResult = yield call(linkAccount, accountId, [profileId], nickname, DISNEY_PLUS_PUBLISHER);

      if (linkAccountResult?.data) {
        yield put({ type: 'fetchLinkedHuluAccounts' });
        yield call(notification.success, {
          message: 'Account Linked',
          description: `Account ${linkAccountResult.data['first-name']} linked`,
          duration: 5,
        });
        return true;
      }
      return false;
    },
    *removeAccount({ payload: selectedProfilesToRemove }, { call, put, select }) {
      const fetchedLinkedAccounts = yield select(accountProfileCombinationsSelector);

      const mappedAccounts = arrToMap(fetchedLinkedAccounts, 'profileId');
      let ids = [];
      selectedProfilesToRemove.sourceSelectedKeys.forEach((profileId) => {
        if (mappedAccounts[profileId]) {
          ids.push(mappedAccounts[profileId].accountId);
        }
      });
      const accountIds = dedupArr(ids);

      for (const id of accountIds) {
        (yield call(unlinkAccount, id)) &&
          (yield put({ type: 'fetchLinkedHuluAccounts' })) &&
          (yield call(notification.success, {
            message: 'Account unlink successful',
            description: `User ${id} account unlinked`,
            duration: 5,
          }));
      }
    },
  },
};
