import { DEFAULT_MIN_WIDTH } from '../../constants';
import { Table } from 'ant-table-extensions';
import { SearchOutlined } from '@ant-design/icons';
import { ResizableHeader } from '../../utils';

export const ResizableTable = (props) => {
  const handleResize =
    (key) =>
    (_, { size }) => {
      const newWidths = props.inputColumns.reduce((acc, obj) => {
        const { key, width } = obj;
        acc[key] = width;
        return acc;
      }, {});
      newWidths[key] = size.width;
      props.widthHandler(newWidths);
    };
  /* eslint-enable */

  const reconcileColumns = props.inputColumns.map((col) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      minWidth: column.minWidth ? column.minWidth : DEFAULT_MIN_WIDTH,
      onResize: handleResize(col.key),
      onRow: column.onRow,
    }),
  }));

  return (
    <Table
      components={{
        header: {
          cell: ResizableHeader,
        },
      }}
      searchableProps={
        props.searchableProps === undefined && {
          fuzzySearch: false,
          inputProps: {
            placeholder: 'Search in table...',
            prefix: <SearchOutlined />,
          },
        }
      }
      className={props.className}
      dataSource={props.dataSource}
      columns={reconcileColumns}
      onChange={props.changeHandler}
      scroll={props.scroll}
      pagination={props.pagination}
      data-testid={props.testId}
      rowClassName={props.rowClassName}
      rowSelection={props.rowSelection}
      onRow={props.onRow}
      expandable={props.expandable}
      exportableProps={props.exportableProps}
      refresh={props.refresh}
    />
  );
};
