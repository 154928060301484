import React from 'react';
import { Collapse, Divider, Alert, Skeleton, Drawer } from 'antd';
import { IconButton, Typography } from '@hulu/react-style-components';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';

import { AuctionErrorBoundary } from '../AuctionErrorBoundary';
import { useAdTagsParameterSummaryResolver } from './useAdTagsParameterSummaryResolver';

const { Panel } = Collapse;

const StyledCloseButton = styled(IconButton)`
  width: auto;
  height: auto;
  border: none;
  margin: 0 0 0 auto;
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow: hidden;

    .ant-drawer-close {
      padding: 0;
    }
  }
  .ant-drawer-header {
    padding: 25px 20px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .drawer-title-text {
      font-weight: normal;
      white-space: pre-wrap;
    }
  }
  .ant-drawer-body {
    padding: 50px 20px 0;
  }

  .ant-collapse-content-box {
    padding: 0 0 24px 0;
  }
`;
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  .expand-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0;
  }
  .expand-actions-link {
    padding-left: 5px;
    text-decoration: none;
    font-weight: bold;
  }
`;
const StyledCollapse = styled(Collapse)`
  border-width: 1px 0 0 0;
  .ant-collapse-item {
    .ant-collapse-header {
      height: 40px;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .ant-divider {
    margin: 0;
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
    }
    .ant-collapse-content-box > p {
      padding-left: 32px;
      line-height: 32px;
    }
  }
  pre {
    margin: 0;
  }
`;
const StyledTypography = styled.div`
  padding: 10px 0;
  font-size: 12px;
`;
const StyledDivider = styled(Divider)`
  border-color: ${colors.colorGray13};
`;
const StyledSnapshotSummaryRowNumber = styled.span`
  font-weight: 600;
`;

export const AdTagsParameterSummary = ({
  isOpen,
  onClose,
  data,
  isLoading,
  error,
  activePanel,
  onCollapsePanelChange,
  onExpandClick,
  onCollapseClick,
}) => {
  const { name, rawData, supplySource } = data;

  const values = useAdTagsParameterSummaryResolver(supplySource, rawData);

  return (
    <StyledDrawer
      placement="right"
      open={isOpen}
      onClose={onClose}
      destroyOnClose
      title={
        <span>
          Ad Tags Parameter Summary: <span className="drawer-title-text">{isLoading ? 'loading...' : name}</span>
        </span>
      }
      width={600}
      closeIcon={<StyledCloseButton variant="outlined" icon="IconClose" />}
    >
      <AuctionErrorBoundary>
        {error ? (
          <Alert type="error" message={error} />
        ) : values && Object.keys(values).length === 0 && !isLoading ? (
          <Alert
            showIcon
            closable
            type="warning"
            message={`Ad Tags Parameter Summary for ${name} is not available.`}
            description="Please try to check later."
          />
        ) : (
          <StyledContent>
            <Skeleton paragraph={{ rows: 13 }} loading={isLoading}>
              <div className="expand-actions">
                <Typography variant="link3" className="expand-actions-link" onClick={onExpandClick}>
                  Expand All
                </Typography>
                <span>&nbsp;/ </span>
                <Typography variant="link3" className="expand-actions-link" onClick={onCollapseClick}>
                  Collapse All
                </Typography>
              </div>
              <div>
                <StyledCollapse activeKey={activePanel} onChange={onCollapsePanelChange}>
                  {values &&
                    Object.keys(values).map((key) => (
                      <Panel header={key} key={key}>
                        {values[key].map((tagParam, i) => (
                          <div key={`${key}-${tagParam}-${i}`}>
                            <StyledTypography>
                              <pre style={{ whiteSpace: 'pre-wrap' }}>
                                <StyledSnapshotSummaryRowNumber>{i + 1}</StyledSnapshotSummaryRowNumber> :{' '}
                                {JSON.stringify(tagParam, null, 2) || 'No Data'}
                              </pre>
                            </StyledTypography>
                            {i < values[key].length - 1 ? <StyledDivider type="horizontal" /> : null}
                          </div>
                        ))}
                      </Panel>
                    ))}
                </StyledCollapse>
              </div>
            </Skeleton>
          </StyledContent>
        )}
      </AuctionErrorBoundary>
    </StyledDrawer>
  );
};
