// Vast Tracker Counts
export const EVENT_TYPE = 'event-type';
export const OCCURRENCE = 'occurrence';

// Vast Tracker Event Types
export const FAILURE = 'failure';

// Vast Tracker Request
export const TEN_TIMES_PER_ITEM = 10;

export const DATE_FORMAT = 'YYYY-MM-DD';
