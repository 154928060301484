import { POST, PUT, Q_CHECK_API } from '../constants';
import { jwtTokenHeader, request } from '../utils';

export const saveMediaFile = async (reqBody) => {
  const headers = await jwtTokenHeader({ 'Content-Type': 'multipart/form-data' });
  headers.data = {};
  return request(POST, `${Q_CHECK_API}/v1/media-file/upload`, reqBody, headers, 'Saving media file failed.').catch(
    (err) => {
      console.error('Saving media file failed. ' + err);
    }
  );
};

export const postMediaFile = async (reqBody) =>
  request(
    POST,
    `${Q_CHECK_API}/v1/mediafile`,
    reqBody,
    await jwtTokenHeader(),
    'Validating media file failed.',
    false
  ).catch((err) => {
    console.error('Validating media file failed. ' + err);
    throw err;
  });

export const postVastXml = async (reqBody) =>
  request(
    POST,
    `${Q_CHECK_API}/v1/vasts/xmls`,
    reqBody,
    await jwtTokenHeader(),
    'Validating VAST XML failed.',
    false
  ).catch((err) => {
    console.error('Validating VAST XML failed. ' + err);
    throw err;
  });
export const postVastUrl = async (reqBody) =>
  request(
    POST,
    `${Q_CHECK_API}/v1/vasts/urls`,
    reqBody,
    await jwtTokenHeader(),
    'Validating VAST URL failed.',
    false
  ).catch((err) => {
    console.error('Validating VAST URL failed. ' + err);
    throw err;
  });
export const ingestVastXml = async (reqBody) =>
  request(PUT, `${Q_CHECK_API}/v1/vasts/ingest`, reqBody, await jwtTokenHeader(), 'Ingesting VAST XML failed.').catch(
    (err) => {
      console.error('Ingesting VAST XML failed. ' + err);
    }
  );
