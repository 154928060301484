import React, { useEffect } from 'react';
import { Select } from 'antd';
import { colors } from '@hulu/design-tokens';
import styled from 'styled-components';

const SelectContainer = styled.div`
  margin: 22px 0 0 32px;
`;
const StyledHeader = styled.div`
  font-weight: 500;
`;
const StyledSelect = styled(Select)`
  width: 360px;
  height: 40px;
  border-color: ${colors.colorGray30};
  color: ${colors.colorBlack50};
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    border-radius: 4px !important;
  }
`;
const StyledTooltip = styled.div`
  font-size: 12px;
  color: ${colors.colorBlack4};
`;

const { Option } = Select;

export const IntegratorDataSelector = ({ data, handleTargetId, targetId, name }) => {
  /**
   * set target index from the available list of target indexes for the specific demand integrator
   * in case when the target index didn't set yet or the target index is not available for this demand integrator
   */
  useEffect(() => {
    if (!targetId || !data?.some(({ id }) => id === targetId)) {
      handleTargetId(data?.[0]?.id);
    }
  }, [data, handleTargetId, targetId]);
  return (
    <SelectContainer>
      <StyledHeader>{name}</StyledHeader>
      <StyledSelect
        value={targetId}
        getPopupContainer={(trigger) => trigger.parentNode}
        onChange={(value) => handleTargetId(value)}
        disabled={data?.some(({ id }) => !id)}
      >
        {data.map(({ id }) => (
          <Option key={id} value={id}>
            {name} {id}
          </Option>
        ))}
      </StyledSelect>
      <StyledTooltip>Choose a {name} to view the specific request and response below</StyledTooltip>
    </SelectContainer>
  );
};
