import { useMemo } from 'react';
import { timestampIntegrators } from '../../timestampIntegrators';
import { REQUEST, RESPONSE, CREATED_TIMESTAMP } from '../../constants';

const INITIAL_STATE = {
  summary: {},
  requestParams: {},
  xmlResponse: null,
  magnitePods: [],
  error: null,
};

export const useMagniteIntegrator = (data, pods) => {
  const request = data?.[REQUEST]?.record?.rawData;
  const response = data?.[RESPONSE]?.record?.rawData;
  const time = timestampIntegrators(data?.[RESPONSE]?.record?.[CREATED_TIMESTAMP]);
  const integratorPods = data?.demandPods?.length ? data?.demandPods : pods;

  const { summary, requestParams, xmlResponse, magnitePods, error } = useMemo(() => {
    try {
      const url = new URL(request);
      const params = Object.fromEntries(url.searchParams.entries());
      return {
        summary: {
          timestamp: time,
          endpoint: request,
        },
        requestParams: params ?? {},
        xmlResponse: response ?? null,
        magnitePods: integratorPods ?? [],
      };
    } catch (err) {
      return { ...INITIAL_STATE, error: err.message || err };
    }
  }, [request, response, time, integratorPods]);

  return {
    summary,
    requestParams,
    xmlResponse,
    magnitePods,
    error,
  };
};
