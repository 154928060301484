import React from 'react';
import { Route, routerRedux } from 'dva/router';
import { connect } from 'dva';
import { NOT_FOUND_PATH, VIEW } from '../constants';

const SecureRoute = ({ dispatch, tool, permissions, component: Component, ...rest }) => {
  return (
    // Show the component only when the user has access
    // Otherwise, redirect user to /404 page
    <Route {...rest} render={(props) => <Component {...props} permissions={permissions} />} />
  );
};

function mapStateToProps(state) {
  return {
    permissions: state.app.currentUser.permissions,
  };
}

export default connect(mapStateToProps)(SecureRoute);
