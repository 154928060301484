import React from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'dva';
import { Button, Col, Row, Spin, Switch } from 'antd';
import { Typography } from '@hulu/react-style-components';
import styled from 'styled-components';

import { COUNT_TYPES, TOP_NUM_SELECTED, VIEW } from '../../../constants';
import { kebabCaseToSentenceCase } from '../../../utils';
import {
  VASTTRACKER_BARCHART,
  VASTTRACKER_BARCHART_TITLE,
  VASTTRACKER_BARCHART_TOPNUMSUFFIX,
} from '../../../testUtils';
import { ALL_AD_UNIT_IDS } from '../../../models/constants';
import { STATUS_COLORS, STATUS_NAMES } from '../constants';

const BAR_HEIGHT = 110;

export const ButtonWrapper = ({ children, ...rest }) => <StyledButtons {...rest}>{children}</StyledButtons>;
const StyledButtons = styled(Button)`
  margin: 0 10px;
`;

const ALLOWED_EVENT_TYPES = new Set([
  STATUS_NAMES.SUCCESS,
  STATUS_NAMES.UNSUPPORTED_FORMAT,
  STATUS_NAMES.UNSUPPORTED_FORMAT_VPAID,
  STATUS_NAMES.UNSUPPORTED_NON_SSL,
  STATUS_NAMES.UNPARSEABLE_DOCUMENT,
  STATUS_NAMES.UNPARSEABLE_DOCUMENT_NULL_VAST,
  STATUS_NAMES.UNPARSEABLE_DOCUMENT_PARSE_FAIL,
  STATUS_NAMES.INVALID_DOCUMENT,
  STATUS_NAMES.INVALID_DOCUMENT_NO_CREATIVES,
  STATUS_NAMES.INVALID_DOCUMENT_INVALID_IMPRESSION_URL,
  STATUS_NAMES.INVALID_DOCUMENT_NO_TRACKING_EVENTS,
  STATUS_NAMES.INVALID_DOCUMENT_NO_VIDEO_CLICKS,
  STATUS_NAMES.MISSING_ASSET,
  STATUS_NAMES.MISSING_ASSET_EXTERNAL_AD,
  STATUS_NAMES.MISSING_ASSET_VAST_AD,
  STATUS_NAMES.INTERNAL_ERROR,
  STATUS_NAMES.INTERNAL_ERROR_INLINE_VAST_PROCESS,
]);

const BarChart = ({
  dispatch,
  permissions,
  loading,
  filters = [],
  selectedCounts = [],
  topNumSelected,
  countTypeSelected = 'N/A',
  startDate = 'N/A',
  endDate = 'N/A',
  dailyViewEnabled,
}) => {
  let labels = [];
  let statuses = {};

  selectedCounts.forEach(({ value, counts }, index) => {
    labels.push(value);
    counts.forEach(({ 'event-type': eventType, occurrence }) => {
      if (!ALLOWED_EVENT_TYPES.has(eventType)) return;
      if (!(eventType in statuses)) statuses[eventType] = [];
      statuses[eventType][index] = occurrence;
    });
  });

  const data = Object.entries(statuses).map(([statusName, countNumbers]) => ({
    label: statusName,
    data: countNumbers.map((val) => val ?? 0),
    backgroundColor: STATUS_COLORS[statusName],
  }));

  const totals = data.reduce((acc, row) => {
    row.data.forEach((value, index) => {
      acc[index] = (acc[index] || 0) + (value || 0);
    });
    return acc;
  }, []);

  const options = {
    responsive: true,
    scales: {
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      x: {
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label;
            const value = context.parsed.y;
            const total = totals[context.dataIndex];
            const percentage = total === 0 ? 'N/A' : ((value / total) * 100).toFixed(2) + '%';
            return `${label}: ${value} (${percentage})`;
          },
        },
      },
    },
  };

  const setDailyViewEnabled = (dailyViewEnabled) => {
    dispatch({
      type: 'vastTracker/setDailyViewEnabled',
      payload: { type: countTypeSelected, dailyViewEnabled },
    });
  };

  const topNumButtons = Object.entries(TOP_NUM_SELECTED).map(([_, num]) => (
    <ButtonWrapper
      style={topNumSelected === num ? { backgroundColor: '#0072ed' } : {}}
      key={`top_${num}`}
      type="primary"
      shape="round"
      size="small"
      onClick={() => {
        dispatch({ type: 'vastTracker/updateTopNumSelected', payload: num });
        dispatch({ type: 'vastTracker/pageInit' });
      }}
      data-testid={VASTTRACKER_BARCHART_TOPNUMSUFFIX + '_' + num}
    >
      {`Top ${num}`}
    </ButtonWrapper>
  ));

  const getTitleForBarChart = () => {
    return filters.length > 0
      ? `Failure Counts from ${startDate} to ${endDate}`
      : `Top ${topNumSelected} ${kebabCaseToSentenceCase(
          countTypeSelected
        )} by Failure Counts from ${startDate} to ${endDate}`;
  };

  return (
    <Spin spinning={loading}>
      <Row justify="space-between">
        {countTypeSelected !== COUNT_TYPES.ADUNITID || permissions.can(VIEW, ALL_AD_UNIT_IDS) ? (
          <>
            <Col>
              <Typography style={{ color: '#090699' }} variant="h5" data-testid={VASTTRACKER_BARCHART_TITLE}>
                {getTitleForBarChart()}
              </Typography>
            </Col>
            <Col span={6}>{filters.length > 0 ? null : topNumButtons}</Col>
            <Col span={4} align="right">
              <Typography variant="h8">Daily View</Typography>
              <Switch
                style={{ marginLeft: 5 }}
                checkedChildren="On"
                unCheckedChildren="Off"
                defaultChecked={dailyViewEnabled}
                onChange={setDailyViewEnabled}
              />
            </Col>
          </>
        ) : (
          <Col span={14}>
            <Typography style={{ color: '#090699' }} variant="h5" data-testid={VASTTRACKER_BARCHART_TITLE}>
              To View Data, Filter by Ad Unit Id to View Failure Counts from {startDate} to {endDate}
            </Typography>
          </Col>
        )}
      </Row>
      <br />
      <Bar
        data={{ labels: labels, datasets: data }}
        options={options}
        height={BAR_HEIGHT}
        data-testid={VASTTRACKER_BARCHART}
      />
    </Spin>
  );
};

function mapStateToProps({
  app,
  vastTracker: { filters, counts, countTypeSelected, topNumSelected, startDate, endDate, dailyViewEnabled },
  loading,
}) {
  return {
    permissions: app.currentUser.permissions,
    loading: loading.effects['vastTracker/pageInit'],
    filters: filters[countTypeSelected]?.selected ?? [],
    selectedCounts: counts[countTypeSelected]?.items ?? [],
    topNumSelected: topNumSelected ?? 'N/A',
    countTypeSelected: countTypeSelected ?? 'N/A',
    startDate: startDate?.format('MM/DD/YYYY') ?? 'N/A',
    endDate: endDate?.format('MM/DD/YYYY') ?? 'N/A',
    dailyViewEnabled,
  };
}

export default connect(mapStateToProps)(BarChart);
