import { useState, useEffect, useRef } from 'react';
import { DEBOUNCE_DELAY } from './constants';

export const useDebouncedCallback = (fn) => {
  const [searchQuery, setSearchQuery] = useState('');

  const cb = useRef(fn);

  const handleSearchQuery = (value) => setSearchQuery(value);

  useEffect(() => {
    const handler = setTimeout((...args) => {
      cb.current(searchQuery);
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  return { searchQuery, handleSearchQuery };
};
