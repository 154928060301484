import React, { useEffect } from 'react';
import { Switch } from 'antd';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';

import { ADS_TRACER, EDIT, NEW_TEST_CASE, REFRESH_TEST_CASE_INTERVAL_MILLIS } from '../../../constants';
import {
  ButtonWrapper,
  HeaderWrapper,
  LeftSideHeaderWrapper,
  RefreshButtonWrapper,
  RightSideHeaderWrapper,
} from './styles';
import {
  ADSTRACER_HEADER_NEWTEST,
  ADSTRACER_HEADER_SHAREDTEST,
  ADSTRACER_HEADER_TOGGLEREFRESH,
  ADSTRACER_HEADER_REFRESH,
} from '../../../testUtils';

const Header = ({
  dispatch,
  autoRefresh,
  setAutoRefresh,
  setLoadingModalOpen,
  searchParams = {},
  pathParams = {},
  permissions,
}) => {
  const refresh = () => dispatch({ type: 'adsTracer/pageInit', payload: { pathParams } });
  const toggleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
    if (autoRefresh) {
      delete searchParams.alwaysRefresh;
    } else {
      searchParams.alwaysRefresh = true;
    }
    dispatch({
      type: 'app/setSearchParams',
      payload: searchParams,
    });
  };
  const handleSharedTestClick = () => {
    dispatch({
      type: 'app/setSearchParams',
      payload: { ...searchParams, showLoadTestCase: true },
    });
    setLoadingModalOpen(true);
  };

  useEffect(() => {
    const backgroundRefresh = setInterval(() => {
      if (autoRefresh) {
        refresh();
      }
    }, REFRESH_TEST_CASE_INTERVAL_MILLIS);
    return () => clearInterval(backgroundRefresh);
  });

  return (
    <HeaderWrapper>
      <LeftSideHeaderWrapper>
        <ButtonWrapper
          icon="IconAdd"
          onClick={() => dispatch(routerRedux.push('/' + NEW_TEST_CASE))}
          disabled={permissions.cannot(EDIT, ADS_TRACER)}
          data-testid={ADSTRACER_HEADER_NEWTEST}
        >
          New Test
        </ButtonWrapper>

        <ButtonWrapper onClick={handleSharedTestClick} data-testid={ADSTRACER_HEADER_SHAREDTEST}>
          Shared Test
        </ButtonWrapper>
      </LeftSideHeaderWrapper>

      <RightSideHeaderWrapper>
        <span style={{ marginRight: '5px' }}>Refresh every {REFRESH_TEST_CASE_INTERVAL_MILLIS / 1000}s</span>
        <Switch
          checkedChildren="ON"
          unCheckedChildren="OFF"
          checked={autoRefresh}
          onChange={toggleAutoRefresh}
          data-testid={ADSTRACER_HEADER_TOGGLEREFRESH}
        />

        <RefreshButtonWrapper icon="IconNotificationsDefault" onClick={refresh} data-testid={ADSTRACER_HEADER_REFRESH}>
          Refresh
        </RefreshButtonWrapper>
      </RightSideHeaderWrapper>
    </HeaderWrapper>
  );
};

function mapStateToProps({ app }) {
  return {
    pathParams: app.pathParams,
    searchParams: app.searchParams,
    permissions: app.currentUser.permissions,
  };
}

export default connect(mapStateToProps)(Header);
