import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { connect } from 'dva';
import { StyledButton, StyledModal } from '../../styles';

const FeedbackForm = ({ visible, onCancel, onSubmit, givenName, familyName, email }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setIsLoading(true); // Start loading
      await onSubmit(values);
      setIsLoading(false); // Stop loading
      form.resetFields();
    } catch (error) {
      setIsLoading(false); // Stop loading in case of an error
      console.error('Error:', error);
    }
  };

  const initialValues = {
    name: `${givenName} ${familyName}`,
    email: email,
  };

  return (
    <StyledModal
      open={visible}
      title="Ad Tools Feedback"
      onCancel={onCancel}
      footer={[
        <StyledButton key="back" onClick={onCancel}>
          Cancel
        </StyledButton>,
        <StyledButton key="submit" type="primary" onClick={handleOk} loading={isLoading}>
          Submit
        </StyledButton>,
      ]}
    >
      <Form form={form} layout="vertical" name="feedbackForm" initialValues={initialValues}>
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input your name' }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your email' },
            { type: 'email', message: 'Invalid email format' },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name="feedback" label="Feedback" rules={[{ required: true, message: 'Please input your feedback' }]}>
          <Input.TextArea style={{ resize: 'vertical', minHeight: '300px' }} />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    givenName: app.currentUser.givenName,
    familyName: app.currentUser.familyName,
    email: app.currentUser.email,
  };
};

export default connect(mapStateToProps)(FeedbackForm);
