export const FORM_FIELDS = {
  PROFILE_NAME: {
    label: 'Profile Name',
    name: 'label',
  },
  SELECT_TYPE: {
    label: 'Select Type',
    name: 'selectType',
  },
  INPUT_VALUE: {
    label: 'Input Value',
    name: 'inputValue',
  },
};
