import styled from 'styled-components';

export const Divider = styled.div`
  width: 2px;
  margin: 6px 0;
  background: black;
`;

export const DividedBox = styled.div`
  padding: 20px;
  flex: 0 1 auto;
`;

export const DividerWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 -8px;
`;
