import React, { useState } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Spin } from 'antd';
import { Tab, TabNavigation, TabPanel, Typography } from '@hulu/react-style-components';
import { ResizableTable } from '../../utils';

import {
  DEFAULT_Y_SCROLL,
  EDIT,
  RESERVATION_TOOL,
  RESERVATION_TOOL_TABS,
  RESERVATION_TOOL_TITLE,
} from '../../constants';
import ReserveSeries from './ReserveSeries';
import { ReservationToolWrapper } from './styles';
import {
  RESERVATION_TOOL_TAB_RESERVE_SERIES,
  RESERVATION_TOOL_TAB_VIEW_AUDIT_TRAIL,
  RESERVATION_TOOL_TABNAV,
} from '../../testUtils';
import { reservationAuditsColumns } from '../Templates/columns';
import { SearchOutlined } from '@ant-design/icons';

const ReservationTool = ({ dispatch, loading, pathParams, audits, permissions, auditWidths = [] }) => {
  const canReserve = permissions.can(EDIT, RESERVATION_TOOL);
  const [tab, setTab] = useState(
    pathParams?.tab ?? (canReserve ? RESERVATION_TOOL_TABS.RESERVE_SERIES : RESERVATION_TOOL_TABS.VIEW_AUDIT_TRAIL)
  );
  const handleWidthChange = (widths) =>
    dispatch({
      type: 'reservationTool/updateAuditWidths',
      payload: widths,
    });

  return (
    <ReservationToolWrapper>
      <Typography variant="h1" data-testid={RESERVATION_TOOL_TITLE}>
        {RESERVATION_TOOL_TITLE}
      </Typography>
      <br />
      <TabNavigation
        activeValue={tab}
        onTabClick={(_, selectedTab) => {
          setTab(selectedTab);
          dispatch(routerRedux.push('/' + RESERVATION_TOOL + '/' + selectedTab));
        }}
        data-testid={RESERVATION_TOOL_TABNAV}
      >
        {canReserve ? <Tab value={RESERVATION_TOOL_TABS.RESERVE_SERIES}>RESERVE SERIES</Tab> : null}
        <Tab value={RESERVATION_TOOL_TABS.VIEW_AUDIT_TRAIL}>VIEW AUDIT TRAIL</Tab>
      </TabNavigation>

      {/* Panel for reserving series */}
      <TabPanel value={RESERVATION_TOOL_TABS.RESERVE_SERIES} activeValue={tab}>
        <div data-testid={RESERVATION_TOOL_TAB_RESERVE_SERIES}>
          <ReserveSeries />
        </div>
      </TabPanel>

      {/* Panel for seeing audit trail via table */}
      <TabPanel value={RESERVATION_TOOL_TABS.VIEW_AUDIT_TRAIL} activeValue={tab}>
        <Spin spinning={loading}>
          <div data-testid={RESERVATION_TOOL_TAB_VIEW_AUDIT_TRAIL}>
            <ResizableTable
              inputColumns={reservationAuditsColumns(audits, auditWidths)}
              dataSource={audits}
              scroll={DEFAULT_Y_SCROLL}
              exportableProps
              widthHandler={handleWidthChange}
              searchableProps={{
                fuzzySearch: false,
                inputProps: {
                  placeholder: 'Search in table...',
                  prefix: <SearchOutlined />,
                },
              }}
            />
          </div>
        </Spin>
      </TabPanel>
    </ReservationToolWrapper>
  );
};

function mapStateToProps({ loading, app, reservationTool, permissions }) {
  return {
    loading: loading.effects['reservationTool/pageInit'],
    pathParams: app.pathParams,
    audits: reservationTool[RESERVATION_TOOL].audits,
    permissions: app.currentUser.permissions,
    auditWidths: reservationTool.auditWidths,
  };
}

export default connect(mapStateToProps)(ReservationTool);
