import { Button, Col, Modal, Row, Tabs, Collapse } from 'antd';
import React from 'react';
import { connect } from 'dva';
import Highlight from 'react-highlight';
import { Typography } from '@hulu/react-style-components';
import { DownloadOutlined } from '@ant-design/icons';

import 'highlight.js/styles/atom-one-light.css';
import { ADSTRACER_MODAL_TRACESPAN } from '../../../testUtils';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const MODAL_WIDTH = 1600;

const TraceSpansModal = ({ selectedTrace, traces = [], setSelectedTrace }) => {
  const open = selectedTrace != null;
  const trace = traces[selectedTrace];

  const hideTraceSpansModal = () => {
    setSelectedTrace(null);
  };

  const paneField = (header, text, key, adserver, highlightTextType = 'json') => (
    <Collapse>
      <Panel
        header={
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              <Typography variant="h6">{header}</Typography>
            </Col>
            <Col>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => {
                  const ref = document.createElement('a');
                  ref.href = URL.createObjectURL(new Blob([text]));
                  ref.download = `${adserver}_${header}.txt`;
                  ref.click();
                }}
              />
            </Col>
          </Row>
        }
        key={key}
      >
        <Highlight className={highlightTextType}>{text}</Highlight>
      </Panel>
    </Collapse>
  );

  const tabPanes = trace?.adsSpans
    // Uncomment when VIOLET-2386 done
    // .filter(({ adserver }) => abilities.can(ACCESS, adserver))
    .map(
      (
        {
          reqHeaders,
          reqBody,
          respHeaders,
          respBody,
          payloadType,
          spanMetadata,
          traceMetadata,
          adserver,
          reqUri,
          respStatus,
        },
        idx
      ) => (
        <TabPane tab={adserver} key={idx}>
          {paneField('Request URI', reqUri, `uri-${idx}`, adserver)}
          {paneField('Request Headers', reqHeaders, `reqh-${idx}`, adserver)}
          {paneField('Request Body', reqBody, `req-${idx}`, adserver, payloadType)}
          {paneField('Response Status Code', respStatus, `respstat-${idx}`, adserver)}
          {paneField('Response Headers', respHeaders, `resph-${idx}`, adserver)}
          {paneField('Response Body', respBody, `resp-${idx}`, adserver, payloadType)}
          {paneField('Trace Metadata', traceMetadata, `trace-data-${idx}`, adserver)}
          {paneField('Span Data', spanMetadata, `span-data-${idx}`, adserver)}
        </TabPane>
      )
    );

  return (
    <Modal
      title={<Typography variant="h3">Trace Spans by Ad Server Integrations</Typography>}
      open={open}
      footer={null}
      onCancel={hideTraceSpansModal}
      width={MODAL_WIDTH}
    >
      <Tabs data-testid={ADSTRACER_MODAL_TRACESPAN}>{tabPanes}</Tabs>
    </Modal>
  );
};

function mapStateToProps({ adsTracer }) {
  return {
    traces: adsTracer.traces,
  };
}

export default connect(mapStateToProps)(TraceSpansModal);
