import React from 'react';
import { colors } from '@hulu/design-tokens';

export const DeleteIcon = ({ onClick, disabled }) => {
  const color = disabled ? colors.colorGray13 : '#040405';
  return (
    <svg onClick={onClick} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51316 1.33829C5.59238 0.854868 6.01013 0.5 6.5 0.5H11.5C11.9899 0.5 12.4076 0.854868 12.4868 1.33829L12.8495 3.55128H17C17.5523 3.55128 18 3.999 18 4.55128C18 5.10357 17.5523 5.55128 17 5.55128H15.9309L14.9974 18.5715C14.9599 19.0947 14.5245 19.5 14 19.5H4C3.47546 19.5 3.04007 19.0947 3.00256 18.5715L2.06912 5.55128H1C0.447715 5.55128 0 5.10357 0 4.55128C0 3.999 0.447715 3.55128 1 3.55128H5.15053L5.51316 1.33829ZM7.1772 3.55128H10.8228L10.6505 2.5H7.34947L7.1772 3.55128ZM4.07426 5.55128L4.93088 17.5H13.0691L13.9257 5.55128H4.07426Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 8C8.05229 8 8.5 8.44772 8.5 9V14C8.5 14.5523 8.05229 15 7.5 15C6.94772 15 6.5 14.5523 6.5 14V9C6.5 8.44772 6.94772 8 7.5 8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 8C11.0523 8 11.5 8.44772 11.5 9V14C11.5 14.5523 11.0523 15 10.5 15C9.94771 15 9.5 14.5523 9.5 14V9C9.5 8.44772 9.94771 8 10.5 8Z"
        fill={color}
      />
    </svg>
  );
};
