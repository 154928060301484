import { connect } from 'dva';
import { useState } from 'react';

import {
  RULE_TYPE_CHANNEL,
  RULE_CHANNEL_ID,
  RULE_DURATIONS_MILLISECONDS,
  RULE_LAST_UPDATED,
  RULE_TYPE,
  RULE_SERIES_ID,
  RULE_LEAGUE_ID,
  RULE_STATUS,
  DISPLAY_STYLE_NONE,
  DISPLAY_STYLE_BLOCK,
  RULE_STATUS_ACTIVE,
  RULE_STATUS_DISABLED,
  RULE_ACTION_ENABLE,
  RULE_ACTION_DISABLE,
  RULE_SINGLE_PROGRAM,
  RULE_TYPE_SERIES,
  RULE_TYPE_LEAGUE,
  STREAM_TYPE,
  RULE_ID,
  STREAM_TYPE_LINEAR,
  RULE_TYPE_SPORTS,
  RULE_SPORTS_ID,
  RULE_TYPE_EVENT,
  RULE_EVENT_ID,
} from '../constants';
import { ButtonWrapper, EditButtonWrapper, RuleInfoContentWrapper, RuleInfoWrapper } from '../styles';
import { dateRender } from '../../../utils';
import { ruleStatusRender } from '../utils';
import EditModal from '../EditModal';
import {
  DURATION_MANAGER_RULEINFO,
  DURATION_MANAGER_RULEINFO_BUTTON,
  DURATION_MANAGER_RULEINFO_CONTENT,
} from '../../../testUtils';
import { PARTIAL_POD } from '../../../constants';
import { string } from 'prop-types';

const RuleInfo = ({ dispatch, durationRule, visible, isViewOnly }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleEditClick = () => {
    dispatch({ type: 'durationManager/fetchDurationRules' });
    setEditModalOpen(true);
  };

  const enableRule = () => {
    dispatch({
      type: 'durationManager/updateRuleStatus',
      payload: {
        path: durationRule[RULE_ID].split('#').join('%23'),
        reqBody: { status: RULE_STATUS_ACTIVE },
        action: RULE_ACTION_ENABLE,
      },
    });
  };

  const disableRule = () => {
    dispatch({
      type: 'durationManager/updateRuleStatus',
      payload: {
        path: durationRule[RULE_ID].split('#').join('%23'),
        reqBody: { status: RULE_STATUS_DISABLED },
        action: RULE_ACTION_DISABLE,
      },
    });
  };

  const deleteRule = () => {
    dispatch({
      type: 'durationManager/deleteRule',
      payload: { path: durationRule[RULE_ID].split('#').join('%23') },
    });
  };

  return visible && durationRule ? (
    <RuleInfoWrapper data-testid={DURATION_MANAGER_RULEINFO}>
      {/* Edit Rule Button */}
      <div data-testid={DURATION_MANAGER_RULEINFO_BUTTON}>
        <EditButtonWrapper disabled={isViewOnly} onClick={handleEditClick}>
          Edit Rule
        </EditButtonWrapper>
      </div>

      {/* Rule Title */}
      <h1>Rule Info</h1>
      <hr />

      {/* Rule Info */}
      <RuleInfoContentWrapper data-testid={DURATION_MANAGER_RULEINFO_CONTENT}>
        <p>
          <b>Stream Types:</b> {durationRule[STREAM_TYPE]}
        </p>
        <p>
          <b>Rule Types:</b> {durationRule[RULE_TYPE]}
        </p>
        <p
          style={{
            display: durationRule[STREAM_TYPE] === STREAM_TYPE_LINEAR ? DISPLAY_STYLE_BLOCK : DISPLAY_STYLE_NONE,
          }}
        >
          <b>Channel ID:</b> {durationRule[RULE_CHANNEL_ID]}
        </p>
        <p style={{ display: durationRule[RULE_TYPE] === RULE_TYPE_SERIES ? DISPLAY_STYLE_BLOCK : DISPLAY_STYLE_NONE }}>
          <b>Series ID:</b> {durationRule?.[RULE_SERIES_ID]}
        </p>
        <p style={{ display: durationRule[RULE_TYPE] === RULE_TYPE_LEAGUE ? DISPLAY_STYLE_BLOCK : DISPLAY_STYLE_NONE }}>
          <b>League ID:</b> {durationRule?.[RULE_LEAGUE_ID]}
        </p>
        <p style={{ display: durationRule[RULE_TYPE] === RULE_TYPE_SPORTS ? DISPLAY_STYLE_BLOCK : DISPLAY_STYLE_NONE }}>
          <b>Sports ID:</b> {durationRule?.[RULE_SPORTS_ID]}
        </p>
        <p style={{ display: durationRule[RULE_TYPE] === RULE_TYPE_EVENT ? DISPLAY_STYLE_BLOCK : DISPLAY_STYLE_NONE }}>
          <b>Event ID:</b> {durationRule?.[RULE_EVENT_ID]}
        </p>
        <p
          style={{ display: durationRule[RULE_TYPE] === RULE_TYPE_CHANNEL ? DISPLAY_STYLE_BLOCK : DISPLAY_STYLE_NONE }}
        >
          <b>Single Program:</b>{' '}
          {durationRule[RULE_SINGLE_PROGRAM] ? durationRule[RULE_SINGLE_PROGRAM].toString() : 'false'}
        </p>
        <p>
          <b>Partial Pods:</b> {durationRule[PARTIAL_POD] ? durationRule[PARTIAL_POD].toString() : 'false'}
        </p>
        <p>
          <b>Duration(s):</b> {durationRule[RULE_DURATIONS_MILLISECONDS].join(', ')}
        </p>
        <p>
          <b>Status:</b> {ruleStatusRender(durationRule[RULE_STATUS])}
        </p>
        <p>
          <b>Last Updated:</b> {dateRender(durationRule[RULE_LAST_UPDATED])}
        </p>
        {durationRule[RULE_STATUS] === RULE_STATUS_ACTIVE ? (
          <ButtonWrapper disabled={isViewOnly} onClick={disableRule}>
            Disable Rule
          </ButtonWrapper>
        ) : (
          <ButtonWrapper disabled={isViewOnly} onClick={enableRule}>
            Enable Rule
          </ButtonWrapper>
        )}
        <ButtonWrapper icon="IconDelete" disabled={isViewOnly} onClick={deleteRule}>
          Delete Rule
        </ButtonWrapper>
      </RuleInfoContentWrapper>
      {/* Modal for editing the rule*/}
      <EditModal open={editModalOpen} setEditModalOpen={setEditModalOpen} durationRule={durationRule} />
    </RuleInfoWrapper>
  ) : null;
};

export default connect()(RuleInfo);
