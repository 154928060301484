import { GET, POST, PUT, PATCH, DELETE, LIVEPOD_DURATION_MANAGER_API, CONFIG_MANAGER_API } from '../constants';
import { jwtTokenHeader, request } from '../utils';

export async function getAllRules() {
  return request(GET, `${LIVEPOD_DURATION_MANAGER_API}/v2/rules`, {}, await jwtTokenHeader(), 'Failed to fetch Rules');
}

export async function postRules(reqBody) {
  return request(
    POST,
    `${LIVEPOD_DURATION_MANAGER_API}/v2/rules`,
    reqBody,
    await jwtTokenHeader(),
    'Failed to post Rules'
  );
}

export async function updateRuleStatus(path, reqBody) {
  const headers = await jwtTokenHeader({ 'Content-Type': 'application/json-patch+json' });
  headers.data = {};
  return request(
    PATCH,
    `${LIVEPOD_DURATION_MANAGER_API}/v2/rules/${path}`,
    reqBody,
    headers,
    'Failed to update Source status'
  );
}

export async function deleteRule(path) {
  return request(
    DELETE,
    `${LIVEPOD_DURATION_MANAGER_API}/v2/rules/${path}`,
    {},
    await jwtTokenHeader(),
    'Failed to delete Rule'
  );
}
