import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';

const mockData = {
  podPosition: 12561,
  totalEligibleSlots: 12,
  totalDuration: 1100,
  slotsFilled: 8,
  podDurationFilled: 987,
  winningAdSource: ['Direct', 'PG', 'Magnite', 'DV360'],
};

export const SummaryCard = () => {
  return (
    <StyledCard title="Pod Summary">
      <RowInfo title="vPod Position" data={mockData.podPosition} />
      <RowInfo title="Total Eligible Slots" data={mockData.totalEligibleSlots} />
      <RowInfo title="Total Ad Pod Duration" data={mockData.totalDuration} />
      <RowInfo title="Slots Filled" data={mockData.slotsFilled} />
      <RowInfo title="Ad Pod Duration Filled" data={mockData.podDurationFilled} />
      <RowInfo title="Winning Ad Sources" data={mockData.winningAdSource.join(', ')} />
    </StyledCard>
  );
};
