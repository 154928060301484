/* eslint-disable */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import Attributes from './attributes';
import CdataElement from './cdata-el';
import CommentElement from './comment-el';
import InstructionElement from './instruction-el';
import TextElement from './text-el';

import styled from 'styled-components';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { colors } from "@hulu/design-tokens";

const StyledCollapseDiv = styled.div`
  padding: 3px 0;
  &.collapsible {
    position: relative;
    white-space: normal;
    padding: 3px 0 3px 24px;
    .inner > div {
      padding: 3px 0 3px 24px;
      position: relative;
      &:after {
        display: block;
        width: 1px;
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        background: ${colors.colorGray08};
      }
    }
    .anticon {
      font-size: 12px;
      color: ${colors.colorBlack50};
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }
`;

function getIndentationString(size) {
    return new Array(size + 1).join(" ");
}

function isTextElement(elements) {
    return elements.length === 1 && elements[0].type === "text";
}

function getSelectedText() {
    if (window.getSelection) {
        return window.getSelection().toString();
    } else if (document.selection) {
        return document.selection.createRange().text;
    }
    return '';
}

function onSelectText(e) {
    e.stopPropagation()
}

const Element = memo(({ name, elements, attributes, theme, indentation, indentSize, collapsible, isCollapseRequired }) => {
    if (!elements) return null;
    const [collapsed, toggleCollapse] = useState(false);
    const cursor = (collapsible && isCollapseRequired) ? 'pointer' : 'text';

    return (
        <StyledCollapseDiv
            style={{ cursor }}
            className={collapsible && isCollapseRequired ? 'collapsible' : ''}
            onClick={(event) => {
                if(!collapsible || !isCollapseRequired) return;
                event.stopPropagation();
                event.preventDefault();

                if(getSelectedText() === '') {
                    toggleCollapse(!collapsed);
                }
            }}
        >
            {
              collapsible && isCollapseRequired && !collapsed && (
                <CaretDownOutlined />
              )
            }
            {
              collapsible && isCollapseRequired && collapsed && (
                <CaretRightOutlined />
              )
            }
            <span style={{ color: theme.separatorColor }}>{`${indentation}<`}</span>
            <span style={{ color: theme.tagColor }}>{name}</span>
            {!collapsed && <Attributes attributes={attributes} theme={theme} /> }
            <span style={{ color: theme.separatorColor }}>{(elements ? '>' : '/>')}</span>
            {elements && !collapsed && <span className="inner" onClick={onSelectText}><Elements elements={elements} theme={theme} indentation={indentation + getIndentationString(indentSize)} indentSize={indentSize} collapsible={collapsible} /></span>}
            {elements && <span style={{ color: theme.separatorColor }}>{`${(isTextElement(elements) || collapsed) ? "" : indentation}</`}</span>}
            {elements && <span style={{ color: theme.tagColor }}>{name}</span>}
            {elements && <span style={{ color: theme.separatorColor }}>{">"}</span>}
        </StyledCollapseDiv>
    );
});

Element.propTypes = {
    name: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(PropTypes.object),
    attributes: PropTypes.object,
    theme: PropTypes.object.isRequired,
    indentation: PropTypes.string.isRequired,
    indentSize: PropTypes.number.isRequired,
    collapsible: PropTypes.bool.isRequired,
    isCollapseRequired: PropTypes.bool.isRequired,
}

const getElement = (theme, indentation, indentSize, collapsible) => (element, index) => {
    const hasChildElements = !!element?.elements?.length;
    const childElements = element?.elements;
    const isCollapseRequired = hasChildElements ? childElements[0].type === 'element' : false;

    switch (element.type) {
        case "text":
            return <TextElement key={`el-${index}`} text={element.text} theme={theme} />;
        case "element":
            return <Element key={`el-${index}`} name={element.name} elements={element.elements} attributes={element.attributes} theme={theme} indentation={indentation} indentSize={indentSize} collapsible={collapsible} isCollapseRequired={isCollapseRequired} />
        case "comment":
            return <CommentElement key={`el-${index}`} comment={element.comment} theme={theme} indentation={indentation} />;
        case "cdata":
            return <CdataElement key={`el-${index}`} cdata={element.cdata} theme={theme} indentation={indentation} />;
        case "instruction":
            return <InstructionElement key={`el-${index}`} instruction={element.instruction} name={element.name} theme={theme} indentation={indentation} />;
        default:
            return null;
    }
}

const Elements = memo(({ elements, theme, indentation, indentSize, collapsible }) => {
    return elements.map(getElement(theme, indentation, indentSize, collapsible));
});

Elements.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.object),
    theme: PropTypes.object.isRequired,
    indentation: PropTypes.string.isRequired,
    indentSize: PropTypes.number.isRequired,
    collapsible: PropTypes.bool.isRequired,
}

export default Elements;
