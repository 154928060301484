import React from 'react';
import { Table } from 'antd';
import { Typography } from '@hulu/react-style-components';
import styled from 'styled-components';
import { IconDelete, IconEdit } from '@hulu-react-style-components/icons';
import { colors } from '@hulu/design-tokens';
import { StyledPagination } from './style';

const { Column } = Table;

const StyledTableContainer = styled.div`
  margin-top: 12px;
`;
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    height: 40px;
    padding: 0 8px;
    background: ${colors.colorGray13};
  }
  .ant-table-thead > tr > th:before {
    display: none;
  }
  .ant-table-tbody > tr > td {
    height: 48px;
    padding: 0 8px;
  }
  .ant-table-tbody > tr > td:nth-child(-n + 2) {
    white-space: pre;
  }
`;
const StyledUsernameCellWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-shrink: 0;
  & > p {
    max-width: 124px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const StyledDeleteIcon = styled(IconDelete)`
  cursor: pointer;
  margin-left: auto;
`;
const StyledEditIcon = styled(IconEdit)`
  cursor: pointer;
  margin-left: auto;
`;
const StyledTableName = styled.span`
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 8px;
`;
const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const MIN_CONTENT_SIZE = 1;

const paginationItemRender = (current, type, originalElement, last) => {
  if (type === 'prev' && current !== 0) {
    return <span className="pagination-link">Previous</span>;
  }
  if (type === 'next' && !last) {
    return <span className="pagination-link">Next</span>;
  }
  if ((type === 'prev' && current === 0) || (type === 'next' && last)) {
    return <span />;
  }
  return originalElement;
};

export const ProfileTable = ({
  tableName,
  profiles,
  loading,
  isCurrentUser,
  pagination,
  onTableChange,
  onPaginationChange,
  onOpenUpdateProfile,
  onOpenProfileDeleteModal,
}) => {
  return (
    <StyledTableContainer>
      <StyledTableName>{tableName}</StyledTableName>
      <StyledTable
        dataSource={profiles.content}
        tableLayout="fixed"
        rowKey={(profile) => profile.id}
        pagination={false}
        loading={loading}
        showSorterTooltip={false}
        onChange={onTableChange}
      >
        <Column
          title="Profile Name"
          dataIndex="label"
          key="label"
          sorter={true}
          sortDirections={['ascend', 'descend']}
          ellipsis
          width="30%"
        />
        <Column title="Device ID" dataIndex="inputValue" ellipsis width="25%" />
        <Column title="ID Type" dataIndex={['selectType', 'label']} ellipsis width="20%" />
        {isCurrentUser ? (
          <Column
            render={(text, profile) => {
              return (
                <StyledUsernameCellWrapper>
                  <StyledEditIcon onClick={() => onOpenUpdateProfile(profile)} />
                  <StyledDeleteIcon onClick={() => onOpenProfileDeleteModal(profile)} />
                </StyledUsernameCellWrapper>
              );
            }}
            width="8%"
          />
        ) : (
          <Column
            title="Username"
            dataIndex="username"
            render={(text, profile) => {
              return (
                <StyledUsernameCellWrapper>
                  <Typography variant="body2">{`${profile.createdBy.firstName} ${profile.createdBy.secondName}`}</Typography>
                </StyledUsernameCellWrapper>
              );
            }}
            ellipsis
            width="25%"
          />
        )}
      </StyledTable>
      {profiles?.content?.length >= MIN_CONTENT_SIZE && (
        <StyledPaginationContainer>
          <StyledPagination
            size="small"
            defaultCurrent={1}
            current={pagination.page}
            total={profiles?.totalElements}
            pageSize={pagination.size}
            pageSizeOptions={['10', '25', '50', '75', '100']}
            itemRender={(current, type, originalElement) =>
              paginationItemRender(current, type, originalElement, profiles.last)
            }
            onChange={onPaginationChange}
            showSizeChanger
          />
        </StyledPaginationContainer>
      )}
    </StyledTableContainer>
  );
};
