import { MAGNITE, CAS, VAST, ROKU } from './AuctionSnapshotDetailedView/constants';

export const parseRawRecord = (rawRecord, supplyParameters) => {
  let record = null;
  let error = null;
  const { source, dataType, rawData, metadata, rawDataKey } = rawRecord;
  const parsedMetaData = metadata?.reduce((obj, { key, value }) => Object.assign(obj, { [key]: value }), {});
  try {
    const decoded = atob(rawData);
    const parsedRawData =
      source === MAGNITE || (supplyParameters && supplyParameters !== CAS && dataType === VAST && source === ROKU)
        ? decoded
        : JSON.parse(decoded);
    record = { rawData: parsedRawData, rawDataKey: rawDataKey, ...parsedMetaData };
  } catch (err) {
    error = `[ERROR]: parsing base64 record for ${source} and ${dataType} - ${err.message || err}`;
    console.error(error);
  }
  return { record, error };
};
