import React, { createRef, useRef, useEffect, useState } from 'react';
import { AccountMenu, Button, ProfileMenu } from '@hulu/react-style-components';
import { connect } from 'dva';
import styled from 'styled-components';

export const StyledProfileMenu = styled(ProfileMenu)`
  width: inherit;
`;

const AccountMenuDropdown = ({ dispatch, givenName, familyName }) => {
  const ref = useRef(null);
  const logoutRef = createRef();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [usingKeyboard, setUsingKeyboard] = useState(false);

  const logout = () => dispatch({ type: 'app/logout' });
  const toggleViewMenu = () => setMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (isMenuOpen && logoutRef.current && usingKeyboard) {
      logoutRef.current.focus();
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, logoutRef, isMenuOpen, usingKeyboard]);

  const accountMenuClicked = () => {
    setUsingKeyboard(false);
    toggleViewMenu();
  };
  const accountMenuKeyDown = async (e) => {
    if (e.code === 'Enter') {
      setUsingKeyboard(true);
      await toggleViewMenu();
    }
  };
  const logoutKeyDown = (e) => {
    if (e.code === 'Enter') {
      logout();
    }
  };

  return (
    <div tabIndex={0} ref={ref} onKeyDown={accountMenuKeyDown}>
      <AccountMenu firstName={givenName} lastName={familyName} isOpen={isMenuOpen} onClick={accountMenuClicked}>
        <StyledProfileMenu isOpen={isMenuOpen}>
          <Button tabIndex={0} onClick={logout} onKeyDown={logoutKeyDown} ref={logoutRef}>
            Log Out
          </Button>
        </StyledProfileMenu>
      </AccountMenu>
    </div>
  );
};

function mapStateToProps(state) {
  const { givenName, familyName } = state.app.currentUser;
  return {
    givenName,
    familyName,
  };
}

export default connect(mapStateToProps)(AccountMenuDropdown);
