import styled from 'styled-components';
import { Button } from '@hulu/react-style-components';
import React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';

// All Rules
const StyledAllRulesBtn = styled(Button)`
  float: right;
  position: relative;
  min-width: 150px;
  height: 35px;

  &:last-child {
    margin-left: 20px;
  }
`;

export const AllRulesButtonWrapper = ({ children, ...rest }) => (
  <StyledAllRulesBtn {...rest}>{children}</StyledAllRulesBtn>
);

export const Y_SCROLL = { y: '50vh' };

// Rule Info
export const RuleInfoWrapper = styled.div``;
export const RuleInfoContentWrapper = styled.div`
  padding-top: 25px;
`;

const StyledBtn = styled(Button)`
  min-width: 150px;
  height: 35px;
  max-width: 200px;
  display: block;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
`;
export const ButtonWrapper = ({ children, ...rest }) => <StyledBtn {...rest}>{children}</StyledBtn>;
const StyledEditBtn = styled(Button)`
  float: right;
  min-width: 150px;
  height: 35px;
  max-width: 100px;
  margin: 0;
`;
export const EditButtonWrapper = ({ children, ...rest }) => <StyledEditBtn {...rest}>{children}</StyledEditBtn>;

export const DurationManagerWrapper = styled.div`
  padding: 30px 50px;
`;

export const CreateEditBtnWrapper = styled.div`
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const DownloadBtn = styled.button`
  float: right;
  background-color: 'green';
`;

// Page Commons
export const SubPageWrapper = styled.div`
  padding: 30px 50px;
`;

// Create Rule Page
export const CreateForm = styled.div`
  padding-top: 50px;
`;

export const SubmitButton = styled(Button)`
  min-width: 150px;
  height: 35px;
  max-width: 100px;
  margin: 0;
`;

export const ButtonContainer = styled.div`
  .ant-btn {
    min-width: 200px;
    height: 35px;
    max-width: 250px;
    margin-top: 20px;
    background-color: black;
    border: none;
  }
`;

export const LinkButton = styled(Button)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: blue;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

export const StyledMinusButton = styled(MinusCircleOutlined)`
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #777;
  }
`;
