import React from 'react';
import { Alert } from 'antd';

import { DescriptionCard } from '../../Common/DescriptionCard';
import { RequestParams } from '../RequestParams';
import { RequestResponseCard } from '../../Common/RequestResponseCard';
import { useMeliIntegrator } from './useMeliIntegrator';
import { IntegratorDataSelector } from '../IntegratorDataSelector';

export const MeliIntegrator = ({ data, isLoading, pods, handlePodId, podId }) => {
  const { summary, requestParams, request, response, meliPods } = useMeliIntegrator(data, pods);

  if (request?.error || response?.error) {
    return <Alert type="error" message={request.error || response.error} />;
  }

  return (
    <>
      <DescriptionCard title="Summary" isLoading={isLoading} data={summary} />
      <RequestParams isLoading={isLoading} request={requestParams} />
      {meliPods && <IntegratorDataSelector name="Pod" data={meliPods} handleTargetId={handlePodId} targetId={podId} />}
      <RequestResponseCard isLoading={isLoading} data={request} header="Request" />
      <RequestResponseCard isLoading={isLoading} data={response} header="Response" />
    </>
  );
};
