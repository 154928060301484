import React, { useState } from 'react';
import { connect } from 'dva';
import { Spin } from 'antd';
import { routerRedux } from 'dva/router';

import { Tab, TabPanel, TabNavigation } from '@hulu/react-style-components';

import CreateAdOverrideRule from './CreateAdOverrideRule';
import ViewExistingOverrideRules from './ViewExistingOverrideRules';
import GridLayout from '../Templates/GridLayout';
import { AD_INVESTIGATOR, OVERRIDES, USER_PROFILE } from '../../constants';
import { ADINVESTIGATOR_CREATE_RULES, ADINVESTIGATOR_VIEW_RULES } from '../../testUtils';

const LEFT_HAND_SPAN = 5;
const RIGHT_HAND_SPAN = 18;

const AdInvestigator = ({ loading, dispatch, pathParams = {}, searchParams = {} }) => {
  // Get Preselected Tab
  const [tab, setTab] = useState(pathParams?.tab ?? OVERRIDES);
  const [viewOverrideBy, setViewOverrideBy] = useState(searchParams?.viewType ?? USER_PROFILE);

  const handleTabClick = (_, tab) => {
    setTab(tab);
    setViewOverrideBy(USER_PROFILE);
    dispatch(routerRedux.push('/' + AD_INVESTIGATOR + '/' + tab));
  };

  return (
    <Spin spinning={loading}>
      <GridLayout
        leftHandSpan={LEFT_HAND_SPAN}
        rightHandSpan={RIGHT_HAND_SPAN}
        leftHandChildren={
          <div data-testid={ADINVESTIGATOR_CREATE_RULES}>
            {/* Create Override Rules form */}
            <CreateAdOverrideRule setTab={setTab} setViewOverrideBy={setViewOverrideBy} />
          </div>
        }
        rightHandChildren={
          <>
            <TabNavigation activeValue={tab} onTabClick={handleTabClick}>
              <Tab value={OVERRIDES} data-testid={ADINVESTIGATOR_VIEW_RULES}>
                {OVERRIDES}
              </Tab>
            </TabNavigation>
            <br />

            {/* View Override Rules Table */}
            <TabPanel value={OVERRIDES} activeValue={tab}>
              <ViewExistingOverrideRules overrideBy={viewOverrideBy} setOverrideBy={setViewOverrideBy} />
            </TabPanel>
          </>
        }
      />
    </Spin>
  );
};

function mapStateToProps({ app, loading }) {
  return {
    pathParams: app.pathParams,
    searchParams: app.searchParams,
    permissions: app.permissions,
    loading: loading.effects['adInvestigator/pageInit'],
  };
}

export default connect(mapStateToProps)(AdInvestigator);
