import React from 'react';
import { Alert } from 'antd';

import { useMagniteIntegrator } from './useMagniteIntegrator';
import { XmlCard } from '../../Common/XmlCard';
import { DescriptionCard } from '../../Common/DescriptionCard';
import { REQUEST, RESPONSE } from '../../constants';
import { IntegratorDataSelector } from '../IntegratorDataSelector';

export const MagniteIntegrator = ({ data, isLoading, pods, handlePodId, podId }) => {
  const { requestParams, xmlResponse, summary, magnitePods, error } = useMagniteIntegrator(data, pods);

  if (data?.[REQUEST]?.error || data?.[RESPONSE]?.error) {
    return <Alert type="error" message={data?.[REQUEST]?.error || data?.[RESPONSE]?.error} />;
  }

  if (error) {
    return <Alert type="error" message={error} />;
  }

  return (
    <>
      <DescriptionCard title="Summary" isLoading={isLoading} data={summary} />
      <DescriptionCard title="Request Params" isLoading={isLoading} data={requestParams} />
      {magnitePods && (
        <IntegratorDataSelector name="Pod" data={magnitePods} handleTargetId={handlePodId} targetId={podId} />
      )}
      <XmlCard isLoading={isLoading} data={xmlResponse} header="XML Response" />
    </>
  );
};
