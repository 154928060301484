import React from 'react';
import { Select } from 'antd';
import { Typography } from '@hulu/react-style-components';
import styled from 'styled-components';

import { MY_SNAPSHOTS, ALL_SNAPSHOTS } from '../../constants';

const { Option } = Select;

const StyledSelectWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 12px;
  min-width: 260px;
  height: 32px;
  .ant-select-selector {
    border-radius: 4px !important;
  }
  span {
    font-size: 14px;
    text-transform: none;
  }
`;

const StyledSelect = styled(Select)`
  width: 260px;
  .ant-select-selection-overflow {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow: hidden;
  }
  .ant-select-selection-overflow-item {
    height: 24px;
  }
  .ant-select-selection-item {
    margin: 0px 4px 4px 0px;
  }
`;

const StyledTypography = styled(Typography)`
  margin-right: 4px;
`;

const StyledOptionLabel = styled.span`
  font-weight: ${({ label }) => (label === MY_SNAPSHOTS || label === ALL_SNAPSHOTS ? 600 : 400)};
`;

export const AuctionFiltersUserSnapshots = ({
  handleUserSnapshotValue,
  onUsersSearch,
  userOptions,
  selectedUserOptions,
}) => (
  <StyledSelectWrapper>
    <StyledTypography variant="button">User</StyledTypography>
    <StyledSelect
      mode="multiple"
      allowClear
      showSearch
      filterOption={false}
      value={selectedUserOptions}
      onSearch={onUsersSearch}
      onBlur={() => onUsersSearch('')}
      placeholder=""
      maxTagCount={1}
      onChange={handleUserSnapshotValue}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {userOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          <StyledOptionLabel label={option.label}>{option.label}</StyledOptionLabel>
        </Option>
      ))}
    </StyledSelect>
  </StyledSelectWrapper>
);
