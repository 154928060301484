import React from 'react';

import { JsonViewer } from './JsonViewer';
import { StyledCard } from '../style';

export const RequestResponseCard = ({ isLoading, data, header }) => {
  return (
    <StyledCard loading={isLoading} title={header}>
      <JsonViewer data={data} />
    </StyledCard>
  );
};
