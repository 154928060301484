import React, { useEffect, useRef } from 'react';
import MediaFileTable from './MediaFileTable';
import GridLayout from '../Templates/GridLayout';

const LEFT_HAND_SPAN = 8;
const RIGHT_HAND_SPAN = 14;

const MediaFileResults = ({ url = '', mediaFileRequirements = [] }) => {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [url]);

  return (
    <GridLayout
      leftHandSpan={LEFT_HAND_SPAN}
      rightHandSpan={RIGHT_HAND_SPAN}
      leftHandChildren={
        url && (
          <video ref={videoRef} width="480" height="360" controls>
            <source src={url}></source>
          </video>
        )
      }
      rightHandChildren={
        Array.isArray(mediaFileRequirements) && !mediaFileRequirements.length ? null : (
          <MediaFileTable requirements={mediaFileRequirements} />
        )
      }
    />
  );
};

export default MediaFileResults;
