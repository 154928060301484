import { useMemo } from 'react';
import { BID_WON, DSS, VDMS } from '../constants';
import { STATUSES } from './constants';

const DEFAULT_FINAL_RESPONSE_SUMMARY = [];

const vdmsFinalResponseSummary = (data, totalBids = []) => {
  const bidsWon = totalBids.filter((bid) => bid.bidStatus === BID_WON);
  const demandResponses = { ...data?.extResponseDebug?.httpcalls, ...data?.errors };
  const [pod] = data?.adPods;
  const [rule] = pod?.targeting;
  return Object.entries(demandResponses).map(([key, data]) => {
    const [value] = data;
    const bids = bidsWon.reduce((curr, bid) => (bid.demandPartner === key ? [...curr, bid.bidId] : curr), []);
    return {
      title: key,
      status: STATUSES[value.status || value.code],
      code: value.status || value.code,
      region: rule?.region,
      requestId: rule?.requestId,
      message: value.message,
      bids,
    };
  });
};

const dssFinalResponseSummary = (data) => {
  const region = data?.ext?.drax?.region;
  const requestId = data?.ext?.drax?.requestid;
  return data?.seatbid?.map((demand) => {
    const [anybid] = demand?.bid;
    const type = anybid?.ext?.drax?.partner;
    const bids = demand?.bid?.map((bid) => bid.id);
    return {
      title: type,
      region,
      requestId,
      bids,
    };
  });
};

const finalResponseMap = {
  [VDMS]: vdmsFinalResponseSummary,
  [DSS]: dssFinalResponseSummary,
};

const resolveFinalResponseSummary = (type, data, bids) => {
  const fn = finalResponseMap[type];
  return fn && data && bids ? fn(data, bids) : DEFAULT_FINAL_RESPONSE_SUMMARY;
};

export const useFinalResponseSummaryResolver = (type, data, bids) => {
  return useMemo(() => resolveFinalResponseSummary(type, data, bids), [type, data, bids]);
};
