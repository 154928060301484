import { connect } from 'dva';
import { Typography } from '@hulu/react-style-components';
import { configDataCols } from '../Templates/columns';
import { AllRulesButtonWrapper, Y_SCROLL } from '../DurationManager/styles';
import { SearchOutlined } from '@ant-design/icons';
import { ConfigManagerWrapper } from './styles';
import { convertToTableData } from './utils';
import { routerRedux } from 'dva/router';
import {
  CONFIG_MANAGER,
  CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH_PREFIX,
  CONFIG_MANAGER_SOURCE_DETAILS_PATH_PREFIX,
  EDIT,
} from '../../constants';
import { ResizableTable } from '../../utils';

const ConfigManager = ({ dispatch, permissions, configData = [], pathParams = {}, tableWidths = [] }) => {
  const isViewOnly = permissions.cannot(EDIT, CONFIG_MANAGER);

  const goToCreateSourceDetails = () => {
    dispatch(routerRedux.push(CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH_PREFIX, pathParams));
  };

  const onRow = (selectedRow) => ({
    onClick: () => {
      dispatch(
        routerRedux.push(
          `${CONFIG_MANAGER_SOURCE_DETAILS_PATH_PREFIX}/source-id/${selectedRow['source-id']}`,
          pathParams
        )
      );
    },
    style: {
      cursor: 'pointer',
    },
  });
  let tableData = Object.entries(configData).map(convertToTableData);
  const handleWidthChange = (widths) =>
    dispatch({
      type: 'configManager/updateTableWidths',
      payload: widths,
    });

  return (
    <ConfigManagerWrapper>
      <Typography variant={'h2'}>Partner DAI Config Manager</Typography>
      <br />
      <AllRulesButtonWrapper icon="IconAdd" onClick={goToCreateSourceDetails} disabled={isViewOnly}>
        Create New
      </AllRulesButtonWrapper>
      <ResizableTable
        dataSource={tableData}
        inputColumns={configDataCols(tableData, tableWidths)}
        scroll={Y_SCROLL}
        onRow={onRow}
        widthHandler={handleWidthChange}
        searchableProps={{
          fuzzySearch: false,
          inputProps: {
            placeholder: 'Search in table...',
            prefix: <SearchOutlined />,
          },
        }}
      />
    </ConfigManagerWrapper>
  );
};
function mapStateToProps({ app, configManager, loading }) {
  return {
    permissions: app.currentUser.permissions,
    configData: configManager.configManagerState.configData,
    pathParams: app.pathParams,
    tableWidths: configManager.tableWidths,
    loading: loading.effects['configManager/fetchConfigData'],
  };
}

export default connect(mapStateToProps)(ConfigManager);
