import { postMediaFile, postVastXml, postVastUrl, saveMediaFile, ingestVastXml } from '../../services/qCheck';
import { CREATED, SUCCESS } from '../../constants';
import clone from 'clone-deep';
import { VAST_URL } from '../../testUtils';
import { COMPLETE, FAILURE } from '../../pages/QCheck/constants';

const INITIAL_MEDIA_FILE_VALIDATION_STATE = {
  record: null,
  error: null,
};

const INITIAL_VAST_VALIDATION_STATE = {
  records: [],
  error: null,
};

export const qCheck = {
  namespace: 'qCheck',
  state: {
    mediaFileValidation: clone(INITIAL_MEDIA_FILE_VALIDATION_STATE),
    vastValidation: clone(INITIAL_VAST_VALIDATION_STATE),
  },
  reducers: {
    updateMediaFileTable(state, { payload: data }) {
      const newState = clone(state);
      newState.mediaFileValidation.record = {
        mediaFileUrl: clone(data['media-file-url']),
        mediaFileRequirements: clone(data['media-file-requirements']),
      };
      newState.mediaFileValidation.error = null;
      return newState;
    },
    updateVastTable(state, { payload: records }) {
      const newState = clone(state);
      newState.vastValidation.records = clone(records);
      newState.vastValidation.error = null;
      return newState;
    },
    resetMediaFileTab(state) {
      return { ...state, mediaFileValidation: clone(INITIAL_MEDIA_FILE_VALIDATION_STATE) };
    },
    resetVastXmlTab(state) {
      return { ...state, vastValidation: clone(INITIAL_VAST_VALIDATION_STATE) };
    },
    updateVastXmlIngestionStatus(state, { payload: data }) {
      const newState = clone(state);
      // TODO: add additional logic here so we can update UI accordingly.
      return newState;
    },
    updateMediaFileValidationError(state, { payload: data }) {
      const newState = clone(state);
      newState.mediaFileValidation.error = data;
      return newState;
    },
    updateVastValidationError(state, { payload: data }) {
      const newState = clone(state);
      newState.vastValidation.error = data;
      return newState;
    },
  },
  effects: {
    *pageInit({ payload: { pathParams } }, { put }) {},
    *saveMediaFile({ payload: reqBody }, { call, put }) {
      const form = new FormData();
      form.append('file', reqBody);

      const response = yield call(saveMediaFile, form);
      if (response?.data) {
        let responseUrl = response?.data?.url;
        let responseStatus = response?.status === CREATED ? COMPLETE : FAILURE;
        return {
          url: responseUrl,
          urlStatus: responseStatus,
        };
      }
    },
    *validateMediaFile({ payload: reqBody }, { call, put }) {
      try {
        const response = yield call(postMediaFile, { payload: reqBody });
        if (response?.status === SUCCESS && response?.data) {
          yield put({
            type: 'updateMediaFileTable',
            payload: response.data,
          });
        }
      } catch (error) {
        const errorDetail = error.response?.data?.message || error.message || 'An error occurred';
        yield put({
          type: 'updateMediaFileValidationError',
          payload: { errorMessage: 'Validating media file failed.', errorDetail: errorDetail },
        });
      }
    },
    *validateVast({ payload: input }, { call, put }) {
      try {
        let response = null;
        if (input.validationType === VAST_URL) {
          response = yield call(postVastUrl, { url: input.payload });
        } else {
          input.payload = input.payload.replaceAll(/[\n\t]+/g, ' ');
          input.payload = input.payload.replaceAll(/ </g, '<');
          response = yield call(postVastXml, { xml: input.payload });
        }
        if (response?.status === SUCCESS && response?.data) {
          yield put({
            type: 'updateVastTable',
            payload: response.data,
          });
        }
      } catch (error) {
        const errorDetail = error.response?.data?.message || error.message || 'An error occurred';
        yield put({
          type: 'updateVastValidationError',
          payload: { errorMessage: 'Validating VAST XML failed.', errorDetail: errorDetail },
        });
      }
    },
    *ingestXmlToAd(action, { call, put }) {
      const { countryCode, xml } = action.payload;
      const response = yield call(ingestVastXml, { country: countryCode, vastXmlStr: xml });
      yield put({
        type: 'updateVastXmlIngestionStatus',
        payload: { records: response.data },
      });
    },
  },
};
