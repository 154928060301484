import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';

import { Typography } from '@hulu/react-style-components';

import RuleInfo from './RuleInfo';
import { durationColumns } from '../Templates/columns';
import { RULE_KEY } from './constants';
import {
  DURATION_MANAGER,
  DURATION_MANAGER_BULK_UPLOAD_PATH,
  DURATION_MANAGER_CREATE_RULE_PATH,
  EDIT,
} from '../../constants';
import { AllRulesButtonWrapper, CreateEditBtnWrapper, DurationManagerWrapper, Y_SCROLL } from './styles';
import { initRuleKey, convertRuleToCSVData, convertRuleToTableData, buildRouteFromRow } from './utils';
import { DURATION_MANAGER_BUTTONS, DURATION_MANAGER_TABLE } from '../../testUtils';
import { ResizableTable } from '../../utils';
import { SearchOutlined } from '@ant-design/icons';

const ViewDurationRules = ({
  dispatch,
  permissions,
  durationRules = [],
  durationRuleMap = new Map(),
  pathParams = {},
  durationWidths = [],
}) => {
  const [selectedDurationRuleKey, setSelectedDurationRuleKey] = useState(initRuleKey(pathParams));
  const isRuleInfoPage = pathParams?.channelId ?? false;

  const isViewOnly = permissions.cannot(EDIT, DURATION_MANAGER);

  const tableData = Object.entries(durationRules).map(convertRuleToTableData);
  const csvData = Object.entries(durationRules).map(convertRuleToCSVData);

  const onRow = (selectedRow) => ({
    onClick: () => {
      dispatch(routerRedux.push(buildRouteFromRow(selectedRow), pathParams));
      setSelectedDurationRuleKey(selectedRow[RULE_KEY]);
    },
    style: {
      cursor: 'pointer',
    },
  });

  const goToCreateRule = () => {
    dispatch(routerRedux.push(DURATION_MANAGER_CREATE_RULE_PATH, pathParams));
  };

  const goToBulkUpload = () => {
    dispatch(routerRedux.push(DURATION_MANAGER_BULK_UPLOAD_PATH));
  };
  const handleWidthChange = (widths) =>
    dispatch({
      type: 'durationManager/updateDurationManagerWidths',
      payload: widths,
    });

  return (
    <DurationManagerWrapper>
      <Typography variant={'h2'}>Duration Manager</Typography>
      <br />
      {!isRuleInfoPage ? (
        <>
          {/* Button redirects for create/edit/delete operations */}
          <CreateEditBtnWrapper data-testid={DURATION_MANAGER_BUTTONS}>
            <CSVLink filename={'DurationRules.csv'} data={csvData}>
              <AllRulesButtonWrapper icon="IconDownload">Export</AllRulesButtonWrapper>
            </CSVLink>
            <AllRulesButtonWrapper icon="IconAdd" onClick={goToCreateRule} disabled={isViewOnly}>
              Create Rule
            </AllRulesButtonWrapper>
            <AllRulesButtonWrapper icon="IconUpload" onClick={goToBulkUpload} disabled={isViewOnly}>
              Upload Rules
            </AllRulesButtonWrapper>
          </CreateEditBtnWrapper>

          {/* Data Table for Duration Rules */}
          <div data-testid={DURATION_MANAGER_TABLE}>
            <ResizableTable
              dataSource={tableData}
              inputColumns={durationColumns(durationRules, durationWidths)}
              onRow={onRow}
              scroll={Y_SCROLL}
              widthHandler={handleWidthChange}
              searchableProps={{
                inputProps: {
                  placeholder: 'Search in table...',
                  prefix: <SearchOutlined />,
                },
                searchFunction(dataSource, searchTerm) {
                  return (dataSource ?? []).filter((row) => {
                    return Object.values(row).some((val) => {
                      const performSearch = (searchVal) => {
                        return searchVal.toString().toLowerCase().includes(searchTerm.toLowerCase());
                      };

                      if (Array.isArray(val)) {
                        return val.forEach(performSearch);
                      }
                      return performSearch(val);
                    });
                  });
                },
              }}
            />
          </div>
        </>
      ) : null}
      <RuleInfo
        visible={isRuleInfoPage}
        isViewOnly={isViewOnly}
        durationRule={durationRuleMap.get(selectedDurationRuleKey)}
      />
    </DurationManagerWrapper>
  );
};

function mapStateToProps({ app, durationManager, loading }) {
  return {
    permissions: app.currentUser.permissions,
    durationRules: durationManager.viewDurationRulesState.durationRules,
    durationRuleMap: durationManager.viewDurationRulesState.durationRuleMap,
    pathParams: app.pathParams,
    durationWidths: durationManager.durationWidths,
    loading: loading.effects['durationManager/fetchDurationRules'],
  };
}

export default connect(mapStateToProps)(ViewDurationRules);
