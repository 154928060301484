import { connect } from 'dva';
import { Form, Modal, Select } from 'antd';
import {
  CONTENT_PARTNER_ID,
  CP_IDENTIFIER,
  NETWORK_NAME,
  SOURCE_ID,
  TRIGGER_TYPE,
  UPID_LOOKUP_STRATEGY,
} from '../utils';
import { NETWORK_OPTIONS, TRIGGER_OPTIONS, UPID_OPTIONS } from '../constants';

const EditSourceModal = ({ dispatch, currentUser, open, setEditModalOpen, selectedSource, cpIdentifiers = [] }) => {
  const [form] = Form.useForm();

  const onFinish = async (value) => {
    const formattedSource = {
      [`${CONTENT_PARTNER_ID}`]: value['cp-id'].value,
      [`${CP_IDENTIFIER}`]: value['cp-id'].label,
      [`${SOURCE_ID}`]: selectedSource[SOURCE_ID],
      [`${TRIGGER_TYPE}`]: value[TRIGGER_TYPE],
      [`${UPID_LOOKUP_STRATEGY}`]: {
        name: value[UPID_LOOKUP_STRATEGY],
        [`${NETWORK_NAME}`]: value.network,
      },
    };
    await dispatch({ type: 'configManager/updateSource', payload: { reqBody: formattedSource } });
    await dispatch({ type: 'configManager/fetchConfigData' });
    setEditModalOpen(false);
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((r) => {
        if (!r.errorFields) {
          form.submit();
        }
      })
      .catch((e) => {
        for (let errorItem of e.errorFields) {
          dispatch({
            type: 'configManager/sendError',
            payload: { message: 'Error in edit source', description: errorItem.errors },
          });
        }
      });
    setEditModalOpen(false);
  };

  const onCancel = () => setEditModalOpen(false);

  return (
    <Modal title="Edit Source" open={open} okText="Submit" onCancel={onCancel} onOk={onSubmit}>
      <div>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            'cp-id': {
              label: selectedSource?.[CP_IDENTIFIER] ?? '',
              value: selectedSource?.[CONTENT_PARTNER_ID] ?? '',
            },
            [`${SOURCE_ID}`]: selectedSource?.[SOURCE_ID] ?? '',
            [`${TRIGGER_TYPE}`]: selectedSource?.[TRIGGER_TYPE] ?? '',
            [`${UPID_LOOKUP_STRATEGY}`]: selectedSource?.[UPID_LOOKUP_STRATEGY]?.name ?? '',
            network: selectedSource?.[UPID_LOOKUP_STRATEGY]?.[NETWORK_NAME] ?? '',
          }}
        >
          <Form.Item name="cp-id" label={'CP Identifier'}>
            <Select
              showSearch
              allowClear
              labelInValue
              options={cpIdentifiers}
              placeholder={'Choose CP Identifier'}
              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
          </Form.Item>
          <p>Source Id: {selectedSource?.[SOURCE_ID]}</p>
          <Form.Item name={TRIGGER_TYPE} label={'Trigger Type'}>
            <Select options={TRIGGER_OPTIONS} />
          </Form.Item>
          <Form.Item name={UPID_LOOKUP_STRATEGY} label="UPID Lookup Strategy" rules={[{ required: true }]}>
            <Select placeholder="Choose UPID Lookup Strategy" allowClear options={UPID_OPTIONS} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[UPID_LOOKUP_STRATEGY] !== currentValues[UPID_LOOKUP_STRATEGY]
            }
          >
            {({ getFieldValue }) =>
              getFieldValue(UPID_LOOKUP_STRATEGY) === 'EspnPccLookUpStrategy' ? (
                <Form.Item name="network" label="Network" rules={[{ required: true }]}>
                  <Select placeholder="Choose a Network" allowClear options={NETWORK_OPTIONS} />
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

function mapStateToProps({ app, loading, configManager }) {
  return {
    loading: loading.effects['configManager/fetchConfigdata'],
    currentUser: app.currentUser,
    cpIdentifiers: configManager.configManagerState.cpIdentifiers,
  };
}

export default connect(mapStateToProps)(EditSourceModal);
