// Rule Field
export const RULE_ID = 'rule-id';
export const RULE_KEY = 'key';
export const RULE_TYPE = 'rule-type';
export const STREAM_TYPE = 'stream-type';
export const RULE_CHANNEL_ID = 'channel-id';
export const RULE_SERIES_ID = 'series-id';
export const RULE_LEAGUE_ID = 'league-id';
export const RULE_SPORTS_ID = 'sports-id';
export const RULE_EVENT_ID = 'event-id';
export const ID = 'id';
export const IDS = 'ids';
export const RULE_DURATIONS_MILLISECONDS = 'durations-ms';
export const RULE_STATUS = 'status';
export const RULE_LAST_UPDATED = 'last-updated-date';
export const RULE_CHANGE_TYPE = 'change-type';
export const RULE_SINGLE_PROGRAM = 'is-single-program';
export const RULE_PARTIAL_POD = 'is-partial-pods';

// Rule Status
export const RULE_STATUS_ACTIVE = 'ACTIVE';
export const RULE_STATUS_DISABLED = 'DISABLED';

// Rule Action
export const RULE_ACTION_ENABLE = 'Enable';
export const RULE_ACTION_DISABLE = 'Disable';

// Single Program
export const RULE_SINGLE_PROGRAM_DISPLAY = 'SINGLE_PROGRAM';
export const RULE_PARTIAL_POD_DISPLAY = 'PARTIAL_POD';

// Rule Type
export const RULE_TYPE_CHANNEL = 'CHANNEL';
export const RULE_TYPE_SERIES = 'SERIES';
export const RULE_TYPE_LEAGUE = 'LEAGUE';
export const RULE_TYPE_EVENT = 'EVENT';
export const RULE_TYPE_SPORTS = 'SPORTS';

// Stream Type
export const STREAM_TYPE_LINEAR = 'LINEAR';
export const STREAM_TYPE_EVENT = 'EVENT';

// Change Type
export const RULE_CHANGE_TYPE_CREATE = 'CREATE';
export const RULE_CHANGE_TYPE_EDIT = 'EDIT';

// Display Style
export const DISPLAY_STYLE_NONE = 'none';
export const DISPLAY_STYLE_BLOCK = 'block';
