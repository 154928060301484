// Test Cases
import { getLocalTimezoneAbbreviation } from '../../utils';

export const TEST_CASE_PROFILE_NAME = 'profileName';
export const TEST_CASE_ID = 'testCaseId';
export const TEST_CASE_STATUS = 'status';
export const TEST_CASE_CREATED_AT = 'createdAt';
export const TEST_CASE_ACTION = 'action';

// Video Ad Traces
export const TRACE_DEVICE_NAME = 'deviceName';
export const TRACE_INTEGRATION = 'integration';
export const TRACE_DEVICE_DISTRIBUTOR_NAME = 'deviceDistributor';
export const TRACE_CONTENT_PARTNER_IDENTIFIER = 'contentPartnerIdentifier';
export const TRACE_CONTENT_DISTRIBUTOR_NAME = 'contentDistributor';
export const TRACE_POD = 'pod';
export const TRACE_TIME = 'time';
export const TRACE_STATUS = 'status';
export const TRACE_SERIES_ID = 'seriesIdentifier';
export const TRACE_CONTENT_TITLE = 'contentTitle';
export const TRACE_COUNTRY = 'country';
export const TRACE_AD_SERVER = 'adServer';
export const TRACE_PUBLISHER = 'publisher';

export const DEVICE_TITLE = 'Device';
export const INTEGRATION_TITLE = 'Integration';
export const DEVICE_DISTRIBUTOR_TITLE = 'Device Distributor';
export const CONTENT_INFO_TITLE = 'Content Info';
export const CONTENT_DISTRIBUTOR_TITLE = 'Content Distributor';
export const POD_TITLE = 'Pod';
export const TIME_TITLE = `Time (${getLocalTimezoneAbbreviation()})`;
export const STATUS_TITLE = 'Status';
export const COUNTRY_TITLE = 'Country';
export const AD_SERVER_TITLE = 'Ad Server';
export const PUBLISHER_TITLE = 'Publisher';

// Time Misc.
export const OUTPUT_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';

// Accounts
export const DISNEY_PLUS_PUBLISHER = 'DISNEY_PLUS';
export const HULU_PUBLISHER = 'HULU';
