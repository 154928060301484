import { connect } from 'dva';
import {
  CP_IDENTIFIER,
  DISABLED,
  ENABLED,
  SOURCE_ID,
  sourceStatusRender,
  STATUS,
  TRIGGER_TYPE,
  UPDATED_AT,
  UPDATED_BY,
  UPID_LOOKUP_STRATEGY,
} from '../utils';
import { Tab, TabNavigation, TabPanel, Typography } from '@hulu/react-style-components';
import React, { useState } from 'react';
import EditSourceModal from '../EditSourceModal';
import { ButtonWrapper, EditButtonWrapper } from '../../DurationManager/styles';
import {
  CONFIG_MANAGER,
  CONFIG_MANAGER_PATH,
  CONFIG_MANAGER_SOURCE_DETAILS,
  EDIT,
  HISTORY,
  PARTNER_DAI_CONFIG_ENABLE_DISABLE_CONFIG,
} from '../../../constants';
import { DivWrapper } from '../CreateSourceDetails/styles';
import { dateRender } from '../../../utils';
import { StyledLink } from './styles';
import SourceDetailsHistory from '../SourceDetailsHistory';

const SourceDetails = ({ dispatch, configData, selectedSource, permissions, visible, isViewOnly, pathParams }) => {
  const [tab, setTab] = useState(pathParams?.tab ?? CONFIG_MANAGER_SOURCE_DETAILS);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const canEditSource = permissions.can(EDIT, CONFIG_MANAGER);
  const canEditStatus = permissions.can(EDIT, PARTNER_DAI_CONFIG_ENABLE_DISABLE_CONFIG);

  const handleEditClick = () => {
    dispatch({ type: 'configManager/fetchConfigData' });
    setEditModalOpen(true);
  };
  // set selectedSource
  selectedSource = configData.filter((value) => value['source-id'] === pathParams.sourceId)[0];
  let network = selectedSource?.[`${UPID_LOOKUP_STRATEGY}`]?.['network-name'];

  const updateStatus = async (status) => {
    await dispatch({
      type: 'configManager/updateSourceStatus',
      payload: { id: selectedSource['source-id'], reqBody: { status: status } },
    });
    dispatch({ type: 'configManager/fetchConfigData' });
  };

  return (
    <DivWrapper>
      <StyledLink to={CONFIG_MANAGER_PATH}>
        <Typography variant={'h6'}>&lt; Back to Partner DAI Config Manager</Typography>
      </StyledLink>
      <br />
      <h1>Source: {selectedSource?.[SOURCE_ID]}</h1>
      {tab === CONFIG_MANAGER_SOURCE_DETAILS ? (
        <EditButtonWrapper disabled={!canEditSource} onClick={handleEditClick}>
          Edit
        </EditButtonWrapper>
      ) : null}
      <TabNavigation
        activeValue={tab}
        onTabClick={(_, selectedTab) => {
          setTab(selectedTab);
        }}
      >
        <Tab value={CONFIG_MANAGER_SOURCE_DETAILS}>Source Details</Tab>
        <Tab value={HISTORY}>History</Tab>
      </TabNavigation>

      {/* Source Details */}
      <TabPanel value={CONFIG_MANAGER_SOURCE_DETAILS} activeValue={tab}>
        <br />
        <p>
          <b>CP Identifier:</b> {selectedSource?.[CP_IDENTIFIER]}
        </p>
        <p>
          <b>Source Id:</b> {selectedSource?.[SOURCE_ID]}
        </p>
        <p>
          <b>Trigger Type:</b> {selectedSource?.[TRIGGER_TYPE]}
        </p>
        <p>
          <b>UPID Lookup Strategy:</b> {selectedSource?.[UPID_LOOKUP_STRATEGY]?.name}
        </p>
        {network ? (
          <p>
            <b>Network: </b> {network}
          </p>
        ) : null}
        <p>
          <b>Status:</b> {sourceStatusRender(selectedSource?.[STATUS])}
        </p>
        <p>
          <b>Last Updated:</b> {dateRender(selectedSource?.[UPDATED_AT])}
        </p>
        <p>
          <b>Last Updated By:</b> {selectedSource?.[UPDATED_BY]}
        </p>
        {selectedSource?.[STATUS] === ENABLED ? (
          <ButtonWrapper disabled={!canEditStatus} onClick={() => updateStatus(DISABLED)}>
            Disable
          </ButtonWrapper>
        ) : (
          <ButtonWrapper disabled={!canEditStatus} onClick={() => updateStatus(ENABLED)}>
            Enable
          </ButtonWrapper>
        )}
        <EditSourceModal open={editModalOpen} setEditModalOpen={setEditModalOpen} selectedSource={selectedSource} />
      </TabPanel>

      {/* History */}
      <TabPanel value={HISTORY} activeValue={tab}>
        <SourceDetailsHistory selectedSourceId={selectedSource?.[SOURCE_ID]} tab={tab} />
      </TabPanel>
    </DivWrapper>
  );
};
function mapStateToProps({ app, configManager, loading }) {
  return {
    permissions: app.currentUser.permissions,
    configData: configManager.configManagerState.configData,
    selectedSource: configManager.configManagerState.selectedSource,
    pathParams: app.pathParams,
    loading: loading.effects['configManager/fetchConfigData'],
  };
}

export default connect(mapStateToProps)(SourceDetails);
