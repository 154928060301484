import styled from 'styled-components';
import React from 'react';
import { Col, Row } from 'antd';

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
`;

export const GridLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 30px;
`;

const GridLayout = ({ leftHandSpan, rightHandSpan, headerChildren, leftHandChildren, rightHandChildren }) => {
  return (
    <GridLayoutWrapper>
      {headerChildren}
      <StyledRow justify="space-around">
        <Col span={leftHandSpan}>{leftHandChildren}</Col>
        <Col span={rightHandSpan}>{rightHandChildren}</Col>
      </StyledRow>
    </GridLayoutWrapper>
  );
};

export default GridLayout;
