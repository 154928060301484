import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { RowArrayInfo } from '../Common/RowArrayInfo';
import { StyledCard, StyledDivider } from '../style';
import { useSupplySourceRequestParamsResolver } from './useSupplySourceRequestParamsResolver';

export const RequestParamsCard = ({ isLoading, data, type }) => {
  const values = useSupplySourceRequestParamsResolver(type, data);
  return (
    <StyledCard title="Request Params" loading={isLoading}>
      <RowArrayInfo title="Video Mimes" data={values?.videoMimes} />
      <RowInfo title="Video Min Duration" data={values.videoMinDuration} />
      <RowInfo title="Video Max Duration" data={values.videoMaxDuration} />
      <RowInfo title="Video Protocols" data={values.videoProtocols} />
      <RowInfo title="Video Width" data={values.videoWidth} />
      <RowInfo title="Video Height" data={values.videoHeight} />

      <StyledDivider height={24} />

      <RowInfo title="Site Page" data={values.sitePage} />
    </StyledCard>
  );
};
