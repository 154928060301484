import React from 'react';
import { Table } from 'ant-table-extensions';
import { vastRequirementColumns } from '../Templates/columns';
import MediaFileResults from './MediaFileResults';

const VastTable = ({ records = [] }) => (
  <Table
    columns={vastRequirementColumns(records)}
    dataSource={records.map((record, index) => ({ ...record, key: record['creative-id'] + '_' + index }))}
    pagination={false}
    expandable={{
      expandedRowRender: (record) => (
        <MediaFileResults
          url={record['media-file-validation']?.['media-file-url']}
          mediaFileRequirements={record['media-file-validation']?.['media-file-requirements']}
        />
      ),
    }}
  />
);

export default VastTable;
