import { connect } from 'dva';
import { Checkbox, Form, Modal } from 'antd';
import { labelRender, ruleStatusRender } from '../utils';
import DurationFormItem from '../RuleFormItem/DurationItem';
import {
  RULE_CHANGE_TYPE_EDIT,
  RULE_CHANNEL_ID,
  RULE_DURATIONS_MILLISECONDS,
  RULE_SERIES_ID,
  RULE_LEAGUE_ID,
  RULE_SINGLE_PROGRAM,
  RULE_STATUS,
  RULE_TYPE,
  RULE_TYPE_CHANNEL,
  RULE_TYPE_SERIES,
  RULE_TYPE_LEAGUE,
  RULE_PARTIAL_POD,
  STREAM_TYPE,
  STREAM_TYPE_LINEAR,
  RULE_EVENT_ID,
  RULE_SPORTS_ID,
  RULE_TYPE_EVENT,
  RULE_TYPE_SPORTS,
} from '../constants';
import { FORM_ITEM_LAYOUT, FORM_ITEM_LAYOUT_WITHOUT_LABEL } from './styles';
import { DURATION_MANAGER_MODAL } from '../../../testUtils';

export const DEFAULT_DURATION_RULE = {
  'durations-ms': [],
  'channel-id': '',
  status: '',
  'rule-type': '',
};

const EditModal = ({ dispatch, open, setEditModalOpen, durationRule = DEFAULT_DURATION_RULE }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const requestRule = {
      'change-type': RULE_CHANGE_TYPE_EDIT,
      'rule-type': durationRule[RULE_TYPE],
      'channel-id': durationRule[RULE_CHANNEL_ID],
      'series-id': durationRule[RULE_SERIES_ID],
      'league-id': durationRule[RULE_LEAGUE_ID],
      'event-id': durationRule[RULE_EVENT_ID],
      'sports-id': durationRule[RULE_SPORTS_ID],
      status: durationRule[RULE_STATUS],
      'stream-type': durationRule[STREAM_TYPE],
      'last-updated-date': new Date().toISOString(),
      ...values,
    };
    await dispatch({ type: 'durationManager/postRules', payload: { reqBody: [requestRule], changeType: 'Edit' } });
    await dispatch({ type: 'durationManager/fetchDurationRules' });
    setEditModalOpen(false);
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((r) => {
        if (!r.errorFields) {
          form.submit();
        }
      })
      .catch((e) => {
        for (let errorItem of e.errorFields) {
          dispatch({
            type: 'durationManager/sendError',
            payload: { message: 'Error in edit rule', description: errorItem.errors },
          });
        }
      });
  };

  const onCancel = () => setEditModalOpen(false);

  return (
    <Modal title="Edit Rule" open={open} okText="Submit" onCancel={onCancel} onOk={onSubmit}>
      <div data-testid={DURATION_MANAGER_MODAL}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            'durations-ms': durationRule[RULE_DURATIONS_MILLISECONDS],
          }}
        >
          <Form.Item {...FORM_ITEM_LAYOUT} label={labelRender('Rule Type')}>
            <span>{durationRule[RULE_TYPE]}</span>
          </Form.Item>
          <Form.Item {...FORM_ITEM_LAYOUT} label={labelRender('Status')}>
            <span>{ruleStatusRender(durationRule[RULE_STATUS])}</span>
          </Form.Item>
          {durationRule[STREAM_TYPE] === STREAM_TYPE_LINEAR ? (
            <Form.Item {...FORM_ITEM_LAYOUT} label={labelRender('Channel ID')}>
              <span>{durationRule[RULE_CHANNEL_ID]}</span>
            </Form.Item>
          ) : null}
          {durationRule[RULE_TYPE] === RULE_TYPE_SERIES ? (
            <Form.Item {...FORM_ITEM_LAYOUT} label={labelRender('Series ID')}>
              <span>{durationRule[RULE_SERIES_ID]}</span>
            </Form.Item>
          ) : null}
          {durationRule[RULE_TYPE] === RULE_TYPE_LEAGUE ? (
            <Form.Item {...FORM_ITEM_LAYOUT} label={labelRender('League ID')}>
              <span>{durationRule[RULE_LEAGUE_ID]}</span>
            </Form.Item>
          ) : null}
          {durationRule[RULE_TYPE] === RULE_TYPE_EVENT ? (
            <Form.Item {...FORM_ITEM_LAYOUT} label={labelRender('Event ID')}>
              <span>{durationRule[RULE_EVENT_ID]}</span>
            </Form.Item>
          ) : null}
          {durationRule[RULE_TYPE] === RULE_TYPE_SPORTS ? (
            <Form.Item {...FORM_ITEM_LAYOUT} label={labelRender('Sports ID')}>
              <span>{durationRule[RULE_SPORTS_ID]}</span>
            </Form.Item>
          ) : null}
          {durationRule[RULE_TYPE] === RULE_TYPE_CHANNEL ? (
            <Form.Item
              {...FORM_ITEM_LAYOUT}
              label={labelRender('Single Program')}
              name="is-single-program"
              valuePropName="checked"
              initialValue={durationRule[RULE_SINGLE_PROGRAM]}
            >
              <Checkbox />
            </Form.Item>
          ) : null}
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            label={labelRender('Partial Pods')}
            name="is-partial-pods"
            valuePropName="checked"
            initialValue={durationRule[RULE_PARTIAL_POD]}
          >
            <Checkbox />
          </Form.Item>
          <DurationFormItem layout={FORM_ITEM_LAYOUT} layoutWithoutLabel={FORM_ITEM_LAYOUT_WITHOUT_LABEL} />
        </Form>
      </div>
    </Modal>
  );
};

function mapStateToProps({ loading }) {
  return {
    loading: loading.effects['durationManager/fetchDurationRules'],
  };
}

export default connect(mapStateToProps)(EditModal);
