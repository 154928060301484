import { Button, Input, Modal, notification } from 'antd';
import React from 'react';
import { connect } from 'dva';
import {
  ADSTRACER_MODAL_SHARETEST,
  ADSTRACER_MODAL_SHARETEST_BUTTON_CANCEL,
  ADSTRACER_MODAL_SHARETEST_BUTTON_COPY,
  ADSTRACER_MODAL_SHARETEST_BUTTON_SHARE,
} from '../../../testUtils';

const ShareTestCaseModal = ({ dispatch, shareModalTestCaseId, setSharedModalTestCaseId, searchParams = {} }) => {
  // States
  const open = shareModalTestCaseId != null;

  // Event Handlers
  const hideShareTestCaseModal = () => {
    delete searchParams.shareTestCase;
    dispatch({
      type: 'app/setSearchParams',
      payload: searchParams,
    });
    setSharedModalTestCaseId(null);
  };

  const handleCopyTestCaseId = () => {
    document.getElementById('share_test_case_id_textarea').select();
    document.execCommand('Copy');
    notification.success({
      message: 'Copied.',
      duration: 3,
    });
  };

  const handleShareTestCase = () => {
    dispatch({
      type: 'adsTracer/shareTestCase',
      payload: shareModalTestCaseId,
    });
    hideShareTestCaseModal();
  };

  return (
    <Modal
      title="Share Test Case"
      open={open}
      onCancel={hideShareTestCaseModal}
      footer={[
        <Button key="cancel" onClick={hideShareTestCaseModal} data-testid={ADSTRACER_MODAL_SHARETEST_BUTTON_CANCEL}>
          Cancel
        </Button>,
        <Button
          key="copy-test-case-id"
          onClick={handleCopyTestCaseId}
          data-testid={ADSTRACER_MODAL_SHARETEST_BUTTON_COPY}
        >
          Copy Test Case ID
        </Button>,
        <Button
          key="share-test-case"
          type="primary"
          onClick={handleShareTestCase}
          data-testid={ADSTRACER_MODAL_SHARETEST_BUTTON_SHARE}
        >
          Share Test Case
        </Button>,
      ]}
    >
      <Input
        id="share_test_case_id_textarea"
        readOnly
        value={shareModalTestCaseId}
        addonBefore="Test Case ID:"
        data-testid={ADSTRACER_MODAL_SHARETEST}
      />
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    searchParams: state.app.searchParams,
  };
}

export default connect(mapStateToProps)(ShareTestCaseModal);
