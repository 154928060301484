import styled from 'styled-components';
import { Button } from 'antd';

export const SearchFilterWrapper = styled.div`
  height: 80vh;
  margin: 20px 0;
  padding: 20px 30px;
  border: 1px solid #706f6f;
  background-color: white;
  border-radius: 10px;
  overflow-y: scroll;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 0 20px;
`;
