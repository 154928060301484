import styled from 'styled-components';

export const AccountsReviewWrapper = styled.div`
  margin: 20px 0;
  padding: 20px 30px;
  border: 1px solid #706f6f;
  background-color: white;
  border-radius: 10px;
`;

export const SelectExpirationWrapper = styled.div`
  padding: 20px;
  border: 1px dotted #79aaf7;
  border-radius: 10px;
  margin: 20px 0;
  width: 50%;
  margin: auto;
`;
