import { notification } from 'antd';
import { colors } from '@hulu/design-tokens';
import { Typography } from '@hulu/react-style-components';
import { IconClose, IconAlertSuccessOutlined, IconAlertErrorOutlined } from '@hulu-react-style-components/icons';

const config = {
  success: {
    message: <Typography variant="eyebrow">Success</Typography>,
    description: 'has been created.',
    style: {
      background: colors.colorGreen05,
      border: `1px solid ${colors.colorGreen60}`,
      padding: '26px',
      whiteSpace: 'pre-wrap',
    },
    icon: <IconAlertSuccessOutlined style={{ stroke: colors.colorGreen60 }} />,
  },
  error: {
    message: <Typography variant="eyebrow">Error</Typography>,
    description: '',
    style: {
      background: colors.colorRed40,
      border: `1px solid ${colors.colorRed40}`,
      padding: '26px',
      whiteSpace: 'pre-wrap',
    },
    icon: <IconAlertErrorOutlined style={{ stroke: colors.colorRed40 }} />,
  },
};

export const openNotificationWithIcon = (type = '', snapshotName, skipDesc = false) => {
  notification[type]({
    message: config[type].message,
    description: `${snapshotName} ${!skipDesc ? config[type].description : ''}`,
    icon: config[type].icon,
    placement: 'bottomRight',
    duration: 5,
    style: config[type].style,
    closeIcon: <IconClose />,
  });
};
