import styled from 'styled-components';
import { Button } from '@hulu/react-style-components';
import React from 'react';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  & > * {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:first-child {
    margin-top: 20px;
  }
`;

export const LeftSideHeaderWrapper = styled.div`
  justify-content: flex-start;
`;

const StyledButton = styled(Button)`
  float: left;
  position: relative;
  min-width: 208px;
  height: 35px;

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const ButtonWrapper = ({ children, ...rest }) => <StyledButton {...rest}>{children}</StyledButton>;

export const RightSideHeaderWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: center;
`;

const StyledRefreshButton = styled(Button)`
  float: right;
  position: relative;
  margin-left: 20px;
  min-width: 208px;
  height: 35px;
`;

export const RefreshButtonWrapper = ({ children, ...rest }) => (
  <StyledRefreshButton {...rest}>{children}</StyledRefreshButton>
);
