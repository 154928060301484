import { ADS_TRACER_API, DELETE, GET, POST, PUT } from '../constants';
import { jwtTokenHeader, request } from '../utils';
import { notification } from 'antd';

// TEST CASE FLOW
export const getTestCasesByUserId = async () =>
  request(
    GET,
    `${ADS_TRACER_API}/v1/users/test-cases`,
    {},
    await jwtTokenHeader(),
    'Fetching Test Cases failed, or none found.'
  );

export const createTestCase = async (reqBody) => {
  const errHandler = (err) => {
    notification.error({
      message: err.response.data.message,
      description: err.message,
      duration: 5,
    });
    throw err;
  };
  return request(
    POST,
    `${ADS_TRACER_API}/v1/users/test-cases`,
    reqBody,
    await jwtTokenHeader(),
    'Creating test case failed',
    errHandler
  );
};

export const updateTestCase = async (testCaseId) =>
  request(
    PUT,
    `${ADS_TRACER_API}/v1/users/test-cases/${testCaseId}`,
    {},
    await jwtTokenHeader(),
    `Updating Test Case ${testCaseId} failed.`
  );

export const deleteTestCase = async (testCaseId) => {
  const headers = await jwtTokenHeader({ 'Content-Type': 'application/json' });
  headers.data = {};
  return request(
    DELETE,
    `${ADS_TRACER_API}/v1/users/test-cases/${testCaseId}`,
    {},
    headers,
    `Deleting Test Case ${testCaseId} failed.`
  );
};

export const deleteSharedTestCase = async (testCaseId) => {
  const headers = await jwtTokenHeader({ 'Content-Type': 'application/json' });
  headers.data = {};
  return request(
    DELETE,
    `${ADS_TRACER_API}/v1/users/shared-test-cases/${testCaseId}`,
    {},
    headers,
    `Deleting Shared Test Case ${testCaseId} failed.`
  );
};

export const shareTestCase = async (testCaseId) =>
  request(
    PUT,
    `${ADS_TRACER_API}/v1/users/test-cases/${testCaseId}/share`,
    {},
    await jwtTokenHeader(),
    `Sharing Test Case: ${testCaseId} failed.`
  );

export const acceptSharedTestCase = async (testCaseId) =>
  request(
    PUT,
    `${ADS_TRACER_API}/v1/users/test-cases/${testCaseId}/accept`,
    {},
    await jwtTokenHeader(),
    `Accepting Shared Test Case: ${testCaseId} failed.`
  );

export const getTracesByUserIdAndTraceIds = async (testCaseId) =>
  request(
    GET,
    `${ADS_TRACER_API}/v1/traces/${testCaseId}`,
    {},
    await jwtTokenHeader(),
    `Fetching Trace for Test Case ${testCaseId} failed.`
  );

export const getLinkAccounts = async () => {
  const config = await jwtTokenHeader();
  config.data = {};
  return request(
    GET,
    `${ADS_TRACER_API}/v1/linked-accounts`,
    {},
    config,
    'Failed fetching Linked Accounts, or no accounts found'
  );
};

export const linkHuluAccount = async (userId) =>
  request(
    POST,
    `${ADS_TRACER_API}/v1/linked-accounts/${userId}`,
    {},
    await jwtTokenHeader(),
    'Failed to Link Hulu Account'
  );

export const linkAccount = async (accountId, profileIds, nickname, publisherApp) =>
  request(
    POST,
    `${ADS_TRACER_API}/v1/accounts`,
    { 'account-id': accountId, 'profile-ids': profileIds, nickname: nickname, 'publisher-app': publisherApp },
    await jwtTokenHeader(),
    'Failed to link account'
  );

export const unlinkAccount = async (userId) => {
  const config = await jwtTokenHeader();
  config.data = {};
  return request(DELETE, `${ADS_TRACER_API}/v1/linked-accounts/${userId}`, {}, config, 'Failed to unlink Hulu Account');
};
