import React from 'react';
import { Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';

const dividerColor = '#c7c7c9';

const StyledDivider = styled(Divider)`
  height: 100%;
  border-color: ${dividerColor};
  margin: 0;
`;
const StyledRow = styled(Row)`
  min-height: 31px;
  align-items: center;
  margin: 7px;
  color: ${colors.colorGray82};
`;
const StyledTitle = styled(Col)`
  width: 26%;
`;
const StyledContent = styled(Col)`
  width: 74%;
  word-break: break-word;
`;

export const BidDetailsRowInfo = ({ title, data }) => {
  return (
    <>
      <StyledRow>
        <StyledTitle>{title}</StyledTitle>
        <StyledContent>{data && data}</StyledContent>
      </StyledRow>
      <StyledDivider />
    </>
  );
};
