import React from 'react';
import PropTypes from 'prop-types';
import { Card as CardComponent } from 'antd';
import styled from 'styled-components';

const StyledCard = styled(CardComponent)`
  .ant-card-head-title {
    padding: 6px 8px 0;
    font-size: 12px;
    color: #646f85;
    box-sizing: border-box;
  }
`;
export const StyledCardItem = styled.div`
  min-height: 32px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  span {
    overflow: auto;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #c7c7c9;
  }
  &.card-item-profile {
    justify-content: space-between;
  }
`;
export const Card = ({ title, children }) => {
  return (
    <StyledCard
      title={title}
      headStyle={{
        height: '31px',
        background: '#f0f1f5',
        boxSizing: 'border-box',
        padding: '0 0',
        minHeight: '31px',
        borderTop: '1px solid #B9BFCB',
      }}
      bodyStyle={{ padding: 0 }}
    >
      {children}
    </StyledCard>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};
