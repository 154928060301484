import { useState, useCallback } from 'react';
import { DEFAULT_PROFILES_PAGE, DEFAULT_PROFILES_PAGE_SIZE } from '../constants';

export const INITIAL_PROFILES_STATE = {
  page: DEFAULT_PROFILES_PAGE,
  size: DEFAULT_PROFILES_PAGE_SIZE,
  search: null,
  sort: ['date', 'descend'],
};

export const useProfilesController = () => {
  const [profiles, setProfiles] = useState({});
  const [profilesState, setProfilesState] = useState(INITIAL_PROFILES_STATE);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const pagination = { page: profilesState.page, size: profilesState.size };

  const handleProfiles = useCallback((profiles) => setProfiles(profiles), []);
  const handleProfilesState = (profilesState) => setProfilesState(profilesState);

  const handleLoading = useCallback((loading) => setLoading(loading), []);
  const handleError = useCallback((error) => setError(error), []);

  const onPaginationChange = (page, size) =>
    handleProfilesState((prevState) => {
      const pageToSet = size === prevState.size ? page : INITIAL_PROFILES_STATE.page;
      return {
        ...prevState,
        page: pageToSet,
        size,
      };
    });

  const onTableChange = (pagination, _, sorter) => {
    const { order, columnKey } = sorter;
    const sortToSet = order && columnKey ? [columnKey, order] : INITIAL_PROFILES_STATE.sort;
    handleProfilesState((prevState) => {
      const pageToSet = prevState.size ? prevState.page : DEFAULT_PROFILES_PAGE;
      return {
        ...prevState,
        page: pageToSet,
        sort: sortToSet,
      };
    });
  };

  const resetProfileState = () => {
    handleProfilesState(INITIAL_PROFILES_STATE);
  };

  return {
    profiles,
    profilesState,
    pagination,
    loading,
    error,
    handleProfiles,
    handleProfilesState,
    handleLoading,
    handleError,
    onPaginationChange,
    onTableChange,
    resetProfileState,
  };
};
