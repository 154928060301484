import styled from 'styled-components';
import React from 'react';
import { Button } from 'antd';

export const SSOSignIn = ({ src, children }) => {
  const StyledIframe = styled.iframe`
    height: 700px;
    width: 800px;
  `;
  return <StyledIframe src={src}>{children}</StyledIframe>;
};

export const PaddedButton = styled(Button)`
  margin: 10px;
`;

export const LogoBtn = ({ src, onClick, alt }) => {
  const StyledButton = styled(Button)`
    width: 130px;
    height: 100px;
    margin: 10px;
    padding: 0;
    display: inline-block;
  `;
  const StyledImg = styled.img`
    width: 120px;
    display: block;
    padding: 10px;
  `;
  return (
    <StyledButton onClick={onClick}>
      <StyledImg src={src} alt={alt} />
    </StyledButton>
  );
};

export const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
