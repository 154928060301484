export const STATUS_NAMES = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  SUCCESS_RATE: 'success_%',
  UNSUPPORTED_FORMAT: 'unsupported_format',
  UNSUPPORTED_FORMAT_VPAID: 'unsupported_format_vpaid',
  UNSUPPORTED_NON_SSL: 'unsupported_non_ssl',
  UNPARSEABLE_DOCUMENT: 'unparseable_document',
  UNPARSEABLE_DOCUMENT_NULL_VAST: 'unparseable_document_null_vast',
  UNPARSEABLE_DOCUMENT_PARSE_FAIL: 'unparseable_document_parse_fail',
  INVALID_DOCUMENT: 'invalid_document',
  INVALID_DOCUMENT_NO_CREATIVES: 'invalid_document_no_creatives',
  INVALID_DOCUMENT_INVALID_IMPRESSION_URL: 'invalid_document_invalid_impression_url',
  INVALID_DOCUMENT_NO_TRACKING_EVENTS: 'invalid_document_no_tracking_events',
  INVALID_DOCUMENT_NO_VIDEO_CLICKS: 'invalid_document_no_video_clicks',
  MISSING_ASSET: 'missing_asset',
  MISSING_ASSET_EXTERNAL_AD: 'missing_asset_external_ad',
  MISSING_ASSET_VAST_AD: 'missing_asset_vast_ad',
  INTERNAL_ERROR: 'internal_error',
  INTERNAL_ERROR_INLINE_VAST_PROCESS: 'internal_error_inline_vast_process',
  LOCAL_PENALTY: 'local_penalty',
  ASSET_REJECTION: 'asset_rejection',
  SENT: 'sent',
};

export const STATUS_COLORS = {
  [STATUS_NAMES.SUCCESS]: 'rgb(0, 128, 0)', // Green
  [STATUS_NAMES.FAILURE]: 'rgb(255, 0, 0)', // Red
  [STATUS_NAMES.SUCCESS_RATE]: 'rgb(0, 0, 255)', // Blue
  [STATUS_NAMES.UNSUPPORTED_FORMAT]: 'rgb(255, 255, 0)', // Yellow
  [STATUS_NAMES.UNSUPPORTED_FORMAT_VPAID]: 'rgb(128, 0, 128)', // Purple
  [STATUS_NAMES.UNSUPPORTED_NON_SSL]: 'rgb(255, 165, 0)', // Orange
  [STATUS_NAMES.UNPARSEABLE_DOCUMENT]: 'rgb(255, 69, 139)', // Rose
  [STATUS_NAMES.UNPARSEABLE_DOCUMENT_NULL_VAST]: 'rgb(255, 0, 255)', // Magenta
  [STATUS_NAMES.UNPARSEABLE_DOCUMENT_PARSE_FAIL]: 'rgb(0, 128, 128)', // Teal
  [STATUS_NAMES.INVALID_DOCUMENT]: 'rgb(128, 128, 0)', // Olive
  [STATUS_NAMES.INVALID_DOCUMENT_NO_CREATIVES]: 'rgb(255, 69, 0)', // Red-Orange
  [STATUS_NAMES.INVALID_DOCUMENT_INVALID_IMPRESSION_URL]: 'rgb(128, 0, 0)', // Maroon
  [STATUS_NAMES.INVALID_DOCUMENT_NO_TRACKING_EVENTS]: 'rgb(0, 255, 0)', // Lime
  [STATUS_NAMES.INVALID_DOCUMENT_NO_VIDEO_CLICKS]: 'rgb(128, 128, 128)', // Gray
  [STATUS_NAMES.MISSING_ASSET]: 'rgb(0, 0, 128)', // Navy
  [STATUS_NAMES.MISSING_ASSET_EXTERNAL_AD]: 'rgb(128, 0, 255)', // Lavender
  [STATUS_NAMES.MISSING_ASSET_VAST_AD]: 'rgb(0, 255, 128)', // Spring Green
  [STATUS_NAMES.INTERNAL_ERROR]: 'rgb(139, 0, 0)', // Dark Red
  [STATUS_NAMES.INTERNAL_ERROR_INLINE_VAST_PROCESS]: 'rgb(139, 69, 19)', // Saddle Brown
  [STATUS_NAMES.LOCAL_PENALTY]: 'rgb(218, 112, 214)', // Orchid
  [STATUS_NAMES.ASSET_REJECTION]: 'rgb(173, 216, 230)', // Light Blue
  [STATUS_NAMES.SENT]: 'rgb(64, 224, 208)', // Turquoise
};
