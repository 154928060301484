import React from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';

import { Button, Typography } from '@hulu/react-style-components';

import pageNotFoundBackground from './assets/images/link_broken.png';
import { CenterText, CenterTextBox } from '../Templates/CenterText';
import { Image } from '../Templates/Image';

const NotFound = ({ dispatch }) => {
  return (
    <CenterTextBox>
      <CenterText>
        <Image src={pageNotFoundBackground} />
        <Typography variant="h2">This Page isn't Available</Typography>
        <Typography variant="body1">
          This link doesn't exist, or page has been removed. Please recheck your link is correct.{' '}
        </Typography>
        <Button onClick={() => dispatch(routerRedux.push('/'))}>Go Back</Button>
      </CenterText>
    </CenterTextBox>
  );
};
export default connect()(NotFound);
