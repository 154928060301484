import React from 'react';
import { Collapse } from 'antd';

import { CollapseIcons } from '../../icons/CollapseIcons';
import { PanelHeader } from './PanelHeader';
import { BidCard } from './BidCard';
import { StyledCollapse } from './style';
import { capitalizer } from './capitalizer';
import { EmptyAdSourceSummaryTab } from './EmptyAdSourceSummaryTab';

const { Panel } = Collapse;

export const BidState = ({
  activeBidStatus,
  handleBidStatusActiveCollapse,
  bidStateData,
  openBidDetails,
  isUnifiedSelection,
}) => {
  if (Object.values(bidStateData)?.every((group) => group?.length === 0)) return <EmptyAdSourceSummaryTab />;
  return (
    <>
      <StyledCollapse
        expandIcon={({ isActive }) => <CollapseIcons isActive={isActive} />}
        activeKey={activeBidStatus}
        onChange={(key) => handleBidStatusActiveCollapse(key)}
      >
        {Object.entries(bidStateData)?.map(
          ([key, value]) =>
            value?.length > 0 && (
              <Panel header={<PanelHeader header={capitalizer(key)} count={value.length} />} key={key}>
                {value?.map((bid) => {
                  return (
                    <BidCard
                      bid={bid}
                      key={bid?.bidId}
                      openBidDetails={openBidDetails}
                      isUnifiedSelection={isUnifiedSelection}
                    />
                  );
                })}
              </Panel>
            )
        )}
      </StyledCollapse>
    </>
  );
};
