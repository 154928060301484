import React from 'react';

import { RowInfo } from '../Common/RowInfo';
import { StyledCard, StyledDivider } from '../style';

export const RequestParams = ({ isLoading, request }) => {
  const { id, app, badv, device, imp, regs, user } = request || {};
  return (
    <StyledCard title="Request Params" loading={isLoading}>
      <RowInfo title="requestID" data={id} />
      <StyledDivider height={24} />
      <RowInfo title="appBundle" data={app?.bundle} />
      <RowInfo title="appContentGenre" data={app?.content?.genre} />
      <RowInfo title="appContentID" data={app?.content?.id} />
      <RowInfo title="appContentLivestream" data={app?.content?.livestream} />
      <RowInfo title="appPublisherID" data={app?.publisher?.id} />
      {!!badv?.length && (
        <>
          <StyledDivider height={24} />
          <RowInfo title="badv" data={badv?.join(',')} />
        </>
      )}
      <StyledDivider height={24} />
      <RowInfo title="deviceDNT" data={device?.dnt} />
      <RowInfo title="deviceIFA" data={device?.ifa} />
      <RowInfo title="deviceIP" data={device?.ip} />
      <RowInfo title="deviceUA" data={device?.ua} />
      {!!imp?.length && (
        <>
          {imp?.map((item, index) => (
            <div key={item?.id}>
              <StyledDivider height={24} />
              <RowInfo title={`imp ${index + 1} ID`} data={item?.id} />
              <RowInfo title={`imp ${index + 1} videoMaxDuration`} data={item?.video?.maxduration} />
              <RowInfo title={`imp ${index + 1} VideoMinDuration`} data={item?.video?.minduration} />
            </div>
          ))}
        </>
      )}
      <StyledDivider height={24} />
      <RowInfo title="regsCoppa" data={regs?.coppa} />
      <RowInfo title="regsExt" data={regs?.ext || 'none'} />
      <StyledDivider height={24} />
      <RowInfo title="userID" data={user?.id} />
    </StyledCard>
  );
};
