import { jwtTokenHeader, request } from '../utils';
import { CONFIG_MANAGER_API, DELETE, GET, PATCH, POST, PUT } from '../constants';
import { notification } from 'antd';
import { AUDIT_HISTORY_PAGINATION_SIZE } from '../pages/ConfigManager/constants';

export async function getConfigData() {
  return request(
    GET,
    `${CONFIG_MANAGER_API}/partners/configs/sources`,
    {},
    await jwtTokenHeader(),
    'Failed to fetch config data'
  );
}

export async function getHistory(id, page) {
  return request(
    GET,
    `${CONFIG_MANAGER_API}/partners/configs/sources/audit/` +
      id +
      '?pageSize=' +
      AUDIT_HISTORY_PAGINATION_SIZE +
      '&page=' +
      page,
    {},
    await jwtTokenHeader(),
    'Failed to fetch history for source id: ' + id
  );
}

export async function createSource(reqBody) {
  const errHandler = (err) => {
    if (err.response.status === 400) {
      notification.error({
        message: 'Attempted to Create Duplicate Source',
        description: 'Failed to create source, it may already exist!',
        duration: 5,
      });
    } else {
      notification.error({
        message: err.response.data.message,
        description: err.message,
        duration: 5,
      });
    }
    throw err;
  };
  return request(
    POST,
    `${CONFIG_MANAGER_API}/partners/configs/sources`,
    reqBody,
    await jwtTokenHeader(),
    'Failed to create source',
    errHandler
  );
}

export async function updateSource(reqBody) {
  return request(
    PUT,
    `${CONFIG_MANAGER_API}/partners/configs/sources`,
    reqBody,
    await jwtTokenHeader(),
    'Failed to update Source'
  );
}

export async function updateSourceStatus(id, reqBody) {
  const headers = await jwtTokenHeader({ 'Content-Type': 'application/json-patch+json' });
  headers.data = {};
  return request(
    PATCH,
    `${CONFIG_MANAGER_API}/partners/configs/sources/${id}`,
    reqBody,
    headers,
    'Failed to update Source status'
  );
}

export async function deleteSource(id) {
  return request(
    DELETE,
    `${CONFIG_MANAGER_API}/partners/configs/sources/${id}`,
    {},
    await jwtTokenHeader(),
    'Failed to delete Source'
  );
}
