import styled from 'styled-components';
import { Button, Card, Select } from 'antd';

export const ReservationToolWrapper = styled.div`
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  float: right;
`;

export const StyledCard = styled(Card)`
  margin: 10%;
  width: 550px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export const StepsWrapper = styled.div`
  width: 80%;
  padding: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ContentPartnerSelect = styled(Select)`
  width: 275px;
`;

export const SeriesSelect = styled(Select)`
  width: 100%;
`;
