export const DSP_ID = 'dsp-id';

export const formatCpOptions = (rawCp) => {
  return Object.entries(rawCp).map(([id, obj]) => {
    return {
      label: obj.name,
      value: obj.id,
    };
  });
};

export const formatDspOptions = (dsp) => {
  return dsp.map(({ 'dsp-name': label, 'dsp-uuid': value }) => ({ label, value }));
};

export const REVIEW_STATUS_OPTIONS = [
  { label: 'PENDING', value: 'PENDING' },
  { label: 'PREVIEW', value: 'PREVIEW' },
  { label: 'REVIEW', value: 'REVIEW' },
  { label: 'REJECTED', value: 'REJECTED' },
  { label: 'APPROVED', value: 'APPROVED' },
];

export const SOURCE_OPTIONS = [
  { label: 'DIRECT', value: 'DIRECT' },
  { label: 'DSP_DIRECT', value: 'DSP_DIRECT' },
  { label: 'FREEWHEEL', value: 'FREEWHEEL' },
  { label: 'GOOGLE', value: 'GOOGLE' },
  { label: 'MAGNITE', value: 'MAGNITE' },
  { label: 'SELF_SERVE', value: 'SELF_SERVE' },
];

export const CREATIVE_TYPE_OPTIONS = [
  { label: 'BRIGHTLINE', value: 'BRIGHTLINE' },
  { label: 'DISPLAY', value: 'DISPLAY' }, // Deprecated
  { label: 'INNOVID', value: 'INNOVID' },
  { label: 'MARQUEE_PROMOTED_CONTENT', value: 'MARQUEE_PROMOTED_CONTENT' }, // Deprecated
  { label: 'PAUSE', value: 'PAUSE' },
  { label: 'SLATE', value: 'SLATE' },
  { label: 'VAST_VIDEO', value: 'VAST_VIDEO' },
  { label: 'VIDEO', value: 'VIDEO' },
  { label: 'MAGNITE', value: 'MAGNITE' },
  { label: 'DRAX', value: 'DRAX' },
  { label: 'BE_SELECTOR_SLATE', value: 'BE_SELECTOR_SLATE' }, // Deprecated
  { label: 'BE_SELECTOR_VIDEO', value: 'BE_SELECTOR_VIDEO' }, // Deprecated
  { label: 'AD_SELECTOR_SLATE', value: 'AD_SELECTOR_SLATE' },
  { label: 'AD_SELECTOR_VIDEO', value: 'AD_SELECTOR_VIDEO' },
  { label: 'GATEWAY_GO', value: 'GATEWAY_GO' },
];

export const INGESTION_TYPE_OPTIONS = [
  { label: 'PRE_INGEST', value: 'PRE_INGEST' },
  { label: 'DYNAMIC', value: 'DYNAMIC' },
];

export const PUBLISHER_TYPE_OPTIONS = [
  { label: 'DISNEY_PLUS', value: 'DISNEY_PLUS' },
  { label: 'HULU', value: 'HULU' },
  { label: 'UNIFIED', value: 'UNIFIED' },
];

export const COUNTRY_CODE_OPTIONS = [
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'CA' },
  { label: 'Denmark', value: 'DK' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Italy', value: 'IT' },
  { label: 'Norway', value: 'NO' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Chile', value: 'CL' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Finland', value: 'FI' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Turkey', value: 'TR' },
];

export const COUNTRY_CODE_TO_NAME_MAP = COUNTRY_CODE_OPTIONS.reduce((map, option) => {
  map[option.value] = option.label;
  return map;
}, {});
