import { Q_CHECK, Q_CHECK_TABS } from '../../constants';
import React, { useState } from 'react';
import { QCheckWrapper } from './styles';
import MediaFileValidationBox from './MediaFileValidationBox';
import VastValidationBox from './VastValidationBox';
import { Spin } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Tab, TabNavigation, TabPanel } from '@hulu/react-style-components';
import { Q_CHECK_TAB_MEDIA_FILE_URL, Q_CHECK_TAB_VAST_XML, Q_CHECK_TABNAV } from '../../testUtils';
import MediaFileResults from './MediaFileResults';
import VastTable from './VastTable';

const QCheck = ({
  dispatch,
  pathParams,
  mediaFileValidationRecord,
  meidaFileValidationError,
  vastValidationRecords,
  vastValidationError,
  permissions,
}) => {
  const [tab, setTab] = useState(pathParams?.tab ?? Q_CHECK_TABS.MEDIA_FILE);
  const [mediaFileValidating, setMediaFileValidating] = useState(false);
  const [vastXmlValidating, setVastXmlValidating] = useState(false);
  return (
    <QCheckWrapper>
      <TabNavigation
        activeValue={tab}
        onTabClick={(_, selectedTab) => {
          setTab(selectedTab);
          dispatch(routerRedux.push('/' + Q_CHECK + '/' + selectedTab));
        }}
        data-testid={Q_CHECK_TABNAV}
      >
        <Tab value={Q_CHECK_TABS.MEDIA_FILE}>MEDIA FILE</Tab>
        <Tab value={Q_CHECK_TABS.VAST_XML}>VAST AD</Tab>
      </TabNavigation>

      {/* Panel for media file url input */}
      <TabPanel value={Q_CHECK_TABS.MEDIA_FILE} activeValue={tab}>
        <div data-testid={Q_CHECK_TAB_MEDIA_FILE_URL}>
          <Spin spinning={mediaFileValidating}>
            <MediaFileValidationBox
              setMediaFileValidating={setMediaFileValidating}
              meidaFileValidationError={meidaFileValidationError}
            />
            {mediaFileValidationRecord && (
              <MediaFileResults
                url={mediaFileValidationRecord.mediaFileUrl}
                mediaFileRequirements={mediaFileValidationRecord.mediaFileRequirements}
              />
            )}
          </Spin>
        </div>
      </TabPanel>

      {/* Panel for vast xml input */}
      <TabPanel value={Q_CHECK_TABS.VAST_XML} activeValue={tab}>
        <div data-testid={Q_CHECK_TAB_VAST_XML}>
          <Spin spinning={vastXmlValidating}>
            <VastValidationBox
              setVastXmlValidating={setVastXmlValidating}
              vastValidationError={vastValidationError}
              vastValidationRecords={vastValidationRecords}
              permissions={permissions}
            />
            {Array.isArray(vastValidationRecords) && !vastValidationRecords.length ? null : (
              <VastTable records={vastValidationRecords} />
            )}
          </Spin>
        </div>
      </TabPanel>
    </QCheckWrapper>
  );
};

function mapStateToProps({ qCheck, app }) {
  return {
    pathParams: app.pathParams,
    mediaFileValidationRecord: qCheck.mediaFileValidation.record,
    meidaFileValidationError: qCheck.mediaFileValidation.error,
    vastValidationError: qCheck.vastValidation.error,
    vastValidationRecords: qCheck.vastValidation.records,
    permissions: app.currentUser.permissions,
  };
}

export default connect(mapStateToProps)(QCheck);
