import { connect } from 'dva';
import { TabPanel } from '@hulu/react-style-components';
import React, { useCallback, useEffect, useState } from 'react';
import { Y_SCROLL } from '../../DurationManager/styles';
import { HISTORY } from '../../../constants';
import { Table } from 'antd';
import { configMgrHistoryCols } from '../../Templates/columns';
import { AUDIT_HISTORY_PAGINATION_SIZE } from '../constants';
import { StyledPagination } from './styles';

const SourceDetailsHistory = ({ dispatch, selectedSource, selectedSourceId, tab }) => {
  const [history, setHistory] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchHistory = useCallback(
    async (page) => {
      const data = await dispatch({
        type: 'configManager/fetchHistory',
        payload: { id: selectedSourceId, page: page - 1 }, // api is indexed starting at 0
      });
      setHistory(data.history);
      setCurrentPage(data.currentPage + 1); // api is indexed starting at 0
      setTotalLogs(data.totalLogs);
    },
    [selectedSourceId, dispatch]
  );

  useEffect(() => {
    fetchHistory(currentPage);
  }, [fetchHistory, currentPage]);

  const onPaginationChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  return (
    <TabPanel value={HISTORY} activeValue={tab}>
      <br />
      <Table dataSource={history} columns={configMgrHistoryCols(history)} scroll={Y_SCROLL} pagination={false} />
      <StyledPagination pageSize={AUDIT_HISTORY_PAGINATION_SIZE} total={totalLogs} onChange={onPaginationChange} />
    </TabPanel>
  );
};
function mapStateToProps({ app, configManager, loading }) {
  return {
    selectedSource: configManager.configManagerState.selectedSource,
    pathParams: app.pathParams,
    loading: loading.effects['configManager/fetchConfigData'],
  };
}

export default connect(mapStateToProps)(SourceDetailsHistory);
