import { Button, Form, InputNumber } from 'antd';
import { labelRender } from '../utils';
import { StyledMinusButton } from '../styles';
import { PlusOutlined } from '@ant-design/icons';
import { DURATION_MANAGER_RULEEDIT_ADD } from '../../../testUtils';

const DURATION_STYLE = { width: '60%' };
const INPUT_DURATION_VALIDATION = [
  {
    required: true,
    message: 'Please input duration (ms)...',
  },
];

const DurationItem = ({ layout, layoutWithoutLabel }) => (
  <Form.List name="durations-ms">
    {(fields, { add, remove }, { errors }) => (
      <>
        {fields.map((field, index) => {
          // Button for removing the duration in form
          const deleteButton =
            fields.length > 1 ? (
              <StyledMinusButton className="dynamic-delete-button" onClick={() => remove(field.name)} />
            ) : null;

          // Form area for enumeration of all durations to commit
          return (
            <Form.Item
              {...(index === 0 ? layout : layoutWithoutLabel)}
              label={index === 0 ? labelRender('Duration(s)') : ''}
              required={true}
              key={field.key}
            >
              <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} rules={INPUT_DURATION_VALIDATION} noStyle>
                <InputNumber style={DURATION_STYLE} min={1} />
              </Form.Item>
              {deleteButton}
            </Form.Item>
          );
        })}

        {/* Form area to add another duration to the rule */}
        <div data-testid={DURATION_MANAGER_RULEEDIT_ADD}>
          <Form.Item {...layoutWithoutLabel}>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              style={DURATION_STYLE}
              icon={<PlusOutlined />}
            >
              Add duration
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </div>
      </>
    )}
  </Form.List>
);
export default DurationItem;
