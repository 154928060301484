import React, { useMemo } from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';
import { BID_WON } from '../constants';

export const SummaryCard = ({ isLoading, pod, bids }) => {
  const bidsWon = useMemo(() => bids.filter((bid) => bid.bidStatus === BID_WON), [bids]);
  const winningAdSources = useMemo(() => [...new Set(bidsWon.map((bid) => bid.demandPartner))], [bidsWon]);
  const separatedWinningAdSources = winningAdSources.join(', ');

  return (
    <StyledCard title={`Pod ${pod?.id}`} loading={isLoading}>
      <RowInfo title="Total Eligible Slots" data={pod?.totalEligibleSlots} />
      <RowInfo title="Ad Pod Duration" data={`${pod?.duration} sec`} />
      <RowInfo title="Total Ads Returned" data={bids.length} />
      <RowInfo title="Slots filled" data={bidsWon.length} />
      <RowInfo title="Winning Ad Sources" data={separatedWinningAdSources} />
    </StyledCard>
  );
};
