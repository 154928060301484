import UrlPattern from 'url-pattern';
export const NOT_FOUND_PATH = '/404';

// ADS TRACER
export const ADS_TRACER_TITLE = 'Ads Tracer';
export const ADS_TRACER = 'ads-tracer';
export const ADS_TRACER_CAPS = 'ADS_TRACER';
export const TEST_CASE = 'test-case';
export const TRACE = 'trace';
export const ADS_TRACER_URL_PATTERN = new UrlPattern(`/${ADS_TRACER}(/${TEST_CASE}/:testCaseId)`);

export const NEW_TEST_CASE = 'new-test-case';
export const LINK_NEW_ACCOUNT = 'link-new-account';
export const NEW_TEST_CASE_URL_PATTERN = new UrlPattern(`/${NEW_TEST_CASE}(/:addAccount)`);
export const REFRESH_TEST_CASE_INTERVAL_MILLIS = 30000;
export const AUTH_DELAY_INTERVAL_MILLIS = 900000; // 15 minutes

export const ACTIVE = 'ACTIVE';
export const SUSPENDED = 'SUSPENDED';
export const EXPIRED = 'EXPIRED';

export const PROVIDER_MAP = {
  hulu: 'Hulu',
  dst: 'Demand Source Transition',
  gam: 'Google Ad Manager',
  ad_exchange: 'Ad Exchange',
  pxp: 'Programmatic For Partners - Disney',
};

export const CREATE_TEST_CASE = 'CreateTestCase';
export const SHARE_TEST_CASE = 'SHARE_TRACES';
export const VIEW_TRACES = 'VIEW_TRACES';
export const DEFAULT_MIN_WIDTH = 50;

// AD INVESTIGATOR
export const AD_INVESTIGATOR = 'ad-investigator';
export const AD_INVESTIGATOR_CAPS = 'AD_INVESTIGATOR';
export const AD_INVESTIGATOR_TITLE = 'Ad Investigator';
export const VIEW_OVERRIDES = 'VIEW_OVERRIDES';
export const USER = 'user';
export const PROFILE = 'profile';
export const DEVICE = 'device';
export const EXPIRATION_TIME = 'exp-time';
export const VIEW_ADS = 'ViewAds';
export const CREATE_OVERRIDES = 'CreateOverrides';
export const EXPORT_CSV = 'ExportCsv';
export const VIDEO_AD_TYPE = 'VideoAds';
export const PAUSE_AD_TYPE = 'PauseAds';

export const ADS = 'ads';
export const OVERRIDES = 'overrides';
export const USER_PROFILE = 'userProfile';
export const POD_POSITIONS = [
  'LIVE',
  'PREROLL',
  'MIDROLL_01',
  'MIDROLL_02',
  'MIDROLL_03',
  'MIDROLL_04',
  'MIDROLL_05',
  'MIDROLL_06',
  'MIDROLL_07',
  'MIDROLL_08',
  'MIDROLL_09',
  'MIDROLL_10',
  'MIDROLL_11',
  'MIDROLL_12',
  'POSTROLL',
];
export const AD_INVESTIGATOR_URL_PATTERN = new UrlPattern(`/${AD_INVESTIGATOR}(/:tab)(/${ADS}/:adId)`);

// LIVEPOD DURATION MANAGER
export const DURATION_MANAGER = 'duration-manager';
export const DURATION_MANAGER_CAPS = 'DURATION_MANAGER';
export const DURATION_MANAGER_TITLE = 'Duration Manager';
export const DURATION_MANAGER_RULE_INFO = 'rule-info';
export const DURATION_MANAGER_CREATE_RULE = 'new-rule';
export const DURATION_MANAGER_BULK_UPLOAD = 'bulk-upload';
export const DURATION_MANAGER_CHANNELS = 'channels';
export const DURATION_MANAGER_SERIES = 'series';
export const DURATION_MANAGER_PREMIERE = 'premiere';
export const DURATION_MANAGER_LEAGUES = 'leagues';
export const DURATION_MANAGER_PATH = `/${DURATION_MANAGER}`;
export const DURATION_MANAGER_RULE_INFO_PATH_PREFIX = `/${DURATION_MANAGER}/${DURATION_MANAGER_RULE_INFO}`;
export const DURATION_MANAGER_CREATE_RULE_PATH = `/${DURATION_MANAGER}/${DURATION_MANAGER_CREATE_RULE}`;
export const DURATION_MANAGER_BULK_UPLOAD_PATH = `/${DURATION_MANAGER}/${DURATION_MANAGER_BULK_UPLOAD}`;
export const DURATION_MANAGER_URL_PATTERN = new UrlPattern(`/${DURATION_MANAGER}`);
export const DURATION_MANAGER_RULE_INFO_CHANNEL_URL_PATTERN = new UrlPattern(
  `${DURATION_MANAGER_RULE_INFO_PATH_PREFIX}/${DURATION_MANAGER_CHANNELS}/:channelId`
);
export const DURATION_MANAGER_RULE_INFO_SERIES_URL_PATTERN = new UrlPattern(
  `${DURATION_MANAGER_RULE_INFO_PATH_PREFIX}/${DURATION_MANAGER_CHANNELS}/:channelId/${DURATION_MANAGER_SERIES}/:seriesId`
);
export const DURATION_MANAGER_RULE_INFO_LEAGUES_URL_PATTERN = new UrlPattern(
  `${DURATION_MANAGER_RULE_INFO_PATH_PREFIX}/${DURATION_MANAGER_CHANNELS}/:channelId/${DURATION_MANAGER_LEAGUES}/:leagueId`
);
export const DURATION_MANAGER_CREATE_RULE_URL_PATTERN = new UrlPattern(DURATION_MANAGER_CREATE_RULE_PATH);

export const RULE_TYPE = 'rule-type';
export const CHANNEL_ID = 'channel-id';
export const SERIES_ID = 'series-id';
export const LEAGUE_ID = 'league-id';
export const SPORTS_ID = 'sports-id';
export const EVENT_ID = 'event-id';
export const DURATION_MS = 'durations-ms';
export const SINGLE_PROGRAM = 'is-single-program';
export const PARTIAL_POD = 'is-partial-pods';
export const CHANGE_TYPE = 'change-type';
export const VIEW_DURATION_RULES = 'VIEW_DURATION_RULES';
export const DURATION_MANAGER_NAME = 'DURATION_MANAGER';

// CREATIVE TRACKER
export const UP_CREATIVE_TRACKER = 'up-creative-tracker';
export const UP_CREATIVE_TRACKER_CAPS = 'UP_CREATIVE_TRACKER';
export const CREATIVE_TRACKER_TITLE = 'Creative Tracker';
export const CREATIVE_TRACKER_URL_PATTERN = new UrlPattern(`/${UP_CREATIVE_TRACKER}`);
export const EMPTY_VALUES = '';
export const STATUS = 'status';
export const VAST = 'VAST';
export const VPAID = 'VPAID';
export const MP4 = 'video/mp4';
export const VIDEO_CONTROLS = ['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen'];
export const VIEW_ASSET_VIDEOS = 'ViewAssetVideos';

export const STATUSES = {
  UNKNOWN: {
    code: 0,
    color: 'cyan',
    title: 'Unknown',
  },
  INGESTING: {
    code: 1,
    color: 'blue',
    title: 'Ingest',
    inTimeline: true,
  },
  SYSTEM_REJECTION: {
    code: 1,
    color: 'red',
    title: 'System Rejection',
  },
  ASSET_CREATED: {
    code: 10,
    color: 'cyan',
    title: 'Asset Create',
    inTimeline: true,
  },
  PRE_QC_TRANSCODING: {
    code: 20,
    color: 'cyan',
    title: 'Pre-QC Transcode',
    inTimeline: true,
  },
  QC: {
    code: 30,
    color: 'cyan',
    title: 'QC',
    inTimeline: true,
  },
  DVP_REJECTION: {
    code: 30,
    color: 'red',
    title: 'DVP Rejection',
  },
  POST_QC_TRANSCODING: {
    code: 40,
    color: 'cyan',
    title: 'Post-QC Transcode',
    inTimeline: true,
  },
  PUBLISHING: {
    code: 50,
    color: 'cyan',
    title: 'Publishing',
  },
  PUBLISHED: {
    code: 60,
    color: 'green',
    title: 'Publish',
    inTimeline: true,
  },
};

export const PAGINATION_SIZE = 5000;

// VAST TRACKER
export const VAST_TRACKER = 'vast-tracker';
export const VAST_TRACKER_CAPS = 'VAST_TRACKER';
export const VAST_TRACKER_TITLE = 'Vast Tracker';
export const VAST_TRACKER_URL_PATTERN = new UrlPattern(`/${VAST_TRACKER}(/:tab)`);
export const COUNT_TYPES = {
  ADUNITID: 'ad-unit-ids',
  ADSERVER: 'ad-servers',
  NETWORK: 'networks',
  PARTNER_GROUP: 'partner-groups',
};
export const TOP_NUM_SELECTED = {
  TOP_TEN: 10,
  TOP_TWENTY: 20,
  TOP_FIFTY: 50,
};

// RESERVATION TOOL
export const RESERVATION_TOOL = 'reservation-tool';
export const RESERVATION_TOOL_CAPS = 'RESERVATION_TOOL';
export const RESERVATION_TOOL_TITLE = 'Reservation Tool';
export const VIEW_INVENTORY_RESERVATIONS = 'VIEW_INVENTORY_RESERVATIONS';
export const RESERVATION_TOOL_URL_PATTERN = new UrlPattern(`/${RESERVATION_TOOL}(/:tab)`);
export const RESERVATION_TOOL_TABS = {
  RESERVE_SERIES: 'reserve-series',
  VIEW_AUDIT_TRAIL: 'view-audit-trail',
};
export const RESERVATION_REQUEST = {
  SERIES: 'series',
  SERIES_IDENTIFIER: 'series-identifier',
  MONTH: 'month',
  CONTENT_PARTNER_ID: 'content-partner-id',
};

// Q-CHECK
export const Q_CHECK = 'q-check';
export const Q_CHECK_CAPS = 'Q_CHECK';
export const Q_CHECK_TITLE = 'Q-Check';
export const Q_CHECK_INGEST_CREATIVE = 'Q_CHECK_INGEST_CREATIVE';
export const Q_CHECK_URL_PATTERN = new UrlPattern(`/${Q_CHECK}(/:tab)`);
export const Q_CHECK_TABS = {
  MEDIA_FILE: 'media-file',
  VAST_XML: 'vast-xml',
};

export const REQUIREMENT_PASS_STATUS = {
  true: {
    color: 'green',
    title: '\u2713',
  },
  false: {
    color: 'red',
    title: '\u2715',
  },
};

export const TAG_VALID_STATUS = {
  true: {
    color: 'green',
    title: 'VALID',
  },
  false: {
    color: 'red',
    title: 'INVALID',
  },
};

// Partner DAI Config Manager
export const CONFIG_MANAGER = 'config-manager';
export const CONFIG_MANAGER_CAPS = 'CONFIG_MANAGER';
export const CONFIG_MANAGER_TITLE = 'Partner DAI Config Manager';
export const CONFIG_MANAGER_PATH = `/${CONFIG_MANAGER}`;
export const PARTNER_DAI_CONFIG = 'PARTNER_DAI_CONFIG';
export const PARTNER_DAI_CONFIG_READ = 'PARTNER_DAI_CONFIG_READ';
export const PARTNER_DAI_CONFIG_ENABLE_DISABLE_CONFIG = 'PARTNER_DAI_CONFIG_ENABLE_DISABLE_CONFIG';
export const CONFIG_MANAGER_URL_PATTERN = new UrlPattern(`/${CONFIG_MANAGER}(/:tab)`);

export const CONFIG_MANAGER_CREATE_SOURCE_DETAILS = 'create-source-details';
export const CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH = `/${CONFIG_MANAGER}/${CONFIG_MANAGER_CREATE_SOURCE_DETAILS}`;
export const CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH_PREFIX = `/${CONFIG_MANAGER}/${CONFIG_MANAGER_CREATE_SOURCE_DETAILS}`;
export const CONFIG_MANAGER_CREATE_SOURCE_DETAILS_URL_PATTERN = new UrlPattern(
  `${CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH_PREFIX}(/:tab)`
);

export const CONFIG_MANAGER_SOURCE_DETAILS = 'source-details';
export const CONFIG_MANAGER_SOURCE_DETAILS_PATH = `/${CONFIG_MANAGER}/${CONFIG_MANAGER_SOURCE_DETAILS}`;
export const CONFIG_MANAGER_SOURCE_DETAILS_PATH_PREFIX = `/${CONFIG_MANAGER}/${CONFIG_MANAGER_SOURCE_DETAILS}`;
export const CONFIG_MANAGER_SOURCE_DETAILS_URL_PATTERN = new UrlPattern(
  `${CONFIG_MANAGER_SOURCE_DETAILS_PATH_PREFIX}/source-id/:sourceId`
);

// AUCTION SNAPSHOT
export const AUCTION_SNAPSHOT = 'auction-snapshot';
export const AUCTION_SNAPSHOT_CAPS = 'AUCTION_SNAPSHOT';
export const AUCTION_SNAPSHOT_TITLE = 'Auction Snapshots';
export const AUCTION_SNAPSHOT_URL_PATTERN = new UrlPattern(`/${AUCTION_SNAPSHOT}(/:tab)`);
export const AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE = 0;
export const AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE_SIZE = 10;
export const AUCTION_SNAPSHOT_DEVICES_FILTER = 'deviceFamily';
export const AUCTION_SNAPSHOT_APP_NAMES_FILTER = 'appName';
export const AUCTION_SNAPSHOT_COUNTRY_FILTER = 'country';
export const AUCTION_SNAPSHOT_CHIP_TITLE_DEVICE = 'Device';
export const AUCTION_SNAPSHOT_CHIP_TITLE_APP = 'Publisher';
export const AUCTION_SNAPSHOT_CHIP_TITLE_COUNTRY = 'Country';
export const AUCTION_SNAPSHOT_FILTERS_DATE_PICKER_FORMAT = 'MMMM Do, YYYY';
export const AUCTION_SNAPSHOT_DATE_FORMAT = 'DD/MM/YYYY h:mm';
export const AUCTION_SNAPSHOT_US_DATE_FORMAT = 'MM/DD/YYYY, h:mm A z';
export const AUCTION_SNAPSHOT_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';
export const INTEGRATORS_TIMESTAMP_FORMAT = 'MM/DD/YYYY, h:mm:ss A z';
export const EASTERN_TIME_ZONE = 'America/New_York';
export const AUCTION_SNAPSHOT_DETAILED_VIEW = 'auction-snapshot-detailed-view';
export const AUCTION_SNAPSHOT_DETAILED_VIEW_URL_PATTERN = new UrlPattern(`/${AUCTION_SNAPSHOT_DETAILED_VIEW}/:id`);
export const DETAILED_VIEW_INTEGRATORS_AMAZON = 'detailed-view-integrators-amazon';
export const DETAILED_VIEW_INTEGRATORS_MAGNITE = 'detailed-view-integrators-magnite';
export const DETAILED_VIEW_INTEGRATORS_ROKU = 'detailed-view-integrators-roku';
export const DETAILED_VIEW_INTEGRATORS_SPOTX = 'detailed-view-integrators-spox';
export const DETAILED_VIEW_FINAL_RESPONSE = 'detailed-view-final-response';
export const DETAILED_VIEW_AD_BREAKS = 'detailed-view-ad-breaks';
export const BID_STATUS = 'bid-status';
export const BIDDER = 'bidder';
export const TIME_FRAMES = [
  { label: '30 minutes', value: 'PT30M' },
  { label: '45 minutes', value: 'PT45M' },
  { label: '1 hour', value: 'PT1H' },
  { label: '2 hours', value: 'PT2H' },
  { label: '4 hours', value: 'PT4H' },
  { label: '8 hours', value: 'PT8H' },
  { label: '12 hours', value: 'PT12H' },
  { label: '24 hours', value: 'PT24H' },
];
export const DURATION_OPTIONS = [
  { label: '5', value: 'PT5S' },
  { label: '15', value: 'PT15S' },
  { label: '30', value: 'PT30S' },
  { label: '60', value: 'PT1M' },
  { label: '90', value: 'PT1M30S' },
];

// APIs
export const ADS_TRACER_API = '/api/ads-tracer';
export const AD_INVESTIGATOR_API = '/api/ad-investigator';
export const ADDITIONAL_AD_INVESTIGATOR_API = '/api/additional-ad-investigator';
export const ASSET_MANAGER_DAAPI_API = '/api/asset-manager-daapi';
export const LIVEPOD_DURATION_MANAGER_API = '/api/ads-livepod-duration-manager';
export const VAST_TRACKER_API = '/api/vast-tracker';
export const RESERVATIONS_API = '/api/reservation-tool';
export const Q_CHECK_API = '/api/q-check';
export const CONFIG_MANAGER_API = '/api/config-manager';
export const AUCTION_SNAPSHOT_API = '/api/auction-snapshot';
export const RBAC_API = '/api/rbac';
export const CREATIVE_TRACKER_API = '/api/creative-tracker';
export const ZENDESK_API = '/api/zendesk';

// HTTP verbs
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';

// HTTP Status codes
export const SUCCESS = 200;
export const CREATED = 201;
export const BAD_REQUEST = 400;
export const NOT_FOUND = 404;
export const NO_CONTENT = 204;
export const CONFLICT = 409;
export const SERVER_ERROR = 500;

// General Strings
export const NA = 'N/A';
export const ACCESS = 'access';
export const VIEW = 'view';
export const EDIT = 'edit';
export const DENY = 'DENY';
export const ALLOW = 'ALLOW';
export const PERMISSIONS = 'permissions';
export const PERMISSION_TYPE = 'permission-type';
export const ACTION_TYPE = 'action-type';
export const HISTORY = 'history';

// Default Styling Props
export const DEFAULT_Y_SCROLL = { y: '43vh' };

export const VAST_TRACKER_TABS = 'VAST_TRACKER_TABS';
export const VIEW_AD_UNIT_IDS_TAB = 'VIEW_AD_UNIT_IDS_TAB';
export const VIEW_AD_SERVERS_TAB = 'VIEW_AD_SERVERS_TAB';
export const VIEW_NETWORKS_TAB = 'VIEW_NETWORKS_TAB';
export const VIEW_PARTNER_GROUPS_TAB = 'VIEW_PARTNER_GROUPS_TAB';

export const TOOL_PERMISSION_MAP = {
  [VIEW_TRACES]: ADS_TRACER,
  [VIEW_OVERRIDES]: AD_INVESTIGATOR,
  [VIEW_DURATION_RULES]: DURATION_MANAGER,
  [VIEW_INVENTORY_RESERVATIONS]: RESERVATION_TOOL,
  [Q_CHECK_INGEST_CREATIVE]: Q_CHECK,
  [PARTNER_DAI_CONFIG_READ]: CONFIG_MANAGER,
  [PARTNER_DAI_CONFIG_ENABLE_DISABLE_CONFIG]: CONFIG_MANAGER,
};

export const TOOL_NAME_MAP = {
  [ADS_TRACER_CAPS]: ADS_TRACER,
  [AD_INVESTIGATOR_CAPS]: AD_INVESTIGATOR,
  [AUCTION_SNAPSHOT_CAPS]: AUCTION_SNAPSHOT,
  [UP_CREATIVE_TRACKER_CAPS]: UP_CREATIVE_TRACKER,
  [DURATION_MANAGER_CAPS]: DURATION_MANAGER,
  [RESERVATION_TOOL_CAPS]: RESERVATION_TOOL,
  [VAST_TRACKER_CAPS]: VAST_TRACKER,
  [Q_CHECK_CAPS]: Q_CHECK,
  [PARTNER_DAI_CONFIG]: CONFIG_MANAGER,
};

export const TOOLS_LIST = {
  [ADS_TRACER]: {
    title: ADS_TRACER_TITLE,
    toolName: ADS_TRACER_CAPS,
    permissions: { view: [VIEW_TRACES] },
  },
  [AD_INVESTIGATOR]: {
    title: AD_INVESTIGATOR_TITLE,
    toolName: AD_INVESTIGATOR_CAPS,
    permissions: { view: [VIEW_OVERRIDES] },
  },
  [UP_CREATIVE_TRACKER]: {
    title: CREATIVE_TRACKER_TITLE,
    toolName: UP_CREATIVE_TRACKER_CAPS,
  },
  [VAST_TRACKER]: {
    title: VAST_TRACKER_TITLE,
    toolName: VAST_TRACKER_CAPS,
  },
  [DURATION_MANAGER]: {
    title: DURATION_MANAGER_TITLE,
    toolName: DURATION_MANAGER_CAPS,
    permissions: { view: [VIEW_DURATION_RULES] },
  },
  [RESERVATION_TOOL]: {
    title: RESERVATION_TOOL_TITLE,
    toolName: RESERVATION_TOOL_CAPS,
    permissions: { view: [VIEW_INVENTORY_RESERVATIONS] },
  },
  [Q_CHECK]: {
    title: Q_CHECK_TITLE,
    toolName: Q_CHECK_CAPS,
    permissions: { edit: [Q_CHECK_INGEST_CREATIVE] },
  },
  [CONFIG_MANAGER]: {
    title: CONFIG_MANAGER_TITLE,
    toolName: CONFIG_MANAGER_CAPS,
    permissions: { view: [PARTNER_DAI_CONFIG_READ], edit: [PARTNER_DAI_CONFIG_ENABLE_DISABLE_CONFIG] },
  },
  [AUCTION_SNAPSHOT]: {
    title: AUCTION_SNAPSHOT_TITLE,
    toolName: AUCTION_SNAPSHOT_CAPS,
  },
};

// Select Component
export const SELECT_MODE = {
  MULTIPLE: 'multiple',
  TAGS: 'tags',
};

export const DATA_TYPE = {
  NUMBER: 'Number',
};
