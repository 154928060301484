import React from 'react';
import { Button, DatePicker, Spin, Tooltip } from 'antd';
import { connect } from 'dva';
import moment from 'moment';

import { routerRedux } from 'dva/router';
import { Typography } from '@hulu/react-style-components';
import { CloseCircleOutlined, ExportOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { ACTIVE, ADS_TRACER, SUSPENDED, TEST_CASE } from '../../../constants';
import { testCaseColumns } from '../../Templates/columns';

import './row.css';
import { ADSTRACER_TESTCASES_TABLE } from '../../../testUtils';
import { ResizableTable } from '../../Templates/ResizableTable';
import { MomentDatePicker } from '../../../utils';
const { RangePicker } = MomentDatePicker;
const TABLE_SCROLL_Y = { y: '60vh' };

const TestCases = ({
  dispatch,
  testCases = [],
  selected,
  setSelectedTestCase,
  setShareModalTestCaseId,
  searchParams = {},
  rangeFilter,
  loading,
  testCaseWidths = [],
}) => {
  const handleSelectTestCase = (key, [{ testCaseId }]) => {
    setSelectedTestCase(testCaseId);
    dispatch(routerRedux.push('/' + ADS_TRACER + '/' + TEST_CASE + '/' + testCaseId));
    dispatch({
      type: 'adsTracer/updateSummariesFilters',
      payload: {},
    });
  };

  const rowExtraRender = (val, { testCaseId, status, isSharedToMe, isSharedToOther }) => {
    let updateTestCaseIcon = '';
    let updateTestCaseToolTipTitle = '';
    let hasUpdateTestCaseButton = true;

    switch (status) {
      case ACTIVE:
        updateTestCaseIcon = <PauseCircleOutlined />;
        updateTestCaseToolTipTitle = 'Suspend Test Case';
        break;
      case SUSPENDED:
        updateTestCaseIcon = <PlayCircleOutlined />;
        updateTestCaseToolTipTitle = 'Activate Test Case';
        break;
      default:
        hasUpdateTestCaseButton = false;
        break;
    }

    const handleShareTestCaseModal = () => {
      dispatch({
        type: 'app/setSearchParams',
        payload: { ...searchParams, shareTestCase: testCaseId },
      });
      setShareModalTestCaseId(testCaseId);
    };

    const handleStatusUpdate = () =>
      dispatch({
        type: 'adsTracer/updateTestCaseStatus',
        payload: testCaseId,
      });

    const handleTestCaseRemove = () =>
      dispatch({
        type: 'adsTracer/deleteTestCase',
        payload: testCaseId,
      });

    const handleSharedTestCaseRemoval = () =>
      dispatch({
        type: 'adsTracer/deleteSharedTestCase',
        payload: testCaseId,
      });

    const updateTestCaseButton = hasUpdateTestCaseButton ? (
      <Tooltip title={updateTestCaseToolTipTitle}>
        <Button shape="circle" icon={updateTestCaseIcon} onClick={handleStatusUpdate} />
      </Tooltip>
    ) : null;

    return isSharedToMe ? (
      <Tooltip title="Delete Shared Test Case">
        <Button shape="circle" icon={<CloseCircleOutlined />} onClick={handleSharedTestCaseRemoval} />
      </Tooltip>
    ) : (
      <>
        <Tooltip title="Share Test Case">
          <Button
            disabled={isSharedToOther}
            shape="circle"
            icon={<ExportOutlined />}
            onClick={handleShareTestCaseModal}
          />
        </Tooltip>
        {updateTestCaseButton}
        <Tooltip title="Delete Test Case">
          <Button shape="circle" icon={<CloseCircleOutlined />} onClick={handleTestCaseRemove} />
        </Tooltip>
      </>
    );
  };

  const dateRangeFilter = () => (
    <div style={{ padding: 8 }}>
      <Typography variant="h8">Filter by Created Time</Typography>
      <br />
      <RangePicker
        onChange={(dates) =>
          dispatch({
            type: 'adsTracer/updateRangeFilter',
            payload: dates.map((date) => date.toString()),
          })
        }
      />
    </div>
  );

  testCases = rangeFilter
    ? testCases.filter(({ createdAt }) => moment(createdAt).isBetween(moment(rangeFilter[0]), moment(rangeFilter[1])))
    : testCases;

  const handleWidthChange = (widths) =>
    dispatch({
      type: 'adsTracer/updateTestCaseWidths',
      payload: widths,
    });

  return (
    <Spin spinning={loading}>
      <Typography variant="h2">Test Cases</Typography>
      <ResizableTable
        rowClassName={({ isSharedToMe, isSharedToOther }) =>
          isSharedToMe ? 'shared-with-me' : isSharedToOther ? 'shared-to-other' : ''
        }
        dataSource={testCases.map((t) => ({ ...t, key: t.testCaseId }))} // added w/ keys
        inputColumns={testCaseColumns(testCases, rowExtraRender, dateRangeFilter, rangeFilter, testCaseWidths)}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selected],
          onChange: handleSelectTestCase,
        }}
        scroll={TABLE_SCROLL_Y}
        pagination={false}
        testId={ADSTRACER_TESTCASES_TABLE}
        widthHandler={handleWidthChange}
      />
    </Spin>
  );
};

function mapStateToProps({ app, adsTracer, loading }) {
  return {
    searchParams: app.searchParams,
    testCases: adsTracer.testCases,
    rangeFilter: adsTracer.rangeFilter,
    testCaseWidths: adsTracer.testCaseWidths,
    loading: loading.effects['adsTracer/fetchTestCases'],
  };
}

export default connect(mapStateToProps)(TestCases);
