export const DEFAULT_PROFILES_PAGE = 1;
export const DEFAULT_PROFILES_PAGE_SIZE = 10;
export const AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE = 0;
export const AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE_SIZE = 10;
export const SNAPSHOT_NAME_MAX_LENGTH = 255;
export const PROFILE_NAME_MAX_LENGTH = 255;
export const INPUT_VALUE_MAX_LENGTH = 50;

export const MY_PROFILES_FILTER = 'my';
export const OTHER_PROFILES_FILTER = 'other';

export const DEBOUNCE_DELAY = 300;

export const USER_OPTIONS_ALL = 'all';

export const MY_SNAPSHOTS = 'My Snapshots';
export const ALL_SNAPSHOTS = 'All Snapshots';
