import React from 'react';
import { connect } from 'dva';
import { Layout, Alert, Skeleton } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import uniqueId from 'lodash/uniqueId';

import { AuctionErrorBoundary } from '../AuctionErrorBoundary';
import { useAuctionSnapshotDetails } from './useAuctionSnapshotDetails';
import { SupplySource } from './SupplySource';
import { AmazonIntegrator } from './Integrators/AmazonIntegrator';
import { MagniteIntegrator } from './Integrators/MagniteIntegrator';
import { RokuIntegrator } from './Integrators/RokuIntegrator';
import { SpotXIntegrator } from './Integrators/SpotXIntegrator';
import { DV360Integrator } from './Integrators/DV360Integrator';
import { MeliIntegrator } from './Integrators/MeliIntegrator';
import { TheTradeDeskIntegrator } from './Integrators/TheTradeDeskIntegrator';
import { FinalResponse } from './FinalResponse';
import { PodAndBids } from './AdBreaks';
import { UnifiedPodAndBids } from './UnifiedSection';
import { AMAZON, MAGNITE, ROKU, SPOTX, REQUEST, RESPONSE, auctionDetailsView, DV360, MELI, TTD } from './constants';

import {
  StyledPageHeader,
  StyledPageHeaderTitle,
  StyledLayout,
  StyledContent,
  StyledSider,
  StyledMenu,
  StyledMenuItem,
  StyledMenuItemGroup,
  StyledMenuDivider,
  StyledTitleSkeleton,
} from './style';

const INTEGRATORS_LABELS = {
  [AMAZON]: 'Amazon',
  [ROKU]: 'Roku',
  [SPOTX]: 'SpotX',
  [MAGNITE]: 'Magnite',
  [DV360]: 'DV360',
  [MELI]: 'Meli',
  [TTD]: 'The Trade Desk',
};

const MenuSkeleton = ({ items = 4 }) => {
  const data = Array(items).fill('*');
  return (
    <>
      {data.map(() => (
        <Skeleton.Button key={uniqueId('MenuSkeleton_')} style={{ margin: '8px 0' }} active block />
      ))}
    </>
  );
};

const RawDataErrorAlert = ({ dataType }) => (
  <Alert
    showIcon
    closable
    type="error"
    message="RAW DATA ERROR."
    description={
      <>
        Auction <strong>{dataType}</strong> data is not found.
      </>
    }
  />
);

const AuctionSnapshotDetailedView = ({ dispatch }) => {
  const {
    error,
    auction,
    snapshot,
    supplyAuctionData,
    demandAuctionData,
    isSupplyRawDataLoading,
    isDemandRawDataLoading,
    isBidsDataLoading,
    activeView,
    bids,
    podId,
    demandDataByPodId,
    getDemandDataByIntegrator,
    handleMenuItemClick,
    handleBack,
    handlePodId,
  } = useAuctionSnapshotDetails(dispatch);

  if (error) {
    return <Alert type="error" message={error.message || error} />;
  }

  return (
    <StyledLayout>
      <StyledPageHeader
        onBack={handleBack}
        backIcon={<ArrowLeftOutlined style={{ fontSize: '21px', cursor: 'pointer', color: 'white' }} />}
        title={
          <StyledPageHeaderTitle>
            <StyledTitleSkeleton
              loading={!auction || !snapshot}
              paragraph={{ rows: 1, width: 320 }}
              active
              size="small"
            >
              <p className="page-header-title">{auction?.externalAuctionId}</p>
              <p className="page-header-subtitle">{snapshot?.name}</p>
            </StyledTitleSkeleton>
          </StyledPageHeaderTitle>
        }
      />
      <Layout>
        <StyledSider>
          <StyledMenu
            mode="vertical"
            onClick={({ key }) => handleMenuItemClick(key)}
            defaultSelectedKeys={[auctionDetailsView.SUPPLY_SOURCE]}
          >
            <StyledMenuItem className="menu-item-single" key={auctionDetailsView.SUPPLY_SOURCE}>
              Supply Source
            </StyledMenuItem>
            <StyledMenuDivider />
            <StyledMenuItemGroup title="Integrators">
              {isDemandRawDataLoading ? (
                <MenuSkeleton />
              ) : (
                demandAuctionData?.integrators?.map((integrator) => (
                  <StyledMenuItem key={integrator.id}>{INTEGRATORS_LABELS[integrator.label]}</StyledMenuItem>
                ))
              )}
            </StyledMenuItemGroup>
            <StyledMenuDivider />
            <StyledMenuItemGroup title="Ad Breaks">
              {isSupplyRawDataLoading || isBidsDataLoading ? (
                <MenuSkeleton />
              ) : (
                supplyAuctionData?.pods?.map((pod) => (
                  <StyledMenuItem
                    key={auctionDetailsView.setPodKey(pod.id)}
                  >{`Pod ${pod.id} (${pod?.bidsWonCount}/${pod?.allBidsCount})`}</StyledMenuItem>
                ))
              )}
            </StyledMenuItemGroup>
            <StyledMenuDivider />
            {isSupplyRawDataLoading ? (
              <Skeleton.Button style={{ margin: '4px 0' }} active block />
            ) : (
              <StyledMenuItem className="menu-item-single" key={auctionDetailsView.DRAX_RESPONSE}>
                Drax Response
              </StyledMenuItem>
            )}
            {/* Hide section Unified Selection from the UI until the BE part will be ready
            <StyledMenuDivider />
             <StyledMenuItemGroup title="Unified Selection">
              {isSupplyRawDataLoading || isBidsDataLoading ? (
                <MenuSkeleton />
              ) : (
                [{ id: 1 }, { id: 2 }, { id: 3 }].map((pod) => (
                  <StyledMenuItem
                    key={auctionDetailsView.setPodKey(pod.id + auctionDetailsView.UNIFIED_POD)}
                  >{`Pod ${pod.id}`}</StyledMenuItem>
                ))
              )}
            </StyledMenuItemGroup> */}
          </StyledMenu>
        </StyledSider>
        <StyledContent>
          {supplyAuctionData && Object.keys(supplyAuctionData).length === 0 && !isSupplyRawDataLoading && (
            <RawDataErrorAlert dataType="supply" />
          )}
          {demandAuctionData && Object.keys(demandAuctionData).length === 0 && !isDemandRawDataLoading && (
            <RawDataErrorAlert dataType="demand" />
          )}
          {activeView === auctionDetailsView.SUPPLY_SOURCE && (
            <AuctionErrorBoundary>
              <SupplySource
                isLoading={isSupplyRawDataLoading}
                data={supplyAuctionData?.supplySource?.[REQUEST]?.record}
                error={supplyAuctionData?.supplySource?.[REQUEST]?.error}
                type={supplyAuctionData?.supplySource?.type}
              />
            </AuctionErrorBoundary>
          )}
          {activeView === AMAZON && (
            <AuctionErrorBoundary>
              <AmazonIntegrator
                isLoading={isDemandRawDataLoading}
                data={getDemandDataByIntegrator(AMAZON)}
                pods={supplyAuctionData?.pods}
                handlePodId={handlePodId}
                podId={podId}
              />
            </AuctionErrorBoundary>
          )}
          {activeView === MAGNITE && (
            <AuctionErrorBoundary>
              <MagniteIntegrator
                isLoading={isDemandRawDataLoading}
                data={getDemandDataByIntegrator(MAGNITE)}
                pods={supplyAuctionData?.pods}
                handlePodId={handlePodId}
                podId={podId}
              />
            </AuctionErrorBoundary>
          )}
          {activeView === SPOTX && (
            <AuctionErrorBoundary>
              <SpotXIntegrator
                isLoading={isDemandRawDataLoading}
                data={getDemandDataByIntegrator(SPOTX)}
                pods={supplyAuctionData?.pods}
                handlePodId={handlePodId}
                podId={podId}
              />
            </AuctionErrorBoundary>
          )}
          {activeView === ROKU && (
            <AuctionErrorBoundary>
              <RokuIntegrator
                isLoading={isDemandRawDataLoading}
                data={getDemandDataByIntegrator(ROKU)}
                pods={supplyAuctionData?.pods}
                handlePodId={handlePodId}
                podId={podId}
              />
            </AuctionErrorBoundary>
          )}
          {activeView === DV360 && (
            <AuctionErrorBoundary>
              <DV360Integrator
                isLoading={isDemandRawDataLoading}
                data={getDemandDataByIntegrator(DV360)}
                pods={supplyAuctionData?.pods}
                handlePodId={handlePodId}
                podId={podId}
              />
            </AuctionErrorBoundary>
          )}
          {activeView === MELI && (
            <AuctionErrorBoundary>
              <MeliIntegrator
                isLoading={isDemandRawDataLoading}
                data={getDemandDataByIntegrator(MELI)}
                pods={supplyAuctionData?.pods}
                handlePodId={handlePodId}
                podId={podId}
              />
            </AuctionErrorBoundary>
          )}
          {activeView === TTD && (
            <AuctionErrorBoundary>
              <TheTradeDeskIntegrator
                isLoading={isDemandRawDataLoading}
                data={getDemandDataByIntegrator(TTD)}
                pods={supplyAuctionData?.pods}
                handlePodId={handlePodId}
                podId={podId}
              />
            </AuctionErrorBoundary>
          )}
          {activeView.startsWith(auctionDetailsView.POD) && !activeView.endsWith(auctionDetailsView.UNIFIED_POD) && (
            <AuctionErrorBoundary>
              <PodAndBids
                currentPodId={auctionDetailsView.getPodKey(activeView)}
                isLoading={isDemandRawDataLoading}
                pods={supplyAuctionData?.pods}
                bids={bids}
                integratorsData={demandDataByPodId}
                auctionSupplySource={auction?.auctionSupplySource}
              />
            </AuctionErrorBoundary>
          )}
          {activeView === auctionDetailsView.DRAX_RESPONSE && (
            <AuctionErrorBoundary>
              <FinalResponse
                isLoading={isSupplyRawDataLoading}
                type={supplyAuctionData?.supplySource?.type}
                data={supplyAuctionData?.supplySource?.[RESPONSE]?.record?.rawData}
                error={supplyAuctionData?.supplySource?.[RESPONSE]?.error}
                bids={bids}
              />
            </AuctionErrorBoundary>
          )}
          {activeView.startsWith(auctionDetailsView.POD) && activeView.endsWith(auctionDetailsView.UNIFIED_POD) && (
            <AuctionErrorBoundary>
              <UnifiedPodAndBids />
            </AuctionErrorBoundary>
          )}
        </StyledContent>
      </Layout>
    </StyledLayout>
  );
};

export default connect()(AuctionSnapshotDetailedView);
