import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { connect } from 'dva';
import { Tab, TabNavigation, Typography } from '@hulu/react-style-components';
import { routerRedux } from 'dva/router';
import {
  COUNT_TYPES,
  VAST_TRACKER,
  VIEW,
  VIEW_AD_SERVERS_TAB,
  VIEW_AD_UNIT_IDS_TAB,
  VIEW_NETWORKS_TAB,
  VIEW_PARTNER_GROUPS_TAB,
} from '../../../constants';
import { VASTTRACKER_HEADER_NAV, VASTTRACKER_HEADER_TITLE } from '../../../testUtils';

const Header = ({ dispatch, permissions, pathParams = {} }) => {
  const [tab, setTab] = useState(pathParams?.tab ?? COUNT_TYPES.ADUNITID);

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography variant="h1" data-testid={VASTTRACKER_HEADER_TITLE}>
            Vast Tracker
          </Typography>
          <TabNavigation
            activeValue={tab}
            onTabClick={(_, selectedTab) => {
              dispatch({ type: 'vastTracker/updateCountTypeSelected', payload: selectedTab });
              setTab(selectedTab);
              dispatch(routerRedux.push('/' + VAST_TRACKER + '/' + selectedTab));
            }}
            data-testid={VASTTRACKER_HEADER_NAV}
          >
            {permissions.can(VIEW, VIEW_AD_UNIT_IDS_TAB) ? <Tab value={COUNT_TYPES.ADUNITID}>AD UNITS</Tab> : null}
            {permissions.can(VIEW, VIEW_AD_SERVERS_TAB) ? <Tab value={COUNT_TYPES.ADSERVER}>AD SERVERS</Tab> : null}
            {permissions.can(VIEW, VIEW_NETWORKS_TAB) ? <Tab value={COUNT_TYPES.NETWORK}>NETWORKS</Tab> : null}
            {permissions.can(VIEW, VIEW_PARTNER_GROUPS_TAB) ? (
              <Tab value={COUNT_TYPES.PARTNER_GROUP}>PARTNER GROUPS</Tab>
            ) : null}
          </TabNavigation>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps({ app }) {
  return {
    pathParams: app,
    permissions: app.currentUser.permissions,
  };
}

export default connect(mapStateToProps)(Header);
