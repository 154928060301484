import styled from 'styled-components';
import { Select } from 'antd';

export const QCheckWrapper = styled.div`
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
`;

export const MediaFileUrlWrapper = styled.div`
  margin: 20px 0 0;
  padding: 20px 0 0;
`;

export const VastXmlWrapper = styled.div`
  margin: 20px 0 0;
  padding: 20px 0 0;
`;

export const VastXmlIngestionWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;

export const CountryCodeSelectWrapper = styled.div`
  padding: 10px 0;
`;

export const StyledSelect = styled(Select)`
  width: 400px;
`;

export const ErrorMessageWrapper = styled.div`
  color: red;
`;
