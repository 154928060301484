import React from 'react';
import { Alert } from 'antd';
import XMLViewer from '../../../../libs/react-xml-viewer';

import { StyledDataInterpretation, StyledXmlJsonFormatter, JsonXMLViewersStyle } from '../style';

const isValidXml = (data) => {
  const domParser = new DOMParser();
  const dom = domParser.parseFromString(data, 'text/xml');
  const [element] = dom.getElementsByTagName('parsererror');
  return !Boolean(element);
};

export const XmlViewer = ({ data, height }) => {
  return isValidXml(data) ? (
    <StyledXmlJsonFormatter height={height}>
      <StyledDataInterpretation>
        <XMLViewer xml={data} style={JsonXMLViewersStyle} collapsible overflowBreak />
      </StyledDataInterpretation>
    </StyledXmlJsonFormatter>
  ) : (
    <Alert type="error" message="No response from integrator" />
  );
};
