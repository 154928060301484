import styled from 'styled-components';
import { NavBar } from '@hulu/react-style-components';
import { colors } from '@hulu/design-tokens';
import { QuestionCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { Button } from '@hulu/react-style-components';
import { Menu } from '@hulu/react-style-components';
import { Alert } from 'antd';

const huluLogoColor = '#1ce783';
const DMED_OrbitBlue = '#0072ED';

export const SkipLink = styled(Button)`
  position: absolute;
  left: -1000px;
  color: ${colors.colorBlack95};
  font-size: 20px;
  text-decoration: none;
  background-color: ${colors.colorGray03} !important;
  width: max-content;
  outline: 4px solid ${DMED_OrbitBlue} !important;
  outline-offset: -2px;
  :focus {
    z-index: 100;
    position: fixed;
    left: 2em;
    top: 2em;
  }
`;

export const StyledNavBar = styled(NavBar)`
  z-index: 99;
  .TrekNavBar-menuIcon {
    padding: 10px;
    margin: 10px;
  }
  .BarItem-Logos {
    width: 500px;
  }
  .TrekNavBar-content {
    background-color: ${colors.colorBlack95};
  }
  .ant-btn-text {
    color: ${colors.colorGray03};
  }
  .TrekAccountMenu-container {
    color: ${colors.colorGray03};
    box-shadow: none;
  }
  div:focus-visible .TrekUserIdentifier-avatar-container {
    outline: 4px solid ${DMED_OrbitBlue};
  }
  .TrekNavBar-appName {
    background-color: ${colors.colorGray03};
    color: ${colors.colorBlack95};
  }
  .TrekNavBar-logoLink {
    svg {
      color: ${huluLogoColor};
    }
  }
  .ant-btn:focus-visible {
    outline: 4px solid ${DMED_OrbitBlue};
  }
  a:focus-within {
    outline: 4px solid ${DMED_OrbitBlue};
  }
`;

export const StyledMenuOutlined = styled(MenuOutlined)`
  :focus: {
    outline: 4px solid blue;
  }
`;

export const StyledMenu = styled(Menu)`
  background: ${colors.colorWhite};
  padding: 0;
  left: 0;
  width: 300px;
  ul {
    width: 100%;
    padding: 0;
  }
`;

export const StyledDropdownButton = styled(Button)`
  margin: 0;
  padding: 10px;
  outline: 0;
  background-color: transparent;
  border-color: transparent;
  .anticon {
    color: white;
    font-size: 18px;
  }
  :focus-visible {
    outline: 4px solid ${DMED_OrbitBlue};
    outline-offset: -2px;
  }
`;

export const ToolSelectionLi = styled.li`
  list-style-type: none;
  width: 100%;
  padding: 5px;
  Button {
    color: ${colors.colorBlack95};
    padding-top: 16px;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    justify-content: left;
    font-size: 14px;
    font-weight: 400px;
    outline: 0;
    background: ${colors.colorWhite};
    border: none;
    border-color: transparent;
    :hover {
      background: ${colors.colorGray03};
    }
    :focus-visible {
      outline: 4px solid ${DMED_OrbitBlue} !important;
    }
  }
  &:last-child {
    border: none;
  }
`;

export const IconContainer = styled.div`
  padding: 16px 16px;
`;
export const FeedbackIcon = styled(QuestionCircleOutlined)`
  font-size: 18px;
  font-weight: 500;
  height: 24px;
  stroke: white;
  stroke-width: 50;
`;
export const StyledText = styled.p`
  display: none;
  font-size: 16px;
  font-weight: 500;
  padding-left: 8px;
`;

export const StyledAlert = styled(Alert)`
  .ant-alert-close-icon:focus-visible,
  a:focus-visible {
    outline: 4px solid ${DMED_OrbitBlue};
    outline-offset: 4px;
  }
`;
