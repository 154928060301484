import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';
import { IconEdit, IconClose, IconCheck, IconAlertErrorOutlined } from '@hulu-react-style-components/icons';
import { Input, Button, Alert, Typography } from 'antd';

import { useOnClickOutside } from '../useOnClickOutside';

const { Paragraph } = Typography;

const ERROR_MESSAGE_PLACEHOLDER = 'Something went wrong, please try again later';

const buttonStyles = {
  normal: {
    enabled: {
      background: colors.colorBlack,
      iconColor: colors.colorWhite,
      borderColor: colors.colorBlack,
    },
    hovered: {
      background: 'rgba(39, 44, 53, 0.8)',
      borderColor: colors.colorGray20,
    },
  },
  outlined: {
    enabled: {
      background: colors.colorWhite,
      iconColor: colors.colorBlack,
      borderColor: colors.colorBlack,
    },
    hovered: {
      background: colors.colorWhite,
      borderColor: colors.colorGray30,
    },
  },
};

const overlayTooltipStyle = { width: '170%', whiteSpace: 'pre-wrap' };

const Container = styled.div`
  display: flex;
  align-items: center;
  .snapshot-edit-icon {
    display: none;
    cursor: pointer;
    height: 18px;
    width: 18px;
  }
`;
const InputWrapper = styled.div`
  position: absolute;
  width: 92%;
  display: flex;
  flex-direction: column;
  top: calc(50% - 16px);
  bottom: calc(50% - 20px);
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
  button {
    padding: 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 8px rgba(0, 0, 0, 0.14), 0 1px 14px rgba(0, 0, 0, 0.12);
    z-index: 1;
    :first-of-type {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`;
const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background: ${({ type }) => buttonStyles?.[type]?.enabled?.background} !important;
  border-color: ${({ type }) => buttonStyles?.[type]?.enabled?.borderColor} !important;
  svg {
    path {
      fill: ${({ type }) => buttonStyles?.[type]?.enabled?.iconColor} !important;
    }
  }
  &:hover {
    background: ${({ type }) => buttonStyles?.[type]?.hovered?.background} !important;
    border-color: ${({ type }) => buttonStyles?.[type]?.hovered?.borderColor} !important;
`;
const StyledInput = styled(Input)`
  border: 1px solid ${colors.colorBlack};
  border-radius: 4px;
`;
const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 3px 15px 3px 15px;
  svg {
    path {
      fill: ${colors.colorRed30};
    }
  }
`;
const StyledName = styled(Paragraph)`
  width: 93%;
  white-space: pre;
`;

export const StyledSnapshotName = ({ name }) => (
  <StyledName
    ellipsis={{
      tooltip: {
        placement: 'bottomLeft',
        overlayInnerStyle: overlayTooltipStyle,
        getPopupContainer: (trigger) => trigger.parentNode,
      },
    }}
  >
    {name}
  </StyledName>
);

const InlineInput = (props) => {
  const { name, maxLength, errMessage, onSave, onClose } = props;
  const [value, setValue] = useState(name);

  const handleInputValue = (event) => setValue(event.target.value);

  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  return (
    <InputWrapper ref={ref}>
      <StyledInput value={value} maxLength={maxLength} onChange={handleInputValue} showCount allowClear />
      <ButtonsContainer>
        {errMessage && <StyledAlert icon={<IconAlertErrorOutlined />} showIcon type="error" message={errMessage} />}
        <StyledButton onClick={() => onSave(value)} type="normal">
          <IconCheck />
        </StyledButton>
        <StyledButton onClick={onClose} type="outlined">
          <IconClose />
        </StyledButton>
      </ButtonsContainer>
    </InputWrapper>
  );
};

export const EditableCell = (props) => {
  const { name, maxLength = 100, onSave } = props;
  const [isEditable, setIsEditable] = useState(false);

  const [errMessage, setErrorMessage] = useState(null);

  const enableEditMode = () => setIsEditable(true);
  const disableEditMode = () => {
    setIsEditable(false);
    setErrorMessage(null);
  };

  const handleSaveClick = async (name) => {
    try {
      await onSave(name);
      disableEditMode();
    } catch (err) {
      setErrorMessage(err?.response?.data?.message ?? ERROR_MESSAGE_PLACEHOLDER);
    }
  };

  return (
    <Container>
      {isEditable ? (
        <InlineInput
          name={name}
          maxLength={maxLength}
          errMessage={errMessage}
          onSave={handleSaveClick}
          onClose={disableEditMode}
        />
      ) : (
        <>
          <StyledSnapshotName name={name} />
          <IconEdit className="snapshot-edit-icon" onClick={enableEditMode} />
        </>
      )}
    </Container>
  );
};
