import styled from 'styled-components';
import { Drawer, Select, Button } from 'antd';
import { colors, spacers } from '@hulu/design-tokens';
import { IconButton } from '@hulu/react-style-components';

export const StyledCloseButton = styled(IconButton)`
  width: auto;
  height: auto;
  border: none;
  margin: 0 0 0 auto;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  input {
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${colors.colorGray30};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  .ant-select-selector {
    height: 40px !important;
    align-items: center;
    border-radius: 4px !important;
  }

  .ant-input-affix-wrapper {
    padding: 0 11px;
    border-radius: 4px;
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow: hidden;

    .ant-drawer-close {
      padding: 14px;
    }

    .ant-collapse-header {
      background-color: ${colors.colorGray03};
    }

    .ant-drawer-header-title {
      flex-direction: row-reverse;
      padding: 0 0 0 14px;
    }

    label {
      font-weight: bold;
    }
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-collapse {
    border: none;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-content {
    border-top: none;
  }

  .ant-collapse-content-box {
    padding: 0 0 24px 0;
  }

  .ant-collapse-header-text {
    font-weight: 600;
    color: ${colors.colorBlack50};
  }

  .ant-drawer-footer {
    padding: 0;
  }
`;

export const StyledPaneFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  z-index: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  height: 72px;
  box-sizing: border-box;
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.08);
  #snapshot-cancel-button {
    &:active,
    &:focus,
    &:hover {
      background-color: ${colors.colorWhite};
    }
  }
  #snapshot-capture-button {
    &:active,
    &:focus,
    &:hover {
      background-color: ${colors.colorBlack95};
    }
  }
`;

export const StyledCancelButton = styled(Button)`
  display: flex;
  align-items: center;
  height: ${spacers.spacer07};
  padding: ${spacers.spacer03} ${spacers.spacer06};
  border-radius: ${spacers.spacer01};
  border: 1px solid ${colors.colorBlack95};
  margin-right: ${spacers.spacer03};
  & > p {
    font-weight: 600;
  }

  &:hover {
    border-color: ${colors.colorGray30};
  }
`;

export const StyledSubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: ${spacers.spacer03} ${spacers.spacer06};
  height: ${spacers.spacer07};
  border-radius: ${spacers.spacer01};
  background: ${colors.colorBlack95};
  border: none;
  outline: none;
  cursor: pointer;

  & > p {
    color: #fff;
    font-weight: 600;
  }

  &:hover {
    background: rgba(39, 44, 53, 0.8);
  }
`;

export const MultipleSelect = styled(Select)`
  width: 402px;

  .ant-select-selection-overflow-item:nth-last-child(n + 3):after {
    content: ',';
  }
`;

export const StyledHeadItemWrapper = styled.div`
  background: #f7f7f9;
  padding: 32px 75px;
  margin-top: 0;
`;

export const StyledFieldsWrapper = styled.div`
  width: 100%;
  padding: 25px 120px 0;
`;

export const StyledFooterWrapper = styled.div`
  width: 100%;
  padding: 35px 75px;
`;

export const StyledSelectSearch = styled(Select)`
  .ant-select-selection-search {
    padding-top: 3px;
  }
`;

export const StyledProfileSelect = styled(Select)`
  .ant-select-item-option-content {
    white-space: pre;
  }
`;
