import React from 'react';

import { RowInfo } from '../../Common/RowInfo';
import { StyledCard, StyledDivider } from '../../style';

export const RequestParams = ({ isLoading, data }) => {
  const { id, app, device, imp, regs, tmax } = data || {};
  return (
    <StyledCard title="Request Params" loading={isLoading}>
      <RowInfo title="requestID" data={id} />
      <StyledDivider height={24} />
      <RowInfo title="appBundle" data={app?.bundle} />
      <RowInfo title="appContentLivestream" data={!!app?.content?.livestream} />
      <RowInfo title="appDomain" data={app?.domain} />
      <RowInfo title="appName" data={app?.name} />
      <StyledDivider height={24} />
      <RowInfo title="deviceDNT" data={device?.dnt} />
      <RowInfo title="deviceIFA" data={device?.ifa} />
      <RowInfo title="deviceIP" data={device?.ip} />
      <RowInfo title="deviceLMT" data={device?.lmt} />
      <RowInfo title="deviceUA" data={device?.ua} />
      <StyledDivider height={24} />
      <RowInfo title="videoID" data={imp?.id} />
      <RowInfo title="videoW" data={imp?.video?.w} />
      <RowInfo title="videoH" data={imp?.video?.h} />
      <RowInfo title="videoMaxDuration" data={imp?.video?.maxduration} />
      <RowInfo title="videoMinDuration" data={imp?.video?.minduration} />
      <RowInfo title="videoMimeTypes" data={imp?.video?.mimes?.join(',')} />
      <RowInfo title="videoExtCustomDrax" data={imp?.video?.ext?.custom?.drax} />
      <RowInfo title="videoExtCustomSport" data={imp?.video?.ext?.custom?.sport || false} />
      <RowInfo title="videoExtCustomLiveStream" data={imp?.video?.ext?.custom?.livestream} />
      <StyledDivider height={24} />
      <RowInfo title="regsCoppa" data={regs?.coppa} />
      <StyledDivider height={24} />
      <RowInfo title="tmax" data={tmax} />
    </StyledCard>
  );
};
