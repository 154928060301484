import React from 'react';

import { RowInfo } from '../../Common/RowInfo';
import { StyledCard } from '../../style';

export const AmazonRequestParamsCard = ({ isLoading, data }) => {
  const { requestId, appName, channel, deviceId, dnt, userAgent, bundle, IP, genre, vdm } = data;
  return (
    <StyledCard title="Request Params" loading={isLoading}>
      <RowInfo title="Request ID" data={requestId} />
      <RowInfo title="appName" data={appName} />
      <RowInfo title="Channel" data={channel} />
      <RowInfo title="Bundle" data={bundle} />
      <RowInfo title="deviceID" data={deviceId} />
      <RowInfo title="IP" data={IP} />
      <RowInfo title="DNT" data={dnt} />
      <RowInfo title="VDM" data={vdm} />
      <RowInfo title="Genre" data={genre} />
      <RowInfo title="User Agent" data={userAgent} />
    </StyledCard>
  );
};
