import React, { useState, useEffect } from 'react';
import _round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import convert from 'xml-js';
import { Drawer, Collapse, Skeleton } from 'antd';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';
import { IconButton } from '@hulu/react-style-components';

import { AuctionErrorBoundary } from '../../AuctionErrorBoundary';
import { JsonViewer } from '../Common/JsonViewer';
import { XmlViewer } from '../Common/XmlViewer';
import { BidDetailsRowInfo } from '../Common/BidDetailsRowInfo';
import { CollapseIcons } from '../../icons/CollapseIcons';
import { SPOTX, MAGNITE } from '../constants';
import { DealDetails } from './DealDetails';

const { Panel } = Collapse;

const StyledCloseButton = styled(IconButton)`
  width: auto;
  height: auto;
  border: none;
  margin: 0 0 0 auto;
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow: hidden;

    .ant-drawer-close {
      padding: 0;
    }
  }
  .ant-drawer-header {
    padding: 25px 20px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .drawer-title-text {
      font-weight: normal;
    }
  }
  .ant-drawer-body {
    padding: 20px;
  }
  .ant-collapse-content-box {
    padding: 0 0 24px 0;
  }
`;
const StyledCollapse = styled(Collapse)`
  border: none;
  border-top: 1px solid ${colors.colorGray30};
  .ant-collapse-content-box {
    padding: 0px;
  }
`;
const StyledPanelHeader = styled.div`
  margin-left: 11px;
`;

const PANEL_ITEMS = {
  AD_INFO: { title: 'Ad Info', key: 'adInfo' },
  BID: { title: 'Bid', key: 'bid' },
  BID_RESPONSE: { title: 'Bid Response', key: 'bidResponse' },
  AD_RESPONSE: { title: 'Ad Response', key: 'adResponse' },
  DEAL_INFO: { title: 'Deal Info', key: 'dealInfo' },
};

const DEFAULT_ACTIVE_PANELS = [
  PANEL_ITEMS.AD_INFO.key,
  PANEL_ITEMS.AD_RESPONSE.key,
  PANEL_ITEMS.BID.key,
  PANEL_ITEMS.BID_RESPONSE.key,
];

const findElement = (elements, tagName, defaultValue = null) => {
  return elements.reduce((acc, element) => {
    if (element.name === tagName) {
      return element?.attributes?.id ?? defaultValue;
    } else if (element.elements) {
      return findElement(element.elements, tagName, acc);
    }
    return acc;
  }, defaultValue);
};
const convertXMLCreativeID = (xml) => {
  try {
    const jsonNotCompact = convert.xml2js(xml, { compact: false, spaces: 0 });
    return findElement(jsonNotCompact.elements, 'Creative');
  } catch (error) {
    console.error(`convertXMLCreativeID ${error}`);
    throw new Error('Unable to convert adResponse to Creative ID!');
  }
};

const PanelHeader = ({ title }) => {
  return <StyledPanelHeader>{title}</StyledPanelHeader>;
};

export const BidDetails = ({ bidDetailData, isBidDetailsLoading, dealDetailsError, isOpen, onClose }) => {
  const { bidWithVastData, bidResponse, adResponse, isAdResponseJson, dealDetails } = bidDetailData;
  const [activeKey, setActiveKey] = useState(DEFAULT_ACTIVE_PANELS);

  const isSpotx = bidWithVastData?.demandPartner?.toUpperCase() === SPOTX;
  const isMagnite = bidWithVastData?.demandPartner?.toUpperCase() === MAGNITE;
  const title = isSpotx ? bidWithVastData?.demandPartner : bidWithVastData?.dsp;
  const creativeID = (isMagnite || isSpotx) && convertXMLCreativeID(adResponse);
  const duration = bidWithVastData?.creativeDuration
    ? moment.duration(bidWithVastData?.creativeDuration).asMilliseconds()
    : null;

  useEffect(() => {
    if (!isEmpty(dealDetails)) {
      setActiveKey([...DEFAULT_ACTIVE_PANELS, PANEL_ITEMS.DEAL_INFO.key]);
    }
  }, [dealDetails]);

  return (
    <StyledDrawer
      placement="right"
      width={600}
      open={isOpen}
      title={title + '_' + bidWithVastData?.bidId}
      onClose={() => {
        onClose();
        setActiveKey(DEFAULT_ACTIVE_PANELS);
      }}
      destroyOnClose
      closeIcon={<StyledCloseButton variant="outlined" icon="IconClose" />}
    >
      <AuctionErrorBoundary>
        <StyledCollapse
          activeKey={activeKey}
          onChange={setActiveKey}
          expandIcon={({ isActive }) => <CollapseIcons isActive={isActive} />}
        >
          <Panel header={<PanelHeader title={PANEL_ITEMS.AD_INFO.title} />} key={PANEL_ITEMS.AD_INFO.key}>
            <Skeleton paragraph={{ rows: 6 }} loading={isBidDetailsLoading}>
              <BidDetailsRowInfo title="Ad Unit Id" data={bidWithVastData?.adId} />
              <BidDetailsRowInfo title="Ad System" data={bidWithVastData?.adSystem} />
              <BidDetailsRowInfo title="Ad Title" data={bidWithVastData?.adTitle} />
              {(isMagnite || isSpotx) && <BidDetailsRowInfo title="DSP : CRID" data={creativeID && creativeID} />}
              <BidDetailsRowInfo title="Pricing" data={`$${_round(bidWithVastData?.pricing, 2).toFixed(2)}`} />
              {!isSpotx && <BidDetailsRowInfo title="DSP" data={bidWithVastData?.dsp} />}
              <BidDetailsRowInfo title="aDomain" data={bidWithVastData?.adomain} />
              {duration !== null && <BidDetailsRowInfo title="Ad Duration" data={duration + ' ms'} />}
              <BidDetailsRowInfo title="Country" data={bidWithVastData?.country} />
            </Skeleton>
          </Panel>
          <Panel header={<PanelHeader title={PANEL_ITEMS.DEAL_INFO.title} />} key={PANEL_ITEMS.DEAL_INFO.key}>
            <Skeleton paragraph={{ rows: 11 }} loading={isBidDetailsLoading}>
              <DealDetails dealDetails={dealDetails} dealDetailsError={dealDetailsError} />
            </Skeleton>
          </Panel>
          <Panel header={<PanelHeader title={PANEL_ITEMS.BID.title} />} key={PANEL_ITEMS.BID.key}>
            <Skeleton paragraph={{ rows: 2 }} loading={isBidDetailsLoading}>
              <BidDetailsRowInfo title="Bid ID" data={bidWithVastData?.demandBidId} />
              <BidDetailsRowInfo title="Crid ID" data={bidWithVastData?.cridId} />
            </Skeleton>
          </Panel>
          {bidResponse && (
            <Panel header={<PanelHeader title={PANEL_ITEMS.BID_RESPONSE.title} />} key={PANEL_ITEMS.BID_RESPONSE.key}>
              <Skeleton loading={isBidDetailsLoading}>
                <JsonViewer data={bidResponse} height={325} />
              </Skeleton>
            </Panel>
          )}
          {adResponse && (
            <Panel header={<PanelHeader title={PANEL_ITEMS.AD_RESPONSE.title} />} key={PANEL_ITEMS.AD_RESPONSE.key}>
              <Skeleton loading={isBidDetailsLoading}>
                {isAdResponseJson ? (
                  <JsonViewer data={adResponse} height={325} />
                ) : (
                  <XmlViewer data={adResponse} height={325} />
                )}
              </Skeleton>
            </Panel>
          )}
        </StyledCollapse>
      </AuctionErrorBoundary>
    </StyledDrawer>
  );
};
