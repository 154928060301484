import React from 'react';
import { connect } from 'dva';
import { Row, Col, Divider } from 'antd';
import { Table } from 'ant-table-extensions';
import { TableContainer } from '@hulu/react-style-components';
import { colors } from '@hulu/design-tokens';
import styled from 'styled-components';

import { TableHeaderContainer } from './TableHeaderContainer';
import { TableFiltersContainer } from './TableFiltersContainer';
import { TableExpandIcon } from './TableExpandIcon';
import DetailTable from './DetailTable';
import { EmptyTable } from './EmptyTable';
import { adRequestSnapshotColumns } from '../../Templates/columns';
import { AUCTION_SNAPSHOT_TABLE } from '../../../testUtils';

const StyledTable = styled(Table)`
  tr > td > div > div:first-child {
    margin: 0 !important;
  }
  .expanded-parent {
    background: ${colors.colorBlue05};
  }
  .ant-table-thead > tr > th {
    background: ${colors.colorWhite};
  }
  .ant-table-expanded-row > td {
    padding: 0;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${colors.colorGray05};
    .snapshot-edit-icon {
      display: flex !important;
      path {
        fill: #586174;
      }
    }
    :hover {
      .snapshot-edit-icon {
        path {
          fill: #0b0c0f;
        }
      }
    }
  }
  .ant-table-cell-ellipsis {
    overflow: inherit;
    white-space: inherit;
  }

  //pagination
  .ant-pagination-disabled {
    visibility: hidden;
  }
  .ant-table-pagination li:last-child {
    position: absolute;
    left: 0;
  }
  .ant-table-pagination > li:last-child:before {
    content: 'Rows per Page';
    margin-right: 8px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 24px;
  }
  .ant-table-pagination li:first-child {
    position: absolute;
    left: 216px;
  }
  .ant-pagination {
    color: ${colors.colorBlack50};
    line-height: 24px;
    margin: 0;
    height: 48px;
    align: center;
  }
  .ant-pagination > li {
    padding-top: 12px;
  }

  // pagination rows per page selector
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border-color: ${colors.colorBlack95};
  }
  .ant-pagination-options-size-changer {
    width: 75px;
  }
  .ant-pagination-options-size-changer > .ant-select-selector {
    border-radius: 4px;
    border-color: ${colors.colorGray30};
    height: 26px;
  }
  .ant-pagination-options-size-changer > .ant-select-selector:hover {
    border-radius: 4px;
    border-color: ${colors.colorBlack95};
  }
  .ant-select-dropdown {
    text-align: center;
  }
  .ant-select-arrow {
    color: ${colors.colorBlack95};
  }
  .ant-select-open > .ant-select-arrow {
    transform: rotate(180deg);
  }

  // pagination control buttons
  .ant-pagination-prev,
  .ant-pagination-next {
    color: ${colors.colorBlack50};
    line-height: 24px;
  }
  .ant-pagination-item {
    border: none;
    line-height: 24px;
    min-width: 24px;
  }
  .ant-pagination-item:not(.ant-pagination-item-active) {
    border: none;
  }
  .ant-pagination-item a {
    color: ${colors.colorBlack50};
    padding: 0;
  }
  .ant-pagination-item-active > a {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-color: ${colors.colorBlack95};
    background-color: ${colors.colorBlack95};
    color: ${colors.colorWhite};
  }
  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${colors.colorBlack50};
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    line-height: 24px;
  }
`;
const TableLayout = styled.div`
  div:first-child {
    margin: 0 !important;
  }
`;
const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  border: 1px solid #b9bfcb;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
`;
const StyledDivider = styled(Divider)`
  margin: 0;
`;

const paginationItemRender = (current, type, originalElement, last) => {
  if (type === 'prev' && current !== 0) {
    return <span className="pagination-link">Previous</span>;
  }
  if (type === 'next') {
    return <span className="pagination-link">Next</span>;
  }
  if ((type === 'prev' && current === 0) || (type === 'next' && current === last)) {
    return <span />;
  }
  return originalElement;
};

const AuctionSnapshotTable = (props) => {
  const {
    snapshots,
    isLoading,
    error,
    onSnapshotParametersInfoClick,
    onRerunSnapshotClick,
    onAdTagsParameterSummaryClick,
    onDeleteSnapshotClick,
    onRefresh,
    pagination,
    onTableChange,
    onSearchAuctionSnapshots,
    filtersDropdownData,
    activeFilters,
    searchValue,
    currentUserEmail,
    dateValue,
    sort,
    onAllFiltersClear,
    onFiltersClear,
    onDeleteFilter,
    onClearAppNameFilter,
    handleFilters,
    handleUserSnapshotValue,
    handleDateRange,
    userOptions,
    selectedUserOptions,
    onUsersSearch,
    snapshotExpandedKeys,
    onSnapshotExpand,
    handleInlineEditName,
  } = props;

  const { content, totalElements } = snapshots || {};

  const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total}`;

  const isEmptyOrError = error ? <EmptyTable errorMessage={error.message} /> : <EmptyTable />;

  const locale = {
    emptyText: isLoading ? ' ' : isEmptyOrError,
  };

  const last = Math.ceil(totalElements / pagination.pageSize);

  const adRequestSnapshotActions = {
    onSnapshotParametersInfoClick,
    onRerunSnapshotClick,
    onAdTagsParameterSummaryClick,
    onDeleteSnapshotClick,
    currentUserEmail,
    handleInlineEditName,
  };

  const [columnKey, order] = sort;

  const sortOrder = {
    [columnKey]: order,
  };

  return (
    <StyledTableContainer>
      <TableHeaderContainer onSearchAuctionSnapshots={onSearchAuctionSnapshots} searchValue={searchValue} />
      <StyledDivider />
      <TableFiltersContainer
        filtersDropdownData={filtersDropdownData}
        activeFilters={activeFilters}
        dateValue={dateValue}
        onAllFiltersClear={onAllFiltersClear}
        onFiltersClear={onFiltersClear}
        onDeleteFilter={onDeleteFilter}
        onClearAppNameFilter={onClearAppNameFilter}
        handleFilters={handleFilters}
        handleUserSnapshotValue={handleUserSnapshotValue}
        handleDateRange={handleDateRange}
        onRefresh={onRefresh}
        userOptions={userOptions}
        selectedUserOptions={selectedUserOptions}
        onUsersSearch={onUsersSearch}
      />
      <StyledDivider />
      <Row>
        <Col span={24}>
          <TableLayout>
            <StyledTable
              onChange={onTableChange}
              locale={locale}
              data-testid={AUCTION_SNAPSHOT_TABLE}
              dataSource={content}
              loading={isLoading}
              columns={adRequestSnapshotColumns(adRequestSnapshotActions, sortOrder)}
              rowKey={({ id }) => id}
              onRow={({ id }) => snapshotExpandedKeys?.includes(id) && { className: 'expanded-parent' }}
              showSorterTooltip={false}
              pagination={{
                position: ['bottomCenter'],
                total: totalElements,
                current: pagination.page,
                pageSize: pagination.pageSize,
                itemRender: (page, type, element) => paginationItemRender(page, type, element, last),
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '75', '100'],
                locale: { items_per_page: '' },
                showTotal: (total, range) => showTotal(total, range),
              }}
              expandable={{
                expandedRowKeys: snapshotExpandedKeys,
                onExpand: (expanded, record) => onSnapshotExpand(expanded, record),
                expandedRowRender: (record) => <DetailTable dataSource={record.auctions} searchValue={searchValue} />,
                expandIcon: ({ expanded, onExpand, record }) => (
                  <TableExpandIcon expanded={expanded} onExpand={onExpand} record={record} />
                ),
              }}
            />
          </TableLayout>
        </Col>
      </Row>
    </StyledTableContainer>
  );
};

function mapStateToProps(state) {
  return {
    currentUserEmail: state.app.currentUser.email,
  };
}

export default connect(mapStateToProps)(AuctionSnapshotTable);
