import styled from 'styled-components';
import { Drawer } from 'antd';
import { IconButton } from '@hulu/react-style-components';

export const StyledCloseButton = styled(IconButton)`
  width: auto;
  height: auto;
  border: none;
  margin: 0 0 0 auto;
`;
export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow: hidden;

    .ant-drawer-close {
      padding: 0;
    }
  }
  .ant-drawer-header {
    padding: 25px 20px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .drawer-title-text {
      font-weight: normal;
      white-space: pre-wrap;
    }
  }
  .ant-drawer-body {
    padding: 50px 20px 0;
  }

  .ant-collapse-content-box {
    padding: 0 0 24px 0;
  }
`;
