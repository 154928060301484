import React from 'react';

export const FilterFunnelIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.08426 3.59828C3.24372 3.23478 3.60308 3 4.00002 3H20C20.397 3 20.7563 3.23478 20.9158 3.59828C21.0752 3.96178 21.0046 4.38518 20.7358 4.67724L15 10.9087V20C15 20.3788 14.786 20.725 14.4472 20.8944C14.1085 21.0638 13.703 21.0273 13.4 20.8L9.40002 17.8C9.14821 17.6111 9.00002 17.3148 9.00002 17V10.9087L3.26426 4.67724C2.99543 4.38518 2.9248 3.96178 3.08426 3.59828ZM6.27961 5L10.7358 9.84128C10.9057 10.0259 11 10.2676 11 10.5185V16.5L13 18V10.5185C13 10.2676 13.0943 10.0259 13.2643 9.84128L17.7204 5H6.27961Z"
      fill="#FFF"
    />
  </svg>
);
