import React from 'react';
import { Collapse } from 'antd';

import { CollapseIcons } from '../../icons/CollapseIcons';
import { PanelHeader } from './PanelHeader';
import { BidCard } from './BidCard';
import { capitalizer } from './capitalizer';

import { StyledCollapse } from './style';

import { EmptyAdSourceSummaryTab } from './EmptyAdSourceSummaryTab';

const { Panel } = Collapse;

export const BidderName = ({
  activeBidderName,
  handleBidderNameActiveCollapse,
  bidderNameData,
  openBidDetails,
  isUnifiedSelection,
}) => {
  if (Object.keys(bidderNameData).length === 0) return <EmptyAdSourceSummaryTab />;

  return (
    <StyledCollapse
      expandIcon={({ isActive }) => <CollapseIcons isActive={isActive} />}
      activeKey={activeBidderName}
      onChange={(key) => handleBidderNameActiveCollapse(key)}
    >
      {Object.entries(bidderNameData).map(
        ([key, value]) =>
          key !== 'undefined' && (
            <Panel header={<PanelHeader header={capitalizer(key)} count={value.length} />} key={key}>
              {value.map((bid) => {
                return (
                  <BidCard
                    bid={bid}
                    key={bid?.bidId}
                    openBidDetails={openBidDetails}
                    isUnifiedSelection={isUnifiedSelection}
                  />
                );
              })}
            </Panel>
          )
      )}
    </StyledCollapse>
  );
};
