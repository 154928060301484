import React from 'react';

export const RerunIcon = ({ onClick }) => {
  return (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 20C3 20.5523 3.44772 21 4 21H14C14.5523 21 15 20.5523 15 20V17.75C15 17.1977 14.5523 16.75 14 16.75C13.4477 16.75 13 17.1977 13 17.75V19H5V10H6C6.55228 10 7 9.55229 7 9C7 8.44771 6.55228 8 6 8H4C3.44772 8 3 8.44771 3 9V20Z"
        fill="#040405"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9995 8.00014C15.9995 8.55243 16.4472 9.00014 16.9995 9.00014L20.7329 9.00014C21.2851 9.00014 21.7329 8.55243 21.7329 8.00014V4.23544C21.7329 3.68315 21.2851 3.23544 20.7329 3.23544C20.1806 3.23544 19.7329 3.68315 19.7329 4.23544V5.44249C19.7329 5.44249 18.4489 2.99986 14.9997 3C11.5453 3.00014 8.71203 6.05709 8.99962 9.49952C9.26427 12.6673 11.8209 15 14.9997 15C17.9995 15 17.9995 13 17.9995 13C17.9995 13 16.8879 13.0043 14.9997 13C13.1115 12.9957 11.259 11.5594 10.9996 9.5C10.7058 7.16723 12.6485 5.0003 14.9997 5.0003C17.4995 5.0003 18.4489 7.00014 18.4489 7.00014L16.9995 7.00014C16.4472 7.00014 15.9995 7.44786 15.9995 8.00014Z"
        fill="#040405"
      />
      <path
        d="M18.5346 12.535C18.9251 12.9256 18.9289 13.5669 18.4793 13.8876C17.4978 14.5877 16.2965 14.9995 14.999 14.9995C11.6853 14.9995 8.99902 12.3132 8.99902 8.99951C8.99902 5.6858 11.6853 2.99951 14.999 2.99951C18.3127 2.99951 20.999 5.6858 20.999 8.99951H18.999C18.999 6.79037 17.2082 4.99951 14.999 4.99951C12.7899 4.99951 10.999 6.79037 10.999 8.99951C10.999 11.2087 12.7899 12.9995 14.999 12.9995C15.7446 12.9995 16.4426 12.7955 17.0401 12.4403C17.5148 12.1581 18.144 12.1445 18.5346 12.535Z"
        fill="#040405"
      />
    </svg>
  );
};
