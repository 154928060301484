import { useMemo } from 'react';
import { VDMS, DSS, CAS } from '../constants';

const DEFAULT_SUPPLY_SOURCE_DEVICE_DETAILS = {
  deviceUA: '-',
  deviceDNT: '-',
  deviceLMT: '-',
  deviceIP: '-',
  deviceIPV6: '-',
  deviceDeviceType: '-',
  deviceMake: '-',
  deviceModel: '-',
  deviceOS: '-',
  deviceOSV: '-',
  deviceHeight: '-',
  deviceWidth: '-',
  deviceIFA: '-',
  deviceIFAType: '-',
  deviceComputerGUID: '-',
  deviceID: '-',
  deviceDistributionPartner: '-',
  deviceDistributorPlatform: '-',
  deviceSessionID: '-',
};

const vdmsSupplySourceDeviceDetails = (data) => ({
  type: VDMS,
  deviceUA: data?.ua,
  deviceDNT: data?.dnt,
  deviceLMT: data?.lmt,
  deviceIP: data?.ip,
  deviceIPV6: data?.ipv6,
  deviceDeviceType: data?.devicetype,
  deviceMake: data?.make,
  deviceModel: data?.model,
  deviceOS: data?.os,
  deviceOSV: data?.osv,
  deviceHeight: data?.h,
  deviceWidth: data?.w,
  deviceIFA: data?.ifa,
  deviceIFAType: data?.ifatype,
  deviceSessionID: data?.sessionid,
});

const dssSupplySourceDeviceDetails = (data) => ({
  type: DSS,
  deviceUA: data?.ua,
  deviceDNT: data?.dnt,
  deviceIP: data?.ip,
  deviceIPV6: data?.ipv6,
  deviceDeviceType: data?.devicetype,
  deviceMake: data?.make,
  deviceModel: data?.model,
  deviceOS: data?.os,
  deviceOSV: data?.osv,
  deviceHeight: data?.h,
  deviceWidth: data?.w,
  deviceIFA: data?.ifa,
  deviceIFAType: data?.ifatype,
  deviceSessionID: data?.sessionid,
  deviceLMT: data?.lmt,
});

const casSupplySourceDeviceDetails = (data) => {
  const deviceDataV2 = data?.['device-info'];
  const deviceDataV3 = data?.device;
  return {
    type: CAS,
    deviceComputerGUID: deviceDataV2?.['computer-guid'],
    deviceDeviceType: deviceDataV2?.device || deviceDataV3?.name,
    deviceIFA: deviceDataV2?.['device-ad-id'] || deviceDataV3?.['ad-id'],
    deviceIFAType: deviceDataV3?.['ifa-type'],
    deviceDNT: deviceDataV2?.['device-dnt'].toString() || deviceDataV3?.['limit-ad-tracking'],
    deviceID: deviceDataV2?.['device-id'] || deviceDataV3?.['ad-id'],
    devicePartner: deviceDataV2?.['distribution-partner'] || data?.content?.distributor,
    devicePlatform: deviceDataV2?.['distributor-platform'],
    deviceIP: deviceDataV2?.['ip-address'] || deviceDataV3?.['ip-v4-address'],
    deviceIPV6: deviceDataV3?.['ip-v6-address'],
    deviceUA: deviceDataV2?.['user-agent'] || deviceDataV3?.['user-agent'],
    deviceLMT: deviceDataV2?.lmt || deviceDataV3?.['limit-ad-tracking'],
  };
};

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceDeviceDetails,
  [DSS]: dssSupplySourceDeviceDetails,
  [CAS]: casSupplySourceDeviceDetails,
};

const resolveSupplySourceDeviceDetails = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_DEVICE_DETAILS;
};

export const useSupplySourceDeviceDetailsResolver = (type, data) => {
  const dataDetail = type === CAS ? data : data?.device;
  return useMemo(() => resolveSupplySourceDeviceDetails(type, dataDetail), [type, dataDetail]);
};
