const separator = ':';

export const auctionDetailsView = {
  SUPPLY_SOURCE: 'SUPPLY_SOURCE',
  POD: 'POD',
  DRAX_RESPONSE: 'DRAX_RESPONSE',
  UNIFIED_POD: 'UNIFIED_POD',
  setPodKey: (podId) => `${auctionDetailsView.POD}${separator}${podId}`,
  getPodKey: (activeViewStr) => {
    const [, podId] = activeViewStr.split(separator);
    return Number(podId);
  },
};

export const REQUEST = 'REQUEST';
export const RESPONSE = 'RESPONSE';
export const VDMS = 'VDMS';
export const DSS = 'DSS';
export const CAS = 'CAS';
export const SUPPLY_KEYS = [VDMS, DSS, CAS];
export const AMAZON = 'AMAZON';
export const MAGNITE = 'MAGNITE';
export const ROKU = 'ROKU';
export const SPOTX = 'SPOTX';
export const DV360 = 'DV360';
export const MELI = 'MELI';
export const TTD = 'TRADEDESK';
export const DEMAND_KEYS = [AMAZON, MAGNITE, ROKU, SPOTX, DV360, MELI, TTD];
export const VOD = 'VOD';
export const VAST = 'VAST';

export const WINNER_CATEGORY = 'WINNER';
export const BID_BUT_LOST_CATEGORY = 'BID_BUT_LOST';
export const FILTERED_BIDS_CATEGORY = 'FILTERED';
export const BIDS_CATEGORIES = [WINNER_CATEGORY, BID_BUT_LOST_CATEGORY, FILTERED_BIDS_CATEGORY];

export const BID_WON = 'BID_WON';
export const WINNER_BID_STATE = ['SELECTED', 'SELECTED_WITHOUT_AUCTION'];
export const BID_BUT_LOST_STATE = ['LOST_TO_GUARANTEED', 'LOST_TO_HIGHER_BID'];

export const CREATED_TIMESTAMP = 'createdTimestamp';

export const PRE_BID_IN_LINE = 'Prebid/in-line';
export const PRE_BID_OUT_OF_LINE = 'Prebid/out-of-line';
export const POST_BID = 'Postbid';

export const REQUEST_TYPE_MAPPING = {
  [CAS]: POST_BID,
  [VDMS]: PRE_BID_IN_LINE,
  [DSS]: PRE_BID_OUT_OF_LINE,
};
