import { Button, Form, Space } from 'antd';
import React, { useState } from 'react';
import { Q_CHECK_VAST_XML_INGEST, VAST_XML } from '../../testUtils';
import { VastXmlIngestionWrapper, CountryCodeSelectWrapper, StyledSelect, ErrorMessageWrapper } from './styles';
import { COUNTRY_CODES } from '../QCheck/constants';
import { connect } from 'dva';
import { Q_CHECK_INGEST_CREATIVE, EDIT } from '../../constants';

const VastXmlIngestionBox = ({
  dispatch,
  records,
  validationType,
  vastXml,
  permissions,
  isIngestButtonClicked,
  setIngestButtonClicked,
}) => {
  const [countryCode, setCountryCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onClicked = async () => {
    if (!countryCode) {
      setErrorMessage('Please select a valid country code.');
      return;
    } else {
      setErrorMessage('');
    }

    setIngestButtonClicked(true);
    try {
      await dispatch({
        type: 'qCheck/ingestXmlToAd',
        payload: { countryCode: countryCode, xml: vastXml },
      });
    } catch (error) {
      setIngestButtonClicked(false);
    }
  };

  const canBeIngested =
    validationType === VAST_XML &&
    Array.isArray(records) &&
    records.length > 0 &&
    records.some((record) => record.valid) &&
    permissions &&
    permissions.can(EDIT, Q_CHECK_INGEST_CREATIVE);

  const countryCodeList = (
    <CountryCodeSelectWrapper>
      <StyledSelect
        options={COUNTRY_CODES}
        value={countryCode}
        onChange={(value) => {
          setCountryCode(value);
          setErrorMessage('');
        }}
        placeholder="Please select country before ingesting!"
      />

      {errorMessage && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}
    </CountryCodeSelectWrapper>
  );

  const ingestButton = (
    <Form.Item>
      <div data-testid={Q_CHECK_VAST_XML_INGEST}>
        <Space>
          <Button type="primary" onClick={onClicked} disabled={isIngestButtonClicked}>
            Submit For Ingestion
          </Button>
        </Space>
      </div>
    </Form.Item>
  );

  return (
    canBeIngested && (
      <VastXmlIngestionWrapper>
        {countryCodeList}
        {ingestButton}
      </VastXmlIngestionWrapper>
    )
  );
};

function mapStateToProps({ loading, app }) {
  return {
    loading: loading.effects['qCheck/pageInit'],
    pathParams: app.pathParams,
  };
}

export default connect(mapStateToProps)(VastXmlIngestionBox);
