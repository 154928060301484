import React from 'react';
import { Alert } from 'antd';

import { AmazonRequestParamsCard } from './AmazonRequestParamsCard';
import { RequestResponseCard } from '../../Common/RequestResponseCard';
import { DescriptionCard } from '../../Common/DescriptionCard';
import { REQUEST, RESPONSE } from '../../constants';
import { useAmazonIntegrator } from './useAmazonIntegrator';
import { IntegratorDataSelector } from '../IntegratorDataSelector';

export const AmazonIntegrator = ({ data, isLoading, pods, handlePodId, podId }) => {
  const { summary, requestParams, bidRequest, bidResponse, amazonPods } = useAmazonIntegrator(data, pods);

  if (data?.[REQUEST]?.error || data?.[RESPONSE]?.error) {
    return <Alert type="error" message="Error loading snapshots." />;
  }

  return (
    <>
      {summary && <DescriptionCard title="Summary" isLoading={isLoading} data={summary} />}
      {requestParams && <AmazonRequestParamsCard isLoading={isLoading} data={requestParams} />}
      {amazonPods && (
        <IntegratorDataSelector name="Pod" data={amazonPods} handleTargetId={handlePodId} targetId={podId} />
      )}
      {bidRequest && <RequestResponseCard isLoading={isLoading} data={bidRequest} header="Bid Request" />}
      {bidResponse && <RequestResponseCard isLoading={isLoading} data={bidResponse} header="Bid Response" />}
    </>
  );
};
