import React, { useEffect } from 'react';
import { Col, Form, Input, notification, Radio, Row } from 'antd';
import { connect } from 'dva';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import { StyledButton } from '../styles';
import { DEVICE, USER_PROFILE } from '../../../constants';
import {
  ADINVESTIGATOR_VIEW_RULES_DEVICE,
  ADINVESTIGATOR_VIEW_RULES_SELECT_OVERRIDEBY,
  ADINVESTIGATOR_VIEW_RULES_SUBMIT,
  ADINVESTIGATOR_VIEW_RULES_USERPROFILE,
} from '../../../testUtils';
import { FORM_NOT_EMPTY_RULE } from '../../../utils';

const RuleSearch = ({ dispatch, overrideBy, setOverrideBy, searchParams = {} }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // Update the local state when the overrideBy prop changes
    form.setFieldsValue({ overrideBy: overrideBy });
  }, [form, overrideBy]);

  const changeOverrideBy = ({ target }) => {
    setOverrideBy(target.value);
    dispatch({
      type: 'app/setSearchParams',
      payload: { ...searchParams, viewType: target.value },
    });
    dispatch({ type: 'adInvestigator/fetchExistingRules', payload: { overrideBy: target.value } });
  };

  const refreshRules = () => {
    dispatch({ type: 'adInvestigator/fetchExistingRules', payload: { overrideBy } });
    notification.success({
      message: 'Refreshed',
      duration: 3,
    });
  };

  const findRules = (values) => {
    dispatch({ type: 'adInvestigator/fetchExistingRules', payload: values });
    notification.success({
      message: 'Rules looked up',
      duration: 3,
    });
  };

  // UI Components
  const userProfileFillout = (
    <>
      <Col span={6} offset={4} data-testid={ADINVESTIGATOR_VIEW_RULES_USERPROFILE}>
        <Form.Item
          name="userId"
          label="User/Account Id"
          rules={[{ required: true, message: 'Please input user/account id value' }, FORM_NOT_EMPTY_RULE]}
          validateTrigger="onSubmit"
        >
          <Input placeholder="User/Account Id" />
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item
          name="profileId"
          label="Profile Id"
          rules={[{ required: true, message: 'Please input profile id value' }, FORM_NOT_EMPTY_RULE]}
          validateTrigger="onSubmit"
        >
          <Input placeholder="Profile Id" />
        </Form.Item>
      </Col>
    </>
  );

  const deviceFillout = (
    <Col span={5} offset={10} data-testid={ADINVESTIGATOR_VIEW_RULES_DEVICE}>
      <Form.Item
        name="deviceId"
        label="Device Id"
        rules={[{ required: true, message: 'Please input device id value' }]}
      >
        <Input placeholder="Device Id" />
      </Form.Item>
    </Col>
  );

  const idFillout = overrideBy === USER_PROFILE ? userProfileFillout : deviceFillout;

  const selectOverrideBy = (
    <Col span={6} data-testid={ADINVESTIGATOR_VIEW_RULES_SELECT_OVERRIDEBY}>
      <Form.Item label="Search By" name="overrideBy">
        <Radio.Group optionType="button" onChange={changeOverrideBy}>
          <Radio value={USER_PROFILE}>User/Account</Radio>
          <Radio value={DEVICE}>Device</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>
  );

  const submit = (
    <>
      <Col data-testid={ADINVESTIGATOR_VIEW_RULES_SUBMIT}>
        <Form.Item>
          <StyledButton icon={<SearchOutlined />} type="primary" htmlType="submit" />
        </Form.Item>
      </Col>
      <Col>
        <StyledButton type="primary" onClick={refreshRules} icon={<ReloadOutlined />}>
          Refresh
        </StyledButton>
      </Col>
    </>
  );

  return (
    <Form form={form} onFinish={findRules} initialValues={{ overrideBy: overrideBy }}>
      <Row gutter={4}>
        {selectOverrideBy}
        {idFillout}
        {submit}
      </Row>
    </Form>
  );
};

function mapStateToProps({ app }) {
  return {
    searchParams: app.searchParams,
  };
}

export default connect(mapStateToProps)(RuleSearch);
