import { request } from '../utils';
import { POST, ZENDESK_API } from '../constants';
import envConfig from '@hulu/env-config';

const createTicket = async ({ subject, name, email, comment, priority = 'normal' }) => {
  const collaborators = envConfig.REACT_APP_ZENDESK_COLLABORATORS
    ? envConfig.REACT_APP_ZENDESK_COLLABORATORS.split(/\s*,\s*/)
    : [];

  const brand_id =
    email.includes('@disney.com') || email.includes('@disneystreaming.com')
      ? envConfig.REACT_APP_ZENDESK_DISNEY_BRAND_ID
      : envConfig.REACT_APP_ZENDESK_EXTERNAL_BRAND_ID;

  const reqBody = {
    ticket: {
      subject: subject,
      comment: {
        body: comment,
      },
      priority: priority,
      group_id: envConfig.REACT_APP_ZENDESK_GROUP_ID,
      requester: {
        name: name,
        email: email,
      },
      collaborators: collaborators,
      brand_id: brand_id,
    },
  };

  return request(POST, `${ZENDESK_API}/v2/tickets.json`, reqBody, {}, 'Failed to create a Zendesk ticket.');
};

export const signUp = async ({ name, email, phone, company, notes }) => {
  const subject = 'AdTools: New User Sign Up Request';
  const comment = `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\nCompany: ${company}\nNotes:\n${notes ?? ''}`;

  return createTicket({
    subject,
    name,
    email,
    comment,
  });
};

export const sendFeedback = async ({ name, email, feedback }) => {
  const subject = 'AdTools: Feedback';
  const comment = `Name: ${name}\nEmail: ${email}\nFeedback:\n${feedback ?? ''}`;

  return createTicket({
    subject,
    name,
    email,
    comment,
  });
};
