import { useState, useEffect } from 'react';
import { getSnapshotById } from '../../../services/auctionSnapshot';

export const useSnapshotParametersInfo = () => {
  const [snapshotId, setSnapshotId] = useState(null);
  const [snapshotParametersLoading, setSnapshotParametersLoading] = useState(false);
  const [snapshotParametersError, setSnapshotParametersError] = useState(null);
  const [isSnapshotParametersInfoOpen, setIsSnapshotParametersInfoOpen] = useState(false);
  const [snapshotParametersInfo, setSnapshotParametersInfo] = useState({});

  const openSnapshotParametersInfoDrawer = ({ id }) => {
    setSnapshotId(id);
    setIsSnapshotParametersInfoOpen(true);
    setSnapshotParametersLoading(true);
    getSnapshotById(id)
      .then(setSnapshotParametersInfo)
      .catch(setSnapshotParametersError)
      .finally(() => setSnapshotParametersLoading(false));
  };
  const closeSnapshotParametersInfoDrawer = () => setIsSnapshotParametersInfoOpen(false);

  useEffect(() => {
    setSnapshotParametersError(null);
    setSnapshotParametersInfo({});
  }, [snapshotId]);

  return {
    snapshotParametersLoading,
    snapshotParametersError,
    isSnapshotParametersInfoOpen,
    snapshotParametersInfo,

    openSnapshotParametersInfoDrawer,
    closeSnapshotParametersInfoDrawer,
    snapshotId,
  };
};
