import { Tag } from 'antd';
import { CONFIG_STATUS_DISABLED, CONFIG_STATUS_ENABLED } from './constants';

export const CP_IDENTIFIER = 'cp-identifier';
export const CONTENT_PARTNER_ID = 'content-partner-id';
export const SOURCE_ID = 'source-id';
export const TRIGGER_TYPE = 'trigger-type';
export const FETCH_METHOD = 'pod-fetch-method';
export const UPID_LOOKUP_STRATEGY = 'upid-lookup-strategy';
export const STATUS = 'status';
export const UPDATED_AT = 'updated-at';
export const UPDATED_BY = 'updated-by';
export const ENABLED = 'ENABLED';
export const DISABLED = 'DISABLED';
export const CREATED_BY = 'created-at';
export const CREATED_AT = 'created-by';
export const NETWORK_NAME = 'network-name';
export const HISTORY_ACTION = 'action';

export const configStatusRender = (status) => {
  switch (status) {
    case CONFIG_STATUS_ENABLED:
      return <Tag color="green">Enabled</Tag>;
    case CONFIG_STATUS_DISABLED:
      return <Tag color="gray">Disabled</Tag>;
    default:
      return <Tag color="red">Unknown</Tag>;
  }
};

export const convertToTableData = ([, data]) => ({
  key: data[SOURCE_ID],
  [`${CP_IDENTIFIER}`]: data[CP_IDENTIFIER],
  [`${SOURCE_ID}`]: data[SOURCE_ID],
  [`${TRIGGER_TYPE}`]: data[TRIGGER_TYPE],
  [`${FETCH_METHOD}`]: data[FETCH_METHOD],
  [`${UPID_LOOKUP_STRATEGY}`]: data[UPID_LOOKUP_STRATEGY]?.name ?? 'N/A',
  [`${STATUS}`]: data[STATUS],
  [`${UPDATED_BY}`]: data[UPDATED_BY],
  [`${UPDATED_AT}`]: data[UPDATED_AT],
});

export const sourceStatusRender = (status) => {
  switch (status) {
    case 'ENABLED':
      return <Tag color="green">Enabled</Tag>;
    case 'DISABLED':
      return <Tag color="gray">Disabled</Tag>;
    default:
      return <Tag color="red">Unknown</Tag>;
  }
};
