import { useState } from 'react';
import { BID_STATUS, BIDDER } from '../../../../constants';
import { BIDS_CATEGORIES } from '../constants';

export const useTabCollapse = (bidderNames) => {
  const [activeTab, setActiveTab] = useState(BID_STATUS);
  const [activeBidStatus, setActiveBidStatus] = useState([]);
  const [activeBidderName, setActiveBidderName] = useState([]);

  const handleActiveTab = (key) => setActiveTab(key);

  const handleBidStatusActiveCollapse = (key) => setActiveBidStatus(key);
  const handleBidderNameActiveCollapse = (key) => setActiveBidderName(key);

  const handleExpandAll = () => {
    if (activeTab === BID_STATUS) {
      return setActiveBidStatus(BIDS_CATEGORIES);
    }
    if (activeTab === BIDDER) {
      return setActiveBidderName(bidderNames);
    }
  };

  const handleCollapseAll = () => {
    if (activeTab === BID_STATUS) {
      return setActiveBidStatus([]);
    }
    if (activeTab === BIDDER) {
      return setActiveBidderName([]);
    }
  };

  return {
    activeTab,
    activeBidStatus,
    activeBidderName,

    handleActiveTab,
    handleBidStatusActiveCollapse,
    handleBidderNameActiveCollapse,

    handleExpandAll,
    handleCollapseAll,
  };
};
