import { jwtTokenHeader, request } from '../utils';
import { GET, POST, RESERVATIONS_API, RESERVATION_REQUEST } from '../constants';

export const searchContentPartners = async ({ allowReservations }) =>
  request(
    GET,
    `${RESERVATIONS_API}/v1/secure/content-partners?allow-reservations=${allowReservations}`,
    {},
    await jwtTokenHeader(),
    'Failed to look up content partners list'
  );

export const searchSeriesByCpMonth = async (contentPartnerId, selectedMonth) =>
  request(
    GET,
    `${RESERVATIONS_API}/v1/secure/series?content-partner-id=${contentPartnerId}&selected-month=${selectedMonth}`,
    {},
    await jwtTokenHeader(),
    'Failed to look up series by selected content partner & month.'
  );

export const submitReservations = async (selectedCp, selectedMonth, selectedSeries) => {
  let body = {
    [RESERVATION_REQUEST.CONTENT_PARTNER_ID]: selectedCp,
    [RESERVATION_REQUEST.MONTH]: selectedMonth,
    [RESERVATION_REQUEST.SERIES]: selectedSeries,
  };
  return request(
    POST,
    `${RESERVATIONS_API}/v3/secure/reservations`,
    body,
    await jwtTokenHeader(),
    'Reservation failed!'
  );
};

export const getReservedSeriesAudits = async () =>
  request(
    GET,
    `${RESERVATIONS_API}/v2/secure/reservations-audit-logs`,
    {},
    await jwtTokenHeader(),
    'Failed to lookup reservation audit events'
  );
