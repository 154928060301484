import moment from 'moment';
import { DSS, VDMS, CAS, REQUEST, BID_WON } from './constants';

const POD_SIZE = 15;
const DSS_POD_TOTAL_ELIGIBLE_SLOTS = 15;
const CAS_POD_POSITION = 'MIDROLL_';

const groupBidsByPod = (bids) => {
  return bids?.reduce((acc, curr) => {
    const podBids = acc[curr.adIndex] || { wonBids: [], allBids: [] };
    if (curr.bidStatus === BID_WON) {
      podBids?.wonBids?.push(curr);
    }
    podBids?.allBids?.push(curr);
    acc[curr.adIndex] = podBids;
    return acc;
  }, {});
};

const vdmsPods = (data, bidsByPods) => {
  const originalPods = data.supplySource[REQUEST]?.record?.rawData?.podconfig.pods ?? [];
  const pods = originalPods.map((el) => {
    const podId = el.podid;
    return {
      id: podId,
      duration: el.adpoddurationsec,
      totalEligibleSlots: Math.floor(el.adpoddurationsec / POD_SIZE),
      bidsWonCount: bidsByPods?.[podId]?.wonBids?.length ?? 0,
      allBidsCount: bidsByPods?.[podId]?.allBids?.length ?? 0,
    };
  });
  return { ...data, pods };
};

const dssPods = (data, bidsByPods) => {
  const originalPods = data.supplySource[REQUEST]?.record?.rawData?.imp ?? [];
  const pods = originalPods.map((el) => {
    const podId = Number(el.id);
    return {
      id: podId,
      duration: el?.video?.maxduration,
      totalEligibleSlots: DSS_POD_TOTAL_ELIGIBLE_SLOTS,
      bidsWonCount: bidsByPods?.[podId]?.wonBids?.length ?? 0,
      allBidsCount: bidsByPods?.[podId]?.allBids?.length ?? 0,
    };
  });
  return { ...data, pods };
};

const casPods = (data, bidsByPods) => {
  const originalPods = data.supplySource[REQUEST]?.record?.rawData?.['pod-info'] ?? [];
  const pods = originalPods.map((el) => {
    const [, id] = el?.['pod-position'].split(CAS_POD_POSITION);
    const podId = Number(id);
    return {
      id: podId,
      duration: moment.duration(el?.['max-duration']).asSeconds(),
      totalEligibleSlots: Math.floor(el?.['max-ads']),
      bidsWonCount: bidsByPods?.[podId]?.wonBids?.length ?? 0,
      allBidsCount: bidsByPods?.[podId]?.allBids?.length ?? 0,
    };
  });
  return { ...data, pods };
};

const podsMap = {
  [VDMS]: vdmsPods,
  [DSS]: dssPods,
  [CAS]: casPods,
};

export const resolvePods = (transformedData, bids) => {
  const fn = podsMap[transformedData?.supplySource?.type];
  return fn ? fn(transformedData, groupBidsByPod(bids)) : transformedData;
};
