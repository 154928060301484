import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { StyledButton, StyledModal } from '../../styles';

const SignUpForm = ({ visible, onCancel, onSignUp }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setIsLoading(true); // Start loading
      await onSignUp(values);
      setIsLoading(false); // Stop loading
      form.resetFields();
    } catch (error) {
      setIsLoading(false); // Stop loading in case of an error
      console.error('Error:', error);
    }
  };

  return (
    <StyledModal
      open={visible}
      title="New User Sign-Up"
      onCancel={onCancel}
      footer={[
        <StyledButton key="back" onClick={onCancel}>
          Cancel
        </StyledButton>,
        <StyledButton key="submit" type="primary" onClick={handleOk} loading={isLoading}>
          Sign Up
        </StyledButton>,
      ]}
    >
      <Form form={form} layout="vertical" name="signUpForm">
        <Form.Item name="name" label="Full Name" rules={[{ required: true, message: 'Please input your full name' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your email' },
            { type: 'email', message: 'Invalid email format' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Mobile Number"
          rules={[
            { required: true, message: 'Please input your mobile number' },
            { pattern: /^[0-9()\-\s]+$/, message: 'Enter a valid mobile number' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="company" label="Company" rules={[{ required: true, message: 'Please input your company' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="notes" label="Notes">
          <Input.TextArea style={{ resize: 'vertical', minHeight: '150px' }} />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default SignUpForm;
