import { notification } from 'antd';
import { routerRedux } from 'dva/router';

import { postRules, getAllRules, updateRuleStatus, deleteRule } from '../../services/durationManager';
import { DURATION_MANAGER_PATH, SUCCESS } from '../../constants';
import { buildHashKey } from '../../pages/DurationManager/utils';
import {
  RULE_CHANGE_TYPE_CREATE,
  RULE_CHANGE_TYPE_EDIT,
  RULE_CHANNEL_ID,
  RULE_SERIES_ID,
  RULE_LEAGUE_ID,
  RULE_TYPE,
  STREAM_TYPE,
  RULE_EVENT_ID,
  RULE_SPORTS_ID,
} from '../../pages/DurationManager/constants';

export const durationRuleMapSelector = (state) => state.durationManager.viewDurationRulesState.durationRuleMap;
export const stagedBulkRulesSelector = (state) => state.durationManager.stagedBulkRules;

const initState = {
  viewDurationRulesState: {
    durationRules: [],
    durationRuleMap: new Map(),
    durationWidths: [],
    bulkUploadWidths: [],
  },
  stagedBulkRules: [],
};

export const durationManager = {
  namespace: 'durationManager',
  state: initState,
  reducers: {
    updateBulkRules(state, { payload: stagedBulkRules }) {
      return { ...state, stagedBulkRules };
    },
    updateBulkUploadWidths(state, { payload: bulkUploadWidths }) {
      return { ...state, bulkUploadWidths };
    },
    updateDurationManagerWidths(state, { payload: durationWidths }) {
      return { ...state, durationWidths };
    },
    updateDurationRules(state, { payload: durationRules }) {
      const viewDurationRulesState = { ...state.viewDurationRulesState };
      viewDurationRulesState.durationRules = durationRules;
      viewDurationRulesState.durationRuleMap = new Map(durationRules.map((rule) => [rule.key, rule]));
      return { ...state, viewDurationRulesState };
    },
    resetStagedBulkRules(state) {
      return { ...state, stagedBulkRules: [] };
    },
  },
  effects: {
    *pageInit({ payload: { pathParams } }, { put }) {
      yield put({ type: 'fetchDurationRules' });
    },
    *fetchDurationRules(emptyPayload, { call, put }) {
      const response = yield call(getAllRules);
      if (response) {
        const rules = response.data.map((rule) => ({
          key: buildHashKey(
            rule[STREAM_TYPE],
            rule[RULE_TYPE],
            rule[RULE_CHANNEL_ID],
            rule[RULE_SERIES_ID],
            rule[RULE_LEAGUE_ID],
            rule[RULE_EVENT_ID],
            rule[RULE_SPORTS_ID]
          ),
          ...rule,
        }));
        yield put({ type: 'updateDurationRules', payload: rules });
      }
    },
    *stageBulkUploadRules({ payload: ruleMatrix }, { put, select }) {
      const durationRuleMap = yield select(durationRuleMapSelector);
      const stagedBulkRules = ruleMatrix
        .filter((rule) => rule.length) // remove empty rows
        .map((rule) => {
          const [
            streamType,
            ruleType,
            channelId,
            seriesId,
            leagueId,
            eventId,
            sportsId,
            duration,
            singleProgram,
            partialPod,
          ] = rule;
          const key = buildHashKey(streamType, ruleType, channelId, seriesId, leagueId, eventId, sportsId);
          return {
            key,
            'stream-type': streamType,
            'rule-type': ruleType,
            'channel-id': channelId,
            ...(seriesId ? { 'series-id': seriesId } : {}),
            ...(leagueId ? { 'league-id': leagueId } : {}),
            ...(eventId ? { 'event-id': eventId } : {}),
            ...(sportsId ? { 'sports-id': sportsId } : {}),
            'durations-ms': duration?.split('|')?.map((s) => parseInt(s, 10)),
            ...(singleProgram ? { 'is-single-program': singleProgram?.toLowerCase() === 'true' } : {}),
            ...(partialPod ? { 'is-partial-pods': partialPod?.toLowerCase() === 'true' } : {}),
            'change-type': durationRuleMap.has(key) ? RULE_CHANGE_TYPE_EDIT : RULE_CHANGE_TYPE_CREATE,
            previousRule: durationRuleMap.get(key),
          };
        });

      yield put({ type: 'updateBulkRules', payload: stagedBulkRules });
    },
    *postRules({ payload: { reqBody, changeType, redirectToAllRules = false } }, { call, put, select }) {
      try {
        const response = yield call(postRules, reqBody);
        if (response.status === SUCCESS) {
          const failedRulesToUpdate = response.data.filter((rule) => rule['failure-reason']);

          // Check if partial success
          if (failedRulesToUpdate.length > 0) {
            const failedChannelIds = failedRulesToUpdate.map(({ 'channel-id': channelId }) => channelId);
            yield call(notification.warn, {
              message: `${JSON.stringify(failedChannelIds.join(', '))} failed to upload`,
              duration: 3,
            });

            const failedChannelIdSet = new Set(failedChannelIds);
            const stagedBulkRules = yield select(stagedBulkRulesSelector);
            const updatedStagedBulkRules = stagedBulkRules.filter(({ 'channel-id': channelId }) =>
              failedChannelIdSet.has(channelId)
            );
            yield put({ type: 'updateBulkRules', payload: updatedStagedBulkRules });

            // Is total success
          } else {
            yield call(notification.success, {
              message: `${changeType} rule successfully!`,
              duration: 3,
            });
            yield put({ type: 'resetStagedBulkRules' });
            if (redirectToAllRules) {
              yield put(routerRedux.push(DURATION_MANAGER_PATH));
            }
          }
        }
        return { statusCode: response.status };
      } catch (error) {
        yield call(notification.error, {
          message: `Failed to ${changeType.toLowerCase()} rule!`,
          description: error.message,
          duration: 3,
        });
        return { statusCode: error.response.status };
      }
    },
    *updateRuleStatus({ payload: { path, reqBody, action } }, { call, put }) {
      try {
        const response = yield call(updateRuleStatus, path, reqBody);
        if (response.status === SUCCESS) {
          yield call(notification.success, {
            message: `${action} rule successfully!`,
            duration: 3,
          });
          yield put({ type: 'fetchDurationRules' });
        }
      } catch (error) {
        yield call(notification.error, {
          message: `Failed to ${action.toLowerCase()} rule!`,
          description: error.message,
          duration: 3,
        });
      }
    },
    *deleteRule({ payload: { path } }, { call, put }) {
      try {
        const response = yield call(deleteRule, path);
        if (response.status === SUCCESS) {
          yield call(notification.success, {
            message: `Delete rule successfully!`,
            duration: 3,
          });
          yield put(routerRedux.push(DURATION_MANAGER_PATH));
        }
      } catch (error) {
        yield call(notification.error, {
          message: `Failed to delete rule!`,
          description: error.message,
          duration: 3,
        });
      }
    },
    *sendError({ payload: { message, description } }, { call }) {
      yield call(notification.error, {
        message: message,
        description: description,
        duration: 3,
      });
    },
  },
};
