import React from 'react';
import { Row } from 'antd';

import { StyledRowHead, StyledRowInfo } from '../style';

export const RowInfo = ({ title, data }) => {
  return (
    <Row wrap={false}>
      <StyledRowHead>{title}:</StyledRowHead>
      <StyledRowInfo>{data || typeof data === 'number' ? String(data) : '-'}</StyledRowInfo>
    </Row>
  );
};
