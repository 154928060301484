import React, { useEffect } from 'react';
import moment from 'moment';
import { Row, Modal, Form, Button } from 'antd';
import { connect } from 'dva';

import { Typography } from '@hulu/react-style-components';
import { expireAtFillout, formList, WIDE_FILLOUT, pauseAdIdFillout } from '../fillouts';
import { FormWrapper } from './styles';
import { ADINVESTIGATOR_VIEW_RULES_MODAL } from '../../../testUtils';
import { VIDEO_AD_TYPE } from '../../../constants';

const MODAL_WIDTH = 750;
const MODAL_STYLES = { maxHeight: '60vh', overflow: 'auto' };

const EditRulesModal = ({ dispatch, overrideBy, selectedOverride, setSelectedOverride, rules = [] }) => {
  const [form] = Form.useForm();

  // State Prepopulation
  const rule = rules.find(({ key }) => key === selectedOverride);

  useEffect(() => {
    if (rule) {
      const { adType } = rule;
      if (adType === VIDEO_AD_TYPE) {
        let editRules = [];
        Object.entries(rule.mappedPositions).forEach(([pod, slots]) =>
          slots.forEach(({ number: slot, 'ad-id': adId }) => editRules.push({ pod, slot, adId }))
        );
        form.setFieldsValue({ editRules, expire: moment.unix(rule.expireAt) });
      } else {
        const pauseAdId = rule.pauseAdId;
        form.setFieldsValue({ pauseAdId, expire: moment.unix(rule.expireAt) });
      }
    }
  }, [form, rule]);

  // Rendering logics
  if (rule) {
    const { adType, userId, profileId, deviceId } = rule;

    // Event Listeners
    const handleCancel = () => {
      setSelectedOverride(null);
      form.resetFields();
    };

    const onFinish = ({ expire, editRules, pauseAdId }) => {
      dispatch({
        type: 'adInvestigator/submitRule',
        payload: { overrideBy, adType, userId, profileId, deviceId, expire, pauseAdId, overrideRules: editRules },
      });
      setSelectedOverride(null);
      form.resetFields();
    };

    // UI Components
    const modalTitle = `Edit Rule for ${
      userId ? `User/Account: "${userId}", Profile: "${profileId}"` : `Device: "${deviceId}"`
    }`;

    const footer = [
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button form="editRules" key="submit" type="primary" htmlType="submit">
        Submit
      </Button>,
    ];

    const formContent = (
      <div data-testid={ADINVESTIGATOR_VIEW_RULES_MODAL}>
        <FormWrapper id="editRules" form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Typography variant="h5">Update Expire Time</Typography>
          </Row>
          <br />
          <Row gutter={16}>{expireAtFillout()}</Row>
          <Row gutter={16}>
            <Typography variant="h5">
              {adType === VIDEO_AD_TYPE ? 'Update Pod-Slot-Ad Rules' : 'Update Ad Rules'}
            </Typography>
          </Row>
          <Row gutter={16}>
            {adType === VIDEO_AD_TYPE ? formList('editRules', WIDE_FILLOUT) : pauseAdIdFillout(WIDE_FILLOUT)}
          </Row>
        </FormWrapper>
      </div>
    );

    return (
      <Modal
        open={selectedOverride !== null}
        title={<Typography variant="h3">{modalTitle}</Typography>}
        width={MODAL_WIDTH}
        onCancel={handleCancel}
        footer={footer}
        bodyStyle={MODAL_STYLES}
      >
        {formContent}
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default connect()(EditRulesModal);
