import React from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';
import { colors, spacers } from '@hulu/design-tokens';
import { Typography } from '@hulu/react-style-components';

const StyledSpacer = styled.div`
  margin-bottom: ${spacers.spacer04};
`;

const StyledEmptyWrapper = styled.div`
  color: ${colors.colorBlack50};
  padding: ${spacers.spacer08};
`;

const StyledErrorWrapper = styled.div`
  text-align: left;

  .ant-alert-error {
    background-color: ${colors.colorRed6};
    border: 1px solid ${colors.colorRed50};
  }
  .ant-alert-description {
    margin-top: ${spacers.spacer04};
  }
  .ant-alert-with-description {
    padding: ${spacers.spacer05} ${spacers.spacer04} ${spacers.spacer04} ${spacers.spacer05};
  }
`;

const StyledEmailLink = styled.a`
  color: ${colors.colorBlue50};
`;

const ErrorAlert = ({ description }) => {
  const errorTitle = (
    <div>
      Sorry, something went wrong. Please try again or email us at{' '}
      <StyledEmailLink href="mailto: drax-support@disneystreaming.com">
        drax-support@disneystreaming.com
      </StyledEmailLink>
      .
    </div>
  );
  const errorDescription = (
    <div>
      <b>Error Details:</b> {description}
    </div>
  );

  return (
    <StyledErrorWrapper>
      <Alert message={errorTitle} description={errorDescription} type="error" showIcon />
    </StyledErrorWrapper>
  );
};

export const EmptyTable = ({ searchQuery = '', errorMessage = '' }) => {
  const noSearchResultTitle = `Sorry! No Snapshots found matching ${searchQuery}`;
  const noSearchResultText = 'Please check your filters or try searching for a different name or ID.';
  const emptyTableTitle = 'No Ad Request Snapshots found matching your filters and selections.';
  const emptyTableText =
    'To create a new Snapshot, click the Create Ad Request Snapshot button in the top right of the page.';

  return (
    <StyledEmptyWrapper>
      {errorMessage ? (
        <ErrorAlert description={errorMessage} />
      ) : (
        <>
          <Typography variant="h6">{searchQuery ? noSearchResultTitle : emptyTableTitle}</Typography>
          <StyledSpacer />
          <Typography variant="body1">{searchQuery ? noSearchResultText : emptyTableText}</Typography>
        </>
      )}
    </StyledEmptyWrapper>
  );
};
