import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Typography } from 'antd';

const { Paragraph } = Typography;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Arial, sans-serif',
    padding: '100px',
  },
  heading: {
    marginBottom: '20px',
    fontSize: '24px',
  },
  tokenContainer: {
    textAlign: 'center',
  },
  token: {
    padding: '12px',
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    fontSize: '14px',
    wordBreak: 'break-all',
    marginBottom: '10px',
  },
  copied: {
    color: 'green',
    fontSize: '16px',
  },
  loading: {
    fontSize: '16px',
  },
};

const JwtTool = () => {
  const [token, setToken] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchAndCopyToken = async () => {
      try {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();
        setToken(jwtToken);
        await navigator.clipboard.writeText(jwtToken);
        setCopied(true);
      } catch (error) {
        console.error('Failed to copy token: ', error);
      }
    };

    fetchAndCopyToken();
  }, []);

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>JWT Token</h2>
      {token ? (
        <div style={styles.tokenContainer}>
          <Paragraph style={styles.token} copyable ellipsis={{ rows: 5, expandable: 'collapsible' }}>
            {token}
          </Paragraph>
          {copied && <p style={styles.copied}>Token copied to clipboard ✅</p>}
        </div>
      ) : (
        <p style={styles.loading}>Fetching token...</p>
      )}
    </div>
  );
};

export default JwtTool;
