import React, { useState } from 'react';
import { Form, Radio, Input, Button, Space, Row, Popover } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Typography } from '@hulu/react-style-components';

import { CreateAdOverrideRuleWrapper, InfoCircleOutlinedWrapper } from './styles';
import { AD_INVESTIGATOR, DEVICE, OVERRIDES, USER_PROFILE, VIDEO_AD_TYPE, PAUSE_AD_TYPE } from '../../../constants';
import { expireAtFillout, formList, NARROW_FILLOUT, pauseAdIdFillout } from '../fillouts';
import LookupUserProfileIdModal from './LookupUserProfileIdModal';
import {
  ADINVESTIGATOR_CREATE_RULES_DEVICE,
  ADINVESTIGATOR_CREATE_RULES_FORM,
  ADINVESTIGATOR_CREATE_RULES_SUBMIT,
  ADINVESTIGATOR_CREATE_RULES_TOGGLE,
  ADINVESTIGATOR_CREATE_RULES_ADTYPE_TOGGLE,
  ADINVESTIGATOR_CREATE_RULES_USERPROFILE,
  ADINVESTIGATOR_CREATE_RULES_ACCOUNTPROFILE,
} from '../../../testUtils';
import { FORM_NOT_EMPTY_RULE } from '../../../utils';

const CreateAdOverrideRule = ({ dispatch, setTab, setViewOverrideBy, searchParams = {} }) => {
  const [form] = Form.useForm();
  const [overrideBy, setOverrideBy] = useState(searchParams?.createType ?? USER_PROFILE);
  const [adType, setAdType] = useState(VIDEO_AD_TYPE);
  const [userProfileVisible, setUserProfileVisible] = useState(false);

  // Effects
  const handleUserProfileOrDeviceToggle = ({ target }) => {
    dispatch({
      type: 'app/setSearchParams',
      payload: { ...searchParams, createType: target.value },
    });
    setOverrideBy(target.value);
  };

  const handleAdTypeToggle = ({ target }) => {
    setAdType(target.value);
  };

  const onFinish = (values) => {
    dispatch({ type: 'adInvestigator/submitRule', payload: { ...values, overrideBy, adType } });
    dispatch(routerRedux.push('/' + AD_INVESTIGATOR + '/' + OVERRIDES));
    setTab(OVERRIDES);
    setViewOverrideBy(overrideBy);
    setAdType(adType);
    dispatch({
      type: 'app/setSearchParams',
      payload: { ...searchParams, viewType: overrideBy },
    });
    form.resetFields();
  };

  const IdsInformation = (
    <div>
      <p>
        UserId: User identifier (a number) for Hulu (Vod , Live) flows. Example: 4562734.
        <br />
        AccountId: User identifier (UUID value) for D+ flows or Hulu(Vod, Live) unified platform flows.
        <br />
        ProfileId: It's an UUID if tied to an account otherwise it's a number tied to an user.
      </p>
    </div>
  );

  // Components
  const userProfileFillout = (
    <div data-testid={ADINVESTIGATOR_CREATE_RULES_USERPROFILE}>
      <Form.Item
        name="userId"
        label="User/Account Id"
        rules={[{ required: true, message: 'Please input user/account id value' }, FORM_NOT_EMPTY_RULE]}
        validateTrigger="onSubmit"
      >
        <Input placeholder="User/Account ID" />
      </Form.Item>
      <Form.Item
        name="profileId"
        label="Profile Id"
        rules={[{ required: true, message: 'Please input profile id value' }, FORM_NOT_EMPTY_RULE]}
        validateTrigger="onSubmit"
      >
        <Input placeholder="Profile ID" />
      </Form.Item>
      <Row justify="center">
        <Button type="primary" onClick={() => setUserProfileVisible(true)}>
          Don't know IDs? Lookup using sign-in
        </Button>
        <Popover content={IdsInformation} title="Ids Information" trigger="hover">
          <InfoCircleOutlinedWrapper />
        </Popover>
      </Row>
      <br />
      <LookupUserProfileIdModal open={userProfileVisible} setOpen={setUserProfileVisible} />
    </div>
  );

  const accountProfileFillout = (
    <div data-testid={ADINVESTIGATOR_CREATE_RULES_ACCOUNTPROFILE}>
      <Form.Item
        name="userId"
        label="User/Account Id"
        rules={[{ required: true, message: 'Please input user/account id value' }, FORM_NOT_EMPTY_RULE]}
        validateTrigger="onSubmit"
      >
        <Input placeholder="User/Account ID" />
      </Form.Item>
      <Form.Item
        name="profileId"
        label="Profile Id"
        rules={[{ required: true, message: 'Please input profile id value' }, FORM_NOT_EMPTY_RULE]}
        validateTrigger="onSubmit"
      >
        <Input placeholder="Profile ID" />
      </Form.Item>
    </div>
  );

  const deviceFillout = (
    <div data-testid={ADINVESTIGATOR_CREATE_RULES_DEVICE}>
      <Form.Item
        name="deviceId"
        label="Device Id"
        rules={[{ required: true, message: 'Please input device id value' }, FORM_NOT_EMPTY_RULE]}
      >
        <Input placeholder="Device ID" />
      </Form.Item>
    </div>
  );

  const overrideByToggle = (
    <Form.Item label="Override By" name="overrideBy">
      <div data-testid={ADINVESTIGATOR_CREATE_RULES_TOGGLE}>
        <Radio.Group defaultValue={overrideBy} onChange={handleUserProfileOrDeviceToggle}>
          <Radio.Button value={USER_PROFILE}>User/Account</Radio.Button>
          <Radio.Button value={DEVICE}>Device</Radio.Button>
        </Radio.Group>
      </div>
    </Form.Item>
  );

  const adTypeToggle = (
    <Form.Item label="Ad Type" name="adType">
      <div data-testid={ADINVESTIGATOR_CREATE_RULES_ADTYPE_TOGGLE}>
        <Radio.Group defaultValue={adType} onChange={handleAdTypeToggle}>
          <Radio.Button value={VIDEO_AD_TYPE}>Video Ads</Radio.Button>
          <Radio.Button value={PAUSE_AD_TYPE}>Pause Ads</Radio.Button>
        </Radio.Group>
      </div>
    </Form.Item>
  );

  const resetSubmitButtons = (
    <Form.Item>
      <div data-testid={ADINVESTIGATOR_CREATE_RULES_SUBMIT}>
        <Space>
          <Button htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </div>
    </Form.Item>
  );

  return (
    <CreateAdOverrideRuleWrapper>
      <div data-testid={ADINVESTIGATOR_CREATE_RULES_FORM}>
        <Typography variant="h3">Create Override Rule</Typography>
        <br />
        <Form form={form} onFinish={onFinish}>
          {adTypeToggle}
          {overrideByToggle}
          {overrideBy === USER_PROFILE
            ? adType === VIDEO_AD_TYPE
              ? userProfileFillout
              : accountProfileFillout
            : deviceFillout}
          {adType === VIDEO_AD_TYPE ? formList('overrideRules', NARROW_FILLOUT) : pauseAdIdFillout(NARROW_FILLOUT)}
          {expireAtFillout()}
          {resetSubmitButtons}
        </Form>
      </div>
    </CreateAdOverrideRuleWrapper>
  );
};

function mapStateToProps({ app: { searchParams } }) {
  return { searchParams };
}

export default connect(mapStateToProps)(CreateAdOverrideRule);
