import { useMemo, useState } from 'react';
import { timestampIntegrators } from '../../timestampIntegrators';
import { REQUEST, RESPONSE, CREATED_TIMESTAMP } from '../../constants';

export const useTheTradeDeskIntegrator = (data, pods) => {
  const [requestNumber, setRequestNumber] = useState(null);
  const integratorPods = data?.demandPods?.length ? data?.demandPods : pods;

  const handleRequestChange = (requestNumber) => setRequestNumber(requestNumber);

  const { summary, requestParams, request, response, ttdPods, availableRequestNumbers } = useMemo(() => {
    const targetRequest = data?.[REQUEST]?.find((req) => req?.requestNumber === requestNumber);
    const targetResponse = data?.[RESPONSE]?.find((res) => res?.requestNumber === requestNumber);

    return {
      summary: {
        timestamp: timestampIntegrators(data?.[RESPONSE]?.record?.[CREATED_TIMESTAMP]),
      },
      requestParams: targetRequest?.record?.rawData ?? {},
      request: targetRequest?.record?.rawData ?? {},
      response: targetResponse?.record?.rawData ?? {},
      ttdPods: integratorPods ?? [],
      availableRequestNumbers: data?.[REQUEST]
        ? data?.[REQUEST]?.sort((a, b) => a.requestNumber - b.requestNumber)?.map(({ requestNumber }) => ({
            id: requestNumber,
          }))
        : [],
    };
  }, [data, integratorPods, requestNumber]);

  return {
    summary,
    requestParams,
    request,
    response,
    ttdPods,
    availableRequestNumbers,
    requestNumber,
    handleRequestChange,
  };
};
