import { Popover, Spin, Steps, Tag } from 'antd';
import React from 'react';
import { connect } from 'dva';

import { creativesColumns, creativeTrackerExportFields } from '../../Templates/columns';
import { CreativesTableWrapper } from './CreativesTableWrapper';
import AssetDetailBox from './AssetDetailBox';
import { AssetBoxWrapper, LoadingTipWrapper } from './styles';
import {
  CREATIVETRACKER_TABLE,
  CREATIVETRACKER_TABLE_ASSET,
  CREATIVETRACKER_TABLE_ROW_LOADING,
  CREATIVETRACKER_TABLE_STEPS,
  PERMISSION_PLACEHOLDER,
} from '../../../testUtils';
import { ResizableTable } from '../../Templates/ResizableTable';
import {
  AD_ID_KEY,
  ASSET_DETAILS_KEY,
  ASSET_ID_KEY,
  SOURCE_AD_ID_KEY,
  CAMPAIGN_MANAGER_NAME_KEY,
  UPDATED_AFTER_DATE_KEY,
  MEDIA_FILE_URL_KEY,
  REJECTION_REASONS_KEY,
  TRANSCODE_STATUS_KEY,
  SOURCE_KEY,
  INGESTION_TYPE_KEY,
  CREATIVE_TYPE_KEY,
  UPDATED_AT_KEY,
  ASSET_NAME_KEY,
  DSP_KEY,
  BRAND_NAMES_KEY,
  CREATIVE_TYPES_KEY,
  REVIEW_STATUS_KEY,
} from '../constants';
import { STATUSES } from '../../../constants';
import Objects from 'validator/es';

import { formatAssetDetails } from '../utils';

const { Step } = Steps;

const TABLE_SCROLL_Y = { y: '60vh' };

const CreativesTable = ({
  dispatch,
  pageInitLoading = false,
  pageUpdateLoading = false,
  assetLoading,
  permissions = PERMISSION_PLACEHOLDER,
  isContentPartner = false,
  creatives = [],
  assets = {},
  creativeWidths = [],
}) => {
  const expandedRowRender = (creative) => {
    const asset = assets[creative[ASSET_ID_KEY]];
    const timeline =
      asset || !assetLoading ? (
        <AssetBoxWrapper>
          {/* TODO add back steps: https://jira.disneystreaming.com/browse/KOALA-266*/}
          {/* Asset In-Details */}
          <div data-testid={CREATIVETRACKER_TABLE_ASSET}>
            <AssetDetailBox asset={asset} creative={creative} permissions />
          </div>
        </AssetBoxWrapper>
      ) : (
        <LoadingTipWrapper data-testid={CREATIVETRACKER_TABLE_ROW_LOADING}>
          <Spin />
        </LoadingTipWrapper>
      );

    return timeline;
  };

  const onExpand = (expanded, creative) => {
    if (expanded) {
      dispatch({ type: 'creativeTracker/fetchAssetDetails', payload: creative });
    }
  };

  const statusPopoverRowRender = (record) => {
    let status = record[REVIEW_STATUS_KEY];
    return (
      <Popover
        trigger="hover"
        placement="topLeft"
        overlayStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '30%' }}
        title="Status Detail"
        onOpenChange={(open) => onExpand(open, record)}
        content={formatAssetDetails(record)}
      >
        <Tag>{status}</Tag>
      </Popover>
    );
  };

  const handleWidthChange = (widths) =>
    dispatch({
      type: 'creativeTracker/updateCreativeWidths',
      payload: widths,
    });

  const excludedColumns = isContentPartner ? new Set([ASSET_ID_KEY, DSP_KEY]) : new Set([SOURCE_AD_ID_KEY]);
  const columns = creativesColumns(creatives, creativeWidths, statusPopoverRowRender).filter(
    (item) => !excludedColumns.has(item.key)
  );
  const exportFields = Object.fromEntries(
    Object.entries(creativeTrackerExportFields).filter(([key]) => !excludedColumns.has(key))
  );

  return (
    <CreativesTableWrapper data-testid={CREATIVETRACKER_TABLE}>
      <Spin spinning={pageInitLoading || pageUpdateLoading}>
        <ResizableTable
          inputColumns={columns}
          dataSource={creatives}
          scroll={TABLE_SCROLL_Y}
          pagination={true}
          widthHandler={handleWidthChange}
          expandable={{
            expandedRowRender: expandedRowRender,
            onExpand: onExpand,
          }}
          searchableProps={null}
          exportableProps={{
            fields: exportFields,
            fileName: 'CreativeTrackerSnapshot ' + new Date().toString(),
          }}
          refresh={null}
        />
      </Spin>
    </CreativesTableWrapper>
  );
};

function mapStateToProps({ app, creativeTracker, loading }) {
  return {
    pageInitLoading: loading.effects['creativeTracker/pageInit'],
    pageUpdateLoading: loading.effects['creativeTracker/pageUpdate'],
    assetLoading: loading.effects['creativeTracker/fetchAssetDetails'],
    creatives: creativeTracker.creatives,
    assets: creativeTracker.assets,
    pathParams: app.pathParams,
    permissions: app.currentUser.permissions,
    isContentPartner: app.currentUser.isContentPartner,
    creativeWidths: creativeTracker.creativeWidths,
  };
}

export default connect(mapStateToProps)(CreativesTable);
