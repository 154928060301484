import { useMemo } from 'react';
import { VDMS, DSS } from '../constants';

const DEFAULT_SUPPLY_SOURCE_REQUEST_PARAMS = {
  videoMimes: [],
  videoMinDuration: '-',
  videoMaxDuration: '-',
  videoProtocols: '-',
  videoWidth: '-',
  videoHeight: '-',
};

const vdmsSupplySourceRequestParams = (data) => ({
  type: VDMS,
  videoMimes: data?.video?.mimes,
  videoMinDuration: data?.video?.minduration,
  videoMaxDuration: data?.video?.maxduration,
  videoProtocols: data?.video?.protocols,
  videoWidth: data?.video?.w,
  videoHeight: data?.video?.h,
  sitePage: data?.site?.page,
});

const dssSupplySourceRequestParams = (data) => ({
  type: DSS,
  videoMimes: data?.imp?.[0]?.video?.mimes,
  videoMinDuration: data?.imp?.[0]?.video?.minduration,
  videoMaxDuration: data?.imp?.[0]?.video?.maxduration,
  videoProtocols: data?.imp?.[0]?.video?.protocols,
  videoWidth: data?.imp?.[0]?.video?.w,
  videoHeight: data?.imp?.[0]?.video?.h,
  sitePage: data?.site,
});

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceRequestParams,
  [DSS]: dssSupplySourceRequestParams,
};

const resolveSupplySourceRequestParams = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_REQUEST_PARAMS;
};

export const useSupplySourceRequestParamsResolver = (type, data) => {
  return useMemo(() => resolveSupplySourceRequestParams(type, data), [type, data]);
};
