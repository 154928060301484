import React from 'react';
import _round from 'lodash/round';
import { Card, Row, Col, Button } from 'antd';
import { colors } from '@hulu/design-tokens';
import { IconClipboardReport } from '@hulu-react-style-components/icons';
import styled from 'styled-components';

import { capitalizer } from './capitalizer';
import { CustomTag } from './CustomTag';
import { SPOTX, BID_WON } from '../constants';
import moment from 'moment';

const winnerStatusColor = '#3CAA82';
const borderColor = '#c6c7c9';

const StyledCard = styled(Card)`
  border-radius: 6px;
  margin-top: 12px;
  border: 1px solid ${borderColor};
  min-height: 124px;
`;

const StyleStatus = styled(Col)`
  width: 1%;
  background-color: ${({ status }) => (status === BID_WON ? winnerStatusColor : colors.colorGray13)};
  min-height: 100%;
  border-radius: 4px 0 0 4px;
  min-height: 124px;
`;

const StyledCardInfo = styled(Col)`
  width: 99%;
  font-size: 12px;
  padding: 24px 12px 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledName = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  width: 130px;
  height: 40px;
  border-radius: 4px;
  border-color: ${colors.colorBlack95};
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 8px;
    font-weight: 600;
  }
  &:hover {
    opacity: 0.6;
  }
`;

const StyledTagRow = styled(Row)`
  margin-top: 12px;
`;

export const BidCard = ({ bid, openBidDetails, isUnifiedSelection }) => {
  const {
    dsp,
    demandPartner,
    pricing,
    floorPrice,
    creativeDuration,
    bidStatus,
    bidState,
    adomain,
    responseTime,
    dealType,
    country,
  } = bid;
  const duration = creativeDuration ? moment.duration(creativeDuration).asSeconds() : null;
  const name = demandPartner?.toUpperCase() === SPOTX ? demandPartner : dsp;

  return (
    <StyledCard>
      <Row>
        <StyleStatus status={bidStatus} />
        <StyledCardInfo>
          <Row justify="space-between">
            <Col>
              <Row align="bottom">
                <StyledName>{name}</StyledName>
              </Row>
            </Col>
            {!isUnifiedSelection && (
              <Col>
                <StyledButton icon={<IconClipboardReport />} onClick={() => openBidDetails(bid)}>
                  Details
                </StyledButton>
              </Col>
            )}
          </Row>
          <StyledTagRow>
            {demandPartner && <CustomTag title="Ad Source" data={demandPartner} />}
            {dealType && <CustomTag title="Deal Type" data={dealType} />}
            {typeof pricing !== 'undefined' && (
              <CustomTag title="Bid Price" data={`$${_round(pricing, 2).toFixed(2)}`} />
            )}
            {floorPrice && <CustomTag title="Supply Floor Price" data={`$${_round(floorPrice, 2).toFixed(2)}`} />}
            {duration !== null && <CustomTag title="Ad Duration" data={duration + ' sec'} />}
            {bidState && <CustomTag title="Bid State" data={capitalizer(bidState)} />}
            {adomain && <CustomTag title="aDomain" data={adomain} />}
            {responseTime && <CustomTag title="Response Time" data={responseTime} />}
            {country && <CustomTag title="Country" data={country} />}
          </StyledTagRow>
        </StyledCardInfo>
      </Row>
    </StyledCard>
  );
};
