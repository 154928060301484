import React from 'react';
import { Alert } from 'antd';

import { RequestResponseCard } from '../Common/RequestResponseCard';
import { RequestParamsCard } from './RequestParamsCard';
import { SummaryCard } from './SummaryCard';
import { AppDetailsCard } from './AppDetailsCard';
import { DeviceDetailsCard } from './DeviceDetailsCard';
import { ContentDetailsCard } from './ContentDetailsCard';
import { UserInfoCard } from './UserInfoCard';
import { CAS } from '../constants';

export const SupplySource = ({ isLoading, data, error, type }) => {
  if (error) {
    return <Alert type="error" message={error.message || error} />;
  }
  return (
    <>
      <SummaryCard isLoading={isLoading} data={data} type={type} />
      <AppDetailsCard isLoading={isLoading} data={data?.rawData} />
      <DeviceDetailsCard isLoading={isLoading} data={data?.rawData} type={type} />
      <ContentDetailsCard isLoading={isLoading} data={data?.rawData} type={type} />
      {type !== CAS && <RequestParamsCard isLoading={isLoading} data={data?.rawData} type={type} />}
      {type === CAS && <UserInfoCard isLoading={isLoading} data={data?.rawData} type={type} />}
      <RequestResponseCard isLoading={isLoading} data={data?.rawData} header="Request" />
    </>
  );
};
