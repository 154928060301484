import moment from 'moment';

import { getCounts } from '../../services/vastTracker';
import { COUNT_TYPES, NO_CONTENT, SELECT_MODE, TOP_NUM_SELECTED } from '../../constants';
import clone from 'clone-deep';
import { getContentPartnerGroups } from '../../services/rbac';
import { currentUserSelector } from '../app';
import { DATE_FORMAT, EVENT_TYPE, FAILURE, OCCURRENCE, TEN_TIMES_PER_ITEM } from './constants';
import { ACCESS_CONTENT_PARTNER_GROUP, ALL } from '../constants';

const INITIAL_STATE = {
  widths: [],
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
  countTypeSelected: COUNT_TYPES.ADUNITID,
  partnerGroups: new Map(),
  topNumSelected: TOP_NUM_SELECTED.TOP_TEN,
  dailyViewEnabled: false,
  filters: {
    [COUNT_TYPES.ADUNITID]: {
      selectMode: SELECT_MODE.TAGS,
      selected: [],
      options: [],
    },
    [COUNT_TYPES.ADSERVER]: {
      selectMode: SELECT_MODE.TAGS,
      selected: [],
      options: [],
    },
    [COUNT_TYPES.NETWORK]: {
      selectMode: SELECT_MODE.TAGS,
      selected: [],
      options: [],
    },
    [COUNT_TYPES.PARTNER_GROUP]: {
      selectMode: SELECT_MODE.MULTIPLE,
      selected: [],
      options: [],
    },
  },
  counts: {
    [COUNT_TYPES.ADUNITID]: {
      items: [],
      widths: [],
    },
    [COUNT_TYPES.ADSERVER]: {
      items: [],
      widths: [],
    },
    [COUNT_TYPES.NETWORK]: {
      items: [],
      widths: [],
    },
    [COUNT_TYPES.PARTNER_GROUP]: {
      items: [],
      widths: [],
    },
  },
};

export const startDateSelector = ({ vastTracker }) => vastTracker.startDate;
export const endDateSelector = ({ vastTracker }) => vastTracker.endDate;
export const countTypeSelectedSelector = ({ vastTracker }) => vastTracker.countTypeSelected;
export const idsSelectedSelector = ({ vastTracker }) => vastTracker.filters[vastTracker.countTypeSelected].selected;
export const partnerGroupsSelector = ({ vastTracker }) => vastTracker.partnerGroups;
export const topNumSelector = ({ vastTracker }) => vastTracker.topNumSelected;

export const vastTracker = {
  namespace: 'vastTracker',
  state: clone(INITIAL_STATE),
  reducers: {
    updateCounts(state, { payload: { type, countsToUpdate } }) {
      const { counts, topNumSelected, dailyViewEnabled, startDate, endDate } = state;
      const sortedCounts = countsToUpdate
        .filter(({ value }) => !value.endsWith('_total'))
        .sort((a, b) => getOccurrenceByEventType(b, FAILURE) - getOccurrenceByEventType(a, FAILURE));

      counts[type].itemsById =
        sortedCounts.length > topNumSelected ? sortedCounts.slice(0, topNumSelected) : sortedCounts;
      counts[type].itemsByDate = computeItemsByDate(counts[type].itemsById, startDate, endDate);
      counts[type].items = dailyViewEnabled ? counts[type].itemsByDate : counts[type].itemsById;

      return { ...state, counts };
    },
    updateTopNumSelected(state, { payload: topNumSelected }) {
      return { ...state, topNumSelected };
    },
    updateDates(state, { payload: { startDate, endDate } }) {
      return { ...state, startDate, endDate };
    },
    updateIds(state, { payload: ids }) {
      const { filters, countTypeSelected } = state;
      filters[countTypeSelected].selected = ids;
      return { ...state, filters };
    },
    updateCountTypeSelected(state, { payload: countTypeSelected }) {
      return { ...state, countTypeSelected };
    },
    updatePartnerGroups(state, { payload: partnerGroups }) {
      const currState = clone(state);
      currState.partnerGroups = partnerGroups;
      return currState;
    },
    updatePartnerGroupOptions(state, { payload: partnerGroupOptions }) {
      const currState = clone(state);
      currState.filters[COUNT_TYPES.PARTNER_GROUP].options = partnerGroupOptions;
      return currState;
    },
    setDailyViewEnabled(state, { payload: { type, dailyViewEnabled } }) {
      const { counts } = state;
      counts[type].items = dailyViewEnabled ? counts[type].itemsByDate : counts[type].itemsById;
      return { ...state, dailyViewEnabled, counts };
    },
    resetToInitialState() {
      return clone(INITIAL_STATE);
    },
    updateCountTableWidths(state, { payload: { type, widths } }) {
      const { counts } = state;
      counts[type].widths = widths;
      return { ...state, counts };
    },
  },
  effects: {
    *pageInit(emptyPayload, { call, put, select }) {
      const startDate = yield select(startDateSelector);
      const endDate = yield select(endDateSelector);
      const countTypeSelected = yield select(countTypeSelectedSelector);
      const idsSelected = yield select(idsSelectedSelector);
      const partnerGroups = yield select(partnerGroupsSelector);
      const topNumSelected = yield select(topNumSelector);

      const resp = yield call(
        getCounts,
        requestCountType(countTypeSelected),
        requestIds(countTypeSelected, idsSelected, partnerGroups),
        startDate.format(DATE_FORMAT),
        endDate.format(DATE_FORMAT),
        topNumSelected * TEN_TIMES_PER_ITEM
      );

      if (resp?.data || resp?.status === NO_CONTENT) {
        const records = resp?.data ? resp.data : [];
        yield put({
          type: 'updateCounts',
          payload: { type: countTypeSelected, countsToUpdate: aggregateCounts(records) },
        });
      }
    },
    *loadPartnerGroups(emptyPayload, { call, put, select }) {
      const { permissions: abilities } = yield select(currentUserSelector);
      const response = yield call(getContentPartnerGroups);

      if (Array.isArray(response?.data)) {
        // a map of partner group and its content partners
        const partnerGroups = buildPartnerGroupMap(response.data, abilities);

        yield put({
          type: 'updatePartnerGroups',
          payload: partnerGroups,
        });

        yield put({
          type: 'updatePartnerGroupOptions',
          payload: buildPartnerGroupOptions(partnerGroups),
        });
      }
    },
  },
};

const requestCountType = (countType) => (countType === COUNT_TYPES.PARTNER_GROUP ? COUNT_TYPES.NETWORK : countType);

const requestIds = (countType, ids, partnerGroups) =>
  countType === COUNT_TYPES.PARTNER_GROUP ? ids.flatMap((id) => partnerGroups.get(id)) : ids;

const buildPartnerGroupMap = (partnerGroups, abilities) => {
  return new Map(
    partnerGroups
      .filter(
        (group) =>
          abilities.can(ACCESS_CONTENT_PARTNER_GROUP, ALL) || abilities.can(ACCESS_CONTENT_PARTNER_GROUP, group.name)
      )
      .map((group) => [group.name, group['content-partners'].map((item) => item.name)])
  );
};

const buildPartnerGroupOptions = (partnerGroups) => {
  return [...partnerGroups.keys()].map((item) => ({
    value: item,
    label: item,
  }));
};

// aggregate counts by id
export const aggregateCounts = (records) => {
  const countMap = new Map();
  records.forEach(({ value, date, counts }) => {
    const { stats, dailyRecords } = countMap.get(value) ?? { stats: new Map(), dailyRecords: new Map() };
    counts.forEach((item) => {
      stats.set(item[EVENT_TYPE], (stats.get(item[EVENT_TYPE]) ?? 0) + item[OCCURRENCE]);
    });

    dailyRecords.set(date, counts);
    countMap.set(value, { stats, dailyRecords });
  });

  return Array.from(countMap, ([id, { stats, dailyRecords }]) => ({
    value: id,
    counts: Array.from(stats, ([eventType, occurrence]) => ({ [EVENT_TYPE]: eventType, [OCCURRENCE]: occurrence })),
    dailyRecords: dailyRecords,
  }));
};

export const computeItemsByDate = (itemsById, startDate, endDate) => {
  const countMap = new Map();
  // init map for each day between startDate to endDate
  for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'days')) {
    countMap.set(date.format(DATE_FORMAT), new Map());
  }

  itemsById.forEach(({ dailyRecords }) => {
    for (const [date, counts] of dailyRecords.entries()) {
      const stats = countMap.get(date) ?? new Map();
      counts.forEach((item) => {
        stats.set(item[EVENT_TYPE], (stats.get(item[EVENT_TYPE]) ?? 0) + item[OCCURRENCE]);
      });
      countMap.set(date, stats);
    }
  });

  const sortedMap = new Map(
    [...countMap.entries()].sort((a, b) => moment(a[0], DATE_FORMAT).unix() - moment(b[0], DATE_FORMAT).unix())
  );
  return Array.from(sortedMap, ([date, counts]) => ({
    value: date,
    counts: Array.from(counts, ([eventType, occurrence]) => ({ [EVENT_TYPE]: eventType, [OCCURRENCE]: occurrence })),
  }));
};

export const getOccurrenceByEventType = ({ counts }, filterEventType) => {
  return counts.find(({ [EVENT_TYPE]: eventType }) => eventType === filterEventType)?.['occurrence'] ?? 0;
};
