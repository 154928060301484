import { useEffect } from 'react';
import { Form } from 'antd';
import { isEqual, pick } from 'lodash';

import { openNotificationWithIcon } from '../notifications';
import { createProfile, updateProfile } from '../../../services/auctionSnapshot';
import { FORM_FIELDS } from './constants';

export const useProfileForm = ({ onClose, setLoading, selectedProfile }) => {
  const [form] = Form.useForm();
  const selectType = Form.useWatch(FORM_FIELDS.SELECT_TYPE.name, form);

  const createProfileSubmit = async (payload) => {
    await createProfile(payload);

    openNotificationWithIcon('success', `"${payload?.label}" profile`);
  };

  const updateProfileSubmit = async (id, payload) => {
    await updateProfile(id, payload);

    openNotificationWithIcon('success', `"${payload?.label}" profile has been updated.`, true);
  };

  const handleSubmit = async (values) => {
    const composeValuesToProfileDTO = {
      ...values,
      label: values?.label?.trim(),
      inputValue: values?.inputValue?.trim(),
    };
    try {
      setLoading(true);

      if (selectedProfile) {
        isEqual(pick(selectedProfile, ['label', 'inputValue', 'selectType.value']), {
          ...composeValuesToProfileDTO,
          selectType: { value: composeValuesToProfileDTO.selectType },
        })
          ? openNotificationWithIcon('error', "Fields weren't changed")
          : await updateProfileSubmit(selectedProfile?.id, composeValuesToProfileDTO).then(closeForm);
      }
      if (!selectedProfile) {
        await createProfileSubmit(composeValuesToProfileDTO).then(closeForm);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      openNotificationWithIcon('error', `Profile with such "${values.inputValue}" Device ID already exists.`);
    }
  };

  const closeForm = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmitFailed = ({ errorFields }) => {
    const errors = errorFields.reduce((acc, item) => {
      let errorFieldLabel = '';
      if (item.name.join() === 'label') {
        errorFieldLabel = 'Profile Name';
      } else if (item.name.join() === 'inputValue') {
        errorFieldLabel = 'Input Value';
      } else if (item.name.join() === 'selectType') {
        errorFieldLabel = 'Select Type';
      }
      return [...acc, errorFieldLabel];
    }, []);
    const errorMessage = errors.length === 1 ? 'is required' : 'are required';
    openNotificationWithIcon('error', [`${errors} ${errorMessage}!`]);
  };

  useEffect(() => {
    if (selectedProfile) {
      form.setFieldsValue({ ...selectedProfile, selectType: selectedProfile?.selectType?.value });
    }
  }, [form, selectedProfile]);

  return {
    form,
    closeForm,

    selectType,
    handleSubmit,
    handleSubmitFailed,
  };
};
