import React from 'react';
import { Tabs } from 'antd';
import { colors } from '@hulu/design-tokens';
import styled from 'styled-components';

import { useTabCollapse } from './useTabCollapse';
import { BID_STATUS, BIDDER } from '../../../../constants';
import { TabBarExtraContent } from './TabBarExtraContent';
import { BidState } from './BidState';
import { BidderName } from './BidderName';
import { StyledCard } from '../style';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding: 8px 12px 8px 16px;
    margin: 0px;
  }
  .ant-tabs-nav:before {
    border-bottom: none;
  }
  .ant-tabs-content-holder {
    padding: 12px;
  }
  .ant-tabs-nav-list {
    color: black;
    padding: 4px;
    border-radius: 8px;
    background-color: ${colors.colorGray05};
    height: 40px;
  }
  .ant-tabs-tab {
    display: flex;
    justify-content: center;
    width: 135px;
    border-radius: 4px;
    &:hover {
      opacity: 0.65;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 8px;
  }
  .ant-tabs-tab-active {
    background-color: ${colors.colorWhite};
    box-shadow: 0px 3px 8px 0px ${colors.colorGray30};
  }
  .ant-tabs-tab-btn {
    font-weight: 500;
  }
  .ant-tabs-tab-btn:focus {
    color: black;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
  }
  .ant-tabs-tab:hover {
    color: black;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
`;

const StyledAdSourceSummaryCard = styled(StyledCard)`
  .ant-card-body {
    padding: ${({ loading }) => (loading ? '24' : '0')}px;
  }
`;

const { TabPane } = Tabs;
export const AdSourceSummary = ({
  isLoading,
  bidderNames,
  combineBidsByBidState,
  bidsByBidderNames,
  openBidDetails,
  handleSearch,
  isUnifiedSelection,
}) => {
  const {
    activeTab,
    activeBidStatus,
    activeBidderName,

    handleActiveTab,
    handleBidStatusActiveCollapse,
    handleBidderNameActiveCollapse,

    handleExpandAll,
    handleCollapseAll,
  } = useTabCollapse(bidderNames);

  return (
    <StyledAdSourceSummaryCard title="Ad Source Summary" loading={isLoading}>
      <StyledTabs
        defaultActiveKey={BID_STATUS}
        tabBarExtraContent={
          <TabBarExtraContent
            handleExpandAll={handleExpandAll}
            handleCollapseAll={handleCollapseAll}
            handleSearch={handleSearch}
            activeTab={activeTab}
          />
        }
        activeKey={activeTab}
        onChange={(key) => handleActiveTab(key)}
      >
        <TabPane tab="Bid Status" key={BID_STATUS}>
          <BidState
            activeBidStatus={activeBidStatus}
            handleBidStatusActiveCollapse={handleBidStatusActiveCollapse}
            bidStateData={combineBidsByBidState}
            openBidDetails={openBidDetails}
            isUnifiedSelection={isUnifiedSelection}
          />
        </TabPane>
        <TabPane tab="Bidder" key={BIDDER}>
          <BidderName
            activeBidderName={activeBidderName}
            handleBidderNameActiveCollapse={handleBidderNameActiveCollapse}
            bidderNameData={bidsByBidderNames}
            openBidDetails={openBidDetails}
            isUnifiedSelection={isUnifiedSelection}
          />
        </TabPane>
      </StyledTabs>
    </StyledAdSourceSummaryCard>
  );
};
