import styled from 'styled-components';

export const NewTestCaseWrapper = styled.div`
  margin-top: 3em;
  padding: 0 23%;
`;

export const StepsContent = styled.div`
  margin-top: 20px;
  border-radius: 6px;
  text-align: center;
  padding-top: 40px;
`;

export const StepsAction = styled.div`
  margin-top: 20px;
  float: right;
`;
