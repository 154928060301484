import React from 'react';
import { Row } from 'antd';

import { StyledRowHead, StyledRowInfo } from '../style';

export const RowArrayInfo = ({ title, data }) => {
  if (data === null || data === undefined || data.length === 0) {
    return (
      <Row wrap={false}>
        <StyledRowHead>{title}:</StyledRowHead>
        <StyledRowInfo>{'-'}</StyledRowInfo>
      </Row>
    );
  }

  return (
    <Row wrap={false}>
      <StyledRowHead>{title}:</StyledRowHead>
      <StyledRowInfo>
        {data?.map((item) => {
          return <Row key={item}>{String(item) ? String(item) : '-'}</Row>;
        })}
      </StyledRowInfo>
    </Row>
  );
};
