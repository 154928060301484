import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { Typography } from '@hulu/react-style-components';
import { connect } from 'dva';
import { SearchOutlined } from '@ant-design/icons';
import { kebabCaseToSentenceCase, MomentDatePicker } from '../../../utils';
import {
  VASTTRACKER_SEARCH_DATE,
  VASTTRACKER_SEARCH_ID,
  VASTTRACKER_SEARCH_ID_BUTTON,
  VASTTRACKER_SEARCH_TITLE,
} from '../../../testUtils';
import moment from 'moment';
import { EMPTY_VALUES, SELECT_MODE } from '../../../constants';

const FilterSearch = ({
  dispatch,
  countTypeSelected = 'N/A',
  selectMode = SELECT_MODE.TAGS,
  selectOptions = [],
  startDate,
  endDate,
}) => {
  const [form] = Form.useForm();
  const refreshSearchIds = (data) => {
    const ids = data[countTypeSelected] ?? [];
    dispatch({ type: 'vastTracker/updateIds', payload: ids });
    dispatch({ type: 'vastTracker/pageInit' });
  };
  const refreshDate = (startDate, endDate) => {
    dispatch({ type: 'vastTracker/updateDates', payload: { startDate, endDate } });
    dispatch({ type: 'vastTracker/pageInit' });
  };
  const { RangePicker } = MomentDatePicker;

  useEffect(() => {
    return () => {
      // reset when unmounting
      dispatch({ type: 'vastTracker/resetToInitialState' });
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography style={{ color: '#090699' }} variant="h5" data-testid={VASTTRACKER_SEARCH_TITLE}>
        Filter Counts
      </Typography>
      <br />
      <Typography variant="h8">Search By {kebabCaseToSentenceCase(countTypeSelected)}</Typography>
      <Form form={form} onFinish={refreshSearchIds}>
        <Row justify="space-between">
          <Col span={20}>
            <Form.Item name={countTypeSelected}>
              <Select
                allowClear
                mode={selectMode}
                notFoundContent={EMPTY_VALUES}
                style={{ width: '100%' }}
                placeholder={`Select ${kebabCaseToSentenceCase(countTypeSelected)}`}
                data-testid={VASTTRACKER_SEARCH_ID}
                options={selectOptions}
                optionFilterProp={'label'}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button htmlType="submit" icon={<SearchOutlined />} data-testid={VASTTRACKER_SEARCH_ID_BUTTON} />
          </Col>
        </Row>
      </Form>
      <Typography variant="h8">Show Data By Date (UTC)</Typography>
      <RangePicker
        allowEmpty={[false, false]}
        defaultValue={[startDate, endDate]}
        onChange={(dates) => dates && refreshDate(dates[0], dates[1])}
        ranges={{
          Today: [moment().startOf('day'), moment().endOf('day')],
          'Last 2 Days': [moment().startOf('day').subtract(1, 'day'), moment().endOf('day')],
          'Last 7 Days': [moment().startOf('day').subtract(6, 'day'), moment().endOf('day')],
          'Last 30 Days': [moment().startOf('day').subtract(29, 'day'), moment().endOf('day')],
        }}
        disabledDate={(date) => date < moment().subtract(1, 'month')}
        data-testid={VASTTRACKER_SEARCH_DATE}
      />
    </>
  );
};

function mapStateToProps({ vastTracker: { countTypeSelected, filters, startDate, endDate } }) {
  return {
    countTypeSelected: countTypeSelected,
    selectMode: filters[countTypeSelected].selectMode,
    selectOptions: filters[countTypeSelected].options,
    startDate,
    endDate,
  };
}

export default connect(mapStateToProps)(FilterSearch);
