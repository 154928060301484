import React from 'react';
import { connect } from 'dva';
import { Row, Col, Layout, Space, Modal } from 'antd';
import styled from 'styled-components';

import { colors } from '@hulu/design-tokens';
import { Button } from '@hulu/react-style-components';

import { AuctionErrorBoundary } from './AuctionErrorBoundary';
import { AuctionSnapshotCreateForm } from './AuctionSnapshotCreateForm';
import { useAuctionSnapshot } from './useAuctionSnapshot';
import { Profiles } from './Profiles';
import AuctionSnapshotTable from './AuctionSnapshotTable/AuctionSnapshotTable';
import { SnapshotParametersInfo } from './SnapshotParametersInfo';
import { AdTagsParameterSummary } from './AdTagsParameterSummary';
import { useSnapshotParametersInfo } from './SnapshotParametersInfo/useSnapshotParametersInfo';
import { useAdTagsParameterSummary } from './AdTagsParameterSummary/useAdTagsParameterSummary';

const AuctionSnapshotLayout = styled(Layout)`
  background-color: ${colors.colorGray03};
  padding: 1rem;
  width: 100%;
  height: 100%;
`;

const StyledModalContent = styled.p`
  margin: 0;
  white-space: pre-wrap;
  & > span {
    font-weight: 600;
    margin-left: 4px;
  }
`;

export const HuluNormalButtonCapitalize = styled(Button)`
  &:hover {
    color: ${colors.colorWhite};
  }
  span {
    text-transform: capitalize;
  }
`;

export const HuluButtonCapitalize = styled(Button)`
  span {
    text-transform: capitalize;
  }
`;

const AuctionSnapshot = (props) => {
  const { currentUser } = props;

  const {
    isAdRequestOpen,
    isProfilesOpen,
    isDeleteModalOpen,
    isSnapshotsLoading,
    snapshotsLoadingError,
    snapshots,
    selectedSnapshot,
    snapshotToDelete,
    pagination,
    activeFilters,
    search,
    dateValue,
    sort,
    selectedUserOptions,
    userOptions,
    snapshotParameters,
    snapshotExpandedKeys,
    profileTypes,

    onSnapshotExpand,
    onAllFiltersClear,
    onFiltersClear,
    onDeleteFilter,
    onClearAppNameFilter,
    handleFilters,
    handleUserSnapshotValue,
    openAdRequestDrawer,
    closeAdRequestDrawer,
    onRerunSnapshot,
    openProfiles,
    closeProfiles,
    openDeleteSnapshotModal,
    closeDeleteSnapshotModal,
    onDeleteSnapshot,
    onSnapshotsRefresh,
    onTableChange,
    setSelectedSnapshot,
    onSearchAuctionSnapshots,
    handleDateRange,
    onUsersSearch,
    getFiltersDropdownData,
    handleInlineEditName,
  } = useAuctionSnapshot(currentUser.email);

  const {
    snapshotId,
    snapshotParametersLoading,
    snapshotParametersInfo,
    snapshotParametersError,
    isSnapshotParametersInfoOpen,

    openSnapshotParametersInfoDrawer,
    closeSnapshotParametersInfoDrawer,
  } = useSnapshotParametersInfo();

  const {
    snapshotSummaryLoading,
    snapshotSummaryError,
    isSnapshotSummaryInfoOpen,
    snapshotSummaryInfo,
    activePanel,

    openSnapshotSummaryInfoDrawer,
    closeSnapshotSummaryInfoDrawer,
    onCollapsePanelChange,
    onExpandClick,
    onCollapseClick,
  } = useAdTagsParameterSummary();

  return (
    <AuctionErrorBoundary>
      <AuctionSnapshotLayout>
        <Space direction="vertical">
          <Row justify="end">
            <Col>
              <HuluButtonCapitalize size="medium" variant="outlined" onClick={openProfiles}>
                Auction Snapshots Profiles
              </HuluButtonCapitalize>
            </Col>
            <Col>
              <HuluNormalButtonCapitalize icon="IconAdd" size="medium" variant="normal" onClick={openAdRequestDrawer}>
                Create Auction Snapshot
              </HuluNormalButtonCapitalize>
            </Col>
          </Row>
          <Row>
            <AuctionSnapshotTable
              isLoading={isSnapshotsLoading}
              snapshots={snapshots}
              error={snapshotsLoadingError}
              pagination={pagination}
              filtersDropdownData={getFiltersDropdownData()}
              activeFilters={activeFilters}
              searchValue={search}
              dateValue={dateValue}
              sort={sort}
              snapshotExpandedKeys={snapshotExpandedKeys}
              onSnapshotExpand={onSnapshotExpand}
              onSearchAuctionSnapshots={onSearchAuctionSnapshots}
              onRefresh={onSnapshotsRefresh}
              onSnapshotParametersInfoClick={openSnapshotParametersInfoDrawer}
              onRerunSnapshotClick={onRerunSnapshot}
              onAdTagsParameterSummaryClick={openSnapshotSummaryInfoDrawer}
              onDeleteSnapshotClick={openDeleteSnapshotModal}
              onCloseDeleteSnapshotModal={closeDeleteSnapshotModal}
              onTableChange={onTableChange}
              onAllFiltersClear={onAllFiltersClear}
              onFiltersClear={onFiltersClear}
              onDeleteFilter={onDeleteFilter}
              onClearAppNameFilter={onClearAppNameFilter}
              handleFilters={handleFilters}
              handleUserSnapshotValue={handleUserSnapshotValue}
              handleDateRange={handleDateRange}
              userOptions={userOptions}
              selectedUserOptions={selectedUserOptions}
              onUsersSearch={onUsersSearch}
              handleInlineEditName={handleInlineEditName}
            />
          </Row>
        </Space>
        <AuctionSnapshotCreateForm
          isVisible={isAdRequestOpen}
          selectedSnapshot={selectedSnapshot}
          setSelectedSnapshot={setSelectedSnapshot}
          closeAdRequestDrawer={closeAdRequestDrawer}
          onSnapshotsRefresh={onSnapshotsRefresh}
          snapshotParameters={snapshotParameters}
          profileTypes={profileTypes}
        />
        <Profiles isOpen={isProfilesOpen} onClose={closeProfiles} profileTypes={profileTypes} />
        <SnapshotParametersInfo
          isOpen={isSnapshotParametersInfoOpen}
          onClose={closeSnapshotParametersInfoDrawer}
          data={snapshotParametersInfo}
          isLoading={snapshotParametersLoading}
          error={snapshotParametersError}
          snapshotId={snapshotId}
          onRerunSnapshot={onRerunSnapshot}
          profileTypes={profileTypes}
        />
        <AdTagsParameterSummary
          isOpen={isSnapshotSummaryInfoOpen}
          activePanel={activePanel}
          onClose={closeSnapshotSummaryInfoDrawer}
          data={snapshotSummaryInfo}
          isLoading={snapshotSummaryLoading}
          error={snapshotSummaryError}
          onCollapsePanelChange={onCollapsePanelChange}
          onExpandClick={onExpandClick}
          onCollapseClick={onCollapseClick}
        />
        {isDeleteModalOpen && snapshotToDelete && (
          <Modal
            open
            title="Delete Snapshot"
            onOk={onDeleteSnapshot}
            onCancel={closeDeleteSnapshotModal}
            footer={[
              <Button key="cancel-button" onClick={closeDeleteSnapshotModal} size="medium" variant="outlined">
                Cancel
              </Button>,
              <Button key="confirm-button" size="medium" variant="normal" onClick={onDeleteSnapshot}>
                Delete
              </Button>,
            ]}
          >
            <StyledModalContent>
              Are you sure you want to delete
              <span>{snapshotToDelete.name}</span> snapshot?
            </StyledModalContent>
          </Modal>
        )}
      </AuctionSnapshotLayout>
    </AuctionErrorBoundary>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.app.currentUser,
  };
}

export default connect(mapStateToProps)(AuctionSnapshot);
