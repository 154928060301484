import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';
import { useSupplySourceContentDetailsResolver } from './useSupplySourceContentDetailsResolver';

export const ContentDetailsCard = ({ isLoading, data, type }) => {
  const values = useSupplySourceContentDetailsResolver(type, data);
  return (
    <StyledCard title="Content Details" loading={isLoading}>
      <RowInfo title="Content ID" data={values.contentId} />
      <RowInfo title="Content Asset ID" data={values.contentAssetId} />
      <RowInfo title="Content Partner" data={values.contentPartnerIdentifier} />
      <RowInfo title="Content Genre" data={values.contentGenre} />
      <RowInfo title="Content Len" data={values.contentLen} />
      <RowInfo title="Content LiveStream" data={values.contentLiveStream} />
      <RowInfo title="Content EXT Channel" data={values.contentEXTChannel} />
      <RowInfo title="Content Series" data={values.contentSeries} />
      <RowInfo title="Distributor" data={values.distributor} />
    </StyledCard>
  );
};
