import React, { useState } from 'react';
import Header from './Header';
import TestCases from './TestCases';
import TraceSummaries from './TraceSummaries';
import TraceSpansModal from './TraceSpansModal';
import ShareTestCaseModal from './ShareTestCaseModal';
import LoadingSharedTestModal from './LoadingSharedTestModal';
import GridLayout from '../Templates/GridLayout';
import { connect } from 'dva';
import {
  ADSTRACER_HEADER,
  ADSTRACER_MODAL_LOADTEST,
  ADSTRACER_MODAL_SHARETEST,
  ADSTRACER_MODAL_TRACESPAN,
  ADSTRACER_TESTCASES,
  ADSTRACER_TRACESUMMARIES,
} from '../../testUtils';

const LEFT_HAND_SPAN = 7;
const RIGHT_HAND_SPAN = 16;

export const AdsTracer = ({ pathParams = {}, searchParams = {} }) => {
  // Process Query & Path Params for Preset states
  const [loadingModalOpen, setLoadingModalOpen] = useState(searchParams?.showLoadTestCase ?? false);
  const [shareModalTestCaseId, setShareModalTestCaseId] = useState(searchParams?.shareTestCase ?? null);
  const [selectedTestCase, setSelectedTestCase] = useState(pathParams?.testCaseId ?? null);
  const [selectedTrace, setSelectedTrace] = useState(pathParams?.traceId ?? null);
  const [isAutoRefresh, setAutoRefresh] = useState(searchParams?.alwaysRefresh ?? false);

  return (
    <>
      {/* Main View */}
      <GridLayout
        leftHandSpan={LEFT_HAND_SPAN}
        rightHandSpan={RIGHT_HAND_SPAN}
        headerChildren={
          <div data-testid={ADSTRACER_HEADER}>
            <Header
              setLoadingModalOpen={setLoadingModalOpen}
              autoRefresh={isAutoRefresh}
              setAutoRefresh={setAutoRefresh}
            />
          </div>
        }
        leftHandChildren={
          <div data-testid={ADSTRACER_TESTCASES}>
            <TestCases
              selected={selectedTestCase}
              setSelectedTestCase={setSelectedTestCase}
              setShareModalTestCaseId={setShareModalTestCaseId}
            />
          </div>
        }
        rightHandChildren={
          <div data-testid={ADSTRACER_TRACESUMMARIES}>
            <TraceSummaries selectedTestCase={selectedTestCase} setSelectedTrace={setSelectedTrace} />
          </div>
        }
      />
      {/* Modals */}
      <div data-testid={ADSTRACER_MODAL_TRACESPAN}>
        <TraceSpansModal
          selectedTrace={selectedTrace}
          selectedTestCase={selectedTestCase}
          setSelectedTrace={setSelectedTrace}
        />
      </div>
      <div data-testid={ADSTRACER_MODAL_SHARETEST}>
        <ShareTestCaseModal
          shareModalTestCaseId={shareModalTestCaseId}
          setSharedModalTestCaseId={setShareModalTestCaseId}
        />
      </div>
      <div data-testid={ADSTRACER_MODAL_LOADTEST}>
        <LoadingSharedTestModal
          open={loadingModalOpen}
          setLoadingModalOpen={setLoadingModalOpen}
          selectedTestCase={selectedTestCase}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { pathParams, searchParams } = state.app;
  return {
    pathParams,
    searchParams,
  };
}

export default connect(mapStateToProps)(AdsTracer);
