import React from 'react';
import { IconChevronDown, IconChevronRight } from '@hulu-react-style-components/icons';
import styled from 'styled-components';

const StyledSvgIcon = styled.div`
  filter: invert(47%) sepia(25%) saturate(376%) hue-rotate(182deg) brightness(85%) contrast(83%);
`;
const iconsSize = { height: '18px', width: '18px' };

export const CollapseIcons = ({ isActive }) => {
  return (
    <StyledSvgIcon>
      {isActive ? <IconChevronDown style={iconsSize} /> : <IconChevronRight style={iconsSize} />}
    </StyledSvgIcon>
  );
};
