import React, { useState } from 'react';
import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { routerRedux } from 'dva/router';
import { NEW_TEST_CASE } from '../../../../constants';
import { NEWTESTCASE_DISNEY, NEWTESTCASE_HULU, NEWTESTCASE_MODAL } from '../../../../testUtils';
import { CenteredDiv, LogoBtn, PaddedButton } from './styles';
import huluLogo from '../../../../../src/assets/Hulu.svg';
import dPlusLogo from '../../../../assets/DisneyPlus.svg';
import { DISNEY_PLUS_PUBLISHER, HULU_PUBLISHER } from '../../constants';
import { FORM_HULU_ID_RULE, FORM_NOT_EMPTY_RULE, FORM_UUID_RULE } from '../../../../utils';
import { InfoCircleOutlined } from '@ant-design/icons';

const MODAL_TITLE = 'Link New Account';

const LAYOUT_STYLES = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const TAIL_STYLES = {
  wrapperCol: { offset: 8, span: 16 },
};

const AddAccount = ({ dispatch, open, setModalOpen }) => {
  const [form] = Form.useForm();
  const [selectedPublisher, setSelectedPublisher] = useState('');

  const onCancel = () => {
    dispatch(routerRedux.push('/' + NEW_TEST_CASE));
    setModalOpen(false);
    setSelectedPublisher('');
    form.resetFields();
  };

  const onSubmitHulu = ({ userid }) => {
    if (dispatch({ type: 'newTestCase/addHuluAccount', payload: userid })) {
      onCancel();
    }
  };

  const onSubmitDisney = ({ accountId, profileId, nickname }) => {
    if (dispatch({ type: 'newTestCase/addDisneyAccount', payload: accountId, profileId, nickname })) {
      onCancel();
    }
  };

  const backBtnClick = () => {
    setSelectedPublisher('');
  };

  const renderModalContent = (publisher) => {
    switch (publisher) {
      case DISNEY_PLUS_PUBLISHER:
        return (
          <Form {...LAYOUT_STYLES} form={form} onFinish={onSubmitDisney}>
            <Form.Item
              label="Account Id"
              name="accountId"
              rules={[{ required: true, message: 'Please input Account Id' }, FORM_UUID_RULE]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Profile Id"
              name="profileId"
              rules={[{ required: true, message: 'Please input Profile Id' }, FORM_UUID_RULE]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Nickname&nbsp;
                  <Tooltip title="Customize Profile Nickname: Links to your Profile, not the Account">
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="nickname"
              rules={[{ required: true, message: 'Please input Nickname' }, FORM_NOT_EMPTY_RULE]}
            >
              <Input />
            </Form.Item>{' '}
            <Form.Item {...TAIL_STYLES}>
              <Button onClick={backBtnClick}>Back</Button>
              <PaddedButton type="primary" htmlType="submit">
                Submit
              </PaddedButton>
            </Form.Item>
          </Form>
        );
      case HULU_PUBLISHER:
        return (
          <Form {...LAYOUT_STYLES} form={form} onFinish={onSubmitHulu}>
            <Form.Item
              label="User Id"
              name="userid"
              rules={[{ required: true, message: 'Please input User Id' }, FORM_HULU_ID_RULE]}
            >
              <Input />
            </Form.Item>
            <Form.Item {...TAIL_STYLES}>
              <PaddedButton onClick={backBtnClick}>Back</PaddedButton>
              <PaddedButton type="primary" htmlType="submit">
                Submit
              </PaddedButton>
            </Form.Item>
          </Form>
        );
      default:
        return (
          <CenteredDiv>
            <LogoBtn
              onClick={showDPlusForm}
              src={dPlusLogo}
              alt={'Add Disney Plus Account'}
              data-testid={NEWTESTCASE_DISNEY}
            />
            <LogoBtn onClick={showHuluForm} src={huluLogo} alt={'Add Hulu Account'} data-testid={NEWTESTCASE_HULU} />
          </CenteredDiv>
        );
    }
  };

  const showDPlusForm = () => {
    setSelectedPublisher(DISNEY_PLUS_PUBLISHER);
  };

  const showHuluForm = () => {
    setSelectedPublisher(HULU_PUBLISHER);
  };

  return (
    <Modal title={MODAL_TITLE} open={open} onCancel={onCancel} footer={null}>
      <div data-testid={NEWTESTCASE_MODAL}>{renderModalContent(selectedPublisher)}</div>
    </Modal>
  );
};

export default AddAccount;
