import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';

import { AuctionErrorBoundary } from '../AuctionErrorBoundary';
import { ProfilesList } from './ProfilesList';
import { ProfileForm } from './ProfileForm';
import { useProfiles } from './useProfiles';

import { StyledCloseButton, StyledDrawer } from './style';

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  form {
    height: 100%;
  }
  .drawer-header {
    padding: 0 0 12px;
  }
  .ad-request-input {
    margin-top: 20px;
  }
`;

const StyledModalContent = styled.p`
  margin: 0;
  white-space: pre-wrap;
  & > span {
    font-weight: 600;
    margin-left: 4px;
  }
`;

export const Profiles = (props) => {
  const { isOpen, onClose, profileTypes } = props;
  const {
    drawerTitle,
    selectedProfile,
    isProfileFormOpen,
    isDeleteProfileOpen,
    isShowAllProfiles,
    search,
    profilesControl,
    otherProfilesControl,
    onToggleMyProfiles,
    onCloseProfileDeleteModal,
    openNewProfile,
    closeProfileForm,
    onDeleteProfile,
    onSearch,
    closeProfiles,
    handleVisibleChange,
  } = useProfiles();

  const onProfilesClose = () => {
    onClose();
    closeProfiles();
  };

  const { pagination, handleLoading, handleProfilesState } = profilesControl;

  return (
    <StyledDrawer
      title={drawerTitle}
      open={isOpen}
      onClose={onProfilesClose}
      width={600}
      closeIcon={<StyledCloseButton variant="outlined" icon="IconClose" />}
      destroyOnClose
      afterOpenChange={handleVisibleChange}
    >
      <AuctionErrorBoundary>
        <StyledContent>
          {isProfileFormOpen && (
            <ProfileForm
              pagination={pagination}
              onClose={closeProfileForm}
              setLoading={handleLoading}
              setProfilesState={handleProfilesState}
              selectedProfile={selectedProfile}
              profileTypes={profileTypes}
            />
          )}
          {!isProfileFormOpen && (
            <ProfilesList
              profilesControl={profilesControl}
              otherProfilesControl={otherProfilesControl}
              isShowAllProfiles={isShowAllProfiles}
              search={search}
              onToggleMyProfiles={onToggleMyProfiles}
              onOpenNewProfile={openNewProfile}
              onSearch={onSearch}
            />
          )}
          {isDeleteProfileOpen && selectedProfile && (
            <Modal
              open
              title="Delete Ad Request Profile"
              onCancel={onCloseProfileDeleteModal}
              destroyOnClose
              footer={[
                <Button
                  onClick={onCloseProfileDeleteModal}
                  key="remove-profile-cancel"
                  size="medium"
                  variant="outlined"
                >
                  Cancel
                </Button>,
                <Button
                  size="medium"
                  type="primary"
                  variant="normal"
                  key="remove-profile-ok"
                  onClick={() => onDeleteProfile()}
                >
                  Delete
                </Button>,
              ]}
            >
              <StyledModalContent>
                Are you sure you want to delete <span>{selectedProfile.label}</span>&nbsp; profile with&nbsp;
                <span>{selectedProfile.inputValue}</span> Device ID?
              </StyledModalContent>
            </Modal>
          )}
        </StyledContent>
      </AuctionErrorBoundary>
    </StyledDrawer>
  );
};
