import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';

import { useDebouncedCallback } from '../useDebouncedCallback';

const DEFAULT_STYLES = {
  HEIGHT: '32px',
  WIDTH: '256px',
  MARGIN: '0px',
};
const DEFAULT_PLACEHOLDER = 'Search';

export const StyledSearchInput = styled(Input)`
  width: ${(props) => props.width || DEFAULT_STYLES.WIDTH};
  height: ${(props) => props.height || DEFAULT_STYLES.HEIGHT};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${colors.colorGray30};
  margin: ${(props) => props.margin || DEFAULT_STYLES.MARGIN};
  font-size: 12px;
  color: ${colors.colorGray82};
  &:hover,
  &:focus {
    border: 1px solid ${colors.colorBlack95} !important;
    outline: none;
    box-shadow: none;
  }
`;

export const SearchInput = (props) => {
  const { allowClear = true, placeholder, searchValue, onChange, ...rest } = props;
  const { searchQuery, handleSearchQuery } = useDebouncedCallback(onChange);
  const firstMount = useRef(true);
  const cb = useRef(handleSearchQuery);

  useEffect(() => {
    if (!searchValue && !firstMount.current) {
      cb.current('');
    }
  }, [searchValue]);

  useEffect(() => {
    firstMount.current = false;
  }, []);

  return (
    <StyledSearchInput
      allowClear={allowClear}
      placeholder={placeholder}
      value={searchQuery}
      onChange={(e) => handleSearchQuery(e.target.value)}
      {...rest}
    />
  );
};

SearchInput.defaultProps = {
  allowClear: true,
  placeholder: DEFAULT_PLACEHOLDER,
};
SearchInput.propTypes = {
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.node,
};
