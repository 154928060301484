import React from 'react';
import { Alert } from 'antd';

import { SummaryCard } from './SummaryCard';
import { RequestResponseCard } from '../Common/RequestResponseCard';
import { useFinalResponseSummaryResolver } from './useFinalResponseSummaryResolver';

export const FinalResponse = ({ isLoading, data, error, type, bids }) => {
  const summaryData = useFinalResponseSummaryResolver(type, data, bids);

  if (error) {
    return <Alert type="error" message={error} />;
  }

  return (
    <>
      {summaryData?.map((summary) => (
        <SummaryCard isLoading={isLoading} key={summary.key} data={summary} />
      ))}
      <RequestResponseCard isLoading={isLoading} data={data} header="Response" />
    </>
  );
};
