import React, { useState } from 'react';
import { Checkbox, Collapse, Spin } from 'antd';
import { connect } from 'dva';
import { Typography } from '@hulu/react-style-components';
import { traceColumns } from '../../Templates/columns';
import { ADSTRACER_TRACESUMMARIES_TABLE } from '../../../testUtils';
import {
  AD_SERVER_TITLE,
  CONTENT_INFO_TITLE,
  CONTENT_DISTRIBUTOR_TITLE,
  COUNTRY_TITLE,
  DEVICE_TITLE,
  DEVICE_DISTRIBUTOR_TITLE,
  INTEGRATION_TITLE,
  POD_TITLE,
  PUBLISHER_TITLE,
  STATUS_TITLE,
  TIME_TITLE,
} from '../constants';
import { StyledCollapse } from './styles';
import { ResizableTable } from '../../Templates/ResizableTable';

const { Panel } = Collapse;
const TABLE_SCROLL_Y = { y: '60vh' };

const TraceSummaries = ({
  dispatch,
  traces = [],
  setSelectedTrace,
  summariesFilters = {},
  summariesWidths = [],
  loading,
}) => {
  const [visibleCols, setVisibleCols] = useState([
    DEVICE_TITLE,
    INTEGRATION_TITLE,
    DEVICE_DISTRIBUTOR_TITLE,
    CONTENT_INFO_TITLE,
    POD_TITLE,
    TIME_TITLE,
    STATUS_TITLE,
  ]);

  const showTraceSpansModal = (traceId) => {
    setSelectedTrace(traceId);
  };

  const handleTableChange = (_, filters) =>
    dispatch({
      type: 'adsTracer/updateSummariesFilters',
      payload: filters,
    });

  const handleWidthChange = (widths) =>
    dispatch({
      type: 'adsTracer/updateSummariesWidths',
      payload: widths,
    });

  const onRow = (selectedRow) => ({
    onClick: () => {
      showTraceSpansModal(selectedRow.trace_id);
    },
    style: {
      cursor: 'pointer',
    },
  });

  const colCheckboxOptions = [
    DEVICE_TITLE,
    INTEGRATION_TITLE,
    DEVICE_DISTRIBUTOR_TITLE,
    CONTENT_INFO_TITLE,
    CONTENT_DISTRIBUTOR_TITLE,
    POD_TITLE,
    TIME_TITLE,
    STATUS_TITLE,
    COUNTRY_TITLE,
    AD_SERVER_TITLE,
    PUBLISHER_TITLE,
  ];
  const onColCheck = (colsChecked) => {
    setVisibleCols(colsChecked);
  };

  return (
    <Spin spinning={loading}>
      <Typography variant="h2">Video Ad Traces</Typography>
      <StyledCollapse defaultActiveKey={[]}>
        <Panel header="Toggle column visibility" key="1">
          <Checkbox.Group options={colCheckboxOptions} defaultValue={visibleCols} onChange={onColCheck} />
        </Panel>
      </StyledCollapse>
      <ResizableTable
        className="rightPane"
        inputColumns={traceColumns(traces, summariesFilters, summariesWidths, visibleCols)}
        onRow={onRow}
        dataSource={traces
          // Uncomment when VIOLET-2386 done
          // .filter(({ contentPartnerIdentifier }) => abilities.can(ACCESS, contentPartnerIdentifier))
          .map((t, key) => ({ ...t, key }))}
        changeHandler={handleTableChange}
        scroll={TABLE_SCROLL_Y}
        pagination={false}
        testId={ADSTRACER_TRACESUMMARIES_TABLE}
        widthHandler={handleWidthChange}
      />
    </Spin>
  );
};

function mapStateToProps({ adsTracer, loading }) {
  return {
    traces: adsTracer.traces,
    summariesFilters: adsTracer.summariesFilters,
    summariesWidths: adsTracer.summariesWidths,
    loading: 'adsTracer/fetchTraces' in loading.effects && loading.effects['adsTracer/fetchTraces'],
  };
}

export default connect(mapStateToProps)(TraceSummaries);
