import { useState } from 'react';
import {
  ROKU,
  REQUEST,
  RESPONSE,
  WINNER_CATEGORY,
  BID_BUT_LOST_CATEGORY,
  FILTERED_BIDS_CATEGORY,
  WINNER_BID_STATE,
  BID_BUT_LOST_STATE,
} from '../constants';

const combineBidsByDsp = (bids) => {
  return bids?.reduce((acc, item) => {
    (acc[item?.dsp] = acc[item?.dsp] || []).push(item);
    return acc;
  }, {});
};

const combineBidsByState = (bids) => {
  return bids?.reduce(
    (bidsByStatus, bid) => {
      WINNER_BID_STATE.includes(bid?.bidState)
        ? bidsByStatus[WINNER_CATEGORY].push(bid)
        : BID_BUT_LOST_STATE.includes(bid?.bidState)
        ? bidsByStatus[BID_BUT_LOST_CATEGORY].push(bid)
        : bidsByStatus[FILTERED_BIDS_CATEGORY].push(bid);

      return bidsByStatus;
    },
    { [WINNER_CATEGORY]: [], [BID_BUT_LOST_CATEGORY]: [], [FILTERED_BIDS_CATEGORY]: [] }
  );
};

export const useAddSourceSummary = (bids, integratorsData, podId) => {
  const [searchQuery, setSearchQuery] = useState('');

  const bidsFilteredByCurrentPodId = bids.filter((bid) => bid?.adIndex === podId);

  const bidsWithMetaAndFloorPrice = bidsFilteredByCurrentPodId.map((bid) => {
    const bidIntegrator = bid.demandPartner.toUpperCase();
    const integrator = integratorsData[bidIntegrator];
    const integratorResponseTime = integrator?.[RESPONSE]?.record?.responseTime;
    if (bidIntegrator === ROKU) {
      return {
        ...bid,
        responseTime: integratorResponseTime,
        floorPrice: integratorsData?.[ROKU]?.[REQUEST]?.record?.rawData?.imp[0]?.pmp?.deals[0]?.bidfloor,
      };
    }
    return { ...bid, responseTime: integratorResponseTime };
  });

  const filteredBids = !searchQuery
    ? bidsWithMetaAndFloorPrice
    : bidsWithMetaAndFloorPrice.filter(
        (bid) =>
          bid?.adomain?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          bid?.demandPartner?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          bid?.dsp?.toLowerCase().includes(searchQuery?.toLowerCase())
      );

  const bidderNames = [...new Set(bids.map((bid) => bid.dsp))];

  const combineBidsByBidState = combineBidsByState(filteredBids);
  const combineBidsByBidderName = combineBidsByDsp(filteredBids);

  const onSearch = (query) => setSearchQuery(query);

  return {
    bidsFilteredByCurrentPodId,
    bidderNames,
    combineBidsByBidState,
    combineBidsByBidderName,
    onSearch,
  };
};
