import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

export const CreateAdOverrideRuleWrapper = styled.div`
  height: 80vh;
  margin: 20px 0;
  padding: 20px 20px;
  border: 1px solid #706f6f;
  background-color: white;
  border-radius: 10px;
  overflow-y: scroll;
`;

export const PodSlotAdIdSelectorWrapper = styled.div`
  padding: 20px 20px 0;
  border: 1px dotted #79aaf7;
  border-radius: 10px;
  margin: 20px 0;
`;

export const InfoCircleOutlinedWrapper = styled(InfoCircleOutlined)`
  margin-left: 5px;
  font-size: 16px;
  color: #1890ff;
`;
