import React from 'react';

import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { IconChevronDown, IconChevronRight } from '@hulu-react-style-components/icons';

import { AUCTION_SNAPSHOT_TABLE_ROW_SELECT } from '../../../testUtils';

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 40px;
`;

export const TableExpandIcon = ({ expanded, onExpand, record }) => {
  return !record.capturing ? (
    expanded ? (
      <IconChevronDown onClick={(e) => onExpand(record, e)} />
    ) : (
      <IconChevronRight data-testid={AUCTION_SNAPSHOT_TABLE_ROW_SELECT} onClick={(e) => onExpand(record, e)} />
    )
  ) : (
    <StyledLoadingOutlined />
  );
};
