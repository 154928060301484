import {
  getConfigData,
  createSource,
  updateSource,
  updateSourceStatus,
  getHistory,
} from '../../services/configManager';
import { currentUserSelector } from '../app';
import { getContentPartnerIdentifiers } from '../../services/creativeTracker';
import { SUCCESS } from '../../constants';
import { formatAuditHistory, formatCpOptions } from './constants';
import { notification } from 'antd';

const initState = {
  configManagerState: {
    configData: [],
    cpIdentifiers: [],
    selectedSource: {},
    tableWidths: [],
  },
};

export const configManager = {
  namespace: 'configManager',
  state: initState,
  reducers: {
    updateConfigData(state, { payload: configData }) {
      const configManagerState = { ...state.configManagerState };
      configManagerState.configData = configData;
      return { ...state, configManagerState };
    },
    updateCpIdOptions(state, { payload: { cps, abilities } }) {
      const configManagerState = { ...state.configManagerState };
      configManagerState.cpIdentifiers = formatCpOptions(cps);
      return { ...state, configManagerState };
    },
    updateTableWidths(state, { payload: tableWidths }) {
      return { ...state, tableWidths };
    },
  },
  effects: {
    *pageInit({ payload: { pathParams } }, { call, put, select }) {
      const { permissions: abilities } = yield select(currentUserSelector);
      const cpResponse = yield call(getContentPartnerIdentifiers);
      if (cpResponse?.data) {
        yield put({ type: 'updateCpIdOptions', payload: { cps: cpResponse.data, abilities } });
      }
      yield put({ type: 'fetchConfigData' });
    },
    *fetchConfigData(emptyPayload, { call, put }) {
      const response = yield call(getConfigData);
      if (response.data) {
        yield put({ type: 'updateConfigData', payload: response.data });
      }
    },
    *fetchHistory({ payload: { id, page } }, { call, put }) {
      const response = yield call(getHistory, id, page);
      if (response.status === SUCCESS && response.data?.logs) {
        return {
          totalLogs: response.data['total-logs'],
          currentPage: response.data.page,
          history: formatAuditHistory(response.data.logs),
        };
      } else {
        return {};
      }
    },
    *createSource({ payload: { reqBody } }, { call, put, select }) {
      try {
        const response = yield call(createSource, reqBody);
        if (response.status === SUCCESS) {
          yield call(notification.success, {
            message: `Created source successfully`,
            duration: 3,
          });
          yield put({ type: 'fetchConfigData' });
        }
        return { statusCode: response.status };
      } catch (error) {
        return { statusCode: error.response.status };
      }
    },
    *updateSource({ payload: { reqBody } }, { call, put, select }) {
      try {
        const response = yield call(updateSource, reqBody);
        if (response.status === SUCCESS) {
          yield call(notification.success, {
            message: `Updated source successfully`,
            duration: 3,
          });
          yield put({ type: 'fetchConfigData' });
        }
      } catch (error) {
        yield call(notification.error, {
          message: `Failed to update source`,
          description: error.message,
          duration: 3,
        });
      }
    },
    *updateSourceStatus({ payload: { id, reqBody } }, { call, put, select }) {
      try {
        const response = yield call(updateSourceStatus, id, reqBody);
        if (response.status === SUCCESS) {
          yield call(notification.success, {
            message: `Updated status successfully`,
            duration: 3,
          });
          yield put({ type: 'fetchConfigData' });
        }
      } catch (error) {
        yield call(notification.error, {
          message: `Failed to update status`,
          description: error.message,
          duration: 3,
        });
      }
    },
    *sendError({ payload: { message, description } }, { call }) {
      yield call(notification.error, {
        message: message,
        description: description,
        duration: 3,
      });
    },
  },
};
