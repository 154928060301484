import GridLayout from '../Templates/GridLayout';
import CreativesTable from './CreativesTable';
import SearchFilter from './SearchFilter';

const LEFT_HAND_SPAN = 5;
const RIGHT_HAND_SPAN = 18;

const CreativeTracker = () => (
  <GridLayout
    leftHandSpan={LEFT_HAND_SPAN}
    rightHandSpan={RIGHT_HAND_SPAN}
    leftHandChildren={<SearchFilter />}
    rightHandChildren={<CreativesTable />}
  />
);

export default CreativeTracker;
