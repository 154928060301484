import { useMemo } from 'react';
import { VDMS, DSS, CAS } from './../AuctionSnapshotDetailedView/constants';

const DEFAULT_VDMS_AD_TAGS_PARAMETER_SUMMARY = {
  raw: [],
  hulurequestid: [],
  storedrequestid: [],
  podconfig: [],
  app: [],
  video: [],
  device: [],
  site: [],
  content: [],
  trackedbysupplyintegrator: [],
};

const DEFAULT_DSS_AD_TAGS_PARAMETER_SUMMARY = {
  raw: [],
  app: [],
  video: [],
  device: [],
  site: [],
  trackedbysupplyintegrator: [],
};

const DEFAULT_CAS_AD_TAGS_PARAMETER_SUMMARY = {
  raw: [],
  appinfo: [],
  contentinfo: [],
  deviceinfo: [],
  podinfo: [],
  ruleinfo: [],
  userinfo: [],
  trackedbysupplyintegrator: [],
};

const vdmsJsonToTagObject = (json) => {
  return {
    raw: json,
    hulurequestid: json?.hulurequestid,
    storedrequestid: json?.storedrequestid,
    podconfig: json?.podconfig,
    app: json?.app,
    video: json?.video,
    device: json?.device,
    site: json?.site,
    content: json?.content,
    trackedbysupplyintegrator: json?.trackedbysupplyintegrator,
  };
};

const dssJsonToTagObject = (json) => {
  return {
    raw: json,
    app: json?.app,
    video: json?.imp?.[0]?.video,
    device: json?.device,
    site: json?.site,
    trackedbysupplyintegrator: json?.ext?.trackedbysupplyintegrator,
  };
};

const casJsonToTagObject = (json) => {
  return {
    raw: json,
    appinfo: json?.['app-info'],
    contentinfo: json?.['content-info'],
    deviceinfo: json?.['device-info'],
    podinfo: json?.['pod-info'],
    ruleinfo: json?.['rule-info'],
    userinfo: json?.['user-info'],
    trackedbysupplyintegrator: json?.['tracked-by-supply-integrator'],
  };
};

const combineTagObjects = (tagObjects) => {
  return tagObjects.reduce((acc, item) => {
    const obj = {};
    for (let key in item) {
      Object.assign(obj, { [key]: [...(acc[key] || []), item[key]] });
    }
    return { ...acc, ...obj };
  }, {});
};

const vdmsAdTagsParameterSummary = (data) => {
  return combineTagObjects(data?.map(({ record }) => vdmsJsonToTagObject(record?.rawData)));
};

const dssAdTagsParameterSummary = (data) => {
  return combineTagObjects(data?.map(({ record }) => dssJsonToTagObject(record?.rawData)));
};

const casAdTagsParameterSummary = (data) => {
  return combineTagObjects(data?.map(({ record }) => casJsonToTagObject(record?.rawData)));
};

const supplySourceMap = {
  [VDMS]: vdmsAdTagsParameterSummary,
  [DSS]: dssAdTagsParameterSummary,
  [CAS]: casAdTagsParameterSummary,
};

export const defaultAdTagsParameterSummaryMap = {
  [VDMS]: DEFAULT_VDMS_AD_TAGS_PARAMETER_SUMMARY,
  [DSS]: DEFAULT_DSS_AD_TAGS_PARAMETER_SUMMARY,
  [CAS]: DEFAULT_CAS_AD_TAGS_PARAMETER_SUMMARY,
};

const resolveAdTagsParameterSummary = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : defaultAdTagsParameterSummaryMap[type];
};

export const useAdTagsParameterSummaryResolver = (type, data) => {
  return useMemo(() => resolveAdTagsParameterSummary(type, data), [type, data]);
};
