import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { connect } from 'dva';
import { Typography } from '@hulu/react-style-components';

import { AccountsReviewWrapper, SelectExpirationWrapper } from './styles';
import { newTestCaseColumns } from '../../../Templates/columns';
import { NEWTESTCASE_DATEPICKER, NEWTESTCASE_REVIEW } from '../../../../testUtils';
import { MomentDatePicker } from '../../../../utils';

const SelectTestingFeature = ({ dispatch, profiles = [], targetKeys, endAt, setEndAt, searchParams }) => {
  const targetKeysSet = new Set(targetKeys);
  const selectedProfiles = profiles.filter((profile) => targetKeysSet.has(profile.key));
  const hideEmail = selectedProfiles.filter((profile) => profile.email !== undefined).length === 0;

  const handleDateChange = (expTime) => {
    dispatch({
      type: 'app/setSearchParams',
      payload: { ...searchParams, expTime },
    });
    setEndAt(expTime);
  };

  return (
    <>
      <SelectExpirationWrapper data-testid={NEWTESTCASE_DATEPICKER}>
        <Typography variant="h3">Test Expiration Time</Typography>
        <br />
        <MomentDatePicker
          allowClear={false}
          defaultValue={moment(endAt)}
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          placeholder="select expiration time"
          onChange={handleDateChange}
          disabledDate={(currentDate) =>
            currentDate <= moment().subtract(1, 'days') || currentDate > moment().add(1, 'month')
          }
          needConfirm={false}
        />
        <br />
      </SelectExpirationWrapper>
      <AccountsReviewWrapper data-testid={NEWTESTCASE_REVIEW}>
        <Typography variant="h3">Account / Profile to set up test case</Typography>
        <Table dataSource={selectedProfiles} columns={newTestCaseColumns(hideEmail)} />
      </AccountsReviewWrapper>
    </>
  );
};

function mapStateToProps({ newTestCase, app }) {
  return {
    profiles: newTestCase.accountProfileCombinations,
    searchParams: app.searchParams,
  };
}

export default connect(mapStateToProps)(SelectTestingFeature);
