import {
  RULE_CHANNEL_ID,
  RULE_DURATIONS_MILLISECONDS,
  RULE_KEY,
  RULE_LAST_UPDATED,
  RULE_SERIES_ID,
  RULE_LEAGUE_ID,
  RULE_SINGLE_PROGRAM,
  RULE_SINGLE_PROGRAM_DISPLAY,
  RULE_STATUS,
  RULE_STATUS_ACTIVE,
  RULE_STATUS_DISABLED,
  RULE_TYPE,
  RULE_TYPE_SERIES,
  RULE_TYPE_LEAGUE,
  STREAM_TYPE,
  RULE_PARTIAL_POD,
  RULE_PARTIAL_POD_DISPLAY,
  RULE_EVENT_ID,
  RULE_SPORTS_ID,
} from './constants';
import { Tag } from 'antd';
import {
  DURATION_MANAGER_CHANNELS,
  DURATION_MANAGER_LEAGUES,
  DURATION_MANAGER_RULE_INFO_PATH_PREFIX,
  DURATION_MANAGER_SERIES,
} from '../../constants';

export const buildHashKey = (streamType, ruleType, ...ids) => {
  let hashKey = `${streamType}#${ruleType}`;
  ids.forEach((id) => {
    if (id) {
      hashKey += `#${id}`;
    }
  });
  return hashKey;
};

export const initRuleKey = (pathParams) => {
  if (!pathParams) {
    return null;
  }

  const { channelId, seriesId, leagueId } = pathParams;
  if (leagueId) {
    return `${channelId}#${leagueId}#LEAGUE`;
  } else if (seriesId) {
    return `${channelId}#${seriesId}`;
  } else {
    return channelId;
  }
};

export const convertRuleToTableData = ([, rule]) => ({
  key: rule[RULE_KEY],
  'stream-type': rule[STREAM_TYPE],
  'rule-type': rule[RULE_TYPE],
  ids: {
    'channel-id': rule[RULE_CHANNEL_ID],
    'series-id': rule[RULE_SERIES_ID],
    'league-id': rule[RULE_LEAGUE_ID],
    'event-id': rule[RULE_EVENT_ID],
    'sports-id': rule[RULE_SPORTS_ID],
  },
  'is-single-program': rule[RULE_SINGLE_PROGRAM] ? RULE_SINGLE_PROGRAM_DISPLAY : '-',
  'is-partial-pods': rule[RULE_PARTIAL_POD] ? RULE_PARTIAL_POD_DISPLAY : '-',
  'durations-ms': rule[RULE_DURATIONS_MILLISECONDS].join(', '),
  status: rule[RULE_STATUS],
  'last-updated-date': rule[RULE_LAST_UPDATED],
});

export const convertRuleToCSVData = ([, rule]) => ({
  'Stream Type': rule[STREAM_TYPE],
  'Configuration Type': rule[RULE_TYPE],
  'Channel ID': rule[RULE_CHANNEL_ID],
  'Series ID': rule[RULE_SERIES_ID] ?? '',
  'League ID': rule[RULE_LEAGUE_ID] ?? '',
  'Event ID': rule[RULE_EVENT_ID] ?? '',
  'Sports ID': rule[RULE_SPORTS_ID] ?? '',
  'Duration(s)': rule[RULE_DURATIONS_MILLISECONDS].join('|'),
  'Single Program': rule[RULE_SINGLE_PROGRAM] ?? '',
  'Partial Pod': rule[RULE_PARTIAL_POD] ?? '',
  Status: rule[RULE_STATUS],
});

export const buildRouteFromRow = (selectedRule) => {
  return `${DURATION_MANAGER_RULE_INFO_PATH_PREFIX}/${buildPathFromRule(selectedRule)}`;
};

export const buildPathFromRule = (selectedRule) => {
  const CHANNEL_ROUTE = `${DURATION_MANAGER_CHANNELS}/${selectedRule[RULE_CHANNEL_ID]}`;
  const SERIES_ROUTE = `${CHANNEL_ROUTE}/${DURATION_MANAGER_SERIES}/${selectedRule[RULE_SERIES_ID]}`;
  const LEAGUE_ROUTE = `${CHANNEL_ROUTE}/${DURATION_MANAGER_LEAGUES}/${selectedRule[RULE_LEAGUE_ID]}`;
  switch (selectedRule[RULE_TYPE]) {
    case RULE_TYPE_SERIES:
      return SERIES_ROUTE;
    case RULE_TYPE_LEAGUE:
      return LEAGUE_ROUTE;
    default:
      return CHANNEL_ROUTE;
  }
};

export const ruleStatusRender = (status) => {
  switch (status) {
    case RULE_STATUS_ACTIVE:
      return (
        <Tag color="green">
          <b>Active</b>
        </Tag>
      );
    case RULE_STATUS_DISABLED:
      return (
        <Tag color="gray">
          <b>Disabled</b>
        </Tag>
      );
    default:
      return (
        <Tag color="red">
          <b>Unknown</b>
        </Tag>
      );
  }
};

export const labelRender = (label) => {
  return <b>{label}</b>;
};

const renderTag = (id, color, label) => {
  if (id != null) {
    return (
      <span>
        <Tag color={color}>
          <b>{label}</b>
        </Tag>
        {id}
        <p></p>
      </span>
    );
  }
  return null;
};

export const idRender = (text, { ids }) => {
  return (
    <div>
      {renderTag(ids[RULE_CHANNEL_ID], 'green', 'Channel Id')}
      {renderTag(ids[RULE_SERIES_ID], 'blue', 'Series Id')}
      {renderTag(ids[RULE_LEAGUE_ID], 'cyan', 'League Id')}
      {renderTag(ids[RULE_EVENT_ID], 'magenta', 'Event Id')}
      {renderTag(ids[RULE_SPORTS_ID], 'orange', 'Sports Id')}
    </div>
  );
};
