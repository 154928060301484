import { Modal } from 'antd';
import React from 'react';
import { connect } from 'dva';
import { ADINVESTIGATOR_CREATE_RULES_MODAL } from '../../../testUtils';
import envConfig from '@hulu/env-config';

const HULU_SIGN_IN_URL = envConfig.REACT_APP_HULU_SIGN_IN_URL;
const HULU_SIGN_OUT_URL = envConfig.REACT_APP_HULU_SIGN_OUT_URL;
const HULU_USER_DATA_URL = envConfig.REACT_APP_HULU_USER_DATA_URL;

const LookupUserProfileIdModal = ({ open, setOpen }) => {
  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal title="Lookup User & Profile ids using Hulu Sign-in" open={open} footer={null} onCancel={onCancel}>
      <div data-testid={ADINVESTIGATOR_CREATE_RULES_MODAL}>
        <h4>
          1. Please{' '}
          <a
            href={HULU_SIGN_IN_URL + '?next=' + encodeURIComponent(HULU_USER_DATA_URL)}
            target="_blank"
            rel="noreferrer"
          >
            sign in
          </a>{' '}
          to look up your user and profile ids.
        </h4>
        <p>Example:</p>
        <img src="/files/user_profile_data_sample.png" alt="User Data Sample" width="90%"></img>
        <h4>
          <br />
        </h4>
        <h4>
          2. (Optional) If you would like to see another user account, please{' '}
          <a href={HULU_SIGN_OUT_URL} target="_blank" rel="noreferrer">
            sign out
          </a>{' '}
          and re-login.
        </h4>
      </div>
    </Modal>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(LookupUserProfileIdModal);
