import React from 'react';
import { Alert, Row, Col } from 'antd';

import { DescriptionCard } from '../../Common/DescriptionCard';
import { RequestParams } from '../RequestParams';
import { RequestResponseCard } from '../../Common/RequestResponseCard';
import { useDV360Integrator } from './useDV360Integrator';
import { IntegratorDataSelector } from '../IntegratorDataSelector';

export const DV360Integrator = ({ data, isLoading, pods, handlePodId, podId }) => {
  const {
    summary,
    requestParams,
    request,
    response,
    dv360pods,
    availableRequestNumbers,
    requestNumber,
    handleRequestChange,
  } = useDV360Integrator(data, pods);

  if (request?.error || response?.error) {
    return <Alert type="error" message={request.error || response.error} />;
  }

  return (
    <>
      <DescriptionCard title="Summary" isLoading={isLoading} data={summary} />
      <RequestParams isLoading={isLoading} request={requestParams} />
      <Row>
        <Col>
          {dv360pods && (
            <IntegratorDataSelector name="Pod" data={dv360pods} handleTargetId={handlePodId} targetId={podId} />
          )}
        </Col>
        <Col>
          {availableRequestNumbers && (
            <IntegratorDataSelector
              name="Request"
              data={availableRequestNumbers}
              handleTargetId={handleRequestChange}
              targetId={requestNumber}
            />
          )}
        </Col>
      </Row>
      <RequestResponseCard isLoading={isLoading} data={request} header="Request" />
      <RequestResponseCard isLoading={isLoading} data={response} header="Response" />
    </>
  );
};
