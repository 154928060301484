import styled from 'styled-components';
import { Drawer, Pagination, Input } from 'antd';
import { IconButton } from '@hulu/react-style-components';
import { colors } from '@hulu/design-tokens';

export const StyledCloseButton = styled(IconButton)`
  width: auto;
  height: auto;
  border: none;
  margin: 0 0 0 auto;
`;
export const StyledProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;
export const StyledProfileInputs = styled.div`
  padding: 32px 75px 32px;
  background-color: #f7f7f9;
  box-sizing: border-box;
  .ant-col.ant-form-item-label > label {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-form-item-control-input-content > input {
    border-radius: 4px;
  }
  .ant-form-item {
    margin: 0;
  }
`;
export const StyledProfileActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
  height: 72px;
  padding: 0px 10px;
  .ant-form-item {
    margin: 0;
  }
`;
export const StyledProfileInput = styled.div`
  padding: 32px 75px 0;
  .ant-col.ant-form-item-label > label {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-textarea > span > textarea {
    border-radius: 4px;
  }
  .ant-form-item {
    margin: 0;
  }
  .ant-input-textarea-show-count:after {
    font-size: 12px;
  }
`;
export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow: hidden;

    .ant-drawer-close {
      padding: 0;
    }
  }
  .ant-drawer-header {
    padding: 25px 20px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .drawer-title-text {
      font-weight: normal;
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
`;
export const StyledPagination = styled(Pagination)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 16px;
  padding-bottom: 8px;
  .ant-pagination-prev {
    a {
      margin: 0 10px;
    }
  }
  .ant-pagination-item {
    border-radius: 50%;
    color: ${colors.colorWhite4};
    &.ant-pagination-item-active {
      background-color: ${colors.colorBlack95};
      a {
        color: ${colors.colorWhite};
      }
    }
  }
  .pagination-link {
    font-size: 14px;
    margin: 0 15px;
    cursor: pointer;
  }
`;

export const StyledOptionLabel = styled.span`
  font-weight: 400;
`;

export const StyledProfileNameInput = styled(Input)`
  padding-right: 7px;
  .ant-input-show-count-suffix {
    position: absolute;
    top: 32px;
    right: 0px;
    font-size: 12px;
  }
  .ant-input-clear-icon {
    margin-right: 0px;
  }
`;
