import React from 'react';
import { Alert, Typography, Collapse } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;
const { Panel } = Collapse;

const StyledAlert = styled(Alert)`
  width: 100%;
  border: none;
  .ant-typography-danger {
    padding-left: 8px;
  }
`;

const DEFAULT_ERROR_HEADER = 'Oops, something went wrong!';
const DEFAULT_ERROR_DESCRIPTION = 'Try again later.';

export class AuctionErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  setError = (error) => {
    this.setState((state) => ({ ...state, error }));
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setError(error);
    console.error(error, info);
  }

  render() {
    const { fallback, errorHeader, errorDescription, children } = this.props;
    const { hasError, error } = this.state;

    const errorHeaderMessage = errorHeader ? errorHeader : DEFAULT_ERROR_HEADER;
    const errorDescriptionMessage = errorDescription ? errorDescription : DEFAULT_ERROR_DESCRIPTION;

    if (hasError) {
      return fallback ? (
        fallback
      ) : (
        <StyledAlert
          type="error"
          message={<Typography variant="eyebrow">{errorHeaderMessage}</Typography>}
          description={
            <Collapse ghost>
              <Panel
                header={
                  <>
                    {errorDescriptionMessage}
                    <Text type="danger">Click to view details.</Text>
                  </>
                }
                showArrow={false}
                collapsible="header"
              >
                {error?.message}
              </Panel>
            </Collapse>
          }
          showIcon
        />
      );
    }
    return children;
  }
}
