import React from 'react';
import { Table } from 'ant-table-extensions';
import { mediaRequirementColumns } from '../Templates/columns';
import { DEFAULT_Y_SCROLL } from '../../constants';

const MediaFileTable = ({ requirements = [] }) => (
  <Table
    columns={mediaRequirementColumns(requirements)}
    dataSource={requirements.map((requirement, index) => ({
      ...requirement,
      key: index,
    }))}
    pagination={false}
    scroll={DEFAULT_Y_SCROLL}
  />
);

export default MediaFileTable;
