import React from 'react';
import { XmlViewer } from './XmlViewer';
import { StyledCard } from '../style';

export const XmlCard = ({ isLoading, data, header }) => {
  return (
    <StyledCard loading={isLoading} title={header}>
      <XmlViewer data={data} />
    </StyledCard>
  );
};
