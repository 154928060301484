import React, { useState } from 'react';
import { connect } from 'dva';

import { Button, Spin, Tooltip } from 'antd';
import { EditOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { overridesColumns } from '../../Templates/columns';
import RuleSearch from './RuleSearch';
import { TabsWrapper } from '../styles';
import EditRulesModal from './EditRulesModal';
import {
  ADINVESTIGATOR_VIEW_RULES_TABLE,
  ADINVESTIGATOR_VIEW_RULES_TABLE_EDIT_TOOLTIP,
  ADINVESTIGATOR_VIEW_RULES_TABLE_REMOVE_TOOLTIP,
} from '../../../testUtils';
import { ResizableTable } from '../../../utils';
import { USER_PROFILE } from '../../../constants';

const Y_SCROLL = { y: '50vh' };

const ViewExistingOverrideRules = ({
  dispatch,
  rules = [],
  loading,
  overrideBy,
  setOverrideBy,
  userWidths = [],
  deviceWidths = [],
}) => {
  const [selectedOverride, setSelectedOverride] = useState(null);
  const editRuleRender = ({ key: overrideKey }) => (
    <Tooltip title="Edit this rule" data-testid={ADINVESTIGATOR_VIEW_RULES_TABLE_EDIT_TOOLTIP}>
      <Button
        shape="circle"
        icon={
          <EditOutlined
            onClick={() => {
              setSelectedOverride(overrideKey);
            }}
          />
        }
      />
    </Tooltip>
  );

  const removeRuleRender = (values) => (
    <Tooltip title="Remove this rule" data-testid={ADINVESTIGATOR_VIEW_RULES_TABLE_REMOVE_TOOLTIP}>
      <Button
        shape="circle"
        icon={
          <MinusCircleOutlined
            onClick={() => {
              dispatch({ type: 'adInvestigator/removeRule', payload: values });
            }}
          />
        }
      />
    </Tooltip>
  );
  const handleUserWidthChange = (widths) =>
    dispatch({
      type: 'adInvestigator/overrideUserWidths',
      payload: widths,
    });
  const handleDeviceWidthChange = (widths) =>
    dispatch({
      type: 'adInvestigator/overrideDeviceWidths',
      payload: widths,
    });

  return (
    <Spin spinning={loading}>
      <TabsWrapper data-testid={ADINVESTIGATOR_VIEW_RULES_TABLE}>
        <RuleSearch overrideBy={overrideBy} setOverrideBy={setOverrideBy} />
        {overrideBy === USER_PROFILE && (
          <ResizableTable
            refresh
            inputColumns={overridesColumns(overrideBy, editRuleRender, removeRuleRender, userWidths)}
            dataSource={rules}
            scroll={Y_SCROLL}
            pagination={false}
            widthHandler={handleUserWidthChange}
            searchableProps={null}
          />
        )}
        {overrideBy !== USER_PROFILE && (
          <ResizableTable
            refresh
            inputColumns={overridesColumns(overrideBy, editRuleRender, removeRuleRender, deviceWidths)}
            dataSource={rules}
            scroll={Y_SCROLL}
            pagination={false}
            widthHandler={handleDeviceWidthChange}
            searchableProps={null}
          />
        )}
      </TabsWrapper>
      {/* Modals */}
      <EditRulesModal
        overrideBy={overrideBy}
        selectedOverride={selectedOverride}
        rules={rules}
        setSelectedOverride={setSelectedOverride}
      />
    </Spin>
  );
};

function mapStateToProps({ adInvestigator, loading }) {
  return {
    rules: adInvestigator.rules,
    userWidths: adInvestigator.userWidths,
    deviceWidths: adInvestigator.deviceWidths,
    loading: loading.effects['adInvestigator/fetchExistingRules'],
  };
}
export default connect(mapStateToProps)(ViewExistingOverrideRules);
