import styled from 'styled-components';
import { Link } from 'dva/router';

const DMED_OrbitBlue = '#0072ED';
export const StyledLink = styled(Link)`
  :focus-visible {
    h6 {
      outline: 4px solid ${DMED_OrbitBlue};
    }
  }
`;
