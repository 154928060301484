import React from 'react';
import { Alert } from 'antd';

import { DescriptionCard } from '../../Common/DescriptionCard';
import { RequestParams } from './RequestParams';
import { RequestResponseCard } from '../../Common/RequestResponseCard';
import { useSpotXIntegrator } from './useSpotXIntegrator';
import { IntegratorDataSelector } from '../IntegratorDataSelector';

export const SpotXIntegrator = ({ data, isLoading, pods, handlePodId, podId }) => {
  const { summary, requestParams, request, response, spotxPods } = useSpotXIntegrator(data, pods);

  if (request?.error || response?.error) {
    return <Alert type="error" message={request.error || response.error} />;
  }

  return (
    <>
      <DescriptionCard title="Summary" isLoading={isLoading} data={summary} />
      <RequestParams isLoading={isLoading} data={requestParams} />
      {spotxPods && (
        <IntegratorDataSelector name="Pod" data={spotxPods} handleTargetId={handlePodId} targetId={podId} />
      )}
      <RequestResponseCard isLoading={isLoading} data={request} header="Request" />
      <RequestResponseCard isLoading={isLoading} data={response} header="Response" />
    </>
  );
};
