import React from 'react';
import styled from 'styled-components';
import { colors, spacers } from '@hulu/design-tokens';
import { Typography } from '@hulu/react-style-components';

const StyledEmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.colorBlack50};
  padding: ${spacers.spacer08};
`;

export const EmptyAdSourceSummaryTab = () => {
  const emptyAdSourceSummaryTabTitle = 'No Bids found matching your filters and selections.';

  return (
    <StyledEmptyWrapper>
      <Typography variant="h6">{emptyAdSourceSummaryTabTitle}</Typography>
    </StyledEmptyWrapper>
  );
};
