import { Layout, Menu, Button, Card, Col, Skeleton } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import { colors } from '@hulu/design-tokens';
import styled from 'styled-components';

const { Sider, Content } = Layout;

const lightGray = '#c6c7c9';

export const StyledPageHeader = styled(PageHeader)`
  background-color: #212329 !important;
  color: #ffffff;
  padding: 6px 24px;

  .ant-page-header-heading-title,
  .ant-page-header-back-button,
  .ant-page-header-heading-sub-title {
    color: #ffffff;
  }
  .ant-page-header-heading-extra {
    margin: 11px 0 0;
  }
  .ant-page-header-back {
    border-right: 1px solid #646f85;
    padding-right: 15px;
    margin-right: 15px;
    height: 48px;
    .ant-page-header-back-button {
      margin: 15px 0 0;
    }
  }
`;
export const StyledPageHeaderTitle = styled.div`
  color: #ffffff;
  p {
    margin: 0;
  }
  .page-header-title {
    font-size: 16px;
    line-height: 25px;
  }
  .page-header-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    white-space: pre;
    overflow: hidden;
  }
`;
export const StyledPageHeaderButton = styled(Button)`
  border-radius: 4px;
  font-size: 12px;
  color: #272c35;
  text-transform: uppercase;
  font-weight: 500;
  padding: 4px 27px;
`;
export const StyledLayout = styled(Layout)`
  height: 100%;
`;
export const StyledContent = styled(Content)`
  overflow-x: hidden;
  overflow-y: auto;
`;
export const StyledSider = styled(Sider)`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent !important;
`;
export const StyledMenu = styled(Menu)`
  padding: 12px 12px 0;
`;
export const StyledMenuItem = styled(Menu.Item)`
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #0b0c0f;
  &.menu-item-single {
    padding: 0 16px 0 28px;
  }
  &.ant-menu-item-selected,
  &.ant-menu-item-active {
    background-color: #272c35 !important;
    color: #ffffff !important;
  }
`;
export const StyledMenuItemGroup = styled(Menu.ItemGroup)`
  .ant-menu-item-group-title {
    color: #6d6e6f;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
  }
`;
export const StyledMenuDivider = styled(Menu.Divider)`
  border-color: #c7c7c9;
  margin-bottom: 12px !important;
`;
export const StyledCard = styled(Card)`
  margin: 22px 90px 0 32px;
  background: transparent !important;
  border: 0;

  .ant-card-head {
    padding: 0;
    font-weight: 400;

    .ant-card-head-title {
      font-size: 18px;
      padding: 12px 0 12px 0;
    }
  }

  .ant-card-body {
    background: ${colors.colorWhite};
    border-radius: 4px;
  }
`;
export const StyledRowHead = styled(Col)`
  min-width: 220px;
  text-align: right;
  font-weight: 700;
  padding-top: 8px;
`;
export const StyledRowInfo = styled(Col)`
  margin-left: 11px;
  font-weight: 400;
  padding-top: 8px;
  word-break: break-word;
`;
export const StyledDivider = styled.div`
  background: transparent;
  height: ${(props) => props.height}px;
`;
export const StyledXmlJsonFormatter = styled.div`
  width: 100%;
  height: ${(props) => props.height && props.height}px;
  border: 1px solid ${lightGray};
`;
export const StyledDataInterpretation = styled.pre`
  height: 100%;
  margin-bottom: 0px;
  &::-webkit-scrollbar {
    width: 24px;
    height: 24px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${lightGray};
    border-radius: 34px;
    border: 6px ${colors.colorWhite} solid;
  }
`;
export const StyledTitleSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    margin-bottom: 0;
    margin-top: 0;
  }

  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }

  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 8px;
  }
`;
export const JsonXMLViewersStyle = { minHeight: '200px', maxHeight: '680px' };
