export const CONFIG_STATUS_ENABLED = 'ENABLED';
export const CONFIG_STATUS_DISABLED = 'DISABLED';

export const TRIGGER_OPTIONS = [
  { label: 'PROVIDER_PLACEMENT_OPPORTUNITY', value: 'PROVIDER_PLACEMENT_OPPORTUNITY' },
  { label: 'DISTRIBUTOR_PLACEMENT_OPPORTUNITY', value: 'DISTRIBUTOR_PLACEMENT_OPPORTUNITY' },
  { label: 'BREAK', value: 'BREAK' },
];

export const UPID_OPTIONS = [
  { label: 'DefaultUpidPassthroughStrategy', value: 'DefaultUpidPassthroughStrategy' },
  { label: 'EspnPccLookUpStrategy', value: 'EspnPccLookUpStrategy' },
  { label: 'ViacomUpidTransformationStrategy', value: 'ViacomUpidTransformationStrategy' },
];

export const NETWORK_OPTIONS = [
  { label: 'espnu', value: 'espnu' },
  { label: 'acc', value: 'acc' },
  { label: 'espnews', value: 'espnews' },
  { label: 'sec', value: 'sec' },
  { label: 'deportes', value: 'deportes' },
];

export const AUDIT_HISTORY_PAGINATION_SIZE = 10;
