import React from 'react';
import { Tag, Typography as AntTypography } from 'antd';
import styled from 'styled-components';
import { Typography } from '@hulu/react-style-components';
import { colors } from '@hulu/design-tokens';

import {
  AUCTION_SNAPSHOT_DEVICES_FILTER,
  AUCTION_SNAPSHOT_APP_NAMES_FILTER,
  AUCTION_SNAPSHOT_COUNTRY_FILTER,
  AUCTION_SNAPSHOT_CHIP_TITLE_DEVICE,
  AUCTION_SNAPSHOT_CHIP_TITLE_APP,
  AUCTION_SNAPSHOT_CHIP_TITLE_COUNTRY,
} from '../../../../constants';

const { Paragraph } = AntTypography;

const StyledChipFiltersWrapper = styled.div`
  max-width: 50%;
  min-width: 8%;
  border-left: 1px solid ${colors.colorGray30};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4px;
  margin-left: 10px;
  flex-wrap: wrap;
  .filters-clear {
    min-width: 55px;
    margin-left: 10px;
  }
`;
const StyledTag = styled(Tag)`
  max-width: 90%;
  background-color: ${colors.colorGray13};
  border-radius: 15px;
  font-size: 12px;
  padding: 0 8px;
  text-align: center;
  line-height: 31px;
  box-sizing: border-box;
  height: 32px;
  margin: 0 0 7px 7px;
  display: inline-flex;
  .anticon-close {
    display: flex;
    align-items: center;
  }
  .ant-typography {
    margin-bottom: 0px;
  }
`;

const ellipsisConfig = {
  tooltip: {
    placement: 'bottomRight',
    overlayStyle: { maxWidth: '700px' },
  },
};

export const ChipFilters = ({ activeFilters, onAllFiltersClear, onDeleteFilter, onClearAppNameFilter }) => {
  const { deviceFamily, appName, country } = activeFilters;
  const isDeviceFamilyActiveFilter = Object.values(deviceFamily).length > 0;
  const isAppNameActiveFilter = Object.values(appName).length > 0;
  const isCountryActiveFilter = Object.values(country).length > 0;
  const appNameTagContent = appName?.join(', ');
  return (
    <StyledChipFiltersWrapper>
      {isDeviceFamilyActiveFilter &&
        deviceFamily?.map((filter) => (
          <StyledTag key={filter} closable onClose={() => onDeleteFilter(AUCTION_SNAPSHOT_DEVICES_FILTER, filter)}>
            {AUCTION_SNAPSHOT_CHIP_TITLE_DEVICE}: {filter}
          </StyledTag>
        ))}
      {isAppNameActiveFilter && (
        <StyledTag key={`${AUCTION_SNAPSHOT_APP_NAMES_FILTER}TagKey`} closable onClose={onClearAppNameFilter}>
          <Paragraph ellipsis={ellipsisConfig}>
            {AUCTION_SNAPSHOT_CHIP_TITLE_APP}: {appNameTagContent}
          </Paragraph>
        </StyledTag>
      )}
      {isCountryActiveFilter &&
        country?.map((filter) => (
          <StyledTag key={filter} closable onClose={() => onDeleteFilter(AUCTION_SNAPSHOT_COUNTRY_FILTER, filter)}>
            {AUCTION_SNAPSHOT_CHIP_TITLE_COUNTRY}: {filter}
          </StyledTag>
        ))}
      <Typography variant="link3" className="filters-clear" onClick={onAllFiltersClear}>
        Clear All
      </Typography>
    </StyledChipFiltersWrapper>
  );
};
