import clone from 'clone-deep';

import { getAssetDetails, getContentPartnerIdentifiers, getCreatives } from '../../services/creativeTracker';
import { SELECT_MODE } from '../../constants';

import {
  formatCpOptions,
  formatDspOptions,
  DSP_ID,
  INGESTION_TYPE_OPTIONS,
  PUBLISHER_TYPE_OPTIONS,
  CREATIVE_TYPE_OPTIONS,
  SOURCE_OPTIONS,
  TRANSCODE_STATUS_OPTIONS,
  COUNTRY_CODE_OPTIONS,
  REVIEW_STATUS_OPTIONS,
} from './constants';

import { ACCESS_CONTENT_PARTNER, ALL } from '../constants';
import { currentUserSelector } from '../app';
import { ASSET_ID_KEY } from '../../pages/CreativeTracker/constants';
import { getAllDsp } from '../../services/assetManagerDaapi';
import * as uuid from 'uuid';
import { SELECT_UUID_LIST_RULE } from '../../utils';

export const INITIAL_FILTER_STATE = {
  publishers: {
    name: 'Publishers',
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: PUBLISHER_TYPE_OPTIONS,
    seenByContentPartner: false,
  },
  'ad-id': {
    name: 'Ad ID',
    selected: [],
    options: [],
    'max-count': 1,
    rules: [SELECT_UUID_LIST_RULE],
    seenByContentPartner: false,
  },
  'source-ad-ids': {
    name: 'Source Ad IDs',
    selected: [],
    options: [],
    seenByContentPartner: true,
  },
  'source-asset-ids': {
    name: 'Source Asset IDs',
    selected: [],
    options: [],
    seenByContentPartner: false,
  },
  'asset-ids': {
    name: 'Asset IDs',
    selected: [],
    options: [],
    rules: [SELECT_UUID_LIST_RULE],
    seenByContentPartner: false,
  },
  'brand-names': {
    name: 'Brand Names',
    selected: [],
    options: [],
    seenByContentPartner: false,
  },
  'dsp-ids': {
    name: 'DSPs',
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: [],
    seenByContentPartner: false,
  },
  sources: {
    name: 'Sources',
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: SOURCE_OPTIONS,
    seenByContentPartner: true,
  },
  'review-statuses': {
    name: 'Review Statuses',
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: REVIEW_STATUS_OPTIONS,
    seenByContentPartner: true,
  },
  'last-updated-range': {
    name: 'Last Updated Range',
    selected: [],
    seenByContentPartner: true,
  },
  'content-partner-ids': {
    name: 'Content Partners',
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: [],
    seenByContentPartner: true,
  },
  'campaign-manager-email': {
    name: 'Campaign Manager Email',
    selected: [],
    options: [],
    'max-count': 1,
    seenByContentPartner: false,
  },
  'mediafile-url': {
    name: 'Mediafile URL',
    selected: [],
    options: [],
    'max-count': 1,
    seenByContentPartner: true,
  },
  'creative-types': {
    name: 'Creative Types',
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: CREATIVE_TYPE_OPTIONS,
    seenByContentPartner: true,
  },
  'ingestion-types': {
    name: 'Ingestion Types',
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: INGESTION_TYPE_OPTIONS,
    seenByContentPartner: false,
  },
  'country-codes': {
    name: 'Countries',
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: COUNTRY_CODE_OPTIONS,
    seenByContentPartner: false,
  },
};

export const INITIAL_SELECTED_VALUES = Object.entries(INITIAL_FILTER_STATE).reduce(
  (accumulator, [field, data]) => ({ ...accumulator, [field]: data.selected }),
  {}
);

export const filterSelectedSelector = (state) => {
  const filterSelected = {};
  Object.entries(state.creativeTracker.filters).forEach(([field, data]) => {
    if (field.endsWith('range')) {
      filterSelected['updated-after-date'] = data.selected?.[0];
      filterSelected['updated-before-date'] = data.selected?.[1];
    } else if (
      field.toLowerCase() === 'ad-id' ||
      field.toLowerCase() === 'campaign-manager-email' ||
      field.toLowerCase() === 'mediafile-url'
    ) {
      filterSelected[field] = data.selected && data.selected?.length > 0 ? data.selected?.[0] : null;
    } else {
      filterSelected[field] = data.selected;
    }
  });
  return filterSelected;
};

const getContentPartners = (contentPartners, abilities) =>
  contentPartners.filter(
    (partner) =>
      abilities.can(ACCESS_CONTENT_PARTNER, ALL) || abilities.can(ACCESS_CONTENT_PARTNER, partner.name.toLowerCase())
  );

const buildDspMap = (dspOptions) => {
  // Create a map of DSP UUIDs to DSP names
  return (dspOptions || []).reduce((acc, dsp) => {
    acc[dsp['dsp-uuid']] = dsp['dsp-name'];
    return acc;
  }, {});
};

const enrichCreativesWithDspName = (creatives, dspMap) => {
  return (creatives || []).map((creative) => {
    const dspName = creative[DSP_ID] ? dspMap[creative[DSP_ID]] || creative[DSP_ID] : null;
    return { ...creative, 'dsp-name': dspName };
  });
};

// Action Creator
const updateDspMap = (dspMap) => ({
  type: 'updateDspMap',
  payload: dspMap,
});

const updateDspOptions = (dsp) => ({
  type: 'updateDspOptions',
  payload: { dsp },
});

const updateCreatives = (creatives) => ({
  type: 'updateCreatives',
  payload: creatives,
});

export const creativeTracker = {
  namespace: 'creativeTracker',
  state: {
    creatives: [],
    filters: INITIAL_FILTER_STATE,
    assets: {},
    creativeWidths: [],
    dspMap: {},
  },
  reducers: {
    updateCreatives(state, { payload: creatives }) {
      creatives = creatives.map((creative) => {
        return { ...creative, key: uuid.v4() };
      });
      return { ...state, creatives };
    },
    updateCreativeWidths(state, { payload: creativeWidths }) {
      return { ...state, creativeWidths };
    },
    updateCpIdOptions(state, { payload: { cps, abilities } }) {
      const currState = clone(state);
      currState.filters['content-partner-ids'].options = formatCpOptions(getContentPartners(cps, abilities));
      return currState;
    },
    updateDspOptions(state, { payload: { dsp } }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          'dsp-ids': {
            ...state.filters['dsp-ids'],
            options: formatDspOptions(dsp),
          },
        },
      };
    },
    updateDspMap(state, { payload: dspMap }) {
      return {
        ...state,
        dspMap,
      };
    },
    updateFilterSelected(state, { payload: selected }) {
      const currState = clone(state);
      Object.entries(selected).forEach(([field, values]) => {
        currState.filters[field].selected = values;
      });
      return currState;
    },
    upsertAssetDetails(state, { payload: { assetId, details } }) {
      const currState = clone(state);
      currState.assets[assetId] = details;
      return currState;
    },
    resetFields(state) {
      const currState = clone(state);
      Object.entries(INITIAL_SELECTED_VALUES).forEach(([field, selected]) => {
        currState.filters[field].selected = selected;
      });
      return currState;
    },
  },
  effects: {
    *pageInit(emptyPayload, { call, put, select }) {
      // load in cp identifiers
      const { permissions: abilities } = yield select(currentUserSelector);
      const cpResponse = yield call(getContentPartnerIdentifiers);
      if (cpResponse?.data) {
        yield put({ type: 'updateCpIdOptions', payload: { cps: cpResponse.data, abilities } });
      }

      // Load Demand Side Platforms (DSPs)
      const dspResponse = yield call(getAllDsp);
      const dspMap = buildDspMap(dspResponse?.data?.data);
      if (Object.keys(dspMap).length > 0) {
        yield put(updateDspMap(dspMap));
        yield put(updateDspOptions(dspResponse.data.data));
      }

      const filterSelected = yield select(filterSelectedSelector);
      const resp = yield call(getCreatives, { ...filterSelected, adUnitIds: filterSelected.adUnitIds });
      const creatives = enrichCreativesWithDspName(resp?.data?.['search-data'], dspMap);
      yield put(updateCreatives(creatives));
    },
    *pageUpdate(emptyPayload, { call, put, select }) {
      const filterSelected = yield select(filterSelectedSelector);
      const resp = yield call(getCreatives, { ...filterSelected, adUnitIds: filterSelected.adUnitIds });
      const dspMap = yield select((state) => state.creativeTracker.dspMap);
      const creatives = enrichCreativesWithDspName(resp?.data?.['search-data'], dspMap);
      yield put(updateCreatives(creatives));
    },
    *fetchAssetDetails({ payload: creative }, { call, put }) {
      let details = {};
      let assetId = creative[ASSET_ID_KEY];

      const resp = yield call(getAssetDetails, { 'asset-id': creative[ASSET_ID_KEY] });
      if (resp?.data?.['asset-data']) {
        details = { ...resp.data?.['asset-data'] };
      }

      // TODO add status steps https://jira.disneystreaming.com/browse/KOALA-266
      yield put({ type: 'upsertAssetDetails', payload: { assetId, details } });
    },
  },
};
