import React from 'react';
import { colors } from '@hulu/design-tokens';
import styled from 'styled-components';

const Header = styled.div`
  color: ${colors.colorBlack50};
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
`;

export const PanelHeader = ({ header, count }) => {
  return <Header>{`${header} (${count})`}</Header>;
};
