import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';
import { useSupplySourceDeviceDetailsResolver } from './useSupplySourceDeviceDetailsResolver';

export const DeviceDetailsCard = ({ isLoading, data, type }) => {
  const values = useSupplySourceDeviceDetailsResolver(type, data);
  return (
    <StyledCard title="Device Details" loading={isLoading}>
      <RowInfo title="Device UA" data={values.deviceUA} />
      <RowInfo title="Device DNT" data={values.deviceDNT} />
      <RowInfo title="Device LMT" data={values.deviceLMT} />
      <RowInfo title="Device IP" data={values.deviceIP} />
      <RowInfo title="Device IPv6" data={values.deviceIPV6} />
      <RowInfo title="Device Device Type" data={values.deviceDeviceType} />
      <RowInfo title="Device Make" data={values.deviceMake} />
      <RowInfo title="Device Model" data={values.deviceModel} />
      <RowInfo title="Device OS" data={values.deviceOS} />
      <RowInfo title="Device OSV" data={values.deviceOSV} />
      <RowInfo title="Device Height" data={values.deviceHeight} />
      <RowInfo title="Device Width" data={values.deviceWidth} />
      <RowInfo title="Device IFA" data={values.deviceIFA} />
      <RowInfo title="Device IFA Type" data={values.deviceIFAType} />
      <RowInfo title="Device Computer GUID" data={values.deviceComputerGUID} />
      <RowInfo title="Device ID" data={values.deviceID} />
      <RowInfo title="Device Partner" data={values.devicePartner} />
      <RowInfo title="Device Platform" data={values.devicePlatform} />
      <RowInfo title="Device Session ID" data={values.deviceSessionID} />
    </StyledCard>
  );
};
