import React from 'react';
import envConfig from '@hulu/env-config';
import { Alert } from 'antd';
import { BidDetailsRowInfo } from '../Common/BidDetailsRowInfo';

const MC_DEMAND_BASE_URL = `${envConfig.REACT_APP_MISSION_CONTROL_BASE_URL}/demand-management/deals`;
const ALWAYS_ON_LABEL = 'Always On';

const HyperLink = ({ id, name }) => {
  return id ? (
    <a href={`${MC_DEMAND_BASE_URL}/${id}`} target="_blank" title="View Deal Details" rel="noreferrer">
      {name}
    </a>
  ) : (
    name
  );
};

export const DealDetails = ({ dealDetails, dealDetailsError }) => {
  const {
    id,
    name,
    externalDealId,
    dealType,
    dsp,
    cpm,
    totalImpressions,
    startDate,
    endDate,
    alwaysOn,
    adomainRules,
    currency,
  } = dealDetails || {};

  const dealEndDate = alwaysOn ? ALWAYS_ON_LABEL : endDate;

  return dealDetailsError ? (
    <Alert type="error" message={dealDetailsError?.response?.data?.message} />
  ) : (
    <>
      <BidDetailsRowInfo title="Deal name" data={<HyperLink id={id} name={name} />} />
      <BidDetailsRowInfo title="Internal Deal ID" data={id} />
      <BidDetailsRowInfo title="External Deal ID" data={externalDealId} />
      <BidDetailsRowInfo title="Deal Type" data={dealType} />
      <BidDetailsRowInfo title="DSP" data={dsp?.dspName} />
      <BidDetailsRowInfo title="Fixed/Floor CPM" data={cpm} />
      <BidDetailsRowInfo title="Impressions" data={totalImpressions} />
      <BidDetailsRowInfo title="Deal start" data={startDate} />
      <BidDetailsRowInfo title="Deal end" data={dealEndDate} />
      <BidDetailsRowInfo
        title="Safelist adomains"
        data={adomainRules?.map(({ aDomain }) => aDomain?.name)?.join(', ')}
      />
      <BidDetailsRowInfo title="Currency" data={currency} />
    </>
  );
};
