import { useMemo } from 'react';
import { timestampIntegrators } from '../../timestampIntegrators';
import { REQUEST, RESPONSE, CREATED_TIMESTAMP } from '../../constants';

export const useRokuIntegrator = (data, pods) => {
  const integratorPods = data?.demandPods?.length ? data?.demandPods : pods;

  const { summary, requestParams, request, response, rokuPods } = useMemo(() => {
    return {
      summary: {
        timestamp: timestampIntegrators(data?.[RESPONSE]?.record?.[CREATED_TIMESTAMP]),
      },
      requestParams: data?.[REQUEST]?.record?.rawData ?? {},
      request: data?.[REQUEST]?.record?.rawData ?? {},
      response: data?.[RESPONSE]?.record?.rawData ?? {},
      rokuPods: integratorPods ?? [],
    };
  }, [data, integratorPods]);

  return {
    summary,
    requestParams,
    request,
    response,
    rokuPods,
  };
};
