import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';

const tagBackgroundColor = '#ececef';

const StyledTag = styled(Tag)`
  padding: 6px 24px 6px 12px;
  border-radius: 4px;
  border: none;
  background-color: ${tagBackgroundColor};
  margin-bottom: 6px;
`;

const StyledTagTitle = styled.span`
  font-weight: 600;
  color: ${colors.colorBlack50};
  margin-right: 6px;
`;

const StyledTagData = styled.span`
  font-weight: 400;
`;

export const CustomTag = ({ title, data }) => (
  <StyledTag>
    <StyledTagTitle>{title}</StyledTagTitle>
    <StyledTagData>{data}</StyledTagData>
  </StyledTag>
);
