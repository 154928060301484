import React from 'react';
import { SummaryCard } from './SummaryCard';
import { AdSourceSummary } from '../AdBreaks/AdSourceSummary';
import { WINNER_CATEGORY, FILTERED_BIDS_CATEGORY, BID_WON } from '../constants';

const mockData = {
  bidderNames: ['amazon', 'brightroll'],
  combineBidsByBidState: {
    [WINNER_CATEGORY]: [
      {
        bidId: 'mockBidId1',
        dsp: 'amazon',
        bidStatus: BID_WON,
        dealType: 'Private Marketplace - Fixed',
        pricing: 28,
        creativeDuration: 30000,
        bidState: 'SELECTED',
        adomain: 'URL',
      },
    ],
    [FILTERED_BIDS_CATEGORY]: [
      {
        bidId: 'mockBidId2',
        dsp: 'brightroll',
        bidStatus: FILTERED_BIDS_CATEGORY,
        dealType: 'Private Marketplace - Dynamic',
        pricing: 12,
        creativeDuration: 45000,
        bidState: 'SELECTED',
        adomain: 'URL',
      },
      {
        bidId: 'mockBidId3',
        dsp: 'brightroll',
        bidStatus: FILTERED_BIDS_CATEGORY,
        dealType: 'Invite Only Auction',
        pricing: 4,
        creativeDuration: 11000,
        bidState: 'SELECTED',
        adomain: 'URL',
      },
    ],
  },
  combineBidsByBidderName: {
    amazon: [
      {
        bidId: 'mockBidId1',
        dsp: 'amazon',
        bidStatus: BID_WON,
        dealType: 'Private Marketplace - Fixed',
        pricing: 28,
        creativeDuration: 30000,
        bidState: 'SELECTED',
        adomain: 'URL',
      },
    ],
    brightroll: [
      {
        bidId: 'mockBidId2',
        dsp: 'brightroll',
        bidStatus: FILTERED_BIDS_CATEGORY,
        dealType: 'Private Marketplace - Dynamic',
        pricing: 12,
        creativeDuration: 45000,
        bidState: 'SELECTED',
        adomain: 'URL',
      },
      {
        bidId: 'mockBidId3',
        dsp: 'brightroll',
        bidStatus: FILTERED_BIDS_CATEGORY,
        dealType: 'Invite Only Auction',
        pricing: 4,
        creativeDuration: 11000,
        bidState: 'SELECTED',
        adomain: 'URL',
      },
    ],
  },
};

export const UnifiedPodAndBids = () => {
  return (
    <>
      <SummaryCard />
      <AdSourceSummary
        isUnifiedSelection={true}
        bidderNames={mockData.bidderNames}
        combineBidsByBidState={mockData.combineBidsByBidState}
        bidsByBidderNames={mockData.combineBidsByBidderName}
        handleSearch={() => {}}
        openBidDetails={() => {}}
      />
    </>
  );
};
