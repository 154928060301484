import React from 'react';
import { connect } from 'dva';
import styled from 'styled-components';
import { createTableColumnFilters } from '../../Templates/tableUtils';
import { VASTTRACKER_TABLE } from '../../../testUtils';
import { ResizableTable } from '../../../utils';
import { vastTrackerColumns } from '../../Templates/columns';
import { STATUS_NAMES } from '../constants';

export const TableWrapper = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 20px;
`;

const TABLE_SCROLL = { x: 500, y: 300 };

const COLUMN_PRIORITY_ORDER = [
  STATUS_NAMES.SUCCESS,
  STATUS_NAMES.FAILURE,
  STATUS_NAMES.SUCCESS_RATE,
  STATUS_NAMES.UNSUPPORTED_FORMAT,
  STATUS_NAMES.UNSUPPORTED_FORMAT_VPAID,
  STATUS_NAMES.UNSUPPORTED_NON_SSL,
  STATUS_NAMES.UNPARSEABLE_DOCUMENT,
  STATUS_NAMES.UNPARSEABLE_DOCUMENT_NULL_VAST,
  STATUS_NAMES.UNPARSEABLE_DOCUMENT_PARSE_FAIL,
  STATUS_NAMES.INVALID_DOCUMENT,
  STATUS_NAMES.INVALID_DOCUMENT_NO_CREATIVES,
  STATUS_NAMES.INVALID_DOCUMENT_INVALID_IMPRESSION_URL,
  STATUS_NAMES.INVALID_DOCUMENT_NO_TRACKING_EVENTS,
  STATUS_NAMES.INVALID_DOCUMENT_NO_VIDEO_CLICKS,
  STATUS_NAMES.MISSING_ASSET,
  STATUS_NAMES.MISSING_ASSET_EXTERNAL_AD,
  STATUS_NAMES.MISSING_ASSET_VAST_AD,
  STATUS_NAMES.INTERNAL_ERROR,
  STATUS_NAMES.INTERNAL_ERROR_INLINE_VAST_PROCESS,
  STATUS_NAMES.LOCAL_PENALTY,
  STATUS_NAMES.ASSET_REJECTION,
  STATUS_NAMES.SENT,
];

const calculateSuccessRate = (success, failure) => {
  if (success + failure > 0) {
    return (success / (success + failure)) * 100; // Calculate success rate as a percentage
  }
  return null; // Indicate that success rate is not calculable
};

const CountsTable = ({
  selectedCounts = [],
  dailyViewEnabled,
  dispatch,
  countTableWidths = [],
  countTypeSelected = 'N/A',
}) => {
  const statuses = new Set([STATUS_NAMES.SUCCESS_RATE]);
  const source = [];

  selectedCounts.forEach(({ value, counts }) => {
    const row = { key: value, name: value };
    let success = 0;
    let failure = 0;

    counts.forEach(({ 'event-type': eventType, occurrence }) => {
      if (eventType === STATUS_NAMES.SUCCESS) {
        success = occurrence;
      } else if (eventType === STATUS_NAMES.FAILURE) {
        failure = occurrence;
      }
      statuses.add(eventType);
      row[eventType] = occurrence;
    });

    const successRate = calculateSuccessRate(success, failure);
    if (successRate !== null) {
      row[STATUS_NAMES.SUCCESS_RATE] = successRate.toFixed(2);
    }
    if (!value.endsWith('_total') && counts.length > 0) source.push(row);
  });

  const columns = [...statuses]
    .map((status) => ({
      title: status.replace(/_/g, ' '),
      dataIndex: status,
      key: status,
      sorter: (a, b) => (a[status] ?? 0) - (b[status] ?? 0),
    }))
    .sort((columnA, columnB) => {
      const priorityA = COLUMN_PRIORITY_ORDER.indexOf(columnA.key);
      const priorityB = COLUMN_PRIORITY_ORDER.indexOf(columnB.key);

      // If both columns are in the priorityOrder, sort them based on their order.
      if (priorityA !== -1 && priorityB !== -1) {
        return priorityA - priorityB;
      }

      // If only one column is in the priorityOrder, it comes first.
      if (priorityA !== -1) {
        return -1;
      }
      if (priorityB !== -1) {
        return 1;
      }

      // If neither column is in the priorityOrder, retain the original order.
      return 0;
    });

  columns.unshift({
    title: dailyViewEnabled ? 'Date' : 'ID',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    filters: createTableColumnFilters(source, 'name'),
    onFilter: (value, record) => record['name'] === value,
    render: (text) => <b>{text}</b>,
  });
  const handleWidthChange = (widths) => {
    dispatch({
      type: 'vastTracker/updateCountTableWidths',
      payload: { type: countTypeSelected, widths: widths },
    });
  };

  return (
    <TableWrapper data-testid={VASTTRACKER_TABLE}>
      <ResizableTable
        bordered
        exportableProps
        dataSource={source}
        searchableProps={null}
        inputColumns={vastTrackerColumns(columns, countTableWidths)}
        scroll={TABLE_SCROLL}
        widthHandler={handleWidthChange}
      />
    </TableWrapper>
  );
};

function mapStateToProps({ vastTracker: { counts, countTypeSelected, dailyViewEnabled } }) {
  return {
    selectedCounts: counts[countTypeSelected].items ?? [],
    countTableWidths: counts[countTypeSelected].widths,
    countTypeSelected: countTypeSelected ?? 'N/A',
    dailyViewEnabled,
  };
}

export default connect(mapStateToProps)(CountsTable);
