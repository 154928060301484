import styled from 'styled-components';
import { Button, Form, Input } from 'antd';

export const StyledButton = styled(Button)`
  position: relative;
  float: right;
  background: #272c35;
  border: #272c35;
`;

export const TabsWrapper = styled.div`
  height: 74vh;
  padding: 20px;
  border: 1px solid #706f6f;
  background-color: white;
  border-radius: 10px;
`;

export const StyledInput = styled(Input)`
  width: 320px;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-top: 20px;
`;
