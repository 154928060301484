import { Checkbox, Popover, Select, Tag, Tree } from 'antd';
import moment from 'moment';
import { FilterFilled, InfoCircleOutlined } from '@ant-design/icons';
import {
  createTableColumnFilters,
  createTableRowToolTip,
  auctionSnapshotActions,
  editableSnapshotNameCell,
  snapshotStatuses,
  StyledAuctionName,
  createTableBrandsColumnFilters,
} from './tableUtils';
import {
  ACTIVE,
  CHANGE_TYPE,
  CHANNEL_ID,
  DURATION_MS,
  EXPIRED,
  REQUIREMENT_PASS_STATUS,
  SERIES_ID,
  STATUS,
  LEAGUE_ID,
  STATUSES,
  SUSPENDED,
  TAG_VALID_STATUS,
  USER_PROFILE,
  SINGLE_PROGRAM,
  AUCTION_SNAPSHOT_DETAILED_VIEW,
  PARTIAL_POD,
  SPORTS_ID,
  EVENT_ID,
} from '../../constants';
import { dateRender, formatDate, getLocalTimezoneAbbreviation } from '../../utils';
import { ruleStatusRender } from '../DurationManager/utils';
import { idRender } from '../DurationManager/utils';
import { formatAssetDetails } from '../CreativeTracker/utils';
import {
  RULE_CHANNEL_ID,
  RULE_DURATIONS_MILLISECONDS,
  RULE_LAST_UPDATED,
  RULE_SERIES_ID,
  RULE_LEAGUE_ID,
  RULE_SINGLE_PROGRAM,
  RULE_SINGLE_PROGRAM_DISPLAY,
  RULE_STATUS,
  RULE_TYPE,
  IDS,
  STREAM_TYPE,
  RULE_PARTIAL_POD,
  RULE_PARTIAL_POD_DISPLAY,
  RULE_EVENT_ID,
  RULE_SPORTS_ID,
} from '../DurationManager/constants';
import {
  BRAND_NAMES_KEY,
  MEDIA_FILE_URL_KEY,
  TRANSCODE_STATUS_KEY,
  ASSET_DETAILS_KEY,
  AD_ID_KEY,
  SOURCE_AD_ID_KEY,
  SOURCE_ASSET_ID_KEY,
  CAMPAIGN_MANAGER_NAME_KEY,
  DSP_KEY,
  SOURCE_KEY,
  ASSET_ID_KEY,
  UPDATED_AT_KEY,
  INGESTION_TYPE_KEY,
  CREATIVE_TYPE_KEY,
  CONTENT_PARTNER_ID_KEY,
  PUBLISHER_KEY,
  COUNTRY_CODES_KEY,
  REVIEW_STATUS_KEY,
} from '../CreativeTracker/constants';

import { COUNTRY_CODE_TO_NAME_MAP } from '../../models/creativeTracker/constants';

import {
  AD,
  AD_ID,
  CREATIVE,
  ERROR_MESSAGE,
  IMPRESSION,
  INLINE,
  LINEAR,
  MEDIA_FILE,
  MEDIA_PASS,
  PARAMETER,
  TYPE,
  VALUE,
  VAST_PASS,
  VAST,
  VPAID,
} from '../QCheck/constants';
import {
  AD_SERVER_TITLE,
  CONTENT_INFO_TITLE,
  CONTENT_DISTRIBUTOR_TITLE,
  COUNTRY_TITLE,
  DEVICE_TITLE,
  DEVICE_DISTRIBUTOR_TITLE,
  INTEGRATION_TITLE,
  POD_TITLE,
  PUBLISHER_TITLE,
  STATUS_TITLE,
  TEST_CASE_CREATED_AT,
  TEST_CASE_ID,
  TEST_CASE_PROFILE_NAME,
  TEST_CASE_STATUS,
  TIME_TITLE,
  TRACE_AD_SERVER,
  TRACE_CONTENT_PARTNER_IDENTIFIER,
  TRACE_CONTENT_TITLE,
  TRACE_COUNTRY,
  TRACE_DEVICE_NAME,
  TRACE_DEVICE_DISTRIBUTOR_NAME,
  TRACE_CONTENT_DISTRIBUTOR_NAME,
  TRACE_INTEGRATION,
  TRACE_POD,
  TRACE_PUBLISHER,
  TRACE_SERIES_ID,
  TRACE_STATUS,
  TRACE_TIME,
  TEST_CASE_ACTION,
} from '../AdsTracer/constants';
import {
  configStatusRender,
  CP_IDENTIFIER,
  HISTORY_ACTION,
  NETWORK_NAME,
  SOURCE_ID,
  TRIGGER_TYPE,
  UPDATED_AT,
  UPDATED_BY,
  UPID_LOOKUP_STRATEGY,
} from '../ConfigManager/utils';

const { Option } = Select;

// TEST CASES SUMMARIES TABLE
const testCaseTableStatusRender = (status, { isSharedToOther, isSharedToMe }) => {
  let testCaseStatus;
  let sharedToOtherStatus;
  let sharedToMeStatus;

  switch (status) {
    case ACTIVE:
      testCaseStatus = <Tag color="green">Active</Tag>;
      break;
    case SUSPENDED:
      testCaseStatus = <Tag color="orange">Suspended</Tag>;
      break;
    case EXPIRED:
      testCaseStatus = <Tag color="red">Expired</Tag>;
      break;
    default:
      testCaseStatus = <Tag color="blue">Unknown</Tag>;
  }

  if (isSharedToOther) {
    sharedToOtherStatus = <Tag color="yellow">Shared</Tag>;
  }

  if (isSharedToMe) {
    sharedToMeStatus = <Tag color="yellow">Shared to me</Tag>;
  }

  return (
    <>
      {testCaseStatus}
      {sharedToOtherStatus}
      {sharedToMeStatus}
    </>
  );
};

const testCaseTableRowRender = (
  val,
  {
    email,
    accountId,
    profileId,
    profileName,
    testCaseId,
    expireAt,
    createdAt,
    subscription,
    isSharedToOther,
    sharedTime,
  }
) => {
  const localTimeZone = getLocalTimezoneAbbreviation();
  return createTableRowToolTip(val, [
    `Test Case Id: ${testCaseId}`,
    ...(email ? [`Email: ${email}`] : []),
    ...(accountId ? [`Account Id: ${accountId}`] : []),
    ...(profileId ? [`Profile Id: ${profileId}`] : []),
    ...(profileName ? [`Profile Name: ${profileName}`] : []),
    `Created At (${localTimeZone}): ${createdAt}`,
    `Expired At (${localTimeZone}): ${expireAt}`,
    ...(subscription ? [`Subscriptions: ${subscription?.packages?.map((pkg) => pkg.value).join(' | ')}`] : []),
    ...(isSharedToOther ? ['*This test case is being shared with others'] : []),
    ...(sharedTime ? [`Test Case shared At (${localTimeZone}): ${sharedTime}`] : []),
  ]);
};

export const testCaseColumns = (testCases, rowExtraRender, customFilter, rangeFilter, widths) => [
  {
    title: 'Profile',
    dataIndex: TEST_CASE_PROFILE_NAME,
    key: TEST_CASE_PROFILE_NAME,
    ellipsis: true,
    render: testCaseTableRowRender,
    filters: createTableColumnFilters(testCases, TEST_CASE_PROFILE_NAME),
    filterSearch: true,
    onFilter: (value, record) => record[TEST_CASE_PROFILE_NAME] === value,
    width: widths[TEST_CASE_PROFILE_NAME] || 100,
  },
  {
    title: 'Test Case',
    dataIndex: TEST_CASE_ID,
    key: TEST_CASE_ID,
    ellipsis: true,
    render: testCaseTableRowRender,
    filterSearch: true,
    filters: createTableColumnFilters(testCases, TEST_CASE_ID),
    onFilter: (value, record) => record[TEST_CASE_ID] === value,
    width: widths[TEST_CASE_ID] || 100,
  },
  {
    title: 'Status',
    dataIndex: TEST_CASE_STATUS,
    key: TEST_CASE_STATUS,
    render: testCaseTableStatusRender,
    filters: createTableColumnFilters(testCases, TEST_CASE_STATUS),
    onFilter: (value, record) => record[TEST_CASE_STATUS] === value,
    width: widths[TEST_CASE_STATUS] || 100,
  },
  {
    title: `Time Created (${getLocalTimezoneAbbreviation()})`,
    dataIndex: TEST_CASE_CREATED_AT,
    key: TEST_CASE_CREATED_AT,
    render: (createdAt) => createdAt,
    width: widths[TEST_CASE_CREATED_AT] || 100,
    filterIcon: <FilterFilled style={{ color: rangeFilter ? '#1890ff' : undefined }} />,
    filterDropdown: customFilter,
  },
  {
    title: 'Actions',
    dataIndex: TEST_CASE_ACTION,
    key: TEST_CASE_ACTION,
    width: widths[TEST_CASE_ACTION] || 100,
    render: rowExtraRender,
  },
];

// TRACE SUMMARIES TABLE
const traceTableRowRender = (
  val,
  {
    trace_id,
    integration,
    contentPartnerIdentifier,
    seriesIdentifier,
    contentTitle,
    deviceId,
    distributorPlatform,
    distributorName,
    pod,
    isVppa,
    isOptOut,
    isCoppa,
  }
) =>
  createTableRowToolTip(val, [
    'Trace Id: ' + trace_id,
    'Integration: ' + integration,
    'Content Partner: ' + contentPartnerIdentifier,
    'Series Name: ' + seriesIdentifier,
    'Content Title: ' + contentTitle,
    'Device Id: ' + deviceId,
    'Distributor Platform: ' + distributorPlatform,
    'Distributor Name: ' + distributorName,
    'Ad Position: ' + pod,
    'Vppa: ' + isVppa?.toString(),
    'Opt Out: ' + isOptOut?.toString(),
    'Coppa: ' + isCoppa?.toString(),
  ]);

const translateStatus = (status) => {
  if (status === '200') {
    return 'FILLED';
  } else if (status === '204') {
    return 'POD MISS';
  } else if (status === '400') {
    return 'BAD REQUEST';
  } else if (status === '500') {
    return 'INTERNAL ERROR';
  } else if (status === '504') {
    return 'TIMEOUT';
  } else {
    return status;
  }
};

const traceTableStatusRender = (status) => {
  let tagColor = 'blue';
  if (status === '200') {
    tagColor = 'green';
  } else if (status === '204') {
    tagColor = 'orange';
  } else if (status.toString().startsWith('5') || status.toString().startsWith('4')) {
    tagColor = 'red';
  }

  return <Tag color={tagColor}>{translateStatus(status)}</Tag>;
};

const createTableStatusFilters = (collection, attr) =>
  [...new Set(collection.map((item) => item[attr]))]
    .filter((item) => item)
    .map((uniqueItem) => ({ text: translateStatus(uniqueItem), value: uniqueItem }));

export const traceColumns = (traces, filters, widths, visibleCols) =>
  [
    {
      title: DEVICE_TITLE,
      dataIndex: TRACE_DEVICE_NAME,
      key: TRACE_DEVICE_NAME,
      width: widths[TRACE_DEVICE_NAME] || 100,
      filteredValue: filters[TRACE_DEVICE_NAME] || null,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_DEVICE_NAME),
      onFilter: (value, record) => record[TRACE_DEVICE_NAME] === value,
    },
    {
      title: INTEGRATION_TITLE,
      dataIndex: TRACE_INTEGRATION,
      key: TRACE_INTEGRATION,
      width: widths[TRACE_INTEGRATION] || 120,
      filteredValue: filters[TRACE_INTEGRATION] || null,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_INTEGRATION),
      onFilter: (value, record) => record[TRACE_INTEGRATION] === value,
    },
    {
      title: DEVICE_DISTRIBUTOR_TITLE,
      dataIndex: TRACE_DEVICE_DISTRIBUTOR_NAME,
      key: TRACE_DEVICE_DISTRIBUTOR_NAME,
      width: widths[TRACE_DEVICE_DISTRIBUTOR_NAME] || 120,
      filteredValue: filters[TRACE_DEVICE_DISTRIBUTOR_NAME] || null,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_DEVICE_DISTRIBUTOR_NAME),
      onFilter: (value, record) => record[TRACE_DEVICE_DISTRIBUTOR_NAME] === value,
    },
    {
      title: CONTENT_INFO_TITLE,
      dataIndex: 'contentInfo',
      key: TRACE_CONTENT_PARTNER_IDENTIFIER,
      width: widths[TRACE_CONTENT_PARTNER_IDENTIFIER] || 500,
      minWidth: 100,
      filteredValue: filters[TRACE_CONTENT_PARTNER_IDENTIFIER] || null,
      render: (text, record) => (
        <>
          <p>
            <b>Content Partner:</b> {record[TRACE_CONTENT_PARTNER_IDENTIFIER]}
          </p>
          <p>
            <b>Series:</b> {record[TRACE_SERIES_ID]}
          </p>
          <p>
            <b>Title:</b> {record[TRACE_CONTENT_TITLE]}
          </p>
        </>
      ),
      filters: createTableColumnFilters(traces, TRACE_CONTENT_PARTNER_IDENTIFIER),
      onFilter: (value, record) => record[TRACE_CONTENT_PARTNER_IDENTIFIER] === value,
    },
    {
      title: CONTENT_DISTRIBUTOR_TITLE,
      dataIndex: TRACE_CONTENT_DISTRIBUTOR_NAME,
      key: TRACE_CONTENT_DISTRIBUTOR_NAME,
      width: widths[TRACE_CONTENT_DISTRIBUTOR_NAME] || 120,
      filteredValue: filters[TRACE_CONTENT_DISTRIBUTOR_NAME] || null,
      ellipsis: true,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_CONTENT_DISTRIBUTOR_NAME),
      onFilter: (value, record) => record[TRACE_CONTENT_DISTRIBUTOR_NAME] === value,
    },
    {
      title: POD_TITLE,
      dataIndex: TRACE_POD,
      key: TRACE_POD,
      ellipsis: true,
      width: widths[TRACE_POD] || 80,
      filteredValue: filters[TRACE_POD] || null,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_POD),
      onFilter: (value, record) => record[TRACE_POD] === value,
    },
    {
      title: TIME_TITLE,
      dataIndex: TRACE_TIME,
      key: TRACE_TIME,
      width: widths[TRACE_TIME] || 200,
      ellipsis: true,
      render: traceTableRowRender,
      sorter: (t1, t2) => moment(t1['time']).unix() - moment(t2['time']).unix(),
      defaultSortOrder: 'descend',
    },
    {
      title: STATUS_TITLE,
      dataIndex: TRACE_STATUS,
      key: TRACE_STATUS,
      width: widths[TRACE_STATUS] || 120,
      ellipsis: true,
      filteredValue: filters[TRACE_STATUS] || null,
      render: traceTableStatusRender,
      filters: createTableStatusFilters(traces, TRACE_STATUS),
      onFilter: (value, record) => record[TRACE_STATUS] === value,
    },
    {
      title: COUNTRY_TITLE,
      dataIndex: TRACE_COUNTRY,
      key: TRACE_COUNTRY,
      width: widths[TRACE_COUNTRY] || 120,
      ellipsis: true,
      filteredValue: filters[TRACE_COUNTRY] || null,
      render: traceTableRowRender,
      filters: createTableStatusFilters(traces, TRACE_COUNTRY),
      onFilter: (value, record) => record[TRACE_COUNTRY] === value,
    },
    {
      title: AD_SERVER_TITLE,
      dataIndex: TRACE_AD_SERVER,
      key: TRACE_AD_SERVER,
      width: widths[TRACE_AD_SERVER] || 120,
      ellipsis: true,
      filteredValue: filters[TRACE_AD_SERVER] || null,
      render: traceTableRowRender,
      filters: createTableStatusFilters(traces, TRACE_AD_SERVER),
      onFilter: (value, record) => record[TRACE_AD_SERVER] === value,
    },
    {
      title: PUBLISHER_TITLE,
      dataIndex: TRACE_PUBLISHER,
      key: TRACE_PUBLISHER,
      width: widths[TRACE_PUBLISHER] || 120,
      ellipsis: true,
      filteredValue: filters[TRACE_PUBLISHER] || null,
      render: traceTableRowRender,
      filters: createTableStatusFilters(traces, TRACE_PUBLISHER),
      onFilter: (value, record) => record[TRACE_PUBLISHER] === value,
    },
  ].filter((col) => visibleCols.includes(col.title));

export const newTestCaseColumns = (hideEmail) =>
  [
    {
      title: 'Profile Name',
      dataIndex: 'profileName',
      key: 'profileName',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      hidden: hideEmail,
    },
    {
      title: 'Profile Id',
      dataIndex: 'profileId',
      key: 'profileId',
    },
    {
      title: 'Account Id',
      dataIndex: 'accountId',
      key: 'accountId',
    },
  ].filter((item) => !item.hidden);

// ADS OVERRIDE RULES TABLE
export const overridesColumns = (overrideBy, editRuleRender, removeRuleRender, widths) => [
  ...(overrideBy === USER_PROFILE
    ? [
        {
          title: 'Ad Type',
          dataIndex: 'adType',
          key: 'adType',
          width: widths[0] || 70,
        },
        {
          title: 'User/Account Id',
          dataIndex: 'userId',
          key: 'userId',
          width: widths[1] || 100,
        },
        {
          title: 'Profile Id',
          dataIndex: 'profileId',
          key: 'profileId',
          width: widths[2] || 100,
        },
        {
          title: 'Pod Positions',
          dataIndex: 'podPositions',
          key: 'podPositions',
          width: widths[3] || 250,
          render: (positions) => (Array.isArray(positions) ? <Tree treeData={positions} /> : positions),
        },
        {
          title: 'Expires At',
          dataIndex: 'expireAt',
          key: 'expireAt',
          width: widths[4] || 100,
          render: (expireAt) => moment.unix(expireAt).format('MM/DD/YYYY H:mm:ss'),
        },
        {
          title: 'Edit Rule',
          key: 'editRule',
          width: widths[5] || 80,
          render: editRuleRender,
        },
        {
          title: 'Remove Rule',
          key: 'removeRule',
          width: widths[6] || 80,
          render: removeRuleRender,
        },
      ]
    : [
        {
          title: 'Ad Type',
          dataIndex: 'adType',
          key: 'adType',
          width: widths[0] || 70,
        },
        {
          title: 'Device Id',
          dataIndex: 'deviceId',
          key: 'deviceId',
          width: widths[1] || 210,
        },
        {
          title: 'Pod Positions',
          dataIndex: 'podPositions',
          key: 'podPositions',
          width: widths[2] || 250,
          render: (positions) => (Array.isArray(positions) ? <Tree treeData={positions} /> : positions),
        },
        {
          title: 'Expires At',
          dataIndex: 'expireAt',
          key: 'expireAt',
          width: widths[3] || 100,
          render: (expireAt) => moment.unix(expireAt).format('MM/DD/YYYY H:mm:ss'),
        },
        {
          title: 'Edit Rule',
          key: 'editRule',
          width: widths[4] || 80,
          render: editRuleRender,
        },
        {
          title: 'Remove Rule',
          key: 'removeRule',
          width: widths[5] || 80,
          render: removeRuleRender,
        },
      ]),
];

// LIVEPOD DURATION MANAGER
export const durationColumns = (durationRules, widths) => [
  {
    title: 'Stream Types',
    dataIndex: STREAM_TYPE,
    key: STREAM_TYPE,
    filters: createTableColumnFilters(durationRules, STREAM_TYPE),
    onFilter: (value, record) => record[STREAM_TYPE] === value,
    width: widths[0] || 150,
  },
  {
    title: 'Rule Types',
    dataIndex: RULE_TYPE,
    key: RULE_TYPE,
    filters: createTableColumnFilters(durationRules, RULE_TYPE),
    onFilter: (value, record) => record[RULE_TYPE] === value,
    width: widths[0] || 150,
  },
  {
    title: 'IDs',
    dataIndex: [RULE_CHANNEL_ID, RULE_SERIES_ID, RULE_LEAGUE_ID, RULE_EVENT_ID, RULE_SPORTS_ID],
    key: IDS,
    render: idRender,
    width: widths[2] || 400,
  },
  {
    title: 'Duration(s)',
    dataIndex: RULE_DURATIONS_MILLISECONDS,
    key: RULE_DURATIONS_MILLISECONDS,
    width: widths[4] || 200,
  },
  {
    title: 'Partial Pod',
    dataIndex: RULE_PARTIAL_POD,
    key: RULE_PARTIAL_POD,
    filters: [
      {
        text: RULE_PARTIAL_POD_DISPLAY,
        value: RULE_PARTIAL_POD_DISPLAY,
      },
      {
        text: 'NOT ' + RULE_PARTIAL_POD_DISPLAY,
        value: '-',
      },
    ],
    onFilter: (value, record) => record[RULE_PARTIAL_POD] === value,
    width: widths[4] || 160,
  },
  {
    title: 'Single Program',
    dataIndex: RULE_SINGLE_PROGRAM,
    key: RULE_SINGLE_PROGRAM,
    filters: [
      {
        text: RULE_SINGLE_PROGRAM_DISPLAY,
        value: RULE_SINGLE_PROGRAM_DISPLAY,
      },
      {
        text: 'NOT ' + RULE_SINGLE_PROGRAM_DISPLAY,
        value: '-',
      },
    ],
    onFilter: (value, record) => record[RULE_SINGLE_PROGRAM] === value,
    width: widths[5] || 160,
  },
  {
    title: 'Status',
    dataIndex: RULE_STATUS,
    key: RULE_STATUS,
    render: ruleStatusRender,
    filters: createTableColumnFilters(durationRules, RULE_STATUS),
    onFilter: (value, record) => record[RULE_STATUS] === value,
    width: widths[6] || 100,
  },
  {
    title: 'Last Updated',
    dataIndex: RULE_LAST_UPDATED,
    key: RULE_LAST_UPDATED,
    width: widths[7] || 150,
    render: dateRender,
    defaultSortOrder: 'descend',
    sorter: (t1, t2) => moment(t1[RULE_LAST_UPDATED]).unix() - moment(t2[RULE_LAST_UPDATED]).unix(),
    showSorterTooltip: false,
  },
];

// Q-CHECK
const qcTablePassRender = (pass) => (
  <Tag color={REQUIREMENT_PASS_STATUS[pass].color}>{REQUIREMENT_PASS_STATUS[pass].title}</Tag>
);

const vastTableValidRender = (valid) => (
  <Tag color={TAG_VALID_STATUS[valid].color}>{TAG_VALID_STATUS[valid].title}</Tag>
);

// Config Manager
export const configDataCols = (data, widths) => [
  {
    title: 'CP Identifier',
    dataIndex: CP_IDENTIFIER,
    key: CP_IDENTIFIER,
    width: widths[0] || 150,
  },
  {
    title: 'Source Id',
    dataIndex: SOURCE_ID,
    key: SOURCE_ID,
    width: widths[1] || 150,
  },
  {
    title: 'Trigger',
    dataIndex: TRIGGER_TYPE,
    key: TRIGGER_TYPE,
    width: widths[2] || 150,
    filters: createTableColumnFilters(data, TRIGGER_TYPE),
    onFilter: (value, record) => record[TRIGGER_TYPE] === value,
  },
  {
    title: 'UPID Lookup Strategy',
    dataIndex: UPID_LOOKUP_STRATEGY.name,
    key: UPID_LOOKUP_STRATEGY,
    width: widths[3] || 150,
    render: (item) => item[UPID_LOOKUP_STRATEGY],
    filters: createTableColumnFilters(data, UPID_LOOKUP_STRATEGY),
    onFilter: (value, record) => record[UPID_LOOKUP_STRATEGY] === value,
  },
  {
    title: 'Status',
    dataIndex: STATUS,
    key: STATUS,
    width: widths[4] || 100,
    render: configStatusRender,
    filters: createTableColumnFilters(data, STATUS),
    onFilter: (value, record) => record[STATUS] === value,
  },
  {
    title: 'Updated By',
    dataIndex: UPDATED_BY,
    key: UPDATED_BY,
    width: widths[5] || 150,
    filters: createTableColumnFilters(data, UPDATED_BY),
    onFilter: (value, record) => record[UPDATED_BY] === value,
  },
  {
    title: 'Updated At',
    dataIndex: UPDATED_AT,
    key: UPDATED_AT,
    width: widths[5] || 150,
    render: dateRender,
    defaultSortOrder: 'descend',
    sorter: (t1, t2) => moment(t1[UPDATED_AT]).unix() - moment(t2[UPDATED_AT]).unix(),
  },
];

export const configMgrHistoryCols = (data) => [
  {
    title: 'CP Identifier',
    dataIndex: CP_IDENTIFIER,
    key: CP_IDENTIFIER,
    width: 50,
    filters: createTableColumnFilters(data, CP_IDENTIFIER),
    onFilter: (value, record) => record[CP_IDENTIFIER] === value,
  },
  {
    title: 'Source Id',
    dataIndex: SOURCE_ID,
    key: SOURCE_ID,
    width: 100,
  },
  {
    title: 'Trigger',
    dataIndex: TRIGGER_TYPE,
    key: TRIGGER_TYPE,
    width: 50,
    filters: createTableColumnFilters(data, TRIGGER_TYPE),
    onFilter: (value, record) => record[TRIGGER_TYPE] === value,
  },
  {
    title: 'UPID Lookup Strategy',
    dataIndex: UPID_LOOKUP_STRATEGY.name,
    key: UPID_LOOKUP_STRATEGY,
    width: 50,
    render: (item) => item[UPID_LOOKUP_STRATEGY],
    filters: createTableColumnFilters(data, UPID_LOOKUP_STRATEGY),
    onFilter: (value, record) => record[UPID_LOOKUP_STRATEGY] === value,
  },
  {
    title: 'Network',
    dataIndex: UPID_LOOKUP_STRATEGY[NETWORK_NAME],
    key: NETWORK_NAME,
    width: 50,
    render: (item) => item[NETWORK_NAME],
    filters: createTableColumnFilters(data, NETWORK_NAME),
    onFilter: (value, record) => record[NETWORK_NAME] === value,
  },
  {
    title: 'Status',
    dataIndex: STATUS,
    key: STATUS,
    width: 50,
    render: configStatusRender,
    filters: createTableColumnFilters(data, STATUS),
    onFilter: (value, record) => record[STATUS] === value,
  },
  {
    title: 'Action',
    dataIndex: HISTORY_ACTION,
    key: HISTORY_ACTION,
    width: 50,
    filters: createTableColumnFilters(data, HISTORY_ACTION),
    onFilter: (value, record) => record[HISTORY_ACTION] === value,
  },
  {
    title: 'Updated By',
    dataIndex: UPDATED_BY,
    key: UPDATED_BY,
    width: 50,
    filters: createTableColumnFilters(data, UPDATED_BY),
    onFilter: (value, record) => record[UPDATED_BY] === value,
  },
  {
    title: 'Updated At',
    dataIndex: UPDATED_AT,
    key: UPDATED_AT,
    width: 50,
    render: dateRender,
    defaultSortOrder: 'descend',
    sorter: (t1, t2) => moment(t1[UPDATED_AT]).unix() - moment(t2[UPDATED_AT]).unix(),
  },
];

export const mediaRequirementColumns = (requirements) => [
  {
    title: 'Pass',
    dataIndex: MEDIA_PASS,
    key: MEDIA_PASS,
    width: 50,
    render: qcTablePassRender,
  },
  {
    title: 'Parameter',
    dataIndex: PARAMETER,
    key: PARAMETER,
    sorter: (p1, p2) => p1[PARAMETER].localeCompare(p2[PARAMETER]),
    width: 150,
  },
  {
    title: 'Value',
    dataIndex: VALUE,
    key: VALUE,
    width: 150,
  },
  {
    title: 'Type',
    dataIndex: TYPE,
    key: TYPE,
    sorter: (t1, t2) => t1[TYPE].localeCompare(t2[TYPE]),
    width: 100,
  },
  {
    title: 'Error Message',
    dataIndex: ERROR_MESSAGE,
    key: ERROR_MESSAGE,
    width: 150,
  },
];

export const vastRequirementColumns = (requirements) => [
  {
    title: 'Pass',
    dataIndex: VAST_PASS,
    key: VAST_PASS,
    width: 50,
    render: qcTablePassRender,
  },
  {
    title: 'Ad ID',
    dataIndex: AD_ID,
    key: AD_ID,
    width: 150,
  },
  {
    title: 'Vast',
    dataIndex: VAST,
    key: VAST,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Ad',
    dataIndex: AD,
    key: AD,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Inline',
    dataIndex: INLINE,
    key: INLINE,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Impression',
    dataIndex: IMPRESSION,
    key: IMPRESSION,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Creative',
    dataIndex: CREATIVE,
    key: CREATIVE,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Linear',
    dataIndex: LINEAR,
    key: LINEAR,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Media File',
    dataIndex: MEDIA_FILE,
    key: MEDIA_FILE,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'VPAID',
    dataIndex: VPAID,
    key: VPAID,
    width: 100,
    render: vastTableValidRender,
  },
];

export const creativeTrackerExportFields = {
  'asset-id': 'Asset Id',
  'source-ad-id': 'Source Ad Id',
  'mediafile-url': 'Mediafile URL',
  'brand-names': 'Brand Names',
  'dsp-name': 'DSP',
  source: 'Source',
  'updated-at': {
    header: 'Updated At',
    formatter: (fieldValue) => moment(fieldValue).local().format('YYYY-MM-DD HH:mm:ss'), // Convert UTC to local time
  },
  'review-status': 'Status',
  detail: { header: 'Status Details', formatter: (fieldValue, record) => formatAssetDetails(record) },
};

const enrichCountryCodes = (codes) => {
  if (!Array.isArray(codes)) {
    return null;
  }

  return codes.map((code) => {
    const countryName = COUNTRY_CODE_TO_NAME_MAP[code];
    return countryName ? `${countryName} (${code})` : code;
  });
};

const creativesTableRowRender = (val, data) =>
  createTableRowToolTip(val, [
    'Ad Id: ' + data[AD_ID_KEY],
    'Source Ad Id: ' + data[SOURCE_AD_ID_KEY],
    'Source Asset Id: ' + data[SOURCE_ASSET_ID_KEY],
    'Mediafile URL: ' + data[MEDIA_FILE_URL_KEY],
    'Brand Names: ' + data[BRAND_NAMES_KEY],
    'Review Status: ' + data[REVIEW_STATUS_KEY],
    'Transcode Status: ' + data[TRANSCODE_STATUS_KEY],
    'Campaign Manager Email: ' + data[CAMPAIGN_MANAGER_NAME_KEY],
    'Content Partner Id: ' + data[CONTENT_PARTNER_ID_KEY],
    'Creative Type: ' + data[CREATIVE_TYPE_KEY],
    'Ingestion Type: ' + data[INGESTION_TYPE_KEY],
    'Updated At: ' + moment(data[UPDATED_AT_KEY]).format('MM/DD/YYYY HH:mm:ss'),
    'Publisher: ' + data[PUBLISHER_KEY],
    'Countries: ' + enrichCountryCodes(data[COUNTRY_CODES_KEY]),
  ]);

const creativesTableBrandsRender = (val, data) => {
  return val
    .filter((v) => v !== null)
    .map((v) => v.replace(/ /g, '\u00A0')) // Replace single spaces
    .join(', ');
};

const creativesTableStatusRender = (status, record) => (
  <Popover
    trigger="hover"
    placement="topLeft"
    overlayStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '30%' }}
    title="Status Detail"
    content={record.detail ? record.detail : 'N/A'}
  >
    <Tag color={STATUSES[status]?.color}>{status}</Tag>
  </Popover>
);

export const creativesColumns = (creatives, widths, statusRowRender) => [
  {
    title: 'Asset Id',
    dataIndex: ASSET_ID_KEY,
    key: ASSET_ID_KEY,
    ellipsis: true,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, ASSET_ID_KEY),
    onFilter: (value, record) => record[ASSET_ID_KEY] === value,
    width: widths[ASSET_ID_KEY] || 100,
  },
  {
    title: 'Source Ad Id',
    dataIndex: SOURCE_AD_ID_KEY,
    key: SOURCE_AD_ID_KEY,
    ellipsis: true,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, SOURCE_AD_ID_KEY),
    onFilter: (value, record) => record[SOURCE_AD_ID_KEY] === value,
    width: widths[SOURCE_AD_ID_KEY] || 100,
  },
  {
    title: 'Mediafile URL',
    dataIndex: MEDIA_FILE_URL_KEY,
    key: MEDIA_FILE_URL_KEY,
    ellipsis: true,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, MEDIA_FILE_URL_KEY),
    onFilter: (value, record) => record[MEDIA_FILE_URL_KEY] === value,
    width: widths[MEDIA_FILE_URL_KEY] || 100,
  },
  {
    title: 'Brand Names',
    dataIndex: BRAND_NAMES_KEY,
    key: BRAND_NAMES_KEY,
    render: creativesTableBrandsRender,
    filters: createTableBrandsColumnFilters(creatives, BRAND_NAMES_KEY),
    onFilter: (value, record) => record[BRAND_NAMES_KEY].includes(value), // TODO step through array
    width: widths[BRAND_NAMES_KEY] || 100,
  },
  {
    title: 'DSP',
    dataIndex: DSP_KEY,
    key: DSP_KEY,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, DSP_KEY),
    onFilter: (value, record) => record[DSP_KEY] === value,
    width: widths[DSP_KEY] || 100,
  },
  {
    title: 'Source',
    dataIndex: SOURCE_KEY,
    key: SOURCE_KEY,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, SOURCE_KEY),
    onFilter: (value, record) => record[SOURCE_KEY] === value,
    width: widths[SOURCE_KEY] || 100,
  },
  {
    title: 'Updated At',
    dataIndex: UPDATED_AT_KEY,
    key: UPDATED_AT_KEY,
    render: (updateAt) => moment(updateAt).format('MM/DD/YYYY HH:mm:ss'),
    sorter: (a, b) => moment(a[UPDATED_AT_KEY]).valueOf() - moment(b[UPDATED_AT_KEY]).valueOf(),
    defaultSortOrder: 'descend',
    width: widths[UPDATED_AT_KEY] || 100,
  },
  {
    title: 'Status',
    dataIndex: ASSET_DETAILS_KEY[REVIEW_STATUS_KEY],
    key: REVIEW_STATUS_KEY,
    render: statusRowRender,
    filters: createTableColumnFilters(creatives, REVIEW_STATUS_KEY),
    onFilter: (value, record) => record[REVIEW_STATUS_KEY] === value,
    align: 'center',
    width: widths[REVIEW_STATUS_KEY] || 100,
  },
];

export const bulkUploaddurationRulesColumns = (dataSource, widths) => [
  {
    title: 'Stream Type',
    dataIndex: STREAM_TYPE,
    key: STREAM_TYPE,
    filters: createTableColumnFilters(dataSource, RULE_TYPE),
    onFilter: (value, record) => record[RULE_TYPE] === value,
    width: widths[0] || 150,
  },
  {
    title: 'Rule Type',
    dataIndex: RULE_TYPE,
    key: RULE_TYPE,
    filters: createTableColumnFilters(dataSource, RULE_TYPE),
    onFilter: (value, record) => record[RULE_TYPE] === value,
    width: widths[1] || 150,
  },
  {
    title: 'Channel Id',
    dataIndex: CHANNEL_ID,
    key: CHANNEL_ID,
    filters: createTableColumnFilters(dataSource, CHANNEL_ID),
    onFilter: (value, record) => record[CHANNEL_ID] === value,
    width: widths[2] || 300,
  },
  {
    title: 'Series Id',
    dataIndex: SERIES_ID,
    key: SERIES_ID,
    filters: createTableColumnFilters(dataSource, SERIES_ID),
    onFilter: (value, record) => record[SERIES_ID] === value,
    width: widths[3] || 300,
  },
  {
    title: 'League Id',
    dataIndex: LEAGUE_ID,
    key: LEAGUE_ID,
    filters: createTableColumnFilters(dataSource, LEAGUE_ID),
    onFilter: (value, record) => record[LEAGUE_ID] === value,
    width: widths[4] || 300,
  },
  {
    title: 'Event Id',
    dataIndex: EVENT_ID,
    key: EVENT_ID,
    filters: createTableColumnFilters(dataSource, EVENT_ID),
    onFilter: (value, record) => record[EVENT_ID] === value,
    width: widths[5] || 300,
  },
  {
    title: 'Sports Id',
    dataIndex: SPORTS_ID,
    key: SPORTS_ID,
    filters: createTableColumnFilters(dataSource, SPORTS_ID),
    onFilter: (value, record) => record[SPORTS_ID] === value,
    width: widths[6] || 300,
  },
  {
    title: 'Pod Durations (ms)',
    dataIndex: DURATION_MS,
    key: DURATION_MS,
    render: (durations) => durations.map((duration) => <Tag key={duration}>{duration}</Tag>),
    width: widths[7] || 150,
  },
  {
    title: (
      <Popover
        trigger="hover"
        placement="right"
        overlayStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '300px' }}
        title={<h3>Usage</h3>}
        content={'This field is exclusively applicable in CHANNEL rules. For any other rules, it will be disregarded.'}
      >
        <span>Single Program </span>
        <InfoCircleOutlined />
      </Popover>
    ),
    dataIndex: SINGLE_PROGRAM,
    key: SINGLE_PROGRAM,
    render: (val) => (val === undefined ? '' : val.toString()),
    width: widths[8] || 100,
  },
  {
    title: 'Partial Pod',
    dataIndex: PARTIAL_POD,
    key: PARTIAL_POD,
    render: (val) => (val === undefined ? '' : val.toString()),
    width: widths[9] || 100,
  },
  {
    title: 'Change Type',
    dataIndex: CHANGE_TYPE,
    key: CHANGE_TYPE,
    filters: createTableColumnFilters(dataSource, CHANGE_TYPE),
    width: widths[10] || 100,
    onFilter: (value, record) => record[CHANGE_TYPE] === value,
  },
];

export const reservationAuditsColumns = (dataSource, widths) => [
  {
    title: 'Last Updated',
    dataIndex: 'lastUpdated',
    key: 'lastUpdated',
    sorter: (a, b) => moment(a.lastUpdated).unix() - moment(b.lastUpdated).unix(),
    defaultSortOrder: 'descend',
    render: dateRender,
    width: widths[0] || 100,
  },
  {
    title: 'User Name',
    dataIndex: 'username',
    key: 'username',
    filters: createTableColumnFilters(dataSource, 'username'),
    onFilter: (value, record) => String(record.username) === value,
    width: widths[1] || 100,
  },
  {
    title: 'Content Partner',
    dataIndex: 'contentPartnerName',
    key: 'contentPartnerName',
    filters: createTableColumnFilters(dataSource, 'contentPartnerName'),
    onFilter: (value, record) => String(record.contentPartnerName) === value,
    width: widths[2] || 100,
  },
  {
    title: 'Field',
    dataIndex: 'field',
    key: 'field',
    filters: createTableColumnFilters(dataSource, 'field'),
    onFilter: (value, record) => String(record.field) === value,
    width: widths[3] || 100,
  },
  {
    title: 'Old Value',
    dataIndex: 'oldValue',
    key: 'oldValue',
    width: widths[4] || 100,
  },
  {
    title: 'New Value',
    dataIndex: 'newValue',
    key: 'newValue',
    width: widths[5] || 100,
  },
  {
    title: 'Date Created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sorter: (a, b) => moment(a.dateCreated).unix() - moment(b.dateCreated).unix(),
    render: dateRender,
    width: widths[6] || 100,
  },
];

export const reserveSplitsEpisodesColumns = (handleCheckbox, handleSelect) => [
  {
    title: 'Content Partner Name',
    dataIndex: 'cpName',
    key: 'cpName',
    width: 50,
  },
  {
    title: 'Series Name',
    dataIndex: 'seriesIdentifierName',
    key: 'seriesIdentifierName',
    width: 50,
  },
  {
    title: 'Select All Episodes',
    dataIndex: 'checkbox',
    key: 'checkbox',
    width: 50,
    // eslint-disable-next-line react/display-name
    render: (_) => (
      <Checkbox index={_.series} series={_.series} onChange={handleCheckbox}>
        Use All Episodes
      </Checkbox>
    ),
  },
  {
    title: 'Specify Episodes',
    key: 'select',
    dataIndex: 'select',
    width: 200,
    // eslint-disable-next-line react/display-name
    render: (_) => (
      <Select
        mode="multiple"
        placeholder="Select episodes"
        defaultValue={_.selectedEpisodes}
        style={{ width: '100%' }}
        onChange={handleSelect}
        key={_.index}
        disabled={_.isSelectedAll}
      >
        {_.allEpisodes.map((episode) => (
          <Option key={episode} value={episode} series={_.series}>
            {episode}
          </Option>
        ))}
      </Select>
    ),
  },
];

// AUCTION SNAPSHOT
export const adRequestSnapshotColumns = (adRequestSnapshotActions, sortOrder) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'snapshotName',
    width: '27%',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder: sortOrder['snapshotName'],
    ellipsis: true,
    render: (text, record) => editableSnapshotNameCell(text, record, adRequestSnapshotActions),
  },
  {
    title: 'Date',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    sortOrder: sortOrder['date'],
    dataIndex: 'date',
    key: 'date',
    width: '20%',
    render: formatDate,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    render: (text) => snapshotStatuses(text),
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: '13%',
    render: (data) => data && `${data.firstName} ${data.secondName}`,
  },
  {
    title: 'Returned Auctions',
    dataIndex: 'returnedAuctions',
    key: 'returnedAuctions',
    width: '11%',
  },
  {
    title: '',
    dataIndex: 'controlBtn',
    key: 'controlBtn',
    width: '15%',
    render: (text, record) => auctionSnapshotActions(adRequestSnapshotActions, record),
  },
];

export const auctionDetailsColumns = [
  {
    title: '',
    key: 'index',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Name',
    dataIndex: 'externalAuctionId',
    key: 'externalAuctionId',
    width: '27%',
    render: (text, record) => (
      <StyledAuctionName
        href={`${AUCTION_SNAPSHOT_DETAILED_VIEW}/${record.id}`}
        title="External Auction ID"
        rel="noreferrer"
      >
        {text}
      </StyledAuctionName>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '22%',
    render: formatDate,
  },
  {
    title: 'Attempted',
    dataIndex: 'attempted',
    key: 'attempted',
    width: '13%',
  },
  {
    title: 'Returned',
    dataIndex: 'returned',
    key: 'returned',
    width: '12%',
  },
  {
    title: 'Publisher',
    dataIndex: 'appName',
    key: 'appName',
    width: '11%',
  },
  {
    title: 'Device Name',
    dataIndex: 'deviceFamily',
    key: 'deviceFamily',
    width: '11%',
  },
];

export const vastTrackerColumns = (columns, widths) =>
  columns.map((column, index) => ({ ...column, width: index === 0 ? widths[0] || 300 : widths[index] || 125 }));
