import axios from 'axios';
import qs from 'qs';
import { Auth } from 'aws-amplify';
import { AUCTION_SNAPSHOT_API, BAD_REQUEST, SERVER_ERROR, NOT_FOUND } from '../constants';

const cacheMap = {};

const getAuthHeaders = async () => ({
  Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
});

export const createSnapshot = async (snapshotCreateDto) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.post(`${AUCTION_SNAPSHOT_API}/snapshots`, snapshotCreateDto, { headers });
    return data;
  } catch (err) {
    let errorMessage = 'Something went wrong, please try again later';
    if (err.status >= 500) {
      errorMessage = 'Internal Server Error';
    }
    throw errorMessage;
  }
};

export const getSnapshots = async (params) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/snapshots`, {
      headers,
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, {
          arrayFormat: 'repeat',
          skipNulls: true,
        }),
    });
    return data;
  } catch (err) {
    console.error('GET snapshots failed with: ', err?.message || err);

    let errorMessage = 'Something went wrong, please try again later';
    if (err.status === BAD_REQUEST) {
      errorMessage = 'Invalid Payload';
    }
    if (err.status === NOT_FOUND) {
      errorMessage = 'Not Found';
    }
    if (err.status >= SERVER_ERROR) {
      errorMessage = 'Internal Server Error';
    }
    throw errorMessage;
  }
};

export const getSnapshotById = async (snapshotId) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/snapshots/${snapshotId}`, { headers });
    return data;
  } catch (err) {
    console.error('GET snapshot by id failed with: ', err?.message || err);

    let errorMessage = 'Something went wrong, please try again later';
    if (err.status === BAD_REQUEST) {
      errorMessage = 'Invalid Payload';
    }
    if (err.status === NOT_FOUND) {
      errorMessage = 'Not Found';
    }
    if (err.status >= SERVER_ERROR) {
      errorMessage = 'Internal Server Error';
    }
    throw errorMessage;
  }
};

export const getSnapshotRawData = async (snapshotId, type) => {
  try {
    const headers = await getAuthHeaders();
    const params = { sourceType: type };
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/snapshots/${snapshotId}/rawData`, { headers, params });
    return data;
  } catch (err) {
    console.error('GET snapshot raw data failed with: ', err?.message || err);

    let errorMessage = 'Something went wrong, please try again later';
    if (err.status === BAD_REQUEST) {
      errorMessage = 'Invalid Payload';
    }
    if (err.status === NOT_FOUND) {
      errorMessage = 'Not Found';
    }
    if (err.status >= SERVER_ERROR) {
      errorMessage = 'Internal Server Error';
    }
    throw errorMessage;
  }
};

export const deleteSnapshot = async (params = {}) => {
  try {
    const headers = await getAuthHeaders();
    await axios.delete(`${AUCTION_SNAPSHOT_API}/snapshots/${params.snapshotId}`, { headers });
  } catch (err) {
    throw err;
  }
};

export const getUsers = async () => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/users`, { headers });
    return data;
  } catch (err) {
    throw err;
  }
};

export const createProfile = async (values) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.post(`${AUCTION_SNAPSHOT_API}/profiles`, values, { headers });
    return data;
  } catch (err) {
    let errorMessage = 'Something went wrong, please try again later';
    if (err.response.data.statusCode === BAD_REQUEST) {
      errorMessage = err.response.data.message;
    }
    if (err.response.data.statusCode >= SERVER_ERROR) {
      errorMessage = 'Internal Server Error';
    }
    throw errorMessage;
  }
};

export const updateProfile = async (id, values) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.put(`${AUCTION_SNAPSHOT_API}/profiles/${id}`, values, { headers });
    return data;
  } catch (err) {
    let errorMessage = 'Something went wrong, please try again later';
    if (err.response.data.statusCode === BAD_REQUEST) {
      errorMessage = err.response.data.message;
    }
    if (err.response.data.statusCode >= SERVER_ERROR) {
      errorMessage = 'Internal Server Error';
    }
    throw errorMessage;
  }
};

export const getProfiles = async (params) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/profiles`, { headers, params });
    return data;
  } catch (err) {
    throw err;
  }
};

export const getProfilesTypes = async () => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/profiles/selectTypes`, { headers });
    return data;
  } catch (err) {
    throw err;
  }
};

export const deleteProfile = async (params = {}) => {
  try {
    const headers = await getAuthHeaders();
    await axios.delete(`${AUCTION_SNAPSHOT_API}/profiles/${params.deleteProfileId}`, { headers });
  } catch (err) {
    throw err;
  }
};

export const getAuctionById = async (auctionId) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/snapshots/auctions/${auctionId}`, { headers });
    return data;
  } catch (err) {
    console.error('GET auction by Id failed with: ', err?.message || err);
    throw err;
  }
};

export const getSupplyAuctionRawData = async (auctionId) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/snapshots/auctions/${auctionId}/rawData/supply`, {
      headers,
    });
    return data;
  } catch (err) {
    console.error('GET auction raw data failed with: ', err?.message || err);
    throw err;
  }
};

export const getDemandAuctionRawData = async (auctionId) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/snapshots/auctions/${auctionId}/rawData/demand`, {
      headers,
    });
    return data;
  } catch (err) {
    console.error('GET auction raw data failed with: ', err?.message || err);
    throw err;
  }
};

export const getBidsByAuction = async (auctionId) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/snapshots/bids/${auctionId}`, { headers });
    return data;
  } catch (err) {
    console.error('GET bids by auctionId failed with: ', err?.message || err);
    throw err;
  }
};

export const getSnapshotParameters = async () => {
  try {
    const key = getSnapshotParameters.name;
    const cachedData = cacheMap[key];
    if (cachedData) {
      return cachedData;
    }
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/snapshots/parameters`, { headers });
    cacheMap[key] = data;
    return data;
  } catch (err) {
    console.error('GET snapshot parameter failed with: ', err?.message || err);

    let errorMessage = 'Something went wrong, please try again later';
    if (err?.status === BAD_REQUEST) {
      errorMessage = 'Invalid Payload';
    }
    if (err?.status === NOT_FOUND) {
      errorMessage = 'Not Found';
    }
    if (err?.status >= SERVER_ERROR) {
      errorMessage = 'Internal Server Error';
    }
    throw errorMessage;
  }
};

export const getDealDetailsById = async (dealId) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.get(`${AUCTION_SNAPSHOT_API}/deals/${dealId}`, { headers });
    return data;
  } catch (err) {
    console.error('GET deal details by dealId failed with: ', err?.message || err);
    throw err;
  }
};

export const editSnapshotName = async (snapshotId, newName) => {
  try {
    const headers = await getAuthHeaders();
    const { data } = await axios.patch(
      `${AUCTION_SNAPSHOT_API}/snapshots/${snapshotId}`,
      {
        op: 'replace',
        path: '/name',
        value: newName,
      },
      {
        headers,
      }
    );
    return data;
  } catch (err) {
    console.error('PATCH snapshot name by id failed with: ', err?.message || err);
    throw err;
  }
};
