import styled from 'styled-components';
import { Card } from 'antd';
import React from 'react';

export const StyledCard = styled(Card)`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  cursor: pointer;
  width: 300px;
  border-radius: 0.5em;
  transition: transform 0.45s ease, background 0.45s;
  padding: 10px;
  margin: 20px;

  &:hover {
    background: #ab87d6;
    transform: scale(1.02);

    h3 {
      color: #ffffff;
      border-bottom-color: #a754c4;
    }

    p {
      color: #ffffff;
      opacity: 1;
      transform: none;
    }
  }
`;
export const CardWrapper = ({ children, ...rest }) => <StyledCard {...rest}>{children}</StyledCard>;

export const ToolSelectionWrapper = styled.div`
  padding: 50px 120px;
`;

export const CardTitleWrapper = styled.h3`
  color: #2e3c40;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 1.5em;
  border-bottom: 2px solid #753bbd;
  transition: color 0.45s ease, border 0.45s ease;
`;

export const DescriptionWrapper = styled.p`
  font-weight: 600;
  line-height: 1.8;
  transform: translateY(-0.25em);
  transition: opacity 0.45s ease, transform 0.5s ease;
`;

export const LinkWrapper = styled.p`
  color: #753bbd;
  font-size: 1.125em;
  font-weight: 600;
  transition: color 0.45s ease;
`;

export const CardGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
