import { useState, useEffect } from 'react';
import { getSnapshotRawData } from '../../../services/auctionSnapshot';
import { parseRawRecord } from '../parseRawRecord';
import { defaultAdTagsParameterSummaryMap } from './useAdTagsParameterSummaryResolver';

export const useAdTagsParameterSummary = () => {
  const [snapshotId, setSnapshotId] = useState(null);
  const [snapshotSummaryLoading, setSnapshotSummaryLoading] = useState(false);
  const [snapshotSummaryError, setSnapshotSummaryError] = useState(null);
  const [isSnapshotSummaryInfoOpen, setIsSnapshotSummaryInfoOpen] = useState(false);
  const [snapshotSummaryInfo, setSnapshotSummaryInfo] = useState([]);
  const [activePanel, setActivePanel] = useState([]);

  const openSnapshotSummaryInfoDrawer = ({ id, name, supplyParameters }) => {
    setSnapshotId(id);
    setSnapshotSummaryLoading(true);
    getSnapshotRawData(id, supplyParameters?.supplySource)
      .then((data) => data.map((record) => parseRawRecord(record)))
      .then((data) => setSnapshotSummaryInfo({ name, rawData: data, supplySource: supplyParameters?.supplySource }))
      .catch(setSnapshotSummaryError)
      .finally(() => setSnapshotSummaryLoading(false));
    setIsSnapshotSummaryInfoOpen(true);
  };
  const closeSnapshotSummaryInfoDrawer = () => setIsSnapshotSummaryInfoOpen(false);

  const onCollapsePanelChange = (key) => setActivePanel(key);
  const onExpandClick = () =>
    setActivePanel(Object.keys(defaultAdTagsParameterSummaryMap[snapshotSummaryInfo?.supplySource]));
  const onCollapseClick = () => setActivePanel([]);

  useEffect(() => {
    setSnapshotSummaryError(null);
    setSnapshotSummaryInfo([]);
  }, [snapshotId]);

  return {
    snapshotSummaryLoading,
    snapshotSummaryError,
    isSnapshotSummaryInfoOpen,
    snapshotSummaryInfo,
    activePanel,

    openSnapshotSummaryInfoDrawer,
    closeSnapshotSummaryInfoDrawer,
    onCollapsePanelChange,
    onExpandClick,
    onCollapseClick,
  };
};
