import React, { useState } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';

import { Spin, Transfer } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import AddAccount from '../AddAccount';
import { ButtonWrapper } from './styles';
import { LINK_NEW_ACCOUNT, NEW_TEST_CASE } from '../../../../constants';
import { NEWTESTCASE_TRANSFER } from '../../../../testUtils';

const TRANSFER_LIST_STYLE = {
  width: 400,
  height: 400,
  textAlign: 'left',
};

const SelectAccount = ({
  dispatch,
  dataSource = [],
  linkAccountModalOpen,
  setLinkAccountModalOpen,
  targetKeys = [],
  setTargetKeys,
  searchParams = {},
  loading,
}) => {
  const [sourceSelectedKeys, setSourceSelectedKeys] = useState([]);

  const handleChange = (keys) => {
    dispatch({
      type: 'app/setSearchParams',
      payload: { ...searchParams, accounts: keys.join(',') },
    });
    setTargetKeys(keys);
  };
  const handleSelectChange = (sourceKeys) => setSourceSelectedKeys(sourceKeys);
  const openAddModal = () => {
    dispatch(routerRedux.push('/' + NEW_TEST_CASE + '/' + LINK_NEW_ACCOUNT));
    setLinkAccountModalOpen(true);
  };
  const removeAccounts = () => dispatch({ type: 'newTestCase/removeAccount', payload: { sourceSelectedKeys } });

  const renderFooter = ({ titleText }) => {
    return (
      titleText === 'Available' && (
        <div className="footer">
          <ButtonWrapper onClick={openAddModal} icon={<PlusOutlined />}>
            Link New Account
          </ButtonWrapper>
          <ButtonWrapper onClick={removeAccounts} icon={<MinusOutlined />}>
            Unlink Selected Account
          </ButtonWrapper>
        </div>
      )
    );
  };

  const transferRowRender = (item) => {
    let toRender = ``;
    if (item.subscription) {
      // Hulu
      const subscriptionDetail = item.subscription.packages.map((sub) => sub.value).join('|');
      toRender = `${item.profileName} | ${item.accountName} - ${subscriptionDetail}`;
    } else {
      // Disney
      toRender = `${item.profileName} | ${item.accountId}`;
    }
    return toRender;
  };

  return (
    <Spin spinning={loading}>
      <div data-testid={NEWTESTCASE_TRANSFER}>
        <Transfer
          dataSource={dataSource}
          showSearch
          titles={['Available', 'Selected']}
          operations={['select', 'remove']}
          listStyle={TRANSFER_LIST_STYLE}
          targetKeys={targetKeys}
          onSelectChange={handleSelectChange}
          onChange={handleChange}
          render={transferRowRender}
          footer={renderFooter}
        />
        <AddAccount dispatch={dispatch} open={linkAccountModalOpen} setModalOpen={setLinkAccountModalOpen} />
      </div>
    </Spin>
  );
};

function mapStateToProps(state) {
  const loadingEffects = state.loading.effects;
  const { accountProfileCombinations } = state.newTestCase;
  return {
    dataSource: accountProfileCombinations,
    searchParams: state.app.searchParams,
    loading: loadingEffects['newTestCase/fetchLinkedHuluAccounts'],
  };
}

export default connect(mapStateToProps)(SelectAccount);
