import React, { useEffect, useRef } from 'react';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';
import AccountMenuDropdown from './AccountMenuDropdown';
import { StyledNavBar, SkipLink } from './styles';
import { NAV_HEADER, NAV_SKIP } from '../../testUtils';
import PageNavDropdown from './PageNavDropdown';
import Feedback from './FeedbackBtn';
import TicketAlert from './TicketAlert';

const NavHeader = ({ dispatch, authenticated, selectedTab, permissions, ticketId }) => {
  const navHeaderRef = useRef(null);

  useEffect(() => {
    if (selectedTab) {
      dispatch(routerRedux.push('/' + selectedTab));
    }
  }, [selectedTab, dispatch]);

  const handleCloseAlert = () => {
    dispatch({ type: 'app/closeTicketAlert', payload: null });
  };

  const onSkipLinkClick = () => {
    const mainContent = document.querySelector('main:first-of-type');
    if (mainContent) {
      mainContent.tabIndex = -1;
      mainContent.focus();
      setTimeout(() => mainContent.removeAttribute('tabindex'), 1000);
    }
  };

  return (
    <>
      <header ref={navHeaderRef} data-testid={NAV_HEADER}>
        <SkipLink data-testid={NAV_SKIP} href="#" onClick={onSkipLinkClick}>
          Skip to main content
        </SkipLink>
        <StyledNavBar
          leftChildren={authenticated && <PageNavDropdown permissions={permissions} />}
          appName="Ad Tools"
          rightChildren={authenticated && [<div />, <Feedback />, <AccountMenuDropdown />]}
          logo={
            <img
              height="50px"
              alt={'Disney Entertainment & ESPN Technology'}
              src="/logos/DE&E_Technology_Logo_1-Line_White.svg"
              width="100%"
            />
          }
        />
      </header>
      {ticketId && <TicketAlert ticketId={ticketId} onClose={handleCloseAlert} />}
    </>
  );
};

function mapStateToProps({ app }) {
  return {
    authenticated: app.authenticated,
    selectedTab: app.selectedTab,
    permissions: app.currentUser.permissions,
    ticketId: app.ticketId,
  };
}

export default connect(mapStateToProps)(NavHeader);
