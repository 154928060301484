import React from 'react';
import { colors } from '@hulu/design-tokens';

export const DataActivityIcon = ({ onClick, disabled }) => {
  const color = disabled ? colors.colorGray13 : '#040405';
  return (
    <svg onClick={onClick} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.06325 0.00201496C7.48554 0.02878 7.84528 0.318434 7.96152 0.725291L11.1888 12.0208L13.0809 7.60609C13.2384 7.23841 13.6 7.00001 14 7.00001H17C17.5523 7.00001 18 7.44773 18 8.00001C18 8.5523 17.5523 9.00001 17 9.00001H14.6594L11.9191 15.3939C11.7525 15.7829 11.359 16.0248 10.9367 15.998C10.5145 15.9712 10.1547 15.6816 10.0385 15.2747L6.81118 3.97919L4.91915 8.39393C4.76157 8.76162 4.40003 9.00001 4 9.00001H1C0.447715 9.00001 0 8.5523 0 8.00001C0 7.44773 0.447715 7.00001 1 7.00001H3.3406L6.08085 0.606093C6.24754 0.217168 6.64096 -0.0247501 7.06325 0.00201496Z"
        fill={color}
      />
    </svg>
  );
};
