import styled, { createGlobalStyle } from 'styled-components';
import { Button, Layout, Modal } from 'antd';
import { colors } from '@hulu/design-tokens';

const { Footer, Content } = Layout;

export const GlobalStyle = createGlobalStyle`
  
  //*** Antd global styles update ***
  .ant-select-dropdown {
    .ant-select-item-option:hover {
      background: ${colors.colorGray03};
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background: ${colors.colorBlue05};
    }
  }
  //*** Antd global styles update (END) ***
  
  // primary button color
  .ant-btn-primary {
      background-color: black;
      border: none;
  }
  
  #root{
    width: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:
            Graphik,
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            Oxygen,
            Ubuntu,
            Cantarell,
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: fallback;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  a {
    color: ${colors.colorBlue50};
    &:hover {
      color: ${colors.colorBlue40};
    }
    &:active {
      color: ${colors.colorBlue50};
    }
  }
   
  // Antd Datepicker Global styles override
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${colors.colorGray05} !important;
  }
  .ant-picker-cell-range-hover-start > .ant-picker-cell-inner::after {
    background: ${colors.colorGray13} !important;
    right: 0 !important;
    border-radius: 50% !important;
  }
  .ant-picker-cell-in-view,.ant-picker-cell-range-start,
  .ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      border-radius: 50% !important;
      line-height: 26px !important;
      min-width: 25px !important;
      &:before {
        border-radius: 50% !important;
      }
    }
  }
  .ant-picker-preset .ant-tag {
    background: ${colors.colorGray13} !important;
  }

  .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: ${colors.colorGray05};
  }
  
  // Antd Transfer List
  .ant-transfer-list-content-item-checked {
    background: ${colors.colorGray13};
  }
  .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
    background: ${colors.colorGray30};
  }

  // Antd Table Styles Override
  .ant-table-tbody>tr.ant-table-row-selected>td {
    background: ${colors.colorGray05} !important;
  }
  
  // Antd Dropdown Styles Override
  .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
    background: ${colors.colorGray05} !important;
  }
  
  // Antd table bug fix
  .ant-table-column-title {
    z-index: 0;
  }
  
  .TrekButton-normal {
    // same as colors.colorBlack
    background: #000000;
    
    &:hover {
      // same as colors.colorBlack70
      background: #3D4451;
    }
  }
  
  .TrekButton-disabled {
    background: #272c3580 !important;
  }
`;

export const AppFooter = styled(Footer)`
  width: 100%;
  text-align: center;
`;

export const MainLayout = styled(Layout)`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const ContentLayout = styled(Content)`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const DMED_OrbitBlue = '#0072ED';

export const StyledModal = styled(Modal)`
  .ant-modal-close:focus-visible {
    outline: 4px solid ${DMED_OrbitBlue};
    outline-offset: -4px;
  }
`;

export const StyledButton = styled(Button)`
  :focus-visible {
    outline: 4px solid ${DMED_OrbitBlue};
    outline-offset: -2px;
  }
`;
