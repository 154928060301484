import React from 'react';
import { Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { Typography } from '@hulu/react-style-components';

import { HuluNormalButtonCapitalize } from '../AuctionSnapshot';
import { SearchInput } from '../AuctionSnapshotTable/SearchInput';
import { ProfileTable } from './ProfileTable';

const StyledProfileList = styled.div`
  padding: 0 10px 0 20px;
  & > a {
    width: 160px;
    margin: 20px 0 20px auto;
  }
`;

const StyledFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const StyledToggleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  & > .ant-switch {
    margin: 0 12px 0 4px;
  }
`;

export const ProfilesList = ({
  onOpenNewProfile,
  search,
  isShowAllProfiles,
  onToggleMyProfiles,
  onSearch,
  profilesControl,
  otherProfilesControl,
}) => {
  const {
    profiles,
    pagination,
    loading,
    onPaginationChange,
    onTableChange,
    openUpdateProfile,
    onOpenProfileDeleteModal,
  } = profilesControl;

  const { otherProfiles, otherProfilesPagination, otherProfilesLoading, onOtherPaginationChange, onOtherTableChange } =
    otherProfilesControl;

  return (
    <StyledProfileList>
      <StyledFilterWrapper>
        <StyledToggleWrapper>
          <Switch checked={isShowAllProfiles} defaultChecked={false} onChange={onToggleMyProfiles} />
          <Typography variant="body3">All Profiles</Typography>
        </StyledToggleWrapper>
        <HuluNormalButtonCapitalize variant="normal" size="medium" onClick={onOpenNewProfile}>
          New Profile
        </HuluNormalButtonCapitalize>
      </StyledFilterWrapper>
      <SearchInput
        width="100%"
        allowClear
        prefix={<SearchOutlined style={{ fontSize: '14px' }} />}
        searchValue={search}
        onChange={onSearch}
      />
      <ProfileTable
        tableName="My Profiles"
        profiles={profiles}
        loading={loading}
        isCurrentUser={true}
        pagination={pagination}
        onTableChange={onTableChange}
        onPaginationChange={onPaginationChange}
        onOpenUpdateProfile={openUpdateProfile}
        onOpenProfileDeleteModal={onOpenProfileDeleteModal}
      />
      {isShowAllProfiles && (
        <ProfileTable
          tableName="All Profiles"
          profiles={otherProfiles}
          loading={otherProfilesLoading}
          isCurrentUser={false}
          pagination={otherProfilesPagination}
          onTableChange={onOtherTableChange}
          onPaginationChange={onOtherPaginationChange}
        />
      )}
    </StyledProfileList>
  );
};
