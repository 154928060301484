import React from 'react';

import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';

export const SummaryCard = ({ isLoading, data }) => {
  return (
    <StyledCard title={`Summary ${data.title}`} loading={isLoading}>
      {data.code !== null && data.code !== undefined && <RowInfo title="HTTP Response Code" data={data.code} />}
      {data.status && <RowInfo title="Response Status" data={data.status} />}
      {data.message && <RowInfo title="Message" data={data?.message} />}
      {data.bids && <RowInfo title="Bids" data={data.bids} />}
      {data.region && <RowInfo title="Region" data={data.region} />}
      {data.requestId && <RowInfo title="Request ID" data={data.requestId} />}
    </StyledCard>
  );
};
