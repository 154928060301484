import React from 'react';
import envConfig from '@hulu/env-config';
import { StyledAlert } from './styles';

function TicketAlert({ ticketId, onClose }) {
  const zendeskTicketUrl = `${envConfig.REACT_APP_ZENDESK_TICKET_BASE_URL}/hc/en-us/requests/${ticketId}`;

  return (
    <StyledAlert
      message={`Your ticket has been created successfully!`}
      description={
        <a href={zendeskTicketUrl} target="_blank" rel="noopener noreferrer" aria-label="View Ticket">
          View Ticket
        </a>
      }
      type="success"
      showIcon
      closable
      onClose={onClose}
    />
  );
}

export default TicketAlert;
