import React, { useState } from 'react';
import { Button, Steps } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import moment from 'moment';

import SelectHuluAccount from './SelectAccount';
import SelectTestingFeature from './SelectTestingFeature';
import { NewTestCaseWrapper, StepsAction, StepsContent } from './styles';
import { ADS_TRACER } from '../../../constants';
import { NEWTESTCASE_STEPS, NEWTESTCASE_STEPS_ACTION, NEWTESTCASE_STEPS_CONTENT } from '../../../testUtils';

const { Step } = Steps;
const BUTTON_MARGIN_RIGHT = { marginRight: 8 };

const NewTestCase = ({ dispatch, searchParams = {}, pathParams = {} }) => {
  // Process Query & Path Params for Preset states
  const isSelectTestingFeatureStep = 'accounts' in searchParams && 'expTime' in searchParams;
  const [currentStep, setCurrentStep] = useState(isSelectTestingFeatureStep ? 1 : 0);
  const [targetKeys, setTargetKeys] = useState(
    searchParams?.accounts ? searchParams?.accounts.split(',').map((e) => parseInt(e)) : []
  );
  const [linkAccountModalOpen, setLinkAccountModalOpen] = useState(pathParams?.addAccount ?? false);
  const [endAt, setEndAt] = useState(searchParams?.expTime ?? moment().add(1, 'days'));

  const backStep = () => {
    if (currentStep === 0) {
      dispatch(routerRedux.push('/' + ADS_TRACER));
      dispatch({ type: 'newTestCase/clearState' });
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const forwardStep = () => {
    if (
      currentStep === 0 &&
      dispatch({ type: 'newTestCase/verifySelectedAccounts', payload: { targetKeys, currentStep } })
    ) {
      setCurrentStep(currentStep + 1);
    } else {
      dispatch({ type: 'newTestCase/createNewTestCase', payload: { targetKeys, endAt } });
    }
  };

  const steps = [
    {
      title: 'Select Account(s)',
      content: () => (
        <SelectHuluAccount
          targetKeys={targetKeys}
          setTargetKeys={setTargetKeys}
          linkAccountModalOpen={linkAccountModalOpen}
          setLinkAccountModalOpen={setLinkAccountModalOpen}
        />
      ),
    },
    {
      title: 'Set Test case Expiration Time',
      content: () => <SelectTestingFeature targetKeys={targetKeys} endAt={endAt} setEndAt={setEndAt} />,
    },
  ];

  return (
    <NewTestCaseWrapper>
      <Steps progressDot current={currentStep} data-testid={NEWTESTCASE_STEPS}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <StepsContent data-testid={NEWTESTCASE_STEPS_CONTENT}>{steps[currentStep].content()}</StepsContent>

      <StepsAction data-testid={NEWTESTCASE_STEPS_ACTION}>
        <Button style={BUTTON_MARGIN_RIGHT} onClick={backStep}>
          {currentStep > 0 ? 'Back' : 'Cancel'}
        </Button>

        <Button onClick={forwardStep}>{currentStep === steps.length - 1 ? 'Submit' : 'Next'}</Button>
      </StepsAction>
    </NewTestCaseWrapper>
  );
};

function mapStateToProps(state) {
  return {
    searchParams: state.app.searchParams,
    pathParams: state.app.pathParams,
  };
}

export default connect(mapStateToProps)(NewTestCase);
