import { Button, Form, Input, Space, Radio, Alert } from 'antd';
import { connect } from 'dva';
import React, { useState } from 'react';
import {
  Q_CHECK_VAST_XML_SUBMIT,
  Q_CHECK_VAST_XML_FORM,
  Q_CHECK_VAST_XML_URL_PLACEHOLDER,
  Q_CHECK_VAST_URL_BUTTON,
  Q_CHECK_VAST_XML_BUTTON,
  VAST_URL,
  VAST_XML,
  Q_CHECK_VAST_XML_TEXTAREA,
  Q_CHECK_ERROR,
} from '../../testUtils';
import { VastXmlWrapper } from './styles';
import VastXMLIngestionBox from './VastXmlIngestionBox';

const VastValidationBox = ({
  dispatch,
  setVastXmlValidating,
  vastValidationError,
  vastValidationRecords,
  permissions,
}) => {
  const [validationType, setValidationType] = useState('vasturl');
  const [vastXml, setVastXml] = useState('');
  const [isIngestButtonClicked, setIngestButtonClicked] = useState(false);
  const [form] = Form.useForm();
  const resetFields = () => {
    dispatch({ type: 'qCheck/resetVastXmlTab' });
    form.resetFields();
  };

  const onFinish = async (value) => {
    setVastXmlValidating(true);
    await dispatch({ type: 'qCheck/resetVastXmlTab' });
    await dispatch({
      type: 'qCheck/validateVast',
      payload: { ...value, validationType },
    });
    setVastXmlValidating(false);
    if (validationType === VAST_XML) {
      setVastXml(value.payload);
      setIngestButtonClicked(false);
    }
  };

  const vastXmlUrlFillout = (
    <Form.Item name="payload">
      <Input placeholder="Vast Tag URL" data-testid={Q_CHECK_VAST_XML_URL_PLACEHOLDER} />
    </Form.Item>
  );

  const vastXmlFillout = (
    <Form.Item name="payload">
      <Input.TextArea
        placeholder="<Paste>&#10;<VAST XML>&#10;<Here>"
        autoSize={{ minRows: 20, maxRows: 20 }}
        data-testid={Q_CHECK_VAST_XML_TEXTAREA}
      />
    </Form.Item>
  );

  const vastValidationErrorMessage = vastValidationError ? (
    <Form.Item>
      <div data-testid={Q_CHECK_ERROR}>
        <Alert
          message={vastValidationError.errorMessage}
          description={`Detail:${vastValidationError.errorDetail}`}
          type="error"
          showIcon
          style={{ marginBottom: '20px' }}
        />
      </div>
    </Form.Item>
  ) : null;

  const submitButton = (
    <Form.Item>
      <div data-testid={Q_CHECK_VAST_XML_SUBMIT}>
        <Space>
          <Button type="primary" htmlType="submit">
            Run Quality Check
          </Button>
        </Space>
      </div>
    </Form.Item>
  );

  const onChange = (e) => {
    resetFields();
    setValidationType(e.target.value);
  };

  return (
    <VastXmlWrapper>
      <div data-testid={Q_CHECK_VAST_XML_FORM}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Radio.Group onChange={onChange} defaultValue={VAST_URL} data-type="horizontal">
            <Radio value={VAST_URL} data-testid={Q_CHECK_VAST_URL_BUTTON}>
              {' '}
              VAST Tag URL{' '}
            </Radio>
            <Radio value={VAST_XML} data-testid={Q_CHECK_VAST_XML_BUTTON}>
              {' '}
              VAST XML{' '}
            </Radio>
          </Radio.Group>
          {validationType === VAST_URL && vastXmlUrlFillout}
          {validationType === VAST_XML && vastXmlFillout}
          <div>{vastValidationErrorMessage}</div>
          {submitButton}
          <VastXMLIngestionBox
            records={vastValidationRecords}
            validationType={validationType}
            vastXml={vastXml}
            permissions={permissions}
            isIngestButtonClicked={isIngestButtonClicked}
            setIngestButtonClicked={setIngestButtonClicked}
          />
        </Form>
      </div>
    </VastXmlWrapper>
  );
};

function mapStateToProps({ loading, app }) {
  return {
    loading: loading.effects['qCheck/pageInit'],
    pathParams: app.pathParams,
  };
}

export default connect(mapStateToProps)(VastValidationBox);
