import { useMemo } from 'react';
import { timestampIntegrators } from '../../timestampIntegrators';
import { REQUEST, RESPONSE, CREATED_TIMESTAMP, VOD } from '../../constants';

const amazonIntegratorRequestParams = (data) => ({
  requestId: data?.id,
  appName: data?.app?.name,
  deviceId: data?.device?.ifa,
  IP: data?.device?.ip,
  dnt: data?.device?.dnt,
  userAgent: data?.device?.ua,
  vdm: VOD,
  bundle: data?.app?.bundle,
  genre: data?.app?.content?.genre,
  channel: data?.app?.content?.id,
});

export const useAmazonIntegrator = (data, pods) => {
  const integratorPods = data?.demandPods?.length ? data?.demandPods : pods;

  const { summary, requestParams, bidRequest, bidResponse, amazonPods } = useMemo(() => {
    return {
      summary: {
        timestamp: timestampIntegrators(data?.[RESPONSE]?.record?.[CREATED_TIMESTAMP]),
      },
      requestParams: amazonIntegratorRequestParams(data?.[REQUEST]?.record?.rawData) ?? {},
      bidRequest: data?.[REQUEST]?.record?.rawData ?? {},
      bidResponse: data?.[RESPONSE]?.record?.rawData ?? {},
      amazonPods: integratorPods ?? [],
    };
  }, [data, integratorPods]);

  return {
    summary,
    requestParams,
    bidRequest,
    bidResponse,
    amazonPods,
  };
};
