import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';

export const AppDetailsCard = ({ isLoading, data }) => {
  const app = data?.app || data?.['app-info'];
  const content = data?.content || data?.['content-info'];
  return (
    <StyledCard title="App Details" loading={isLoading}>
      <RowInfo title="App ID" data={app?.id || content?.['bundle-id'] || content?.alid} />
      <RowInfo title="Publisher" data={app?.name || content?.publisher} />
      <RowInfo title="App Bundle" data={app?.bundle} />
      <RowInfo title="App Domain" data={app?.domain} />
      <RowInfo title="App Store Url" data={app?.storeurl} />
    </StyledCard>
  );
};
