import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';
import { Button } from '@hulu/react-style-components';

import { AuctionFiltersUserSnapshots } from './AuctionFiltersUserSnapshots';
import { AuctionFiltersDatePicker } from './AuctionFiltersDatePicker';
import { FilterSnapshotsDropdown } from './FilterSnapshotsDropdown';
import { ChipFilters } from './ChipFilters';

import { FilterFunnelIcon } from '../../icons/FilterFunnelIcon';

const StyledFiltersWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  max-width: calc(100% - 105px);
  .TrekDropDown-root {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding-left: 12px;
  }
  .TrekLabel-root {
    span {
      text-transform: none;
      padding-right: 8px;
    }
  }
`;
const StyledFilterButton = styled(Button)`
  height: 32px;
  margin: 0;
  &:hover {
    color: ${colors.colorWhite};
  }
  span {
    margin-left: 4px;
    font-weight: 500;
  }
`;

const AuctionFiltersComponent = (props) => {
  const {
    filtersDropdownData,
    activeFilters,
    dateValue,
    onAllFiltersClear,
    onFiltersClear,
    onDeleteFilter,
    onClearAppNameFilter,
    handleFilters,
    handleUserSnapshotValue,
    handleDateRange,
    userOptions,
    onUsersSearch,
    selectedUserOptions,
  } = props;

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleOpen = () => setIsFilterOpen(true);
  const handleClose = () => setIsFilterOpen(false);

  return (
    <StyledFiltersWrapper>
      <StyledFilterButton variant="normal" size="regular" icon={<FilterFunnelIcon />} onClick={handleOpen}>
        Show Filters
      </StyledFilterButton>
      <FilterSnapshotsDropdown
        data={filtersDropdownData}
        onFiltersClear={onFiltersClear}
        handleFilters={handleFilters}
        activeFilters={activeFilters}
        isOpen={isFilterOpen}
        onClose={handleClose}
      />
      <AuctionFiltersUserSnapshots
        handleUserSnapshotValue={handleUserSnapshotValue}
        userOptions={userOptions}
        selectedUserOptions={selectedUserOptions}
        onUsersSearch={onUsersSearch}
      />
      <AuctionFiltersDatePicker onChange={handleDateRange} dateValue={dateValue} />
      <ChipFilters
        activeFilters={activeFilters}
        onAllFiltersClear={onAllFiltersClear}
        onDeleteFilter={onDeleteFilter}
        onClearAppNameFilter={onClearAppNameFilter}
      />
    </StyledFiltersWrapper>
  );
};

export const AuctionFilters = memo(AuctionFiltersComponent);
