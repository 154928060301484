import { jwtTokenHeader, request } from '../utils';
import { GET, VAST_TRACKER_API } from '../constants';
import qs from 'qs';

export const getCounts = async (countType, ids, startDate, endDate, limitPerDay) => {
  const params = {
    params: {
      ids: ids,
      'start-date': startDate,
      'end-date': endDate,
      'limit-per-day': limitPerDay,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  };
  return request(
    GET,
    `${VAST_TRACKER_API}/${countType}/counts`,
    {},
    { ...(await jwtTokenHeader()), ...params },
    'Failed getting vast track counts by dates'
  );
};
