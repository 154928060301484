import React, { useEffect, useRef } from 'react';
import { Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { colors } from '@hulu/design-tokens';
import styled from 'styled-components';

import { useDebouncedCallback } from '../../useDebouncedCallback';

const StyledSpan = styled.span`
  color: ${colors.colorBlue50};
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
`;

const StyledCol = styled(Col)`
  margin-right: 16px;
`;

const StyledInput = styled(Input)`
  width: 256px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${colors.colorGray30};
  margin: 0px;
  font-size: 12px;
  color: ${colors.colorGray82};
  &:hover,
  &:focus {
    border: 1px solid ${colors.colorBlack95} !important;
    outline: none;
    box-shadow: none;
  }
`;

export const TabBarExtraContent = ({ handleExpandAll, handleCollapseAll, handleSearch, activeTab }) => {
  const { searchQuery, handleSearchQuery } = useDebouncedCallback(handleSearch);

  const ref = useRef();

  useEffect(() => {
    ref.current = activeTab;
  });

  const tab = ref.current;

  useEffect(() => {
    if (tab !== activeTab) {
      handleSearch('');
      handleSearchQuery('');
    }
  }, [tab, activeTab, handleSearch, handleSearchQuery]);

  return (
    <Row align="middle">
      <StyledCol>
        <StyledSpan onClick={() => handleExpandAll()}>Expand All</StyledSpan>
        {' / '}
        <StyledSpan onClick={() => handleCollapseAll()}>Collapse All</StyledSpan>
      </StyledCol>
      <Col>
        <StyledInput
          allowClear
          placeholder=""
          prefix={<SearchOutlined style={{ fontSize: '18px' }} />}
          onChange={(event) => handleSearchQuery(event.target.value)}
          value={searchQuery}
        />
      </Col>
    </Row>
  );
};
