import React, { useState } from 'react';
import { connect } from 'dva';
import { Tooltip } from 'antd';
import { FeedbackIcon } from './styles';
import FeedbackForm from './FeedbackForm';
import { StyledButton } from '../../styles';

const Feedback = ({ dispatch }) => {
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  const handleSubmit = async (values) => {
    await dispatch({ type: 'app/sendFeedback', payload: values });
    setIsFeedbackModalVisible(false);
  };

  return (
    <Tooltip title={'Send Feedback'} trigger={['hover', 'focus']}>
      <>
        <StyledButton type="link" onClick={() => setIsFeedbackModalVisible(true)}>
          <FeedbackIcon />
        </StyledButton>
        <FeedbackForm
          visible={isFeedbackModalVisible}
          onCancel={() => setIsFeedbackModalVisible(false)}
          onSubmit={handleSubmit}
        />
      </>
    </Tooltip>
  );
};

export default connect()(Feedback);
