import React from 'react';
import { Row, Col } from 'antd';
import { Typography } from '@hulu/react-style-components';

import styled from 'styled-components';

import { AUCTION_SNAPSHOT_TITLE } from '../../../constants';
import { AUCTION_SNAPSHOT_HEADING } from '../../../testUtils';

import { SearchInput } from './SearchInput';
import { SearchOutlined } from '@ant-design/icons';

const StyledTableHeaderContainer = styled(Row)`
  padding: 1rem 0.8rem 1rem 1rem;
`;

const StyledSearchWrapper = styled.div`
  .TrekSearchField-submitButton {
    display: none;
  }
`;

export const TableHeaderContainer = ({ onSearchAuctionSnapshots, searchValue }) => (
  <StyledTableHeaderContainer justify="space-between">
    <Col>
      <Typography variant="h5" data-testid={AUCTION_SNAPSHOT_HEADING}>
        {AUCTION_SNAPSHOT_TITLE}
      </Typography>
    </Col>
    <Col>
      <StyledSearchWrapper>
        <SearchInput
          onChange={onSearchAuctionSnapshots}
          searchValue={searchValue}
          prefix={<SearchOutlined style={{ fontSize: '24px' }} />}
          placeholder=""
          height={'40px'}
          width={'267px'}
        />
      </StyledSearchWrapper>
    </Col>
  </StyledTableHeaderContainer>
);
