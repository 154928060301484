import styled from 'styled-components';

export const CenterText = styled.div`
  max-width: 500px;
  height: auto;
  text-align: center;
`;

export const CenterTextBox = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
