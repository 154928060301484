import { Row, Col } from 'antd';
import BarChart from './BarChart';
import Header from './Header';
import CountsTable from './CountsTable';
import FilterSearch from './FilterSearch';
import { VastTrackerWrapper } from './VastTrackerWrapper';
import React, { useEffect } from 'react';
import { connect } from 'dva';

const VastTracker = ({ dispatch }) => {
  useEffect(() => {
    // loading partner groups during mounting
    dispatch({ type: 'vastTracker/loadPartnerGroups' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VastTrackerWrapper>
      <Header />
      <br />
      <Row>
        <Col span={19}>
          <BarChart />
        </Col>
        <Col span={4} offset={1}>
          <FilterSearch />
        </Col>
      </Row>
      <br />
      <br />
      <CountsTable />
    </VastTrackerWrapper>
  );
};

export default connect()(VastTracker);
