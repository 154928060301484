import React from 'react';

import { Row, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import styled from 'styled-components';

import { AuctionFilters } from './AuctionFilters';

const StyledTableFiltersContainer = styled(Row)`
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  align-items: baseline;
  justify-content: space-between;
`;

export const TableFiltersContainer = (props) => {
  const {
    filtersDropdownData,
    activeFilters,
    dateValue,
    onAllFiltersClear,
    onFiltersClear,
    onDeleteFilter,
    onClearAppNameFilter,
    handleFilters,
    handleUserSnapshotValue,
    handleDateRange,
    onRefresh,
    selectedUserOptions,
    userOptions,
    onUsersSearch,
  } = props;

  return (
    <StyledTableFiltersContainer>
      <AuctionFilters
        filtersDropdownData={filtersDropdownData}
        activeFilters={activeFilters}
        dateValue={dateValue}
        onAllFiltersClear={onAllFiltersClear}
        onFiltersClear={onFiltersClear}
        onDeleteFilter={onDeleteFilter}
        onClearAppNameFilter={onClearAppNameFilter}
        handleFilters={handleFilters}
        handleUserSnapshotValue={handleUserSnapshotValue}
        handleDateRange={handleDateRange}
        userOptions={userOptions}
        selectedUserOptions={selectedUserOptions}
        onUsersSearch={onUsersSearch}
      />
      <Button type="text" icon={<SyncOutlined />} onClick={onRefresh}>
        Refresh
      </Button>
    </StyledTableFiltersContainer>
  );
};
