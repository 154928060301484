import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Tooltip } from 'antd';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { POD_POSITIONS } from '../../constants';
import { PodSlotAdIdSelectorWrapper } from './CreateAdOverrideRule/styles';
import {
  ADINVESTIGATOR_CREATE_RULES_ADD,
  ADINVESTIGATOR_CREATE_RULES_ADS,
  ADINVESTIGATOR_CREATE_RULES_DATEPICKER,
  ADINVESTIGATOR_CREATE_RULES_LIST,
  ADINVESTIGATOR_CREATE_RULES_POD_SLOT,
  ADINVESTIGATOR_CREATE_RULES_REMOVE,
  ADINVESTIGATOR_CREATE_RULES_SELECT,
} from '../../testUtils';
import { FORM_NOT_EMPTY_RULE, FORM_UUID_RULE, MomentDatePicker, NO_EXTRA_SPACE_RULE } from '../../utils';
import { StyledFormItem, StyledInput } from './styles';

const { Option } = Select;
const SPACE_STYLE = { display: 'flex', marginBottom: 8 };
export const NARROW_FILLOUT = 'NARROW_FILLOUT';
export const WIDE_FILLOUT = 'WIDE_FILLOUT';

export const expireAtFillout = () => (
  <div data-testid={ADINVESTIGATOR_CREATE_RULES_DATEPICKER}>
    <Form.Item
      label="Expire when?"
      name="expire"
      rules={[{ required: true, message: 'Please select expiration time' }]}
    >
      <MomentDatePicker
        format="YYYY-MM-DD HH:mm"
        defaultPickerValue={moment().endOf('day')}
        disabledDate={(currentDate) =>
          currentDate <= moment().endOf('day').subtract(1, 'days') ||
          currentDate > moment().endOf('day').add(4, 'month')
        }
        onChange={(date) => {
          return date?.endOf('day');
        }}
      />
    </Form.Item>
  </div>
);

export const podSelection = (
  <Select placeholder="Select pod">
    {POD_POSITIONS.map((position) => (
      <Option key={position} value={position}>
        {position}
      </Option>
    ))}
  </Select>
);

export const podSlotSelector = ({ key, fieldKey, name, ...restField }) => (
  <div data-testid={ADINVESTIGATOR_CREATE_RULES_POD_SLOT}>
    <div data-testid={ADINVESTIGATOR_CREATE_RULES_SELECT}>
      <Form.Item {...restField} label="Pod" name={[name, 'pod']} fieldKey={[fieldKey, 'pod']}>
        {podSelection}
      </Form.Item>
    </div>
    <Form.Item {...restField} label="Slot" name={[name, 'slot']} fieldKey={[fieldKey, 'slot']}>
      <InputNumber min={1} />
    </Form.Item>
  </div>
);

export const adIdSelector = ({ key, fieldKey, name, ...restField }) => (
  <div data-testid={ADINVESTIGATOR_CREATE_RULES_ADS}>
    <Space>
      <Form.Item {...restField} label="Ad Id" name={[name, 'adId']} fieldKey={[fieldKey, 'adId']}>
        <Input placeholder="Ad Id to Override" />
      </Form.Item>
    </Space>
  </div>
);

export const adRemoveRow = (props, remove) => (
  <div data-testid={ADINVESTIGATOR_CREATE_RULES_REMOVE}>
    <Row justify="space-between">
      <Col span={20}>{adIdSelector(props)}</Col>
      <Col>
        <Tooltip title="Remove this pod/slot selection">
          <MinusCircleOutlined onClick={() => remove(props.name)} />
        </Tooltip>
      </Col>
    </Row>
  </div>
);

export const adAddRow = (add) => (
  <Form.Item>
    <div data-testid={ADINVESTIGATOR_CREATE_RULES_ADD}>
      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
        Add Ad to Override
      </Button>
    </div>
  </Form.Item>
);

export const formList = (name, style) => (
  // Antd Form List Implementation
  // Refer to https://ant.design/components/form/#components-form-demo-dynamic-form-item
  <Form.List name={name}>
    {(fields, { add, remove }) => (
      <div data-testid={ADINVESTIGATOR_CREATE_RULES_LIST}>
        {fields.map((props) => (
          <PodSlotAdIdSelectorWrapper>
            <Space key={props.key} style={SPACE_STYLE} align="baseline">
              {podSlotSelector(props)}
              {style === WIDE_FILLOUT ? adRemoveRow(props, remove) : null}
            </Space>
            {style === NARROW_FILLOUT ? <Row gutter={8}>{adRemoveRow(props, remove)}</Row> : null}
          </PodSlotAdIdSelectorWrapper>
        ))}
        {adAddRow(add)}
      </div>
    )}
  </Form.List>
);

export const pauseAdIdFillout = (style) => {
  const InputComponent = style === WIDE_FILLOUT ? StyledInput : Input;
  return (
    <StyledFormItem
      name="pauseAdId"
      label="Ad Id"
      rules={[
        { required: true, message: 'Please input ad id value' },
        FORM_NOT_EMPTY_RULE,
        NO_EXTRA_SPACE_RULE,
        FORM_UUID_RULE,
      ]}
      validateTrigger="onSubmit"
    >
      <InputComponent placeholder="Ad Id to Override" />
    </StyledFormItem>
  );
};
