import { notification, Tag } from 'antd';
import { createUpdateOverrides, getOverrides, removeOverrides } from '../../services/adInvestigator';
import { USER_PROFILE, DEVICE, ADS, VIDEO_AD_TYPE, PAUSE_AD_TYPE } from '../../constants';
import { getFromRedisKey } from '../../utils';
import { searchParamsSelector } from '../app';

export const adInvestigator = {
  namespace: 'adInvestigator',
  state: {
    rules: [],
    userWidths: [],
    deviceWidths: [],
  },
  reducers: {
    updateExistingRules(state, { payload: rules }) {
      return { ...state, rules };
    },
    overrideUserWidths(state, { payload: userWidths }) {
      return { ...state, userWidths };
    },
    overrideDeviceWidths(state, { payload: deviceWidths }) {
      return { ...state, deviceWidths };
    },
  },
  effects: {
    *pageInit(emptyPayload, { put, select }) {
      yield put({ type: 'adCampaignProvider/fetchObjectData', payload: ADS });
      const searchParams = yield select(searchParamsSelector);
      yield put({ type: 'fetchExistingRules', payload: { overrideBy: searchParams?.viewType ?? USER_PROFILE } });
    },
    *removeRule({ payload: { adType, userId, profileId, deviceId } }, { call, put }) {
      let overrideBy = '';
      if (userId && profileId) {
        overrideBy = USER_PROFILE;
      } else if (deviceId) {
        overrideBy = DEVICE;
      } else {
        yield call(notification.error, {
          message: 'No User/Profile/Device id to rule',
          description: 'Need ids to do remove rule action',
          duration: 3,
        });
        return;
      }

      (yield call(removeOverrides, { adType, overrideBy, userId, profileId, deviceId })) &&
        (yield put({ type: 'pageInit' })) &&
        (yield call(notification.success, {
          message: 'Success deleting Override Rule.',
          duration: 3,
        }));
    },
    *submitRule(
      { payload: { overrideBy, adType, userId, profileId, deviceId, overrideRules, pauseAdId, expire } },
      { call, put }
    ) {
      if (adType === VIDEO_AD_TYPE && !overrideRules) {
        yield call(notification.error, {
          message: 'Need Pod & Slot for override rule.',
          duration: 3,
        });
        return;
      }

      const rules = {
        'expire-at': expire.unix(),
      };

      if (adType === VIDEO_AD_TYPE) {
        rules['pod-list'] = overrideRules.map(({ pod, adId, slot }) => {
          return {
            'pod-position': pod,
            'slot-list': [
              {
                'ad-id': adId,
                number: slot,
              },
            ],
          };
        });
      } else {
        rules['ad-id'] = pauseAdId;
      }

      const overrides = {
        overrideBy,
        adType,
        userId,
        profileId,
        deviceId,
        rules: rules,
      };

      (yield call(createUpdateOverrides, overrides)) &&
        (yield put({ type: 'pageInit' })) &&
        (yield call(notification.success, {
          message: 'Created/Updated Override Rule.',
          duration: 3,
        }));
    },
    *fetchExistingRules({ payload: overrideInfo }, { call, all, put }) {
      const videoAdsOverridesPromise = call(getOverrides, overrideInfo, VIDEO_AD_TYPE);
      const pauseAdsOverridesPromise = call(getOverrides, overrideInfo, PAUSE_AD_TYPE);
      const [videoAdsOverridesResponse, pauseAdsOverridesResponse] = yield all([
        videoAdsOverridesPromise,
        pauseAdsOverridesPromise,
      ]);
      const combinedRules = [];
      if (videoAdsOverridesResponse?.data) {
        const response = videoAdsOverridesResponse;
        const fetchedRules = Array.isArray(response.data) ? response.data : [response.data];
        const rules = fetchedRules.map(({ key, 'pod-list': positions, 'expire-at': expireAt }, ruleIndex) => {
          const mappedPositions = {};
          positions.forEach(({ 'pod-position': pod, 'slot-list': slots }) => {
            mappedPositions[pod] = mappedPositions[pod] ? mappedPositions[pod].concat(slots) : slots;
          });
          return {
            key: key + ruleIndex,
            adType: VIDEO_AD_TYPE,
            userId: getFromRedisKey(key, 'user'),
            profileId: getFromRedisKey(key, 'profile'),
            deviceId: getFromRedisKey(key, 'device'),
            podPositions: Object.entries(mappedPositions).map(([pod, slots]) => ({
              title: (
                <>
                  <Tag color="volcano">Pod</Tag>
                  {pod}
                </>
              ),
              key: pod,
              children: slots.map(({ number, 'ad-id': adId }, slotIndex) => ({
                title: (
                  <>
                    <div>
                      <Tag color="magenta">Slot</Tag>
                      {number}
                      <Tag color="lime">Ad</Tag>
                      {adId}
                    </div>
                  </>
                ),
              })),
            })),
            mappedPositions,
            expireAt,
          };
        });
        combinedRules.push(...rules);
      }

      if (pauseAdsOverridesResponse?.data) {
        const response = pauseAdsOverridesResponse;
        const fetchedRules = Array.isArray(response.data) ? response.data : [response.data];
        const rules = fetchedRules.map(({ key, 'ad-id': adId, 'expire-at': expireAt }, ruleIndex) => {
          return {
            key: key + ruleIndex,
            adType: PAUSE_AD_TYPE,
            userId: getFromRedisKey(key, 'account'),
            profileId: getFromRedisKey(key, 'profile'),
            deviceId: getFromRedisKey(key, 'device'),
            podPositions: (
              <div>
                <Tag color="lime">Ad</Tag> {adId}
              </div>
            ),
            pauseAdId: adId,
            expireAt,
          };
        });
        combinedRules.push(...rules);
      }

      if (combinedRules.length > 0) {
        yield put({ type: 'updateExistingRules', payload: combinedRules });
      } else {
        yield put({ type: 'updateExistingRules', payload: [] });
      }
    },
  },
};
