import React from 'react';
import { useBidDetails } from './useBidDetails';
import { SummaryCard } from './SummaryCard';
import { AdSourceSummary } from './AdSourceSummary';
import { BidDetails } from '../BidDetails';
import { useAddSourceSummary } from './useAddSourceSummary';

export const PodAndBids = ({ currentPodId, isLoading, pods, bids, integratorsData, auctionSupplySource }) => {
  const {
    isOpenBidDetails,
    bidDetailsData,
    isBidDetailsLoading,
    dealDetailsError,

    openBidDetails,
    closeBidDetails,
  } = useBidDetails(integratorsData, auctionSupplySource);

  const { bidsFilteredByCurrentPodId, bidderNames, combineBidsByBidState, combineBidsByBidderName, onSearch } =
    useAddSourceSummary(bids, integratorsData, currentPodId);

  const currentPod = pods?.find((el) => el.id === currentPodId);
  return (
    <>
      <SummaryCard isLoading={isLoading} pod={currentPod} bids={bidsFilteredByCurrentPodId} />
      {bidsFilteredByCurrentPodId && (
        <AdSourceSummary
          isLoading={isLoading}
          bidderNames={bidderNames}
          combineBidsByBidState={combineBidsByBidState}
          bidsByBidderNames={combineBidsByBidderName}
          openBidDetails={openBidDetails}
          handleSearch={onSearch}
        />
      )}
      {bidsFilteredByCurrentPodId && (
        <BidDetails
          bidDetailData={bidDetailsData}
          isBidDetailsLoading={isBidDetailsLoading}
          dealDetailsError={dealDetailsError}
          isOpen={isOpenBidDetails}
          onClose={closeBidDetails}
        />
      )}
    </>
  );
};
