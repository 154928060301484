import React from 'react';
import ReactJson from 'react-json-view';
import { Alert } from 'antd';

import { StyledXmlJsonFormatter, StyledDataInterpretation, JsonXMLViewersStyle } from '../style';

export const JsonViewer = ({ data, height }) => {
  if (!data) return <Alert type="error" message="No data from integrator" />;

  return (
    <StyledXmlJsonFormatter height={height}>
      <StyledDataInterpretation>
        <ReactJson
          src={data}
          name={false}
          displayDataTypes={false}
          displayArrayKey={false}
          style={JsonXMLViewersStyle}
        />
      </StyledDataInterpretation>
    </StyledXmlJsonFormatter>
  );
};
