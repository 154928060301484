import { useState, useEffect, useCallback, useMemo } from 'react';
import createHistory from 'history/createBrowserHistory';
import { routerRedux } from 'dva/router';
import {
  getAuctionById,
  getBidsByAuction,
  getSnapshotById,
  getSupplyAuctionRawData,
  getDemandAuctionRawData,
} from '../../../services/auctionSnapshot';
import { AUCTION_SNAPSHOT, AUCTION_SNAPSHOT_DETAILED_VIEW } from '../../../constants';
import { auctionDetailsView, DEMAND_KEYS, MAGNITE, SUPPLY_KEYS, CAS, DV360, TTD, AMAZON, VAST } from './constants';
import { parseRawRecord } from '../parseRawRecord';
import { resolvePods } from './podsResolver';

const FAVICON_QUERY_SELECTOR = "link[rel='shortcut icon']";
const FAVICON_TARGET_HREF = '/favicon.png';

const transformRawData = (rawData, supplyParameters) => {
  const decodedData = rawData.reduce((transformed, rawRecord) => {
    const { source, dataType, rawDataKey } = rawRecord;
    const { podIndex, requestNumber } = rawDataKey;
    const parseData = parseRawRecord(rawRecord, supplyParameters);

    const demandPayload =
      source === DV360 || source === TTD
        ? [
            ...(transformed[source]?.[podIndex]?.[dataType] || []),
            { ...parseData, requestNumber, id: podIndex + '_' + requestNumber },
          ]
        : source === AMAZON && dataType === VAST
        ? [...(transformed[source]?.[podIndex]?.[dataType] || []), parseData]
        : parseData;

    const parseDataRecord = DEMAND_KEYS.some((el) => el === source)
      ? {
          ...transformed[source],
          [podIndex]: { ...transformed[source]?.[podIndex], [dataType]: demandPayload, podIndex },
        }
      : { ...transformed[source], [dataType]: parseData };

    return { ...transformed, [source]: parseDataRecord };
  }, {});

  const adaptedData = Object.entries(decodedData).reduce((data, [key, value]) => {
    if (SUPPLY_KEYS.some((el) => el === key)) {
      const record = { ...value, type: key };
      return { ...data, supplySource: record };
    }

    if (DEMAND_KEYS.some((el) => el === key)) {
      const availablePodIds = Object.keys(value);
      const uniquePodIds = [...new Set(availablePodIds)];
      const demandPods = supplyParameters !== CAS ? uniquePodIds?.map((podId) => ({ id: Number(podId) })) : [];
      const record = {
        id: key.toUpperCase(),
        label: key.charAt(0).toUpperCase() + key.slice(1),
        demandPods,
      };
      return {
        ...data,
        integrators: data.integrators ? [record, ...data.integrators] : [record],
        [key]: Object.values(value),
      };
    }

    return data;
  }, {});

  return adaptedData;
};

export const useAuctionSnapshotDetails = (dispatch) => {
  const { location } = createHistory();
  const [, auctionId] = location.pathname.split(`/${AUCTION_SNAPSHOT_DETAILED_VIEW}/`);
  const [activeView, setActiveView] = useState(auctionDetailsView.SUPPLY_SOURCE);

  const [auction, setAuction] = useState(null);
  const [snapshot, setSnapshot] = useState(null);

  const [supplyAuctionData, setSupplyAuctionData] = useState(null);
  const [demandAuctionData, setDemandAuctionData] = useState(null);

  const [podId, setPodId] = useState(null);

  const [isSupplyRawDataLoading, setSupplyIsRawDataLoading] = useState(false);
  const [isDemandRawDataLoading, setDemandIsRawDataLoading] = useState(false);
  const [isBidsDataLoading, setIsBidsDataLoading] = useState(false);

  const [bids, setBids] = useState(null);
  const [error, setError] = useState(null);

  const handleMenuItemClick = useCallback(
    (page) => {
      const podKey = auctionDetailsView.getPodKey(page) || podId;
      setPodId(podKey);
      setActiveView(page);
    },
    [podId]
  );

  const handlePodId = (id) => setPodId(id);

  const handleBack = () => {
    dispatch(routerRedux.push(`/${AUCTION_SNAPSHOT}`));
  };

  const getDemandDataByIntegrator = useCallback(
    (integrator) => {
      const integratorCommonData = demandAuctionData?.integrators?.find((el) => el.id === integrator);
      const integratorDemandData = demandAuctionData?.[integrator]?.find((item) => item?.podIndex === podId);

      if (integrator === MAGNITE && integratorCommonData && !integratorDemandData) {
        return { ...demandAuctionData?.[MAGNITE]?.[0], demandPods: integratorCommonData?.demandPods };
      }
      return { ...integratorDemandData, demandPods: integratorCommonData?.demandPods };
    },
    [demandAuctionData, podId]
  );

  const demandDataByPodId = useMemo(() => {
    const magniteIntegrator = demandAuctionData?.integrators?.find((el) => el.id === MAGNITE);
    let result = {};

    for (const key in demandAuctionData) {
      if (DEMAND_KEYS.some((item) => item === key)) {
        const podIdData = demandAuctionData?.[key]?.find((item) => item?.podIndex === podId);
        key === MAGNITE && magniteIntegrator && !podIdData
          ? Object.assign(result, { [key]: demandAuctionData[MAGNITE]?.[0] })
          : Object.assign(result, { [key]: podIdData });
      }
    }

    return result;
  }, [demandAuctionData, podId]);

  const fetchSupplyRawData = useCallback(() => {
    setSupplyIsRawDataLoading(true);
    if (bids) {
      getSupplyAuctionRawData(auctionId)
        .then(transformRawData)
        .then((data) => resolvePods(data, bids))
        .then((data) => {
          setSupplyAuctionData(data);
        })
        .catch(setError)
        .finally(() => setSupplyIsRawDataLoading(false));
    }
  }, [auctionId, bids]);

  const fetchDemandRawData = useCallback(() => {
    setDemandIsRawDataLoading(true);
    if (auction) {
      getDemandAuctionRawData(auctionId)
        .then((data) => transformRawData(data, auction?.auctionSupplySource))
        .then(setDemandAuctionData)
        .catch(setError)
        .finally(() => setDemandIsRawDataLoading(false));
    }
  }, [auctionId, auction]);

  useEffect(() => {
    getAuctionById(auctionId)
      .then((auction) => setAuction(auction))
      .catch(setError);
  }, [auctionId]);

  useEffect(() => {
    if (auction) {
      getSnapshotById(auction.snapshotId)
        .then((snapshot) => setSnapshot(snapshot))
        .catch(setError);
    }
  }, [auction]);

  useEffect(() => {
    fetchSupplyRawData();
  }, [fetchSupplyRawData]);

  useEffect(() => {
    fetchDemandRawData();
  }, [fetchDemandRawData]);

  useEffect(() => {
    setIsBidsDataLoading(true);
    if (demandAuctionData && snapshot) {
      getBidsByAuction(auctionId)
        .then((bids) => {
          const bidsWithAvailableIntegrators = bids.reduce((bidsWithCountry, bid) => {
            if (
              demandAuctionData?.integrators?.some((integrator) => integrator?.id === bid?.demandPartner?.toUpperCase())
            ) {
              bidsWithCountry.push({ ...bid, country: snapshot?.supplyParameters?.country });
            }
            return bidsWithCountry;
          }, []);
          setBids(bidsWithAvailableIntegrators);
        })
        .catch(setError)
        .finally(() => setIsBidsDataLoading(false));
    }
  }, [auctionId, demandAuctionData, snapshot]);

  useEffect(() => {
    let link = document.querySelector(FAVICON_QUERY_SELECTOR);
    link.href = FAVICON_TARGET_HREF;
  }, []);

  return {
    auction,
    snapshot,
    supplyAuctionData,
    demandAuctionData,
    isSupplyRawDataLoading,
    isDemandRawDataLoading,
    isBidsDataLoading,
    bids,
    activeView,
    error,
    podId,
    demandDataByPodId,

    getDemandDataByIntegrator,
    handleMenuItemClick,
    handleBack,
    handlePodId,
  };
};
