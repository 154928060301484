import { connect } from 'dva';
import { Link } from 'dva/router';
import React from 'react';
import { Upload, Row, Col } from 'antd';
import csv from 'csvtojson';
import { Typography } from '@hulu/react-style-components';

import { SubPageWrapper } from '../styles';
import { UploadOutlined } from '@ant-design/icons';
import { bulkUploaddurationRulesColumns } from '../../Templates/columns';
import { ButtonWrapper } from './styles';
import { RULE_CHANGE_TYPE_EDIT, RULE_PARTIAL_POD, RULE_SINGLE_PROGRAM, RULE_TYPE_CHANNEL } from '../constants';
import { DURATION_MANAGER_PATH } from '../../../constants';
import {
  DURATION_MANAGER_BULK_BACK,
  DURATION_MANAGER_BULK_HEADER,
  DURATION_MANAGER_BULK_TABLE,
} from '../../../testUtils';
import { ResizableTable } from '../../../utils';

const TABLE_SCROLL_Y = { y: '45vh' };

const BulkUploadRules = ({ dispatch, stagedBulkRules = [], bulkUploadWidths = [] }) => {
  const isRulesStaged = stagedBulkRules && stagedBulkRules.length > 0;

  const publishBulkRules = () => {
    // Sanitize rules from unneeded fields
    stagedBulkRules.forEach((rule) => {
      delete rule.key;
      delete rule.previousRule;
    });

    dispatch({
      type: 'durationManager/postRules',
      payload: { reqBody: stagedBulkRules, changeType: 'Bulk Upload', redirectToAllRules: true },
    });
  };

  const uploadProps = {
    accept: '.csv',
    showUploadList: false,
    beforeUpload: (file) => {
      // On file upload read file & update states
      const reader = new FileReader();
      reader.onload = ({ target }) =>
        csv({ output: 'csv' })
          .fromString(target.result)
          .then((rules) => dispatch({ type: 'durationManager/stageBulkUploadRules', payload: rules }));
      reader.readAsText(file);

      // Not actually upload, just read file
      return false;
    },
  };

  const downloadTemplateButton = (
    <ButtonWrapper
      icon={<UploadOutlined />}
      target="_blank"
      href="/files/duration-rules-bulk-upload-template.csv"
      rel="noopener noreferrer"
    >
      Use CSV Template
    </ButtonWrapper>
  );

  // Different emphasis on Upload Button depending on whether rules loaded
  const uploadButton = isRulesStaged ? (
    <ButtonWrapper icon={<UploadOutlined />}>Upload a different CSV</ButtonWrapper>
  ) : (
    <ButtonWrapper type="primary" icon={<UploadOutlined />}>
      Upload a CSV
    </ButtonWrapper>
  );

  const rowExpandRenderer = ({
    'stream-type': streamType,
    'rule-type': ruleType,
    'channel-id': channelId,
    'series-id': seriesId,
    'league-id': leagueId,
    'sports-id': sportsId,
    'event-id': eventId,
    'durations-ms': durationMs,
    'is-single-program': singleProgram,
    'is-partial-pods': partialPod,
    previousRule,
  }) => (
    <Row justify="space-around">
      <Col>
        <Row>
          <b>Stream Type: </b>
        </Row>
        <Row>{streamType}</Row>
      </Col>
      <Col>
        <Row>
          <b>Channel Id: </b>
        </Row>
        <Row>{channelId}</Row>
        {seriesId ? (
          <>
            <Row>
              <b>Series Id:</b>
            </Row>
            <Row>{seriesId}</Row>
          </>
        ) : null}
        {leagueId ? (
          <>
            <Row>
              <b>League Id:</b>
            </Row>
            <Row>{leagueId}</Row>
          </>
        ) : null}
        {eventId ? (
          <>
            <Row>
              <b>Event Id:</b>
            </Row>
            <Row>{eventId}</Row>
          </>
        ) : null}
        {sportsId ? (
          <>
            <Row>
              <b>Sports Id:</b>
            </Row>
            <Row>{sportsId}</Row>
          </>
        ) : null}
      </Col>

      {ruleType === RULE_TYPE_CHANNEL ? (
        <Col>
          <Row>
            <b>Single Program:</b>
          </Row>
          <Row>
            <i>Original: </i>&nbsp;&nbsp;
            {previousRule[RULE_SINGLE_PROGRAM] === undefined ? '' : previousRule[RULE_SINGLE_PROGRAM].toString()} ->
          </Row>
          <Row>
            <i>Changed To: </i>&nbsp;&nbsp;
            {singleProgram === undefined ? '' : singleProgram.toString()}
          </Row>
        </Col>
      ) : null}
      {ruleType != RULE_TYPE_CHANNEL ? (
        <Col>
          <Row>
            <b>Partial Pod:</b>
          </Row>
          <Row>
            <i>Original: </i>&nbsp;&nbsp;
            {previousRule[RULE_PARTIAL_POD] === undefined ? '' : previousRule[RULE_PARTIAL_POD].toString()} ->
          </Row>
          <Row>
            <i>Changed To: </i>&nbsp;&nbsp;
            {partialPod === undefined ? '' : partialPod.toString()}
          </Row>
        </Col>
      ) : null}
      <Col>
        <Row>
          <b>Pod Durations (ms):</b>
        </Row>
        <Row>
          <i>Original: </i>&nbsp;&nbsp;
          {previousRule['durations-ms'].join(', ')} ->
        </Row>
        <Row>
          <i>Changed To: </i>&nbsp;&nbsp;
          {durationMs.join(', ')}
        </Row>
      </Col>
    </Row>
  );
  const handleWidthChange = (widths) =>
    dispatch({
      type: 'durationManager/updateBulkUploadWidths',
      payload: widths,
    });

  return (
    <SubPageWrapper>
      {/* Back Redirect */}
      <div data-testid={DURATION_MANAGER_BULK_BACK}>
        <Link to={DURATION_MANAGER_PATH}>
          <Typography variant={'h6'}>&lt; Back to Duration Manager</Typography>
        </Link>
        <br />
      </div>

      {/* Form for Bulk Rules Creation */}
      <h1>Bulk Upload Rules</h1>
      <hr />
      <br />

      {/* Staged Rules header */}
      <Row justify="space-between" align="bottom" data-testid={DURATION_MANAGER_BULK_HEADER}>
        <Col>
          <Typography variant={'h5'}>Staged Duration Rules:</Typography>
        </Col>
        <Col>
          {downloadTemplateButton}
          <Upload {...uploadProps}>{uploadButton}</Upload>
          <ButtonWrapper type="primary" disabled={!isRulesStaged} onClick={publishBulkRules}>
            Submit Rules
          </ButtonWrapper>
        </Col>
      </Row>
      <br />

      {/* Staged Rules table */}
      <Row data-testid={DURATION_MANAGER_BULK_TABLE}>
        <ResizableTable
          inputColumns={bulkUploaddurationRulesColumns(stagedBulkRules, bulkUploadWidths)}
          dataSource={stagedBulkRules}
          scroll={TABLE_SCROLL_Y}
          widthHandler={handleWidthChange}
          expandable={{
            expandedRowRender: rowExpandRenderer,
            rowExpandable: (row) => row['change-type'] === RULE_CHANGE_TYPE_EDIT,
          }}
          searchableProps={null}
        />
      </Row>
    </SubPageWrapper>
  );
};

function mapStateToProps({ durationManager }) {
  return {
    stagedBulkRules: durationManager.stagedBulkRules,
    bulkUploadWidths: durationManager.bulkUploadWidths,
  };
}

export default connect(mapStateToProps)(BulkUploadRules);
