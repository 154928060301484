import { Button, Form, Input, Modal, Col, Row } from 'antd';
import React from 'react';
import { connect } from 'dva';
import {
  ADSTRACER_MODAL_LOADTEST,
  ADSTRACER_MODAL_LOADTEST_BUTTON_CANCEL,
  ADSTRACER_MODAL_LOADTEST_BUTTON_SUBMIT,
} from '../../../testUtils';
import { FORM_UUID_RULE } from '../../../utils';

const LoadingSharedTestCaseModal = ({ dispatch, open, setLoadingModalOpen, searchParams = {} }) => {
  const [form] = Form.useForm();

  const handleHideModal = () => {
    delete searchParams.showLoadTestCase;
    dispatch({
      type: 'app/setSearchParams',
      payload: searchParams,
    });
    setLoadingModalOpen(false);
    form.resetFields();
  };

  const onFinish = ({ testCaseIdToLoad }) => {
    dispatch({ type: 'adsTracer/acceptSharedTestCase', payload: testCaseIdToLoad });
    handleHideModal();
  };

  return (
    <Modal title="Enter Shared Test Case ID" open={open} footer={null} onCancel={handleHideModal}>
      <Form form={form} onFinish={onFinish} data-testid={ADSTRACER_MODAL_LOADTEST}>
        <Form.Item
          name="testCaseIdToLoad"
          rules={[{ required: true, message: 'Please input Shared Test Case ID' }, FORM_UUID_RULE]}
        >
          <Input addonBefore="Test Case ID:" placeholder="Shared Test Case ID" />
        </Form.Item>
        <Row gutter={8} justify="end">
          <Col>
            <Button key="cancel" onClick={handleHideModal} data-testid={ADSTRACER_MODAL_LOADTEST_BUTTON_CANCEL}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button key="submit" type="primary" htmlType="submit" data-testid={ADSTRACER_MODAL_LOADTEST_BUTTON_SUBMIT}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    searchParams: state.app.searchParams,
  };
}

export default connect(mapStateToProps)(LoadingSharedTestCaseModal);
