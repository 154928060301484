import moment from 'moment';
import { connect } from 'dva';
import { useState } from 'react';
import { Button, Spin, Steps } from 'antd';
import { RESERVATION_TOOL } from '../../constants';
import { StepsWrapper, StyledButton, StyledCard, ContentPartnerSelect, SeriesSelect } from './styles';
import {
  RESERVATION_TOOL_RESERVE_SERIES_FIRST_STEP,
  RESERVATION_TOOL_RESERVE_SERIES_FIRST_STEP_SELECT,
  RESERVATION_TOOL_RESERVE_SERIES_SECOND_STEP,
  RESERVATION_TOOL_RESERVE_SERIES_SECOND_STEP_PICKER,
  RESERVATION_TOOL_RESERVE_SERIES_THIRD_STEP,
  RESERVATION_TOOL_RESERVE_SERIES_THIRD_STEP_PICKER,
} from '../../testUtils';
import { MomentDatePicker } from '../../utils';

const { Step } = Steps;
const { MonthPicker } = MomentDatePicker;

const ReserveSeries = ({
  dispatch,
  pageInitLoading,
  nextStepLoading,
  contentPartnerOptions = [],
  selectedContentPartnerId,
  selectedMonth,
  numReservationsAllowed,
  seriesOptions = [],
  selectedSeriesIds = [],
  contentStep = 0,
}) => {
  const [step, setStep] = useState(contentStep ?? 0);

  const steps = [
    {
      title: 'Select Content Partner',
      content: () => (
        <Spin spinning={pageInitLoading}>
          <StyledCard
            title="Select a content partner to begin:"
            data-testid={RESERVATION_TOOL_RESERVE_SERIES_FIRST_STEP}
          >
            <ContentPartnerSelect
              placeholder="Select content partner"
              defaultValue={selectedContentPartnerId}
              onChange={(contentPartner) =>
                dispatch({
                  type: 'reservationTool/selectContentPartner',
                  payload: { contentPartner, toolType: RESERVATION_TOOL },
                })
              }
              data-testid={RESERVATION_TOOL_RESERVE_SERIES_FIRST_STEP_SELECT}
              options={contentPartnerOptions}
            />
          </StyledCard>
        </Spin>
      ),
    },
    {
      title: 'Select Month',
      content: () => (
        <Spin spinning={nextStepLoading}>
          <StyledCard title="Select current or next month:" data-testid={RESERVATION_TOOL_RESERVE_SERIES_SECOND_STEP}>
            <MonthPicker
              defaultPickerValue={moment().add(1, 'M')}
              format="YYYY/MM"
              defaultValue={selectedMonth}
              onChange={(month) =>
                dispatch({
                  type: 'reservationTool/updateSelectedTime',
                  payload: { time: month, toolType: RESERVATION_TOOL },
                })
              }
              disabledDate={(date) =>
                date.isBefore(moment().startOf('month')) || date.isAfter(moment().add(1, 'M').endOf('month'))
              }
              data-testid={RESERVATION_TOOL_RESERVE_SERIES_SECOND_STEP_PICKER}
            />
          </StyledCard>
        </Spin>
      ),
    },
    {
      title: 'Select Series',
      content: () => (
        <Spin spinning={nextStepLoading}>
          <StyledCard
            title={`Select up to ${numReservationsAllowed} series:`}
            data-testid={RESERVATION_TOOL_RESERVE_SERIES_THIRD_STEP}
          >
            <SeriesSelect
              mode="multiple"
              placeholder="Select series"
              defaultValue={selectedSeriesIds}
              onChange={(series) =>
                dispatch({
                  type: 'reservationTool/updateSeriesSelection',
                  payload: { series, toolType: RESERVATION_TOOL },
                })
              }
              data-testid={RESERVATION_TOOL_RESERVE_SERIES_THIRD_STEP_PICKER}
              options={seriesOptions}
            />
          </StyledCard>
        </Spin>
      ),
    },
  ];

  const handleNext = () => {
    dispatch({
      type: 'reservationTool/nextStep',
      payload: { step, toolType: RESERVATION_TOOL },
    }).then((isValidated) => {
      if (isValidated) setStep(step === steps.length - 1 ? 0 : step + 1);
    });
  };

  return (
    <StepsWrapper>
      <Steps current={step}>
        {steps.map(({ title }) => (
          <Step key={title} title={title} />
        ))}
      </Steps>

      {steps[step].content()}

      {step > 0 && <Button onClick={() => setStep(step - 1)}>Back</Button>}

      <StyledButton type="primary" onClick={handleNext}>
        {step === steps.length - 1 ? 'Submit' : 'Next'}
      </StyledButton>
    </StepsWrapper>
  );
};

function mapStateToProps({
  loading,
  reservationTool: {
    [RESERVATION_TOOL]: {
      selectedTime: selectedMonth,
      contentPartnerData: { selected: selectedContentPartnerId, options: contentPartnerOptions },
      seriesData: { numReservationsAllowed, selected: selectedSeriesIds, options: seriesOptions },
    },
  },
}) {
  return {
    pageInitLoading: loading.effects['reservationTool/pageInit'],
    nextStepLoading: loading.effects['reservationTool/nextStep'],
    contentPartnerOptions,
    selectedContentPartnerId,
    selectedMonth,
    numReservationsAllowed,
    seriesOptions,
    selectedSeriesIds,
  };
}

export default connect(mapStateToProps)(ReserveSeries);
