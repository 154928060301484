import { useState, useEffect, useCallback } from 'react';

import { openNotificationWithIcon } from '../notifications';

import { BAD_REQUEST, SERVER_ERROR } from '../../../constants';
import { MY_PROFILES_FILTER, OTHER_PROFILES_FILTER } from '../constants';
import { getProfiles, deleteProfile } from '../../../services/auctionSnapshot';
import { useProfilesController, INITIAL_PROFILES_STATE } from './useProfilesController';

const INITIAL_TITLE = 'Auction Snapshot Profiles';

export const useProfiles = () => {
  const {
    profiles,
    profilesState,
    pagination,
    loading,
    handleProfiles,
    handleProfilesState,
    handleLoading,
    handleError,
    onPaginationChange,
    onTableChange,
    resetProfileState,
  } = useProfilesController();

  const {
    profiles: otherProfiles,
    profilesState: otherProfilesState,
    pagination: otherProfilesPagination,
    loading: otherProfilesLoading,
    handleProfiles: handleOtherProfiles,
    handleProfilesState: handleOtherProfilesState,
    handleLoading: handleOtherLoading,
    handleError: handleOtherError,
    onPaginationChange: onOtherPaginationChange,
    onTableChange: onOtherTableChange,
    resetProfileState: resetOtherProfileState,
  } = useProfilesController();

  const [search, setSearch] = useState(null);

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState(INITIAL_TITLE);

  const [isProfileFormOpen, setIsProfileFormOpen] = useState(false);
  const [isDeleteProfileOpen, setIsDeleteProfileOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isShowAllProfiles, setIsShowAllProfiles] = useState(false);

  const { page, size } = profilesState;

  const fetchProfiles = useCallback(async (profilesState, handleProfilesSet, handleError, handleLoading) => {
    const { page, size, search, user, sort } = profilesState;
    const orderPartToRemove = 'end';
    const sortToSend = `${sort[0]},${sort[1].slice(0, -orderPartToRemove.length)}`;
    try {
      handleLoading(true);
      const data = await getProfiles({ page, size, search, user, sort: sortToSend });
      handleProfilesSet(data);
      handleLoading(false);
    } catch (err) {
      console.error('GET profiles failed with:', err.message || err);
      handleError(err);
      handleLoading(false);
    }
  }, []);

  const onDeleteProfile = async () => {
    try {
      await deleteProfile({ deleteProfileId: selectedProfile.id });
      const pageToSet = !profiles.first && profiles.totalElements - 1 <= (page - 1) * size ? page - 1 : page;
      handleProfilesState((prevState) => ({
        ...prevState,
        page: pageToSet,
      }));

      openNotificationWithIcon(
        'success',
        `"${selectedProfile.label}" profile with "${selectedProfile.inputValue}" Device ID was successfully deleted.`,
        true
      );
    } catch (err) {
      if (err.response.status === BAD_REQUEST) {
        openNotificationWithIcon(
          'error',
          `"${selectedProfile.label}" profile with "${selectedProfile.inputValue}" Device ID deletion failed.`,
          true
        );
      }
      if (err.response.status >= SERVER_ERROR) {
        openNotificationWithIcon('error', `Something went wrong, please try again later.`, true);
      }
    } finally {
      setSelectedProfile(null);
      onCloseProfileDeleteModal();
    }
  };

  const openNewProfile = () => {
    setDrawerTitle('Create Auction Snapshot Profile');
    setIsProfileFormOpen(true);
  };
  const openUpdateProfile = (profile) => {
    setDrawerTitle('Update Auction Snapshot Profile');
    setSelectedProfile(profile);
    setIsProfileFormOpen(true);
  };
  const closeProfileForm = () => {
    setDrawerTitle(INITIAL_TITLE);
    setIsProfileFormOpen(false);
    setSelectedProfile(null);
  };

  const onOpenProfileDeleteModal = (profile) => {
    setSelectedProfile(profile);
    setIsDeleteProfileOpen(true);
  };
  const onCloseProfileDeleteModal = () => {
    setIsDeleteProfileOpen(false);
    setSelectedProfile(null);
  };

  const onToggleMyProfiles = (checked) => {
    setIsShowAllProfiles(checked);
    if (!checked) {
      resetOtherProfileState();
    }
  };

  const onSearch = (query) => {
    setSearch(query);
    handleProfilesState((prevState) => ({
      ...prevState,
      search: query || INITIAL_PROFILES_STATE.search,
      page: INITIAL_PROFILES_STATE.page,
    }));
    handleOtherProfilesState((prevState) => ({
      ...prevState,
      search: query || INITIAL_PROFILES_STATE.search,
      page: INITIAL_PROFILES_STATE.page,
    }));
  };

  const closeProfiles = () => {
    resetProfileState();
    resetOtherProfileState();
  };

  const handleVisibleChange = (visible) => setIsVisible(visible);

  useEffect(() => {
    if (isVisible) {
      fetchProfiles(
        {
          ...profilesState,
          user: MY_PROFILES_FILTER,
          page: profilesState.page - 1,
        },
        handleProfiles,
        handleError,
        handleLoading
      );
    }
  }, [isVisible, profilesState, fetchProfiles, handleProfiles, handleError, handleLoading]);

  useEffect(() => {
    if (isShowAllProfiles) {
      fetchProfiles(
        {
          ...otherProfilesState,
          user: OTHER_PROFILES_FILTER,
          page: otherProfilesState.page - 1,
        },
        handleOtherProfiles,
        handleOtherError,
        handleOtherLoading
      );
    }
  }, [isShowAllProfiles, otherProfilesState, fetchProfiles, handleOtherProfiles, handleOtherError, handleOtherLoading]);

  const profilesControl = {
    profiles,
    pagination,
    loading,
    handleProfilesState,
    handleLoading,
    onPaginationChange,
    onTableChange,
    openUpdateProfile,
    onOpenProfileDeleteModal,
  };
  const otherProfilesControl = {
    otherProfiles,
    otherProfilesPagination,
    otherProfilesLoading,
    onOtherPaginationChange,
    onOtherTableChange,
  };

  return {
    drawerTitle,
    isProfileFormOpen,
    isDeleteProfileOpen,
    selectedProfile,
    isShowAllProfiles,
    search,
    profilesControl,
    otherProfilesControl,

    openNewProfile,
    closeProfileForm,
    onCloseProfileDeleteModal,
    onDeleteProfile,
    onToggleMyProfiles,
    onSearch,
    handleLoading,
    closeProfiles,
    handleVisibleChange,
  };
};
